import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconDone } from "assets/icons/waiter/Done.svg";

import "./DoneButton.scss";

export const ENUMS = {
  name: "DoneButton",
};

const DoneButton = ({ onClick }) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      type="button"
      onClick={(e) => handleOnClick(e)}
      className="DoneButton"
    >
      <IconDone className="DoneButtonIcon" />
    </button>
  );
};

DoneButton.propTypes = {
  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,
};

export default DoneButton;
