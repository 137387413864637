import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "react-calendar/dist/Calendar.css";
import Modal from "components/modal/Modal";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import ReservationForm from "components/reservation-form/ReservationForm";
import FooterButtons from "components/admin/elements/footer-buttons/FooterButtons";
import CloseButton from "components/buttons/close-button/CloseButton";
import { EDITOR_TYPE } from "pages/admin/data/constants/general";
import PrimaryButton, {
  ENUMS as ENUMS_PRIMARY_BUTTON,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useAsync from "utils/hooks/useAsync";
import {
  createReservationAdmin,
  deleteReservation,
  updateReservationAdmin,
} from "utils/api/services/reservation";
import { STORE_NAMES } from "utils/constants/redux";
import { findTableByReservationId } from "utils/general";
import {
  commonAsyncErrorMessage,
  RESERVATION_STATUS,
} from "utils/constants/data/base";
import {
  getDestructuredReservation,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { getZonesAsync } from "redux/actions/zoneAction";

import "./AdminReservationEditorModal.scss";

const ReservationTimer = ({ requestDateTime }) => {
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    const updateTimer = () => {
      const requestDate = new Date(requestDateTime);
      const currentTime = new Date();
      const timeDifference = currentTime - requestDate;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setElapsedTime(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    };

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, [requestDateTime]);

  return <span>{elapsedTime}</span>;
};

const AdminReservationEditorModal = ({
  mainElementRef,
  openSlide,
  setOpenSlide,
  title,
  formType,
  data,
}) => {
  const dispatch = useDispatch();
  const { execute: executeDeleteReservation } = useAsync(deleteReservation, {
    onError: () => handleOnAsyncErrorForReservation(),
    onSuccess: () => {
      dispatch(getZonesAsync({ businessId }));
      handleOnAsyncSuccessForReservation(
        t("toastMessages.success.deleteReservation")
      );
    },
  });
  const { execute: executeUpdateReservation } = useAsync(
    updateReservationAdmin,
    {
      onError: () => handleOnAsyncErrorForReservation(),
      onSuccess: () => {
        handleCloseModal();
        handleOnAsyncSuccessForReservation(
          t("toastMessages.success.updateReservation")
        );
      },
    }
  );
  const { execute: executeCreateReservation } = useAsync(
    createReservationAdmin,
    {
      onError: () => handleOnAsyncErrorForReservation(),
      onSuccess: () => {
        handleCloseModal();
        handleOnAsyncSuccessForReservation(
          t("toastMessages.success.createReservation")
        );
      },
    }
  );
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const initialFormData = {
    guestName: "",
    guestNumber: "",
    note: "",
    guestsCount: 1,
    requestDateTime: null,
    reservationDay: new Date().toDateString(),
    startTime: "10:00",
    endTime: "11:00",
    tableId: null,
  };
  const allTables = zones?.flatMap((zone) => zone.tables);
  const [formData, setFormData] = useState(initialFormData);
  const { t } = useTranslation();

  const formatTimeToOptionsFormat = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad single-digit hours and minutes with leading zeros
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  useEffect(() => {
    if (data) {
      const { startDateTime, endDateTime, ...otherData } = data;
      const updatedTableId = findTableByReservationId(data?.id, allTables)?.id;

      // Extract day, month, and hours from startDateTime
      const startDateTimeObj = new Date(startDateTime);

      setFormData({
        reservationDay: startDateTimeObj.toLocaleDateString(),
        startTime: formatTimeToOptionsFormat(startDateTime),
        endTime: formatTimeToOptionsFormat(endDateTime),
        tableId: updatedTableId,
        ...otherData,
      });
    } else {
      setFormData(initialFormData);
    }
  }, [data, openSlide]);

  const isFormValid = () => {
    return !!formData?.guestName && !!formData?.guestNumber;
  };

  const handleOnAsyncErrorForReservation = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const handleOnAsyncSuccessForReservation = (successMessage) => {
    handleOnAsyncSuccess(successMessage);
  };

  const handleCloseModal = () => {
    setOpenSlide(false);
  };

  const handleOnClickCancel = async (reservation) => {
    await executeDeleteReservation(businessId, reservation.id);
    handleCloseModal();
  };

  const handleOnClickConfirm = async (reservation) => {
    const destructuredReservation = getDestructuredReservation(formData);
    const updatedReservation = {
      ...destructuredReservation,
      status: RESERVATION_STATUS.CONFIRMED,
    };
    await executeUpdateReservation(
      businessId,
      updatedReservation,
      reservation.id
    );
    handleCloseModal();
  };

  const handleOnDeleteReservation = async (reservation) => {
    await executeDeleteReservation(businessId, reservation.id);
    handleCloseModal();
  };

  const handleOnSaveReservation = async (reservation) => {
    const updatedReservation = getDestructuredReservation(reservation);

    if (reservation.id) {
      await executeUpdateReservation(
        businessId,
        updatedReservation,
        reservation.id
      );
    } else {
      await executeCreateReservation(businessId, updatedReservation);
    }
  };

  const reservationModalHead = (
    <div className="ReservationEditorModalHeader">
      <div className="ReservationEditorModalHeaderTitle">
        <h3 className="SemiBold">{title}</h3>
        {formType === EDITOR_TYPE.CONFIRM && (
          <ReservationTimer requestDateTime={formData?.requestDateTime} />
        )}
      </div>

      <div className="ReservationEditorModalControls">
        {formType === EDITOR_TYPE.EDIT && (
          <DeleteButton onClick={() => handleOnDeleteReservation(formData)} />
        )}
        <CloseButton onClick={handleCloseModal} />
      </div>
    </div>
  );

  const reservationModalBody = (
    <div className="ReservationEditorModalBody">
      <ReservationForm onDataUpdate={setFormData} data={formData} />
    </div>
  );

  const AdminReservationEditorModalFooter = (
    <>
      {formType === EDITOR_TYPE.CONFIRM ? (
        <FooterButtons
          onCancel={() => handleOnClickCancel(formData)}
          onConfirm={() => handleOnClickConfirm(formData)}
          cancelButtonText={t("buttons.deny")}
          confirmButtonText={t("buttons.confirm")}
          cancelButtonType={ENUMS_PRIMARY_BUTTON.types.TYPE_D}
        />
      ) : (
        <PrimaryButton
          onClick={() => handleOnSaveReservation(formData)}
          text={t("buttons.save")}
          disabled={!isFormValid()}
        />
      )}
    </>
  );

  return (
    <div className="AdminReservationModal">
      <Modal
        header={reservationModalHead}
        body={reservationModalBody}
        footer={AdminReservationEditorModalFooter}
        mainElementRef={mainElementRef}
        openSlide={openSlide}
      />
    </div>
  );
};

AdminReservationEditorModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,
  /**
   * An object containing data for the reservation.
   */
  data: PropTypes.object,
  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,
  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,
  /**
   * The title of the modal
   */
  title: PropTypes.string,
  /**
   * The type of the editor
   */
  formType: PropTypes.oneOf(Object.values(EDITOR_TYPE)).isRequired,
};

ReservationTimer.propTypes = {
  requestDateTime: PropTypes.string,
};

export default AdminReservationEditorModal;
