import { useDispatch, useSelector } from "react-redux";
import { setDarkMode, updateReduxVersion } from "redux/slices/appStore";
import { STORE_NAMES } from "utils/constants/redux";
import { resetAllReduxStores } from "utils/general";

function useReduxVersioning() {
  const dispatch = useDispatch();
  const currentReduxVersion = useSelector(
    (state) => state[STORE_NAMES.app].reduxVersion
  );
  const newReduxVersion = parseFloat(process.env.REACT_APP_REDUX_VERSION);
  const isDarkMode = useSelector((state) => state[STORE_NAMES.app].isDarkMode);

  if (
    newReduxVersion &&
    (!currentReduxVersion || newReduxVersion !== currentReduxVersion)
  ) {
    resetAllReduxStores(dispatch);
    dispatch(setDarkMode(isDarkMode));
    dispatch(updateReduxVersion(newReduxVersion));
  }
}

export default useReduxVersioning;
