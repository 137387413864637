import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Category from "components/elements/category/Category";
import SmallButton, {
  ENUMS as SMALL_BUTTON_ENUMS,
} from "components/buttons/small-button/SmallButton";
import MenuCategories from "pages/client/menu/menu-category/MenuCategories";
import MenuItemSelected from "pages/client/menu-item-selected/MenuItemSelected";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as IconSettings } from "assets/icons/filter/Settings.svg";
import ICON_EMPTY_MENU from "assets/icons/menu/guest-empty-menu.svg";
import {
  filterCategoryByIsPublishedAndSchedule,
  filterMenuItemByIsPublishedAndSchedule,
  sortCategoriesAndMenuItems,
} from "utils/general";
import EmptyState from "components/admin/empty-state/EmptyState";
import { resetMenuFilters, setFilterOptions } from "redux/slices/guestStore";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import { QUERY_PARAMS } from "utils/constants/routes";
import useLanguage from "utils/hooks/useLanguage";
import { ImageVisibilityProvider } from "utils/context-api/ImageVisibilityContext";

import "./MenuDisplay.scss";

export const ENUMS = {
  types: {
    GUEST: "GUEST",
    ADMIN: "ADMIN",
  },
};

const MenuDisplay = ({
  menuViewType,
  onClick,
  onAdd,
  distanceLeft,
  type,
  mostExpensivePrice,
  hasSearchInput = false,
  isOrderSection = false,
  menu,
  scrollElement,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const rawCategories =
    filterCategoryByIsPublishedAndSchedule(menu?.categories) || [];
  const initCategories = useMemo(
    () => sortCategoriesAndMenuItems(rawCategories, "placeInTheList"),
    [menu]
  );
  const [categories, setCategories] = useState(initCategories);
  const [activeCategory, setActiveCategory] = useState(categories[0]?.id);
  const { isLoading } = useSelector((state) => state[STORE_NAMES.menu]);
  const categoriesRef = useRef(null);
  const menuRef = useRef(null);
  const menuItemSelectedRef = useRef(null);
  const [opacity, setOpacity] = useState(0);
  const [categoriesTopDistance, setCategoriesTopDistance] = useState(20);
  const { priceRange } = useSelector(
    (state) => state[STORE_NAMES.guest].menuFilters
  );
  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedMenuItemId, setSelectedMenuItemId] = useState(
    searchParams.get(QUERY_PARAMS.selectedItem) || null
  );
  useEffect(() => {
    setCategories(initCategories);
  }, [initCategories]);

  const handleCategoryOnClick = (item) => {
    const anchorTarget = document.getElementById(item.id);
    const topPosition = anchorTarget.offsetTop - menuRef.current.clientHeight;
    if (isOrderSection) {
      return categoriesRef.current.scrollTo({
        top: topPosition - 140,
        behavior: "smooth",
      });
    }
    scrollElement.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  };
  const handleScroll = () => {
    const categoriesTopDistance =
      categoriesRef.current?.getBoundingClientRect().top;
    setOpacity(Math.min(1, Math.max(0, 1 - categoriesTopDistance / 20)));
    setCategoriesTopDistance(
      Math.max(0, categoriesTopDistance - menuRef.current?.clientHeight)
    );
  };

  const handleOnResetFilter = () => {
    dispatch(resetMenuFilters());
    dispatch(
      setFilterOptions({
        priceRange: {
          min: 0,
          max: mostExpensivePrice,
        },
        selectedTags: [],
      })
    );
    scrollElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (isOrderSection) {
      return categoriesRef?.current?.addEventListener("scroll", handleScroll);
    }
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
      if (isOrderSection) {
        return categoriesRef?.current?.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, [scrollElement]);

  const isGuest = type === ENUMS.types.GUEST;
  const isAdmin = type === ENUMS.types.ADMIN;
  const isStateEmpty =
    categories.length === 0 &&
    priceRange.min === 0 &&
    priceRange.max >= mostExpensivePrice;

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };
  const { displayDataByLanguage } = useLanguage();

  const filteredCategoryId = initCategories
    .flatMap((category) =>
      filterMenuItemByIsPublishedAndSchedule(
        category.menuItems,
        category.id,
        displayDataByLanguage,
        searchValue
      )
    )
    .flatMap((menuItem) => menuItem.categoryId);

  useEffect(() => {
    const lowerCasedSearchValue = searchValue.trim().toLowerCase();

    const matchesSearch = (category) => {
      const categoryName = displayDataByLanguage(category?.name).toLowerCase();
      return categoryName.includes(lowerCasedSearchValue);
    };

    const filterCategories = (categories) => {
      return categories.filter((category) => {
        if (matchesSearch(category)) {
          return filterMenuItemByIsPublishedAndSchedule(
            category.menuItems,
            category.id
          );
        }
        return false;
      });
    };

    if (lowerCasedSearchValue.length > 0) {
      const filteredCategoriesByMenuItems = initCategories.filter((category) =>
        filteredCategoryId.includes(category.id)
      );

      const filteredCategoriesByCategory = filterCategories(initCategories);

      setCategories(
        filteredCategoriesByCategory.length > 0
          ? filteredCategoriesByCategory
          : filteredCategoriesByMenuItems
      );
    } else {
      setCategories(initCategories);
    }
  }, [searchValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuItemSelectedRef.current &&
        !menuItemSelectedRef.current.contains(event.target)
      ) {
        setSelectedMenuItemId(null);
        searchParams.delete(QUERY_PARAMS.selectedItem);
        setSearchParams(searchParams);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isLoading) return;

  if (isStateEmpty)
    return (
      <div className="MenuDisplayNoResult">
        <EmptyState
          title={t("menu.category.emptyGuestMenuTitle")}
          description={t("menu.category.emptyGuestMenuDescription")}
          icon={ICON_EMPTY_MENU}
          isAdmin={isAdmin}
        />
      </div>
    );

  if (categories.length === 0 && isGuest)
    return (
      <div className="MenuDisplayNoResult">
        <IconSettings />
        <h2 className="MenuDisplayNoResultTitle Medium">
          {t("menu.filter.noResult")}
        </h2>
        <h5 className="MenuDisplayNoResultDescription Regular">
          {t("menu.filter.noResultDesc")}
        </h5>

        <SmallButton
          name={t("buttons.clearFilters")}
          onClick={handleOnResetFilter}
          type={SMALL_BUTTON_ENUMS.types.TYPE_MEDIUM_UNDERLINED}
        />
      </div>
    );
  return (
    <ImageVisibilityProvider>
      <div
        className={cx("MenuDisplay", {
          isGuest: isGuest,
          isAdmin: isAdmin,
        })}
      >
        <div
          className="MenuDisplayCategorySelectionSection"
          style={
            isGuest
              ? {
                  top: categoriesTopDistance,
                  opacity: opacity,
                  pointerEvents: opacity > 0 ? "auto" : "none",
                }
              : {}
          }
          ref={menuRef}
        >
          <Category
            activeCategoryId={activeCategory}
            items={categories}
            onClick={handleCategoryOnClick}
            distanceLeft={distanceLeft}
            isMultiLanguage
            isOrderSection={isOrderSection}
          />
          {hasSearchInput && (
            <Search
              onChange={(value) => {
                handleSearchChange(value);
              }}
              value={searchValue}
              type={SEARCH_ENUMS.types.TYPE_B}
            />
          )}
        </div>
        <div className="MenuDisplayCategory" ref={categoriesRef}>
          {categories.map((category, index) => {
            return (
              <MenuCategories
                key={index}
                category={category}
                menuViewType={menuViewType}
                setActiveCategory={setActiveCategory}
                onClick={onClick}
                onAdd={onAdd}
                isAdmin={isAdmin}
                searchValue={searchValue}
                isOrderSection={isOrderSection}
                categoriesRef={categoriesRef}
                scrollElement={scrollElement}
                categories={categories}
                setSearchParams={setSearchParams}
                setSelectedMenuItemId={setSelectedMenuItemId}
              />
            );
          })}
        </div>
        {selectedMenuItemId && (
          <div className="MenuItemSelectedContainerOverlay">
            <MenuItemSelected
              menuItemId={selectedMenuItemId}
              setSelectedMenuItemId={setSelectedMenuItemId}
              setSearchParams={setSearchParams}
              menuItemSelectedRef={menuItemSelectedRef}
            />
          </div>
        )}
      </div>
    </ImageVisibilityProvider>
  );
};

MenuDisplay.propTypes = {
  /**
   * The id of menu view type to display (e.g., SCROLL, GRID, etc.).
   */
  menuViewType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * The onClick function to be called when a specific action is triggered.
   */
  onClick: PropTypes.func,

  /**
   * The onAdd function to be called when adding an item.
   */
  onAdd: PropTypes.func,

  /**
   * The distance left for padding
   */
  distanceLeft: PropTypes.number,

  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The most expensive price of menu
   */
  mostExpensivePrice: PropTypes.number,

  hasSearchInput: PropTypes.bool,

  isOrderSection: PropTypes.bool,

  menu: PropTypes.object,

  scrollElement: PropTypes.object,
};

MenuDisplay.defaultProps = {
  menuViewType: MENU_VIEW_ENUMS.SCROLL.id,
};

export default MenuDisplay;
