import React from "react";
import PropTypes from "prop-types";
import StatsNumberData from "components/admin/charts/line-chart/StatsNumberData";

import "./StatsNumberDataWrapper.scss";

const StatsNumberDataWrapper = ({ data }) => {
  return (
    <div className="StatsNumberDataWrapper">
      {data?.map((dataItem, index) => {
        return (
          <StatsNumberData
            key={index}
            title={dataItem.title}
            amount={dataItem.amount}
          />
        );
      })}
    </div>
  );
};

StatsNumberDataWrapper.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StatsNumberDataWrapper;
