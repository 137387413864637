import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import Modal from "components/modal/Modal";
import IconButton from "components/buttons/icon-button/IconButton";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import { STORE_NAMES } from "utils/constants/redux";

import "./AdminBusinessModal.scss";

const AdminBusinessModal = ({
  mainElementRef,
  openSlide,
  setOpenSlide,
  businessToEdit,
  onSave,
  onDelete,
  isLoading,
}) => {
  const isEditable = !!businessToEdit;
  const { t } = useTranslation();

  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);

  const schemas = useValidationSchema(t);

  const methods = useForm({
    resolver: zodResolver(schemas.businessModalSchema(signInMethod, isEditable)),
    criteriaMode: "all",
  });

  const { register, reset, handleSubmit, control, getValues, formState: { errors }, } = methods;

  const { plans } = useSelector((state) => state[STORE_NAMES.app]);

  useEffect(() => {
    if (businessToEdit && openSlide) {
      reset(businessToEdit);
      setSignInMethod(businessToEdit?.phoneNumber ? SIGN_IN_METHOD.phoneNumber : SIGN_IN_METHOD.email);
    } else {
      reset({});
      setSignInMethod(SIGN_IN_METHOD.phoneNumber);
    }
  }, [businessToEdit, openSlide, reset]);

  const handleOnSave = () => {
    const formData = getValues();
    if (formData.phoneNumber) {
      formData.phoneNumber = formData.phoneNumber.replace(/\+/g, "");
    }

    onSave(
      formData,
      businessToEdit?.id,
      signInMethod,
    );
  };

  const AddBusinessModalHeader = (
    <div className="AdminBusinessModalTitle">
      <h3 className="SemiBold">
        {businessToEdit ? t("business.edit") : t("business.addNew")}
      </h3>
      <div className="AdminBusinessModalTitleRight">
        {isEditable && (
          <DeleteButton onClick={() => onDelete(businessToEdit.id)} />
        )}
        <IconButton
          onClick={() => setOpenSlide(false)}
          svgComponent={<IconClose />}
        />
      </div>
    </div>
  );

  const AdminBusinessModalBody = (
    <div className="AdminBusinessModalBody">
      <form className="AdminBusinessModalForm">
        <InputControl
          {...register("name")}
          placeholder={t("inputs.businessName")}
          type="text"
          readOnly={isEditable}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
        />
        <Controller
          name="plan"
          control={control}
          defaultValue={businessToEdit?.plan || {}}
          render={({ field: { value, onChange } }) => (
            <Dropdown
              options={plans}
              className="SuperAdminHeaderDropdown"
              onChange={onChange}
              placeholder={t("business.plan")}
              value={value}
              name="plan"
              isOptionRequired
              required
              error={
                <ErrorMessage
                  errors={errors}
                  name="plan"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
              hasError={errors.plan}
            />
          )}
        />
        {!isEditable && (
          <>
            <InputControl
              {...register("firstName")}
              placeholder={t("inputs.firstName")}
              type="text"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              error={
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
              hasError={errors.firstName}
              required
            />
            <InputControl
              {...register("lastName")}
              placeholder={t("inputs.lastName")}
              type="text"
              labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
              error={
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
              hasError={errors.lastName}
              required
            />
          </>
        )}
        <FormProvider {...methods}>
          <EmailOrPhone
            signInMethod={signInMethod}
            setSignInMethod={setSignInMethod}
            readOnly={isEditable}
          />
        </FormProvider>
      </form>
      {/* {isEditable && (
        <div className="AdminBusinessVerificationContainer">
          <h6 className="Medium">{t("business.businessStatus")}</h6>
          <div className="AdminBusinessVerificationStatus">
            <p className="h7 Medium AdminBusinessVerificationStatusText">
              {t("business.unverified")}
            </p>
            <Switch
              isChecked={false}
              onChange={null}
            />
          </div>
        </div>
      )
      } */}
    </div>
  );

  const AdminBusinessModalFooter = (
    <div className={"AdminBusinessModalFooter"}>
      <PrimaryButton
        onClick={handleSubmit(handleOnSave)}
        // isDisabled={!!businessToEdit}
        text={t("buttons.save")}
        isLoading={isLoading}
      />
    </div>
  );

  return (
    <Modal
      header={AddBusinessModalHeader}
      body={AdminBusinessModalBody}
      footer={AdminBusinessModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AdminBusinessModal.propTypes = {
  /**
   * The ref for the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The currently selected business to edit
   */
  businessToEdit: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the data.
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item.
   */
  onDelete: PropTypes.func,

  /**
   * Flag to determine if the form is loading
   */
  isLoading: PropTypes.bool,
};

export default AdminBusinessModal;
