import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BasketHeroSection from "pages/client/basket/basket-hero-section/BasketHeroSection";
import { STORE_NAMES } from "utils/constants/redux";
import {
  findMenuItemByIdAndPublished,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateServiceFee,
  calculateTax,
  findItemById,
} from "utils/general";
import {
  ACTIONS,
  resetItems,
  updateBasket,
  updateFavorites,
  updateOrderMessage,
} from "redux/slices/basketStore";
import { setLoading } from "redux/slices/ordersStore";
import OrderInfo from "components/cards/order-info/OrderInfo";
import BasketItem from "components/elements/basket-item/BasketItem";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import InputControl from "components/admin/forms/input-control/InputControl";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useAsync from "utils/hooks/useAsync";
import { updateOrderGuest } from "utils/api/services/order";
import EmptyState from "components/admin/empty-state/EmptyState";
import ICON_EMPTY_BASKET from "assets/icons/basket/empty-basket.svg";
import WelcomeClient from "components/welcome-client/WelcomeClient";

import "./Basket.scss";

const Basket = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { id: businessId } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const isLoadingOrder = useSelector(
    (state) => state[STORE_NAMES.orders]?.thunkAPIStates?.getAllOrder
  );
  const { execute: executeUpdateOrder } = useAsync(updateOrderGuest, {
    onSuccess: () => {
      const queryString = new URLSearchParams({
        ...searchParams,
        [QUERY_PARAMS.showConfetti]: true,
      }).toString();
      navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}?${queryString}`
      );
      dispatch(
        resetItems({
          userId: guestId,
          actionType: ACTIONS.resetOrderItems,
        })
      );
    },
  });
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const basketOrder = useSelector((state) => state[STORE_NAMES.basket].order);
  const tableId = useSelector((state) => state[STORE_NAMES.qrScan].table.id);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { data: menu, isLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );

  const [subtotal, setSubtotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = orders?.find((order) => order.table.id === tableId);
  const guests = basketOrder ? basketOrder.guests : [];
  const guest = guests.find((guest) => guest.person.id === guestId);
  const guestInOrder = order?.guests.find(
    (guest) => guest.person.id === guestId
  );
  const isOrderEmpty = !order;
  const favoriteItems =
    menu && guest
      ? guest?.favoriteItems.map((id) => findItemById(id, menu))
      : [];

  const orderItems = guests.flatMap((guest) => guest.orderItems);
  const orderItemsCount = orderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );
  const isBasketEmpty = orderItemsCount === 0;

  const tax = menu ? calculateTax(subtotal, menu.extraFees.tax) : 0;
  const serviceFeeTotal = menu
    ? calculateServiceFee(subtotal, discountPrice, serviceFee)
    : 0;

  useEffect(() => {
    const convertBasketItems = guests.map((guest) => {
      if (guest.orderItems.length > 0) {
        return {
          ...guest,
          orderItems: guest.orderItems.map((orderItem) => {
            return {
              ...orderItem,
              item: findMenuItemByIdAndPublished(
                menu.categories,
                orderItem.item,
                dispatch,
                guest.id
              ),
            };
          }),
        };
      }
      return guest;
    });

    setSubtotal(
      orderItems.length > 0 ? calculateAllOrdersPrice(convertBasketItems) : 0
    );
    setDiscountPrice(
      orderItems.length > 0
        ? calculateAllOrdersDiscountPrice(convertBasketItems)
        : 0
    );
    menu &&
      setTotalPrice(
        calculateAllOrdersTotalPrice(
          subtotal,
          menu.extraFees,
          null,
          serviceFeeTotal,
          discountPrice
        )
      );
  }, [orderItems]);

  const handleRemoveFavItem = (e, menuItem) => {
    e.stopPropagation();
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const handleAddFavItem = (e, menuItem) => {
    e.stopPropagation();
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    );
    const filteredModifications = modifications.filter((modification) => {
      return modification.options.length !== 0;
    });
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count: 1,
        userId: guestId,
      })
    );
    dispatch(
      updateFavorites({
        userId: guestId,
        menuItemID: menuItem.id,
      })
    );
  };

  const onBasketItemClick = (menuItemId, basketIndex) => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}/${menuItemId}?isEditMode=true&basketIndex=${basketIndex}`
    );
  };
  const handleRemoveBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: null,
        userId: guestId,
      })
    );
  };
  const handleAddBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: 1,
        userId: guestId,
      })
    );
  };
  const handleDecreaseBasketItem = (menuItem) => {
    dispatch(
      updateBasket({
        menuItem: {
          ...menuItem,
        },
        count: -1,
        userId: guestId,
      })
    );
  };

  const handleConfirmOrder = async () => {
    dispatch(setLoading(true));
    const allOrderItems = [...guest.orderItems, ...guestInOrder.orderItems];

    const updatedBasketOrder = {
      ...basketOrder,
      guests: [
        {
          ...guest,
          orderMessage:
            guest.orderMessage.trim() !== "" ? guest.orderMessage : undefined,
          orderItems: allOrderItems,
        },
      ],
      hasNewOrder: true,
    };
    await executeUpdateOrder(businessId, { ...updatedBasketOrder }, order.id);
    dispatch(setLoading(false));
  };

  const handleBackToMenu = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  if (isLoading) {
    return <WelcomeClient />;
  }

  return (
    <div className={cx("Basket", { isBasketEmpty })}>
      <BasketHeroSection
        favoriteItems={favoriteItems}
        onClose={handleRemoveFavItem}
        onAdd={handleAddFavItem}
      />
      {isBasketEmpty ? (
        <EmptyState
          title={t("basket.emptyBasketTitle")}
          description={t("basket.emptyBasketDescription")}
          icon={ICON_EMPTY_BASKET}
          isAdmin={false}
        />
      ) : (
        <div className="BasketMainSection">
          <div className="OrderItemsWrapper">
            {orderItems &&
              orderItems.map((orderItem, index) => (
                <BasketItem
                  key={index}
                  menuItem={orderItem.item}
                  basketIndex={index}
                  menuCategories={menu.categories}
                  onAdd={handleAddBasketItem}
                  onRemove={handleRemoveBasketItem}
                  onClick={onBasketItemClick}
                  onDecrease={handleDecreaseBasketItem}
                  count={orderItem.count}
                  guestId={guestId}
                />
              ))}
          </div>
          <div className="AddMessageForTheRestaurant">
            <h6 className="Medium">{t("basket.addMessage")}</h6>
            <div className="AddMessageTextareaContainer">
              <InputControl
                placeholder={t("basket.messagePlaceholder")}
                value={guest?.orderMessage || ""}
                onChange={(e) =>
                  dispatch(
                    updateOrderMessage({
                      userId: guestId,
                      message: e.target.value,
                    })
                  )
                }
                noLabelFloating
                textarea
              />
            </div>
          </div>
          <OrderInfo
            serviceFee={serviceFee}
            totalPrice={totalPrice}
            subtotal={subtotal}
            tax={tax}
            serviceFeeTotal={serviceFeeTotal}
            discountPrice={discountPrice}
          />
        </div>
      )}
      <CTAButton
        className={cx("ConfirmOrderBtn", { isBasketEmpty })}
        onClick={
          !isBasketEmpty && !isOrderEmpty
            ? handleConfirmOrder
            : handleBackToMenu
        }
        name={
          !isBasketEmpty && !isOrderEmpty
            ? t("buttons.confirmOrder")
            : t("buttons.backToMenu")
        }
        type={CTA_ENUMS.types.TYPE_B}
        price={totalPrice}
        // disabled={isOrderEmpty}
        isLoading={isLoadingOrder}
      />
    </div>
  );
};

export default Basket;
