import React, { useEffect, useMemo, useRef, useState } from "react";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { useTranslation } from "react-i18next";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateMenuItemPrice,
  calculateMenuItemPriceBySchedule,
  calculateServiceFee,
  findItemById,
} from "utils/general";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import PropTypes from "prop-types";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";
import { isGuestOrUserHasAnyOrder } from "utils/helpers";
import OrderItemModificationList from "components/admin/cards/order-item-modification-list/OrderItemModificationList";
import AdminOrderGeneralInfo from "../admin-order-general-info/AdminOrderGeneralInfo";
import useLanguage from "utils/hooks/useLanguage";

import "./AdminOrderInfo.scss";

const AdminOrderInfo = ({
  guests,
  users,
  handleOnUpdateOrder,
  formData,
  pendingData,
  setPendingData,
}) => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const initialData = {
    users: [],
    rate: {
      isFixed: false,
      amount: 0,
    },
  };

  // const paymentOptions = Object.values(enums.paymentMethods).map(
  //   (item, index) => {
  //     return {
  //       id: index,
  //       name: item,
  //     };
  //   }
  // );
  const [orderInfo] = useState(initialData);

  // Discount State
  // const [discountValue, setDiscountValue] = useState({
  //   isFixed: false,
  //   amount: 0,
  // });

  const [showOrderDetail, setShowOrderDetail] = useState(true);
  const [orderDetailsHeight, setOrderDetailsHeight] = useState(0);
  const OrderDetailRef = useRef(null);

  useEffect(() => {
    if (OrderDetailRef.current) {
      const height = OrderDetailRef.current.clientHeight;
      setOrderDetailsHeight(height);
    }
  }, [guests, users]);

  const subTotal = useMemo(
    () => (menu ? calculateAllOrdersPrice([...guests, ...users]) : 0),
    [guests, users]
  );

  const calculatedDiscount = useMemo(
    () => (menu ? calculateAllOrdersDiscountPrice([...guests, ...users]) : -1),
    [orderInfo, subTotal]
  );
  const calculatedServiceFee = useMemo(
    () => calculateServiceFee(subTotal, calculatedDiscount, serviceFee),
    [subTotal]
  );
  const totalPrice = useMemo(
    () => subTotal + calculatedDiscount + calculatedServiceFee,
    [subTotal, calculatedServiceFee]
  );

  const isOrderItemsAdded = isGuestOrUserHasAnyOrder(guests, users);

  //Discount Functions
  // const handleOnChangeDiscountType = (e) => {
  //   const { value } = e.target;
  //   setDiscountValue({
  //     ...discountValue,
  //     isFixed: value === "true",
  //   });
  // };
  //
  // const handleOnRateConfirm = () => {
  //   // const updatedFormData = {
  //   //   ...formData,
  //   //   rate: {
  //   //     ...discountValue,
  //   //     amount:
  //   //       Number(discountValue.amount) >= 0 ? Number(discountValue.amount) : "",
  //   //   },
  //   // };
  //   //
  //   // setFormData(updatedFormData);
  //   // setOpenSlide(false);
  //   const rate = {
  //     ...discountValue,
  //     amount:
  //       Number(discountValue.amount) >= 0 ? Number(discountValue.amount) : "",
  //   };
  //   handleOnUpdateOrder({ rate });
  //   console.log(rate);
  // };
  //
  // const handleOnRateChange = (e) => {
  //   const { value } = e.target;
  //   setDiscountValue({
  //     ...discountValue,
  //     amount: value && Number(value) >= 0 ? Number(value) : "",
  //   });
  // };

  // Discount Modal Body
  // const DiscountModalBody = (
  //   <form className="AdminOrderPageHomeInfoBoxDiscountModal">
  //     <h5 className="Medium">{t("inputs.discount")}</h5>
  //     <div className="AdminOrderPageHomeInfoBoxDiscount">
  //       <div className="AdminOrderPageHomeInfoBoxDiscountIsFixed">
  //         <RadioSelection
  //           onChange={handleOnChangeDiscountType}
  //           name="isFixed"
  //           isChecked={!discountValue.isFixed}
  //           value={false}
  //           label={t("inputs.notFixed")}
  //         />
  //         <RadioSelection
  //           onChange={handleOnChangeDiscountType}
  //           name="isFixed"
  //           isChecked={discountValue.isFixed}
  //           value={true}
  //           label={t("inputs.fixed")}
  //         />
  //       </div>
  //       <InputControl
  //         type="number"
  //         name="rate"
  //         onChange={handleOnRateChange}
  //         value={discountValue.amount}
  //         placeholder={t("inputs.amount")}
  //         definitionText={formData.rate.isFixed ? undefined : "%"}
  //         className="AdminOrderPageHomeInfoBoxDiscountInput"
  //       />
  //       <HelperText message="The discount cannot be greater than the total price" />
  //     </div>
  //   </form>
  // );

  // Discount Modal Footer
  // const ConfirmFooter = (
  //   <FooterButtons
  //     onConfirm={handleOnRateConfirm}
  //     onCancel={() => setOpenSlide(false)}
  //     cancelButtonText={t("modal.close")}
  //     confirmButtonText={t("modal.apply")}
  //   />
  // );

  const OrderDetails = (
    <div className="AdminOrderPageHomeInfoBoxOrderDetails">
      <div
        className="OrderDetailsButton"
        onClick={() => {
          setShowOrderDetail(!showOrderDetail);
        }}
      >
        <p>{t("dashboard.table.adminOrderHistoryTable.orderDetails")}</p>
        <ArrowIcon
          className={showOrderDetail ? "RotateArrowIcon" : undefined}
        />
      </div>
      <ul className="OrderDetails" ref={OrderDetailRef}>
        {users.map((user) => {
          return user.orderItems.map((orderItem, index) => {
            if (orderItem.isConfirmed !== false) {
              const menuItemInRedux =
                findItemById(orderItem.item.id, menu) || orderItem.item;
              const menuItemPrice =
                calculateMenuItemPrice(orderItem.item) * orderItem.count;
              const discountPrice =
                calculateMenuItemPriceBySchedule(orderItem.item) *
                orderItem.count;
              return (
                <li key={index}>
                  <div className="AdminOrderInfoDetails">
                    <p style={{ marginRight: "8px" }}>
                      <span>{orderItem.count}x </span>
                      {displayDataByLanguage(menuItemInRedux.name)}
                    </p>
                    <div className="AdminOrderInfoPrice">
                      {discountPrice !== 0 && (
                        <Price
                          type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                          value={menuItemPrice}
                        />
                      )}
                      <Price
                        type={ENUMS_PRICE.types.PRICE_XXS}
                        value={
                          discountPrice !== 0
                            ? menuItemPrice + discountPrice
                            : menuItemPrice
                        }
                      />
                    </div>
                  </div>
                  {orderItem.item.modifications.length > 0 && (
                    <ul className="OrderItemDetailsListModifications">
                      {orderItem.item.modifications.map(
                        (modification) =>
                          modification.options.length > 0 && (
                            <OrderItemModificationList
                              modification={modification}
                              className="OrderItemDetailsListModificationList"
                              key={modification.id}
                              hasPriceInfo
                              menuItemModificationsInRedux={
                                menuItemInRedux.modifications
                              }
                            />
                          )
                      )}
                    </ul>
                  )}
                </li>
              );
            }
          });
        })}
        {guests.map((guest) => {
          return guest.orderItems.map((orderItem, index) => {
            if (orderItem.isConfirmed !== false) {
              const menuItemPrice =
                calculateMenuItemPrice(orderItem.item) * orderItem.count;
              const discountPrice =
                calculateMenuItemPriceBySchedule(orderItem.item) *
                orderItem.count;
              return (
                <li key={index}>
                  <div className="AdminOrderInfoDetails">
                    <p style={{ marginRight: "8px" }}>
                      <span>{orderItem.count}x </span>
                      {displayDataByLanguage(orderItem.item.name)}
                    </p>
                    <div className="AdminOrderInfoPrice">
                      {discountPrice !== 0 && (
                        <Price
                          type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                          value={menuItemPrice}
                        />
                      )}
                      <Price
                        type={ENUMS_PRICE.types.PRICE_S_REGULAR}
                        value={
                          discountPrice !== 0
                            ? menuItemPrice + discountPrice
                            : menuItemPrice
                        }
                      />
                    </div>
                  </div>
                  {orderItem.item.modifications.length > 0 && (
                    <ul className="OrderItemDetailsListModifications">
                      {orderItem.item.modifications.map(
                        (modification) =>
                          modification.options.length > 0 && (
                            <OrderItemModificationList
                              modification={modification}
                              className="OrderItemDetailsListModificationList"
                              key={modification.id}
                              hasPriceInfo
                            />
                          )
                      )}
                    </ul>
                  )}
                </li>
              );
            }
          });
        })}
      </ul>
    </div>
  );

  const staticBoxHeight = isOrderItemsAdded ? 230 : 215;

  return (
    <>
      <div
        className="AdminOrderPageHomeInfoBox"
        style={{
          height: !showOrderDetail
            ? `${staticBoxHeight}px`
            : `calc(${staticBoxHeight}px + ${orderDetailsHeight}px)`,
        }}
      >
        <div className="AdminOrderPageHomeInfoBoxHeader">
          <h3>{t("dashboard.table.adminOrderHistoryTable.receipt")}</h3>
          <h3>#{formData.id}</h3>
        </div>
        <div className="AdminOrderPageHomeInfoBoxPrice">
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h3 className="SemiBold">{t("basket.order.total")}</h3>
            <Price value={totalPrice} type={ENUMS_PRICE.types.PRICE_L} />
          </div>
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h6>{t("basket.order.subtotal")}</h6>
            <Price
              value={subTotal + calculatedDiscount}
              type={ENUMS_PRICE.types.PRICE_S_REGULAR}
            />
          </div>
          <div className="AdminOrderPageHomeInfoBoxPriceLine">
            <h6>
              {t("inputs.serviceFee")} ({serviceFee || 0}%)
            </h6>
            <Price
              value={calculatedServiceFee}
              type={ENUMS_PRICE.types.PRICE_S_REGULAR}
            />
          </div>
          {/*<div className="AdminOrderPageHomeInfoBoxPriceLine">*/}
          {/*  <h6>{t("inputs.discount")}</h6>*/}
          {/*  <Price*/}
          {/*    value={calculatedDiscount}*/}
          {/*    type={ENUMS_PRICE.types.PRICE_S_REGULAR}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*Add Discount*/}
          {/*<div className="AdminOrderPageHomeInfoBoxPriceLine">*/}
          {/*  <h6>*/}
          {/*    {t("inputs.discount")} ({formData.rate.amount || 0}*/}
          {/*    {formData.rate.isFixed ? selectedCurrency?.symbol : "%"}) :{" "}*/}
          {/*  </h6>*/}
          {/*  <TextIconButton*/}
          {/*    onClick={() => setOpenSlide(true)}*/}
          {/*    text="Add"*/}
          {/*    svgComponent={<PlusIcon />}*/}
          {/*    className="AdminOrderPageHomeInfoBoxAddDiscount"*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        {/*<TextIconButton*/}
        {/*  onClick={() => {}}*/}
        {/*  text="Print the bill"*/}
        {/*  svgComponent={<PrintIcon />}*/}
        {/*  className="AdminOrderPageHomeInfoBoxPrint"*/}
        {/*/>*/}
        {isOrderItemsAdded && OrderDetails}
      </div>
      <div className="AdminOrderPageHomeInfo">
        <AdminOrderGeneralInfo
          formData={formData}
          pendingData={pendingData}
          setPendingData={setPendingData}
        />
      </div>
      {/*Discount Modal*/}
      {/*<Modal*/}
      {/*  openSlide={openSlide}*/}
      {/*  mainElementRefConfirm={mainElementRefConfirm}*/}
      {/*  body={DiscountModalBody}*/}
      {/*  footer={ConfirmFooter}*/}
      {/*/>*/}
    </>
  );
};

AdminOrderInfo.propTypes = {
  guests: PropTypes.array,
  users: PropTypes.array,
  formData: PropTypes.object,
  setPendingData: PropTypes.func,
  pendingData: PropTypes.object,
  handleOnUpdateOrder: PropTypes.func,
  orderCreateDate: PropTypes.string,
};

export default AdminOrderInfo;
