import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import EditButton, {
  ENUMS as ENUMS_EDIT,
} from "components/admin/buttons/edit-button/EditButton";
import useLanguage from "utils/hooks/useLanguage";

import "./TagCard.scss";

export const ENUMS = {
  name: "TagCard",
};

const TagCard = ({ tag, onEdit, onClick, activeTagId, isMultiLanguage }) => {
  const { displayDataByLanguage } = useLanguage();
  const updatedTag = isMultiLanguage
    ? {
        ...tag,
        name: displayDataByLanguage(tag.name),
      }
    : tag;
  return (
    <div
      onClick={onClick}
      className={cx("TagCard", {
        isActive: activeTagId === tag.id,
      })}
    >
      <h6 className="SemiBold">{updatedTag.name}</h6>
      <EditButton onClick={onEdit} type={ENUMS_EDIT.types.TYPE_C} />
    </div>
  );
};

TagCard.propTypes = {
  /**
   * The tag object
   */
  tag: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  }).isRequired,

  /**
   * The function called when edit button clicked
   */
  onEdit: PropTypes.func.isRequired,

  /**
   * The function called when component clicked
   */
  onClick: PropTypes.func,

  /**
   * The active tag id
   */
  activeTagId: PropTypes.number,

  /**
   * Flag to determine if the category supports multiple languages
   */
  isMultiLanguage: PropTypes.bool,
};

export default TagCard;
