import AWS from "aws-sdk";

import { DO_FILE_TYPES, DO_FOLDER_NAMES } from "utils/constants/DOSpaces";
import { compressImage } from "utils/helpers";

const DO_SPACE = new AWS.S3({
  endpoint: new AWS.Endpoint(process.env.REACT_APP_DO_SPACES_URL),
  accessKeyId: process.env.REACT_APP_DO_SPACES_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_DO_SPACES_SECRET_KEY,
});
const DO_SPACES_BUCKET_NAME = process.env.REACT_APP_DO_SPACES_BUCKET_NAME;

// TODO bucket URL should be always included or never. Should not be conditional
export function createDOBucketName(image) {
  const bucketUrl = `https://${DO_SPACES_BUCKET_NAME}.${
    process.env.REACT_APP_DO_SPACES_URL.split("https://")[1]
  }/`;
  if (image) {
    if (image.includes(bucketUrl)) {
      return image;
    }
    return bucketUrl.concat(image);
  }
  return null;
}

export const uploadImageToDO = async ({
  image,
  maxSizeMB,
  maxWidthOrHeight,
  fileType,
  businessId,
  onSuccess,
  onError,
}) => {
  // Compress only if the image's size is greater than the max size
  const compressedImage =
    image.size > maxSizeMB * 1024 * 1024
      ? await compressImage(image, maxSizeMB, maxWidthOrHeight)
      : image;
  const uniqueImageName = createUniqueImageName(fileType, businessId);
  const uploadParams = {
    Bucket: DO_SPACES_BUCKET_NAME,
    Key: uniqueImageName,
    Body: compressedImage,
    ACL: "public-read",
  };

  DO_SPACE.putObject(uploadParams, (err) => {
    if (err) {
      console.error("Error uploading image:", err);
      onError(err);
    } else {
      onSuccess(uniqueImageName);
    }
  });
};

export const removeImageFromDO = async (image, onSuccess) => {
  const params = {
    Bucket: DO_SPACES_BUCKET_NAME,
    Key: image,
  };

  DO_SPACE.deleteObject(params, (err) => {
    if (err) {
      console.error("Error deleting image from Digital Ocean:", err);
    } else {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
  });
};

export const duplicateImageInDO = async (
  sourceKey,
  destinationKey,
  onSuccess
) => {
  // Copy the source image to the destination with a new name
  const copyParams = {
    Bucket: DO_SPACES_BUCKET_NAME,
    CopySource: `${DO_SPACES_BUCKET_NAME}/${sourceKey}`,
    Key: destinationKey,
    ACL: "public-read",
  };

  DO_SPACE.copyObject(copyParams, (err) => {
    if (err) {
      console.error("Error duplicating image:", err);
    } else {
      onSuccess(destinationKey);
    }
  });
};

export const createUniqueImageName = (fileType, businessId) => {
  const imageNameSalt = `${new Date().getTime()}-${Math.floor(
    Math.random() * 1000001
  )}`;
  const imageNameRoot = `${process.env.REACT_APP_DEPLOY_ENV}/${DO_FOLDER_NAMES.MERCHANT}/${businessId}`;
  switch (fileType) {
    case DO_FILE_TYPES.MENU:
      return `${imageNameRoot}/${DO_FOLDER_NAMES.MENU}/mItem_${imageNameSalt}`;
    case DO_FILE_TYPES.BUSINESS_LOGO_IMAGE:
      return `${imageNameRoot}/${DO_FOLDER_NAMES.BUSINESS}/logo_${imageNameSalt}`;
    case DO_FILE_TYPES.BUSINESS_BACKGROUND_IMAGE:
      return `${imageNameRoot}/${DO_FOLDER_NAMES.BUSINESS}/bg_${imageNameSalt}`;
    case DO_FILE_TYPES.BUSINESS_OTHER_IMAGES:
      return `${imageNameRoot}/${DO_FOLDER_NAMES.BUSINESS}/mix_${imageNameSalt}`;
    case DO_FILE_TYPES.GUEST_PROFILE_PHOTO:
      return `${imageNameRoot}/${DO_FOLDER_NAMES.GUEST}/profile_${imageNameSalt}`;
    default:
      throw new Error(
        "File type must be defined to generate unique image name"
      );
  }
};
