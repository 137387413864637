import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./RadioSelection.scss";

export const ENUMS = {
  name: "RadioSelection",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_YELLOW: "TYPE_YELLOW",
  },
};

const RadioSelection = ({
  value,
  name,
  isChecked,
  onChange,
  label,
  description,
  imgSrc,
  type,
  isDisabled,
}) => {
  return (
    <label
      className={cx("RadioSelection", {
        hasImage: imgSrc,
        isSelected: isChecked,
        typeYellow: type === ENUMS.types.TYPE_YELLOW,
        isDisabled: isDisabled,
      })}
    >
      <div className="RadioSelectionHeader">
        <input
          name={name}
          type="radio"
          disabled={isDisabled}
          value={value}
          checked={isChecked}
          onChange={onChange}
          className="RadioSelectionInput"
        />
        <div className="RadioSelectionCircle"></div>
        {label && (
          <h6 className="Medium RadioSelectionText">
            {label}
            {description && (
              <span className="RadioSelectionDescription">
                {" "}
                ({description})
              </span>
            )}
          </h6>
        )}
      </div>
      {imgSrc && (
        <img className="RadioSelectionImage" src={imgSrc} alt={label || name} />
      )}
    </label>
  );
};

RadioSelection.propTypes = {
  /**
   * The name attribute for the radio button input
   */
  name: PropTypes.string.isRequired,

  /**
   * The value associated with the radio button
   */
  value: PropTypes.any.isRequired,

  /**
   * Indicates whether the radio button is checked
   */
  isChecked: PropTypes.bool.isRequired,

  /**
   * The function called when the radio button state changes
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The label text to display next to the radio button
   */
  label: PropTypes.string,

  /**
   * The description text to display below the label
   */
  description: PropTypes.string,

  /**
   * The source URL for the image to display next to the radio button
   */
  imgSrc: PropTypes.string,

  /**
   * The type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * Indicates whether the radio button is disabled
   */
  isDisabled: PropTypes.bool,
};

export default RadioSelection;
