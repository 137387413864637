import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import PropTypes from "prop-types";
import { updateUserLastReadTime } from "../../../redux/slices/chatStore";
import { STORE_NAMES } from "../../constants/redux";

const useChatControllerHelper = ({
  author,
  onPublishMessage,
  businessId,
  orderId,
  userId,
}) => {
  const dispatch = useDispatch();
  const currentTopic = useSelector(
    (state) => state[STORE_NAMES.chat].topics
  )?.find((topic) => topic.id === orderId);
  const authorLastReadTime = currentTopic?.usersLastReadTime.find(
    (item) => item.userId === userId
  )?.dateTime;
  const handleNewMessage = (newText) => {
    onPublishMessage({
      businessId: businessId,
      orderId: orderId,
      text: newText,
      authorId: author.id,
      authorName: author.name,
      authorRoleName: author.role?.name,
    });
  };
  const updateUserLastReadTimeWithArgs = () => {
    if (currentTopic) {
      dispatch(
        updateUserLastReadTime({
          businessId,
          orderId,
          userId: userId,
          time: new Date().toISOString(),
        })
      );
    }
  };
  useEffect(() => {
    return updateUserLastReadTimeWithArgs;
  }, []);

  return {
    authorLastReadTime,
    handleNewMessage,
    updateUserLastReadTimeWithArgs,
  };
};

// useChatControllerHelper.propTypes = {
//   author: PropTypes.object.isRequired,
//   onNewMessage: PropTypes.func.isRequired,
//   businessId: PropTypes.number.isRequired,
//   orderId: PropTypes.number.isRequired,
//   userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
// };

export default useChatControllerHelper;
