import React from "react";
import PropTypes from "prop-types";

import Icon_Attention from "assets/icons/other/Attention.svg";

import "./Band.scss";

const UnderConstructionBand = ({ text }) => {
  return (
    <div className="UnderConstructionBand">
      <div className="UnderConstructionBandTicker">
        {[...Array(25)].map((_, index) => {
          return (
            <React.Fragment key={index}>
              <img src={Icon_Attention} alt="attention" />
              <h3 className="SemiBold">{text}</h3>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

UnderConstructionBand.propTypes = {
  text: PropTypes.string,
};

export default UnderConstructionBand;
