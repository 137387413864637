import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import { formatDateToCustomFormat } from "utils/helpers";

import "./DateSelection.scss";

export const ENUMS = {
  name: "DateSelection",
};

const DateSelection = ({ value, onSelect, buttonLabel, label, maxWidth }) => {
  const containerStyle = {
    maxWidth: maxWidth ? `${maxWidth}px` : "none",
  };

  return (
    <div
      className={cx("DateSelectionContainer", { isLabelVisible: label })}
      style={containerStyle}
    >
      <div className="DisplayDateByMonth">
        {label && <h6 className="Medium"> {label}</h6>}
        <span> {formatDateToCustomFormat(value)}</span>
      </div>
      <CTAButton
        onClick={onSelect}
        name={buttonLabel}
        type={ENUMS_CTA_BUTTON.types.TYPE_H}
        className="ChangeDateCta"
      />
    </div>
  );
};

//TODO add comment the props
DateSelection.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

export default DateSelection;
