import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import EditButton from "components/admin/buttons/edit-button/EditButton";
import {
  calculateMenuItemPrice,
  calculateMenuItemPriceBySchedule,
  findItemById,
} from "utils/general";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import DoneButton from "components/admin/buttons/done-button/DoneButton";
import useLanguage from "utils/hooks/useLanguage";
import UndoButton from "components/admin/buttons/undo-button/UndoButton";
import OrderItemModificationList from "components/admin/cards/order-item-modification-list/OrderItemModificationList";
import { STORE_NAMES } from "utils/constants/redux";

import "./OrderItem.scss";

export const ENUMS = {
  name: "OrderItem",
};

const OrderItem = ({
  orderItem,
  onRemove,
  onEdit,
  onIncrease,
  onDecrease,
  onConfirm,
  onUndo,
  isPendingDelete,
  isPendingEdit,
}) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const { item, count } = orderItem;
  const menuItemInRedux = findItemById(item.id, menu) || item;
  const discountPrice = calculateMenuItemPriceBySchedule(item) * count;
  const price = calculateMenuItemPrice(item) * count;

  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const [hasSelectedModifications, setHasSelectedModifications] =
    useState(false);

  useEffect(() => {
    const hasSelectedModifications = item.modifications.some((modification) =>
      modification.options.some((option) => option.count > 0)
    );

    setHasSelectedModifications(hasSelectedModifications);
  }, [item.modifications]);
  const actionButtons = (item) => {
    if (orderItem.isConfirmed === false) {
      return (
        <>
          <UndoButton onClick={onUndo} />
        </>
      );
    } else if (item.isConfirmed !== null && item.isPendingList) {
      return (
        <>
          <EditButton onClick={onEdit} className="EditButtonMargin" />
          <UndoButton onClick={onUndo} />
        </>
      );
    } else if (item.isConfirmed && !item.isPendingList) {
      return (
        <>
          <DeleteButton onClick={onRemove} showModalDescription={false} />
          <EditButton onClick={onEdit} />
        </>
      );
    } else {
      return (
        <>
          <DeleteButton
            onClick={onRemove}
            svgComponent={
              <IconClose className="OrderItemDetailsListButtonsDontConfirm" />
            }
          />
          <DoneButton onClick={onConfirm} />
        </>
      );
    }
  };
  return (
    <div
      className={cx("OrderItem", {
        RedBorderForDeletedItem:
          orderItem.isArchived && orderItem.isPendingList,
        isPendingDelete: isPendingDelete,
        isPendingEdit: isPendingEdit,
        isDenied:
          orderItem.isConfirmed === false &&
          !orderItem.isArchived &&
          !orderItem.isPendingList,
        isDeleted: orderItem.isArchived && !orderItem.isPendingList,
      })}
    >
      <div
        className={cx("OrderItemStatus", {
          isDenied:
            orderItem.isConfirmed === false &&
            !orderItem.isArchived &&
            !orderItem.isPendingList,
          isDeleted: orderItem.isArchived && !orderItem.isPendingList,
        })}
      >
        <span className="OrderItemStatusCircle"></span>
        <h6 className="h8">
          {!orderItem.isConfirmed &&
            orderItem.isArchived &&
            !orderItem.isPendingList &&
            t("orderItem.deleted")}
          {orderItem.isConfirmed === false &&
            !orderItem.isArchived &&
            !orderItem.isPendingList &&
            t("orderItem.denied")}
          {/*{orderItem.isConfirmed &&*/}
          {/*  orderItem.isKitchenDone &&*/}
          {/*  t("basket.order.done")}*/}
          {/*{orderItem.isConfirmed &&*/}
          {/*  !orderItem.isKitchenDone &&*/}
          {/*  t("basket.order.preparing")}*/}
        </h6>
      </div>
      {/*<div*/}
      {/*  className={cx("OrderItemKitchenStatus", {*/}
      {/*    // isDone: orderItem.isKitchenDone && orderItem.isConfirmed,*/}
      {/*    // isPreparing: !orderItem.isKitchenDone && orderItem.isConfirmed,*/}
      {/*    isDenied:*/}
      {/*      orderItem.isConfirmed === false &&*/}
      {/*      !orderItem.isArchived &&*/}
      {/*      !orderItem.isPendingList,*/}
      {/*    isDeleted: orderItem.isArchived && !orderItem.isPendingList,*/}
      {/*  })}*/}
      {/*>*/}
      {/*  <span className="OrderItemKitchenStatusCircle"></span>*/}
      {/*  <h6 className="h8">*/}
      {/*    {!orderItem.isConfirmed &&*/}
      {/*      orderItem.isArchived &&*/}
      {/*      !orderItem.isPendingList &&*/}
      {/*      t("orderItem.deleted")}*/}
      {/*    {orderItem.isConfirmed === false &&*/}
      {/*      !orderItem.isArchived &&*/}
      {/*      !orderItem.isPendingList &&*/}
      {/*      t("orderItem.denied")}*/}
      {/*    /!*{orderItem.isConfirmed &&*!/*/}
      {/*    /!*  orderItem.isKitchenDone &&*!/*/}
      {/*    /!*  t("basket.order.done")}*!/*/}
      {/*    /!*{orderItem.isConfirmed &&*!/*/}
      {/*    /!*  !orderItem.isKitchenDone &&*!/*/}
      {/*    /!*  t("basket.order.preparing")}*!/*/}
      {/*  </h6>*/}
      {/*</div>*/}

      <div
        className={
          orderItem.isConfirmed === false
            ? "OrderItemDetails OrderItemDetailsDeleted"
            : "OrderItemDetails"
        }
        style={
          hasSelectedModifications && orderItem.isConfirmed !== false
            ? { height: "50px" }
            : {}
        }
      >
        {orderItem.isPendingList && (
          <div className="OrderItemDetailsLeft">
            <AnimatedPlusMinusButton
              count={count}
              onPlusClick={onIncrease}
              onMinusClick={onDecrease}
              disableMinusButtonAtOne
            />
          </div>
        )}

        <div className="OrderItemDetailsCenter">
          <div className="Medium OrderItemDetailCountAndName">
            {!orderItem.isPendingList && (
              <h6 className="Medium OrderItemDetailsCount">{count}x </h6>
            )}
            <div className="OrderItemDetailsNameContainer">
              <h6 className="Medium OrderItemDetailsName">
                {displayDataByLanguage(menuItemInRedux.name)}
              </h6>
            </div>
          </div>
          <div className="OrderItemDetailsPrice">
            {discountPrice !== 0 && (
              <Price
                type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
                value={price}
              />
            )}
            <Price
              type={ENUMS_PRICE.types.PRICE_XS}
              value={discountPrice !== 0 ? price + discountPrice : price}
            />
          </div>
        </div>
        <div className="OrderItemDetailsRight">
          <div className="OrderItemDetailsListButtons">
            {actionButtons(orderItem)}
          </div>
        </div>
      </div>
      {hasSelectedModifications && orderItem.isConfirmed !== false && (
        <div className="OrderItemDetailsList">
          {item.modifications.length > 0 && (
            <ul className="OrderItemDetailsListModifications">
              {item.modifications.map(
                (modification) =>
                  modification.options.length > 0 && (
                    <OrderItemModificationList
                      modification={modification}
                      className="OrderItemDetailsListModification"
                      key={modification.id}
                      menuItemModificationsInRedux={
                        menuItemInRedux.modifications
                      }
                    />
                  )
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

OrderItem.propTypes = {
  /**
   * The order item object with its details
   */
  orderItem: PropTypes.object.isRequired,

  /**
   * Callback function to handle removing the item from the basket
   */
  onRemove: PropTypes.func.isRequired,

  /**
   * Callback function to handle editing the item
   */
  onEdit: PropTypes.func.isRequired,

  /**
   * Callback function to handle editing the item
   */
  onUndo: PropTypes.func.isRequired,

  /**
   * Callback function to handle increasing one item to the basket
   */
  onIncrease: PropTypes.func.isRequired,

  /**
   * Callback function to handle decreasing one item in the basket
   */
  onDecrease: PropTypes.func.isRequired,

  /**
   * Callback function to handle confirming the item
   */
  onConfirm: PropTypes.func.isRequired,
  isPendingUndo: PropTypes.bool,
  isPendingDelete: PropTypes.bool,
  isPendingEdit: PropTypes.bool,
};

export default OrderItem;
