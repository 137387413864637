import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import AdminOrderList from "pages/admin/admin-pages/admin-order/admin-order-list/AdminOrderList";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import isEqual from "lodash/isEqual";
import { DATA_TYPES, KEY_NAMES } from "../admin-order-detail/AdminOrderDetail";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as ChangesIcon } from "assets/icons/edit/changes.svg";

import "./AdminOrderPendingItems.scss";

const AdminOrderPendingItems = ({
  setPendingData,
  pendingData,
  formData,
  setFormData,
  onSaveOrder,
  onEditOrderItem,
}) => {
  const { t } = useTranslation();

  const isLoadingUpdateOrder = useSelector(
    (state) => state[STORE_NAMES.orders].thunkAPIStates.getAllOrder
  );

  const handleOnUpdateOrderItem = ({ count, orderItemId, item, isUser }) => {
    const key = isUser ? KEY_NAMES.USERS : KEY_NAMES.GUESTS;
    const guestId = pendingData[key].find((guest) => {
      return guest.orderItems.some((orderItem) => orderItem.id === orderItemId);
    }).person.id;
    const updatedItems = pendingData[key].map((currentItem) => {
      if (currentItem.person.id === guestId) {
        return {
          ...currentItem,
          orderItems: currentItem.orderItems.map((orderItem) => {
            if (
              orderItem.id === orderItemId &&
              !isEqual(orderItem.item, item)
            ) {
              return {
                ...orderItem,
                count: count,
                item: item ? item : orderItem.item,
              };
            }
            return orderItem;
          }),
        };
      }
      return currentItem;
    });
    setPendingData({ ...pendingData, [key]: updatedItems });
  };

  const handleOnUndoOrderItem = (orderItemId, guestId, isUser) => {
    const key = isUser ? KEY_NAMES.USERS : KEY_NAMES.GUESTS;
    const updatedGuests = formData[key].map((item) => {
      if (item.person.id === guestId) {
        return {
          ...item,
          orderItems: item.orderItems.map((orderItem) => {
            if (orderItem.id === orderItemId) {
              return { ...orderItem, isPendingList: false };
            }
            return orderItem;
          }),
        };
      }
      return item;
    });
    setFormData({ ...formData, [key]: updatedGuests });

    const removeItemFromPendingList = pendingData[key]
      .map((guest) => {
        if (guest.person.id === guestId) {
          return {
            ...guest,
            orderItems: guest.orderItems.filter(
              (orderItem) => orderItem.id !== orderItemId
            ),
          };
        }
        return guest;
      })
      .filter((guest) => guest.orderItems.length > 0);
    setPendingData({ ...pendingData, [key]: removeItemFromPendingList });
  };
  const isPendingItemExist =
    pendingData.guests.length > 0 || pendingData.users.length > 0;

  return (
    <>
      <div className={cx("AdminOrderPendingItems", { isPendingItemExist })}>
        <div className="AdminOrderPendingItemsHeader">
          <div className="AdminOrderPendingItemsTitle">
            <ChangesIcon />
            <h4 className="Bold">{t("basket.order.updatesToSave")}</h4>
          </div>
          {isPendingItemExist ? (
            <PrimaryButton
              className="AdminOrderPendingItemsSaveButton"
              onClick={onSaveOrder}
              isLoading={isLoadingUpdateOrder}
            />
          ) : (
            <h6 className="Medium AdminOrderPendingItemsEmptyText">
              {t("orderItem.empty")}
            </h6>
          )}
        </div>
        {isPendingItemExist && (
          <div className="AdminOrderPendingItemsNewOrders">
            <AdminOrderList
              guests={pendingData.guests}
              users={pendingData.users}
              onUpdateOrderItem={handleOnUpdateOrderItem}
              onRemoveOrderItem={() => {}}
              onUndoOrderItem={handleOnUndoOrderItem}
              onEditOrderItem={onEditOrderItem}
              dataType={DATA_TYPES.PENDING_DATA}
            />
          </div>
        )}
      </div>
    </>
  );
};

AdminOrderPendingItems.propTypes = {
  /**
   * The form data for order notifications
   */
  pendingData: PropTypes.object.isRequired,

  setPendingData: PropTypes.func.isRequired,

  /**
   * The form data for order notifications
   */
  formData: PropTypes.object.isRequired,

  /**
   * Function to set the form data
   */
  setFormData: PropTypes.func.isRequired,

  /**
   * Function to remove the order item
   */
  onSaveOrder: PropTypes.func.isRequired,

  /**
   * Function to remove the order item
   */
  onEditOrderItem: PropTypes.func.isRequired,
};

export default AdminOrderPendingItems;
