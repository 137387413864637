import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Modal from "components/modal/Modal";
import CloseButton from "components/buttons/close-button/CloseButton";
import { ReactComponent as CopyIcon } from "assets/icons/copy/copy.svg";
import cx from "classnames";
import useTimeout from "utils/hooks/useTimeout";
import { STORE_NAMES } from "utils/constants/redux";
import ICON_WIFI from "assets/icons/other/WiFi.svg";
import { createDOBucketName } from "utils/DO-Spaces";

import "./WifiModal.scss";

const WifiModal = ({ openSlide, mainElementRef, onClose, closeButtonType }) => {
  const { t } = useTranslation();
  const [showCopySuccessText, setShowCopySuccessText] = useState(false);
  const [selectedWifi, setSelectedWifi] = useState(0);
  const { business } = useSelector((state) => state[STORE_NAMES.business]);
  const hasWifi = business?.wifi?.length > 0 || false;
  const handleWifiClick = (index) => {
    setSelectedWifi(index);
  };
  const WifiModalHeader = (
    <div className="PaymentHeader">
      <div className="WifiTitle">
        <img src={ICON_WIFI} alt="wiFi" />
        <h2 className="Bold">{t("common.wifi")}</h2>
      </div>
      <CloseButton onClick={onClose} type={closeButtonType || undefined} />
    </div>
  );
  const handleCopyWifiPassword = useCallback(async (wifiPassword) => {
    try {
      await navigator.clipboard.writeText(wifiPassword);
      setShowCopySuccessText(true);
    } catch (error) {
      console.error("Error copying wifi password:", error);
    }
  }, []);

  useTimeout(
    () => {
      if (showCopySuccessText) {
        setShowCopySuccessText(false);
      }
    },
    showCopySuccessText ? 5000 : null
  );

  if (!hasWifi) {
    return null;
  }
  const WifiModalBody = (
    <div className="WifiModalBody">
      {business.wifi.length > 1 && (
        <div className="WifiModalBodyNetworks">
          {business?.wifi?.map((wifi, index) => (
            <div
              key={index}
              className={cx("WifiModalBodyNetwork", {
                isSelectedWifi: selectedWifi === index,
              })}
              onClick={() => handleWifiClick(index)}
            >
              <h6 className="Medium WifiModalBodyNetworkName">{wifi.name}</h6>
            </div>
          ))}
        </div>
      )}
      <div className="WifiModalBodyDetails">
        <div className=" WifiModalBodyDetail">
          <h4 className="Medium WifiName color-light-gray">
            {t("inputs.name")}:
          </h4>
          <h4 className="SemiBold WifiValue">
            {business?.wifi[selectedWifi]?.name}
          </h4>
        </div>
        <div className="WifiModalBodyDetail">
          <h4 className="Medium WifiPassword">{t("inputs.password")}:</h4>
          <h4 className="SemiBold WifiValue">
            {business?.wifi[selectedWifi]?.password}
          </h4>
          <span
            className="WifiModalCopy"
            onClick={() => handleCopyWifiPassword(123456)}
          >
            <CopyIcon />
          </span>
          {showCopySuccessText && (
            <h5 className="Medium WifiModalCopiedText">{t("common.copied")}</h5>
          )}
        </div>
      </div>
      <div className="WifiModalQR">
        <h5 className="Medium">{t("qr.scanToConnect")}</h5>
        <img
          src={createDOBucketName(business?.wifi[selectedWifi]?.image)}
          alt="QR"
        />
      </div>
    </div>
  );

  return (
    <Modal
      header={WifiModalHeader}
      body={WifiModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      className={"MascotDashboardWifeModal"}
    />
  );
};

WifiModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  mainElementRef: PropTypes.object,
  onClose: PropTypes.func,
  closeButtonType: PropTypes.string,
};
export default WifiModal;
