import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/slices/ordersStore";
import { updateOrderActionAsync } from "redux/actions/orderActions";

import CloseButton from "components/buttons/close-button/CloseButton";
import GuestInformation, {
  ENUMS,
} from "components/elements/guest-information/GuestInformation";
import MethodSelection from "components/elements/method-selection/MethodSelection";
import {
  commonAsyncErrorMessage,
  PAYMENT_METHODS,
} from "utils/constants/data/base";
import { ReactComponent as CashIcon } from "assets/icons/payment/cash.svg";
import { ReactComponent as PosTerminalIcon } from "assets/icons/payment/pos-terminal.svg";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import Modal from "components/modal/Modal";
import { handleOnAsyncError } from "utils/helpers";
import { STORE_NAMES } from "utils/constants/redux";
import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";

import "./PaymentModal.scss";

const PaymentModal = ({
  openSlide,
  mainElementRef,
  onClose,
  totalPrice,
  menuItemCount,
  selectedGuests,
  tableName,
  className,
  closeButtonType,
}) => {
  const { t } = useTranslation();

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.CASH);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { table, businessId } = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  );
  const isLoadingOrder = useSelector(
    (state) => state[STORE_NAMES.orders]?.thunkAPIStates?.getAllOrder
  );
  const order = orders?.find((order) => order.table.id === table.id);
  const handlePaymentSelect = (method) => {
    setPaymentMethod(method);
  };

  const handlePay = async () => {
    dispatch(setLoading(true));
    const findUpdatedAction = order.actions.find(
      (action) => action.name === ORDER_ACTIONS_TYPE.bill
    );

    const updatedAction = {
      ...findUpdatedAction,
      value: true,
      type: paymentMethod,
    };

    const updatedOrder = { action: updatedAction, actionBill: true };
    const response = await dispatch(
      updateOrderActionAsync({ businessId, order: updatedOrder, id: order.id })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      onClose();
    }
    dispatch(setLoading(false));
  };

  const PaymentModalHeader = (
    <div className="PaymentHeader">
      <h2 className="Bold">{t("payment.payment")}</h2>
      <CloseButton onClick={onClose} type={closeButtonType} />
    </div>
  );

  const PaymentModalBody = (
    <div className={"PaymentContentWrapper"}>
      {/*
      // isMethodSelected && !isAskForBill ? (
        // <div className="PaymentConfirmStepContainer">
        //   <div className="PaymentConfirmStep">
        //     {selectedPaymentMethod === PAYMENT_METHODS.CASH && (
        //       <img src={IMAGE_CASH} alt="cash" />
        //     )}
        //     {selectedPaymentMethod === PAYMENT_METHODS.POS_TERMINAL && (
        //       <img src={IMAGE_POS_TERMINAL} alt="pos-terminal" />
        //     )}
        //     <h3 className="SemiBold">{t("payment.ready")}</h3>
        //     {selectedPaymentMethod === PAYMENT_METHODS.CASH && (
        //       <h6>{t("payment.confirmCash")}</h6>
        //     )}
        //     {selectedPaymentMethod === PAYMENT_METHODS.POS_TERMINAL && (
        //       <h6>{t("payment.confirmPOSTerminal")}</h6>
        //     )}
        //   </div>
        //   <div className="GoToDashboardBtn">
        //     <CTAButton
        //       onClick={onClose}
        //       name={t("buttons.goToDashboard")}
        //       type={CTA_BUTTON_ENUMS.types.TYPE_E}
        //     />
        //   </div>
        // </div>
        */}
      <div className="PaymentMethodSelection">
        <GuestInformation
          totalPrice={totalPrice}
          menuItemCount={menuItemCount}
          guestCount={selectedGuests?.length}
          type={ENUMS.types.TYPE_B}
          tableName={tableName}
        />
        <h4 className="SemiBold PaymentMethodQuestion">
          {t("payment.pageTitle")}
        </h4>
        <h6 className="PaymentMethodInfoText">{t("payment.pageSubtitle")}</h6>
        <div className="PaymentMethods">
          <MethodSelection
            isSelected={paymentMethod === PAYMENT_METHODS.CASH}
            methodSelectionName={t("payment.cash")}
            methodSelectionIcon={<CashIcon />}
            onClick={() => handlePaymentSelect(PAYMENT_METHODS.CASH)}
          />
          <MethodSelection
            isSelected={paymentMethod === PAYMENT_METHODS.POS_TERMINAL}
            methodSelectionName={t("payment.POSTerminal")}
            methodSelectionIcon={<PosTerminalIcon />}
            onClick={() => handlePaymentSelect(PAYMENT_METHODS.POS_TERMINAL)}
          />
        </div>
        <div className="PayWithButton">
          <CTAButton
            onClick={handlePay}
            name={t("buttons.confirm")}
            type={CTA_BUTTON_ENUMS.types.TYPE_E}
            disabled={!paymentMethod}
            isLoading={isLoadingOrder}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      header={PaymentModalHeader}
      body={PaymentModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      className={className}
    />
  );
};

PaymentModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
  totalPrice: PropTypes.number,
  menuItemCount: PropTypes.number,
  selectedGuests: PropTypes.array,
  tableName: PropTypes.string,
  className: PropTypes.string,
  closeButtonType: PropTypes.string,
};

export default PaymentModal;
