import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./BasketButton.scss";

export const ENUMS = {
  name: "BasketButton",
};

const BasketButton = ({ isShown, svgComponent, count, onClick }) => {
  const { t } = useTranslation();
  if (!isShown) {
    return;
  }
  const [internalCount, setInternalCount] = useState(count);
  const [showAnimation, setShowAnimation] = useState({
    status: false,
    text: "",
  });
  const [animationKey, setAnimationKey] = useState(0);
  useEffect(() => {
    let animationTimer;
    if (count > 0 && count !== internalCount) {
      setShowAnimation({
        status: true,
        text: t("basket.itemAdded", {
          count: count - internalCount,
        }),
      });
      setAnimationKey((prevKey) => prevKey + 1);
      animationTimer = setTimeout(() => {
        setShowAnimation({
          status: false,
          text: "",
        });
      }, 1500);
      setInternalCount(count);
    }
    return () => {
      clearTimeout(animationTimer);
    };
  }, [count]);
  return (
    <div className="BasketButtonContainer" onClick={onClick}>
      <div className="BasketButtonWrapper">
        {count > 0 && (
          <div className="BasketButtonCount">
            <h6 className="h7 SemiBold">{count}</h6>
          </div>
        )}
        <button className="BasketButton" type="button">
          {svgComponent}
        </button>
        <div
          key={animationKey}
          style={showAnimation.status ? { animationName: "move" } : undefined}
          className={`BasketButtonAnimation ${showAnimation ? "show" : ""}`}
        >
          <h6 className="h7 SemiBold">{showAnimation.text}</h6>
        </div>
      </div>
    </div>
  );
};

BasketButton.propTypes = {
  /**
   * Indicates whether the button should be shown.
   */
  isShown: PropTypes.bool,

  /**
   * The SVG component to be displayed as the button's content.
   */
  svgComponent: PropTypes.node,

  /**
   * The count to be displayed as a badge (optional).
   */
  count: PropTypes.number,

  /**
   * The callback function to be called when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
};

BasketButton.defaultProps = {
  isShown: true,
};

export default BasketButton;
