import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./HamburgerButton.scss";

const HamburgerButton = ({ onClick, hasBackground, onDashboard }) => {
  return (
    <button
      className={cx("HamburgerButton", {
        hasBackground: hasBackground,
        onDashboard: onDashboard,
      })}
      onClick={onClick}
      type="button"
    >
      <div className="HamburgerButtonLineContainer">
        <div className="HamburgerButtonLine"></div>
        <div className="HamburgerButtonLine"></div>
        <div className="HamburgerButtonLine"></div>
      </div>
    </button>
  );
};

HamburgerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasBackground: PropTypes.bool,
  onDashboard: PropTypes.bool,
};

HamburgerButton.defaultProps = {
  hasBackground: false,
  onDashboard: false,
};

export default HamburgerButton;
