import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import {
  createZoneAsync,
  deleteZoneAsync,
  getZonesAsync,
  updateZoneAsync,
} from "redux/actions/zoneAction";
import {
  getAdminMetaDataAsync,
  getGuestMetaDataAsync,
} from "redux/actions/metaDataAction";
import { filterZonesAndTablesByIsArchived } from "utils/helpers";

const initialState = {
  thunkAPIStates: {
    getGuestMetaData: false,
    getZones: false,
    createZone: false,
    updateZone: false,
    deleteZone: false,
  },
  zones: [],
  error: null,
};

export const zoneStore = createSlice({
  name: STORE_NAMES.zones,
  initialState: initialState,
  reducers: {
    resetZoneStore: () => {
      return { ...initialState };
    },
    setOrReplaceReservation: (state, action) => {
      const newReservations = action.payload;
      const updatedZones = state.zones.map((zone) => {
        const updatedTables = zone.tables.map((table) => {
          const updatedReservations = [...table.reservations];
          newReservations.forEach((newReservation) => {
            if (newReservation.table.id === table.id) {
              const existingReservationIndex = table.reservations.findIndex(
                (existingReservation) =>
                  existingReservation.id === newReservation.id
              );
              if (existingReservationIndex !== -1) {
                updatedReservations[existingReservationIndex] = newReservation;
              } else {
                updatedReservations.push(newReservation);
              }
            }
          });
          return {
            ...table,
            reservations: updatedReservations,
          };
        });
        return {
          ...zone,
          tables: updatedTables,
        };
      });
      return {
        ...state,
        zones: updatedZones,
      };
    },
    addMessagesToTheTable: (
      state,
      { payload: { zoneId, tableId, messages } }
    ) => {
      const updatedZones = [
        ...state.zones.map((zone) => {
          if (zone.id === zoneId) {
            return {
              ...zone,
              tables: zone.tables.map((table) => {
                if (table.id === tableId) {
                  return {
                    ...table,
                    messages: table.messages
                      ? [...table.messages, ...messages]
                      : [...messages],
                  };
                } else {
                  return table;
                }
              }),
            };
          } else {
            return zone;
          }
        }),
      ];

      return {
        ...state,
        zones: updatedZones,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGuestMetaDataAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getGuestMetaData: true },
      };
    });
    builder.addCase(getGuestMetaDataAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getGuestMetaData: false },
      };
    });
    builder.addCase(getGuestMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        zones: filterZonesAndTablesByIsArchived(payload.zones),
        thunkAPIStates: { ...state.thunkAPIStates, getGuestMetaData: false },
      };
    });
    builder.addCase(getZonesAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getZones: true },
      };
    });
    builder.addCase(getZonesAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getZones: false },
      };
    });
    builder.addCase(getZonesAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        zones: filterZonesAndTablesByIsArchived(payload),
        thunkAPIStates: { ...state.thunkAPIStates, getZones: false },
      };
    });
    builder.addCase(updateZoneAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, updateZone: true },
      };
    });
    builder.addCase(updateZoneAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, updateZone: false },
      };
    });
    builder.addCase(updateZoneAsync.fulfilled, (state, { payload }) => {
      const updatedZones = state.zones.map((zone) => {
        if (zone.id === payload.id) {
          return payload;
        }
        return zone;
      });
      return {
        ...state,
        zones: updatedZones,
        thunkAPIStates: { ...state.thunkAPIStates, updateZone: false },
      };
    });
    builder.addCase(createZoneAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, createZone: true },
      };
    });
    builder.addCase(createZoneAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, createZone: false },
      };
    });
    builder.addCase(createZoneAsync.fulfilled, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, createZone: false },
      };
    });
    builder.addCase(deleteZoneAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, deleteZone: true },
      };
    });
    builder.addCase(deleteZoneAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, deleteZone: false },
      };
    });
    builder.addCase(deleteZoneAsync.fulfilled, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, deleteZone: false },
      };
    });
    builder.addCase(getAdminMetaDataAsync.pending, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getZones: true },
      };
    });
    builder.addCase(getAdminMetaDataAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getZones: false },
      };
    });
    builder.addCase(getAdminMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getZones: false },
        zones: filterZonesAndTablesByIsArchived(payload.zones),
      };
    });
  },
});

export const {
  resetZoneStore,
  addMessagesToTheTable,
  setOrReplaceReservation,
} = zoneStore.actions;
export default zoneStore.reducer;
