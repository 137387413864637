// TODO rename and move this file to constants folder
export const MENU_COLOR_OPTIONS = [
  [
    { code: "#1F1F1F", name: "Black" },
    { code: "#ffdd00", name: "Yellow" },
  ],
  [
    { code: "#220083", name: "DeepPurple800" },
    { code: "#F7A300", name: "Orange" },
  ],
  [
    {
      code: "#1E4D2B",
      name: "Green800",
    },
  ],
  [
    {
      code: "#009688",
      name: "Teal500",
    },
  ],
  [
    {
      code: "#0ABAB5",
      name: "Tiffany 500",
    },
  ],
  [
    {
      code: "#4CAF50",
      name: "Green500",
    },
  ],
  [
    {
      code: "#CDDC39",
      name: "Lime500",
    },
  ],
  [
    {
      code: "#FFC107",
      name: "Yellow500",
    },
  ],
  [
    {
      code: "#FF8C00",
      name: "Orange500",
    },
  ],
  [
    {
      code: "#DE0A26",
      name: "Red500",
    },
  ],
  [
    {
      code: "#FFDAE9",
      name: "Pink200",
    },
  ],
  [
    {
      code: "#CC1967",
      name: "Pink500",
    },
  ],
  [
    {
      code: "#9C27B0",
      name: "Purple500",
    },
  ],
  [
    {
      code: "#673AB7",
      name: "DeepPurple500",
    },
  ],
  [
    {
      code: "#B57EDC",
      name: "Purple300",
    },
  ],
  [
    {
      code: "#3F51B5",
      name: "Indigo500",
    },
  ],
  [
    {
      code: "#AFDBF5",
      name: "Blue300",
    },
  ],
  [
    {
      code: "#607D8B",
      name: "BlueGrey500",
    },
  ],
  // [
  //   {
  //     code: "#9E9E9E",
  //     name: "Grey500",
  //   },
  // ],
  // [
  //   {
  //     code: "#E5E4E2",
  //     name: "Grey300",
  //   },
  // ],
  [
    {
      code: "#EFDECD",
      name: "Beige500",
    },
  ],
  [
    {
      code: "#D1AA9C",
      name: "Brown300",
    },
  ],
  [
    {
      code: "#722F37",
      name: "Brown800",
    },
  ],
  [
    {
      code: "#000000",
      name: "Black",
    },
  ],
];

export const RADIUS_OPTIONS = [
  {
    type: "None",
    size: {
      A: 2,
      B: 2,
      C: 2,
      D: 2,
      E: 2,
      F: 2,
      G: 2,
      L: 2,
      M: 2,
      K: 2,
      S: 2,
      T: 0,
      Z: 0,
      MR: 0,
    },
  },
  {
    type: "S",
    size: {
      A: 8,
      B: 8,
      C: 8,
      D: 8,
      E: 8,
      F: 8,
      G: 8,
      L: 4,
      M: 4,
      K: 3,
      S: 4,
      T: 0,
      Z: 0,
      MR: 0,
    },
  },
  {
    type: "M",
    size: {
      A: 16,
      B: 14,
      C: 14,
      D: 16,
      E: 12,
      F: 14,
      G: 14,
      L: 8,
      M: 8,
      K: 8,
      S: 12,
      T: 0,
      Z: 0,
      MR: 0,
    },
  },
  {
    type: "L",
    size: {
      A: 24,
      B: 32,
      C: 32,
      D: 24,
      E: 16,
      F: 32,
      G: 32,
      L: 32,
      M: 32,
      K: 32,
      S: 32,
      T: 32,
      Z: 32,
      MR: 8,
    },
  },
];
