import React from "react";
import PropTypes from "prop-types";

import Checkbox, {
  ENUMS as CHECK_ENUMS,
} from "components/admin/forms/checkbox/Checkbox";
import useLanguage from "utils/hooks/useLanguage";
import { findItemById } from "utils/general";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { findModificationById, findOptionById } from "utils/helpers";

import "./OrderInfoCardItem.scss";

export const ENUMS = {
  name: "OrderInfoCardItem",
};

const OrderInfoCardItem = ({ orderItem, onClick, className }) => {
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const { isKitchenDone, count, item } = orderItem;
  const menuItemInRedux = findItemById(item.id, menu) || item;
  const { displayDataByLanguage } = useLanguage();

  return (
    <div className={`OrderInfoCardItem ${className}`} onClick={onClick}>
      <div className="OrderInfoCardItemTitle">
        <Checkbox isChecked={isKitchenDone} type={CHECK_ENUMS.types.TYPE_C} />
        <div className="OrderInfoCardItemName">
          <span>{displayDataByLanguage(menuItemInRedux.name)}</span>
          {item.modifications && item.modifications.length > 0 && (
            <ul className="OrderInfoCardItemModifications">
              {item.modifications.map((modification) => {
                const modificationInRedux =
                  findModificationById(
                    menuItemInRedux.modifications,
                    modification.id
                  ) || modification;
                return (
                  modification.options.length > 0 && (
                    <li key={modification.id}>
                      {displayDataByLanguage(modificationInRedux.name)}:{" "}
                      {modification.options
                        .map((option) => {
                          const optionInRedux =
                            findOptionById(
                              modificationInRedux.options,
                              option.id
                            ) || option;
                          return option.count > 1
                            ? `${option.count} x ${displayDataByLanguage(
                                optionInRedux.name
                              )}`
                            : displayDataByLanguage(optionInRedux.name);
                        })
                        .join(", ")}
                    </li>
                  )
                );
              })}
            </ul>
          )}
        </div>
      </div>

      <div className="OrderInfoCardItemCount">{count}</div>
    </div>
  );
};

OrderInfoCardItem.propTypes = {
  /**
   * Represents the order details.
   */

  orderItem: PropTypes.shape({
    id: PropTypes.number,
    isConfirmed: PropTypes.bool,
    isKitchenDone: PropTypes.bool,
    count: PropTypes.number,
    item: PropTypes.object,
  }),

  /**
   * Function triggered on click event.
   */
  onClick: PropTypes.func,

  className: PropTypes.string,
};

export default OrderInfoCardItem;
