import React from "react";
import PropTypes from "prop-types";

import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { ReactComponent as IconCrown } from "assets/icons/mascot/Crown.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./Photo.scss";

export const ENUMS = {
  name: "Photo",
};

const Photo = ({ image, activeImage, onClose, onClick }) => {
  const handleOnClose = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <div className="Photo" onClick={onClick}>
      {image === activeImage && <IconCrown className="ActivePhotoSign" />}
      <ImageWithPlaceholder
        imageSource={image}
        placeholder={IMAGE_ITEM_PLACEHOLDER}
        alt="photo"
        className="PhotoSource"
      />
      <div className="PhotoRemove">
        <CloseButton
          onClick={(e) => handleOnClose(e)}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_B}
        />
      </div>
    </div>
  );
};

Photo.propTypes = {
  /**
   * The image to be displayed
   */
  image: PropTypes.string.isRequired,

  /**
   * The active image
   */
  activeImage: PropTypes.string,

  /**
   * The function called when the close button is clicked
   */
  onClose: PropTypes.func.isRequired,

  /**
   * The function called when the component is clicked
   */
  onClick: PropTypes.func.isRequired,
};

export default Photo;
