import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";

const initialState = {
  kitchens: [
    {
      name: "General",
    },
    {
      name: "Bar",
    },
    {
      name: "Bar 2",
    },
    {
      name: "Bar 3",
    },
    {
      name: "Kitchen",
    },
    {
      name: "Kitchen 2",
    },
  ],
  isLoading: false,
  error: null,
};

export const kitchenStore = createSlice({
  name: STORE_NAMES.kitchens,
  initialState: initialState,
});

export default kitchenStore.reducer;
