import { useEffect, useRef, useState } from "react";

export default function useOutsideClick(
  handleOutsideClickAction = () => {},
) {
  const [openSlide, setOpenSlide] = useState(false);
  const [isClosable, setIsClosable] = useState(true);
  const mainElementRef = useRef(null);
  const toggleBtnRef = useRef(null);
  const handleOutsideClick = (e) => {
    const isToggleBtnClick = () => {
      if (toggleBtnRef.current)
        return !toggleBtnRef?.current?.contains(e.target);
      return true;
    };

    if (
      isClosable &&
      mainElementRef.current &&
      !mainElementRef?.current?.contains(e.target) &&
      isToggleBtnClick()
    ) {
      setOpenSlide(false);
      handleOutsideClickAction();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClickAction]);

  const setOutsideClickAction = (action) => {
      handleOutsideClickAction = action;
  };

  return [
    openSlide,
    setOpenSlide,
    mainElementRef,
    toggleBtnRef,
    setIsClosable,
    setOutsideClickAction,
  ];
}
