import React from "react";
import PropTypes from "prop-types";
import GuestComments from "./comment-item//GuestComments";

import "./OrderGuestCommentWrapper.scss";

const OrderGuestCommentWrapper = ({ guests }) => {
  return (
    <div className="OrderGuestCommentWrapper">
      <ol className="OrderGuestCommentWrapperList">
        {guests?.map(
          (guest) =>
            guest.orderMessage &&
            guest.orderMessage.length > 0 && (
              <GuestComments key={guest.person.id} guest={guest} />
            )
        )}
      </ol>
    </div>
  );
};

OrderGuestCommentWrapper.propTypes = {
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      person: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
      orderMessage: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default OrderGuestCommentWrapper;
