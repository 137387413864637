import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import Switch from "components/forms/switch/Switch";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import { getActiveLanguageValue } from "utils/helpers";
import Language, {
  ENUMS as LANGUAGE_ENUMS,
} from "components/admin/elements/language/Language";
import { ErrorMessage } from "@hookform/error-message";

import "./ModificationOption.scss";

export const ENUMS = {
  name: "ModificationOption",
};

const SWITCH_TYPES = {
  default: "default",
  multiple: "multiple",
};

const ModificationOption = ({
  item,
  handleInputChange,
  handleOptionRemove,
  errors,
}) => {
  const { t } = useTranslation();

  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencyCode = menuCurrency?.code;
  const [isMultipleSelectionChecked, setIsMultipleSelectionChecked] = useState(
    item.isCountable
  );
  const [activeLanguageCode, setActiveLanguageCode] = useState(null);
  const [isDefaultSelectionChecked, setIsDefaultSelectionChecked] = useState(
    item.defaultValue
  );
  useEffect(() => {
    setIsDefaultSelectionChecked(item.defaultValue);
    setIsMultipleSelectionChecked(item.isCountable);
    if (!activeLanguageCode) setActiveLanguageCode(item.name[0].languageCode);
  }, [item]);
  const handleToggleSwitch = (type) => {
    let updatedOption;
    if (type === SWITCH_TYPES.default) {
      setIsDefaultSelectionChecked((prevChecked) => !prevChecked);
      updatedOption = {
        ...item,
        defaultValue: !isDefaultSelectionChecked,
      };
    } else if (type === SWITCH_TYPES.multiple) {
      setIsMultipleSelectionChecked((prevChecked) => !prevChecked);
      updatedOption = {
        ...item,
        isCountable: !isMultipleSelectionChecked,
      };
    }
    handleInputChange(updatedOption);
  };

  const nameContent = (
    <InputControl
      name="name"
      placeholder={t("inputs.name")}
      required
      onChange={(e) => handleInputValueChange("name", e.target.value)}
      value={getActiveLanguageValue(item.name, activeLanguageCode)}
      labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
      error={
        <ErrorMessage
          errors={errors}
          name={`optionName${item.id}`}
          render={({ message }) => (
            <p className="h7 error-message">{message}</p>
          )}
        />
      }
      hasError={errors[`optionName${item.id}`]}
    />
  );

  const handleOnPriceChange = (e, name) => {
    const { value } = e.target;
    const updatedOption = {
      ...item,
      [name]: value !== "" ? parseFloat(value) : "",
    };
    handleInputChange(updatedOption);
  };

  const handleInputValueChange = (name, value) => {
    const updatedOption = {
      ...item,
      [name]: item[name].map((property) => {
        if (property.languageCode === activeLanguageCode) {
          return {
            ...property,
            value: value,
          };
        }
        return property;
      }),
    };
    handleInputChange(updatedOption);
  };

  const handleOnMaxNumberChange = (e) => {
    const maxNumber = parseFloat(e.target.value);
    const updatedOption = {
      ...item,
      maxNumber,
      isCountable: maxNumber > 1,
    };
    handleInputChange(updatedOption);
  };

  const setTabProperties = (properties, tabs) => {
    const newProperties = properties.map((property) => {
      return property.filter((item) => item.value !== null);
    });
    const updatedProperties = tabs.reduce((total, tab, index) => {
      return { ...total, [tab]: newProperties[index] };
    }, {});
    handleInputChange({ ...item, ...updatedProperties });
  };

  return (
    <div className="ModificationOption">
      <div className="ModificationOptionTop">
        <div className="ModificationOptionCheckbox">
          <Switch
            isChecked={isDefaultSelectionChecked}
            onChange={() => handleToggleSwitch(SWITCH_TYPES.default)}
            label={t("modification.selection.option.default")}
          />
        </div>
        <DeleteButton
          onClick={() => {
            handleOptionRemove(item.id);
          }}
        />
      </div>
      <Language
        type={LANGUAGE_ENUMS.types.TYPE_B}
        content={nameContent}
        activeLanguageCode={activeLanguageCode}
        setActiveLanguageCode={setActiveLanguageCode}
        properties={[item.name]}
        setProperties={(properties) => setTabProperties(properties, ["name"])}
      ></Language>
      <div className="ModificationOptionPrice">
        <div className="AddMenuItemModalBodyFormPrice">
          <InputControl
            name={`priceSell${item.id}`}
            type="number"
            value={item.priceSell}
            placeholder={t("inputs.sell")}
            className="AddMenuItemModalBodyFormInputHalfWidth"
            onChange={(e) => handleOnPriceChange(e, "priceSell")}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
            definitionText={currencyCode}
            error={
              <ErrorMessage
                errors={errors}
                name={`priceSell${item.id}`}
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            hasError={errors[`priceSell${item.id}`]}
          />

          <InputControl
            type="number"
            name={`priceCost${item.id}`}
            value={item.priceCost}
            placeholder={t("inputs.cost")}
            className="AddMenuItemModalBodyFormInputHalfWidth"
            onChange={(e) => handleOnPriceChange(e, "priceCost")}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
            definitionText={currencyCode}
            error={
              <ErrorMessage
                errors={errors}
                name={`priceCost${item.id}`}
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            hasError={errors[`priceCost${item.id}`]}
          />
        </div>
        <InputControl
          type="number"
          name={`maxNumber${item.id}`}
          value={item.maxNumber || ""}
          placeholder={t("inputs.maxNumber")}
          infoText={t("info.itemModificationOptionMaxNumberInfo")}
          className="ModificationOptionMaxNumber"
          onChange={handleOnMaxNumberChange}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
          error={
            <ErrorMessage
              errors={errors}
              name={`maxNumber${item.id}`}
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
          hasError={errors[`maxNumber${item.id}`]}
        />
      </div>
    </div>
  );
};

ModificationOption.propTypes = {
  /**
   * The item data to be displayed and edited
   */
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    value: PropTypes.string,
    priceSell: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    priceCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCountable: PropTypes.bool.isRequired,
    defaultValue: PropTypes.bool.isRequired,
    maxNumber: PropTypes.number,
  }).isRequired,

  /**
   * A callback function to handle option changes
   */
  handleInputChange: PropTypes.func.isRequired,

  /**
   * A callback function to handle option removal
   */
  handleOptionRemove: PropTypes.func.isRequired,

  errors: PropTypes.object,
};

export default ModificationOption;
