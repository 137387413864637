import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as IconClock } from "assets/icons/clock/ClockCircle.svg";

import "./WorkingHoursInfo.scss";

export const ENUMS = {
  name: "WorkingHoursInfo",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const WorkingHoursInfo = ({ name, from, to, type, className }) => {
  return (
    <div
      className={cx("WorkingHoursInfo", className, {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
      })}
    >
      <h6 className="WorkingHoursInfoName SemiBold">{name}</h6>
      <div className="WorkingHoursInfoDetails">
        <div className="WorkingHoursInfoIcon">
          <IconClock />
        </div>
        <h6 className="WorkingHoursInfoInterval SemiBold ">
          {from}-{to}
        </h6>
      </div>
    </div>
  );
};

WorkingHoursInfo.propTypes = {
  name: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
  className: PropTypes.string,
};
export default WorkingHoursInfo;
