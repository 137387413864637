import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import OrderInfoCard from "pages/admin/admin-pages/admin-kitchen/order-info-card/OrderInfoCard";
import { STORE_NAMES } from "utils/constants/redux";
import { getZoneAndTableById } from "utils/helpers";
import { updateOrderAsync } from "redux/actions/orderActions";

const AdminKitchenColumn = ({ title, droppableId, orders }) => {
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const dispatch = useDispatch();

  // Filter guests and users with non-empty orderItems
  const filteredOrders = orders
    .map((order) => ({
      ...order,
      guests: (order.guests || []).filter(
        (guest) => guest.orderItems?.length > 0
      ),
      users: (order.users || []).filter((user) => user.orderItems?.length > 0),
    }))
    .filter((order) => order.guests.length > 0 || order.users.length > 0);

  // Filter guests with confirmed orderItems
  const ordersWithConfirmedItems = filteredOrders.map((order) => ({
    ...order,
    guests: order.guests?.map((guest) => ({
      ...guest,
      orderItems: guest.orderItems?.filter(
        (orderItem) => orderItem.isConfirmed
      ),
    })),
  }));

  const updateOrderItems = (orderItems, orderItemId) => {
    return orderItems.map((orderItem) =>
      orderItem.id === orderItemId
        ? { ...orderItem, isKitchenDone: !orderItem.isKitchenDone }
        : orderItem
    );
  };

  const handleOrderItemUpdate = (orderItemId, orderId) => {
    const order = orders.find((order) => order.id === orderId);

    const updatedOrder = {
      ...order,
      guests: order.guests.map((guest) => ({
        ...guest,
        orderItems: updateOrderItems(guest.orderItems, orderItemId),
      })),
      users: order.users.map((user) => ({
        ...user,
        orderItems: updateOrderItems(user.orderItems, orderItemId),
      })),
    };

    dispatch(
      updateOrderAsync({ businessId, order: updatedOrder, id: order.id })
    );
  };

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          className="AdminKitchenColumn"
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ background: snapshot.isDraggingOver && "#e1e1e1" }}
        >
          <h6 className="Medium AdminKitchenColumnTitle">
            {title} <span>({ordersWithConfirmedItems?.length})</span>
          </h6>
          <div className="AdminKitchenColumnOrders">
            {ordersWithConfirmedItems.length > 0 &&
              [...ordersWithConfirmedItems]
                .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
                .map((order, index) => (
                  <OrderInfoCard
                    key={order.id}
                    order={order}
                    table={getZoneAndTableById(zones, order.table?.id)}
                    onOrderItemClick={(id) =>
                      handleOrderItemUpdate(id, order.id)
                    }
                    bottomMargin={8}
                    index={index}
                  />
                ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

AdminKitchenColumn.propTypes = {
  title: PropTypes.string.isRequired,
  droppableId: PropTypes.string,
  orders: PropTypes.array,
};

export default AdminKitchenColumn;
