import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getGuestMetaDataAsync } from "redux/actions/metaDataAction";

const initialState = {
  qrId: null,
  table: {},
  businessId: null,
  scanDateTime: null,
  isLoading: false,
  error: null,
};

export const qrScanStore = createSlice({
  name: STORE_NAMES.qrScan,
  initialState: initialState,
  reducers: {
    setBusinessIdAndQrId: (state, action) => {
      const { businessId, qrId } = action.payload;
      return {
        ...state,
        businessId: businessId,
        qrId: qrId,
      };
    },
    resetQrScanStore: () => {
      return { ...initialState };
    },
    replaceQrTable: (state, { payload }) => {
      if (payload?.id) {
        return {
          ...state,
          table: payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGuestMetaDataAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getGuestMetaDataAsync.rejected, (state, { payload }) => {
      return { ...state, isLoading: false, error: payload };
    });
    builder.addCase(getGuestMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        table: payload.qr.table ?? state.table,
      };
    });
  },
});

export const { setBusinessIdAndQrId, resetQrScanStore, replaceQrTable } =
  qrScanStore.actions;

export default qrScanStore.reducer;
