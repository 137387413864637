import React from "react";
import PropTypes from "prop-types";

import MenuViewGrid from "components/homepage-views/menu-view-grid/MenuViewGrid";

import "./MenuViewGridWrapper.scss";

export const ENUMS = {
  name: "MenuViewGridWrapper",
};

const MenuViewGridWrapper = ({
  menuItems,
  onAdd,
  onFavorite,
  onClick,
  favoriteItems = [],
  orderItems = [],
}) => {
  return (
    <div className="MenuViewGridWrapper">
      {menuItems.map((item, index) => (
        <MenuViewGrid
          key={index}
          onAdd={onAdd}
          onFavorite={onFavorite}
          onClick={onClick}
          menuItem={item}
          favoriteItems={favoriteItems}
          orderItems={orderItems}
        />
      ))}
    </div>
  );
};

MenuViewGridWrapper.propTypes = {
  /**
   * Menu items array
   */
  menuItems: PropTypes.array.isRequired,

  /**
   * Add action
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Favorite action
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * A function to handle a click event on an item.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * An array of favorite items.
   */
  favoriteItems: PropTypes.array,

  /**
   * An array of order items.
   */
  orderItems: PropTypes.array,
};

export default MenuViewGridWrapper;
