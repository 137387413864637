import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { ORDER_STATUS_ENUM } from "utils/constants/data/menu-model";
import {
  createOrderAsync,
  getOrderByIdAsync,
  getOrdersAsync,
} from "redux/actions/orderActions";
import { deepMergeById } from "utils/helpers";

const initialState = {
  orders: [],
  selectedOrder: null,
  thunkAPIStates: {
    getAllOrder: false,
    getSelectedOrder: false,
  },
  error: null,
  isFinished: false,
  isDeleted: false,
};

export const ordersStore = createSlice({
  name: STORE_NAMES.orders,
  initialState: initialState,
  reducers: {
    setOrReplaceOrder: (state, action) => {
      const newOrders = action.payload;
      newOrders.forEach((newOrder) => {
        const index = state.orders.findIndex(
          (existingOrder) => existingOrder.id === newOrder.id
        );
        if (index >= 0) {
          state.orders[index] = deepMergeById(state.orders[index], newOrder);
        } else {
          state.orders.push(newOrder);
        }
      });

      state.isFinished = state.orders.some(
        (order) => order.status === "FINISHED"
      );

      state.isDeleted = state.orders.some((order) => order.isArchived === true);

      state.orders = state.orders.filter(
        (order) => !order.isArchived && order.status !== "FINISHED"
      );
    },
    setOrReplaceSelectedOrder: (state, action) => {
      const newOrders = action.payload;
      newOrders.forEach((newOrder) => {
        if (state.selectedOrder && newOrder.id === state.selectedOrder.id) {
          if (newOrder.status === "FINISHED" || newOrder.isArchived) {
            return (state.selectedOrder = null);
          }
          state.selectedOrder = deepMergeById(state.selectedOrder, newOrder);
        }
      });
    },
    setNullSelectedOrder(state) {
      state.selectedOrder = null;
    },
    placeOrder: (state, action) => {
      const newOrder = action.payload;
      const date = new Date();
      const doesTableExist = state.orders.find(
        (order) => order.table === newOrder.table
      );
      let updatedOrders;
      if (doesTableExist) {
        updatedOrders = state.orders.map((order) => {
          if (order.table === newOrder.table) {
            return newOrder;
          }
          return order;
        });
      } else {
        updatedOrders = [
          ...state.orders,
          {
            ...newOrder,
            createDate: date,
            status: ORDER_STATUS_ENUM.ordered,
          },
        ];
      }
      return {
        ...state,
        orders: updatedOrders,
      };
    },
    removeFinishedOrder: (state, action) => {
      const orderId = action.payload;
      const updatedOrders = state.orders.filter(
        (order) => order.id !== orderId
      );
      return {
        ...state,
        orders: updatedOrders,
      };
    },
    resetOrdersStore: () => {
      return { ...initialState };
    },
    resetOrderPaymentFinishedFlag: (state) => {
      return { ...state, isFinished: false };
    },
    resetOrderDeletedFlag: (state) => {
      return { ...state, isDeleted: false };
    },
    setLoading: (state, action) => {
      const status = action.payload;
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getAllOrder: status },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdersAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getAllOrder: true },
      };
    });
    builder.addCase(getOrdersAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getAllOrder: false },
      };
    });
    builder.addCase(getOrdersAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        orders: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getAllOrder: false },
      };
    });
    builder.addCase(getOrderByIdAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getSelectedOrder: true },
      };
    });
    builder.addCase(getOrderByIdAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getSelectedOrder: false },
      };
    });
    builder.addCase(getOrderByIdAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        selectedOrder: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getSelectedOrder: false },
      };
    });
    builder.addCase(createOrderAsync.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, getSelectedOrder: true },
      };
    });
    builder.addCase(createOrderAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getSelectedOrder: false },
      };
    });
    builder.addCase(createOrderAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        selectedOrder: payload,
        thunkAPIStates: { ...state.thunkAPIStates, getSelectedOrder: false },
      };
    });
  },
});

export const {
  setOrReplaceOrder,
  setOrReplaceSelectedOrder,
  setNullSelectedOrder,
  placeOrder,
  removeFinishedOrder,
  resetOrdersStore,
  resetOrderPaymentFinishedFlag,
  resetOrderDeletedFlag,
  setLoading,
} = ordersStore.actions;

export default ordersStore.reducer;
