import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import ActionNotificationButton, {
  ENUMS as ACTION_NOTIFICATION_BUTTON_ENUMS,
} from "components/admin/buttons/action-notification-button/ActionNotificationButton";
import AddNewButton from "components/admin/buttons/add-new-button/AddNewButton";
import DateSelection from "components/admin/forms/date-selection/DateSelection";
import CalendarPicker from "components/elements/calendar-picker/CalendarPicker";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { STORE_NAMES } from "utils/constants/redux";

import "./AdminReservationHeader.scss";

const AdminReservationHeader = ({
  selectedDate,
  selectedZone,
  pendingReservations,
  openPendingBooks,
  onAddNewReservation,
  onDateChange,
  setSelectedZone,
}) => {
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const [openCalendar, setOpenCalendar, mainElementRefCalendar] =
    useOutsideClick();
  const { t } = useTranslation();

  return (
    <>
      <div className="AdminReservationHeader">
        <div className="AdminReservationSelectDateAndZone">
          <DateSelection
            buttonLabel={t("buttons.change")}
            onSelect={() => setOpenCalendar(true)}
            value={selectedDate.toLocaleDateString()}
            maxWidth={240}
          />
          <Dropdown
            onChange={(zone) => setSelectedZone(zone)}
            placeholder={t("zone.zone")}
            value={selectedZone}
            options={zones}
            className="AdminReservationSelectZone"
          />
        </div>

        <div className="AdminReservationHeaderControls">
          <ActionNotificationButton
            count={pendingReservations?.length || 0}
            label={t("reservation.pendingBooks")}
            onClick={openPendingBooks}
            type={ACTION_NOTIFICATION_BUTTON_ENUMS.types.TYPE_A}
          />
          <AddNewButton
            onClick={onAddNewReservation}
            label={t("buttons.addNew")}
          />
        </div>
      </div>

      <CalendarPicker
        value={selectedDate.toLocaleDateString()}
        minDate={new Date()}
        refCalendar={mainElementRefCalendar}
        setOpenCalendar={setOpenCalendar}
        openCalendar={openCalendar}
        handleDateChange={(date) => {
          setOpenCalendar(false);
          onDateChange(date);
        }}
      />
    </>
  );
};

AdminReservationHeader.propTypes = {
  selectedDate: PropTypes.object,
  selectedZone: PropTypes.object,
  pendingReservations: PropTypes.array,
  openPendingBooks: PropTypes.func,
  setSelectedZone: PropTypes.func,
  onAddNewReservation: PropTypes.func,
  onDateChange: PropTypes.func,
};

export default AdminReservationHeader;
