import { createAsyncThunk } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getBusiness, updateBusiness, updateBusinessSettings } from "utils/api/services/business";

export const getBusinessAsync = createAsyncThunk(
  `${STORE_NAMES.business}/getBusiness`,
  async (id) => {
    const response = await getBusiness(id);
    return response.data;
  }
);

export const updateBusinessAsync = createAsyncThunk(
  `${STORE_NAMES.business}/updateBusiness`,
  async ({ business, id }) => {
    const response = await updateBusiness(business, id);
    return response.data;
  }
);

export const updateBusinessSettingsAsync = createAsyncThunk(
  `${STORE_NAMES.business}/updateBusinessSettings`,
  async ({ business, id }) => {
    const response = await updateBusinessSettings(business, id);
    return response.data;
  }
);
