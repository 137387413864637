import React from "react";
import PropTypes from "prop-types";

import RadioButton from "components/buttons/radio-button/RadioButton";

import "./MethodSelection.scss";

export const ENUMS = {
  name: "MethodSelection",
};
const MethodSelection = ({
  methodSelectionIcon,
  methodSelectionName,
  isSelected,
  onClick,
}) => {
  return (
    <div className="MethodSelection" onClick={onClick}>
      {methodSelectionIcon && (
        <div className="MethodSelectionIcon">{methodSelectionIcon}</div>
      )}
      <h5 className="MethodSelectionName">{methodSelectionName}</h5>
      <RadioButton count={isSelected} />
    </div>
  );
};

MethodSelection.propTypes = {
  methodSelectionName: PropTypes.string.isRequired,
  methodSelectionIcon: PropTypes.node,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default MethodSelection;
