import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { setOrReplaceOrder } from "redux/slices/ordersStore";
import { useEffect } from "react";
import { generateDynamicWSEventName4, REDIS_CHANNELS } from "utils/api/redis";
import { setOrReplaceReservation } from "redux/slices/zoneStore";
import { addPartialMenuUpdate } from "redux/slices/menuStore";
import { replaceQrTable, resetQrScanStore } from "redux/slices/qrScanStore";
import { resetChatStore } from "redux/slices/chatStore";

const useGuestWebsocket = (socket) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state[STORE_NAMES.user]);
  const {
    enums: { orderStatus },
  } = useSelector((state) => state[STORE_NAMES.app]);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const { table, businessId } = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  );
  const tableId = table?.id;

  useEffect(() => {
    if (socket && businessId) {
      // join
      socket.emit(REDIS_CHANNELS.guestOrder.join, {
        businessId,
        tableId: tableId,
        user,
      });
      // subscribe to messages
      socket.on(
        generateDynamicWSEventName4(
          REDIS_CHANNELS.guestOrder.eventName1,
          businessId,
          REDIS_CHANNELS.guestOrder.eventName2,
          tableId
        ),
        (payloadString) => {
          const payload = JSON.parse(payloadString);
          if (businessId === payload.businessId) {
            if (payload.data) {
              if (payload.data.reservations) {
                dispatch(setOrReplaceReservation(payload.data.reservations));
              }
              if (payload.data.orders) {
                payload.data.orders.forEach((newOrder) => {
                  const index = orders.findIndex((existingOrder) => {
                    return existingOrder.id === newOrder.id;
                  });
                  if (
                    index >= 0 &&
                    (newOrder.isArchived ||
                      newOrder.status === orderStatus.finished)
                  ) {
                    dispatch(resetChatStore());
                    return dispatch(resetQrScanStore());
                  }
                });
                dispatch(setOrReplaceOrder(payload.data.orders));
                // if order table change, also change guest table in qrScanStore
                dispatch(replaceQrTable(payload.data?.newTable));
              }
              if (payload.data.menu) {
                dispatch(addPartialMenuUpdate(payload.data.menu));
              }
            }
            if (payload.message) {
              // toast.success(payload.message);
            }
          }
        }
      );
    }
    return () => {
      // TODO replace disconnect with  socket.off
      if (socket) {
        socket.off(
          generateDynamicWSEventName4(
            REDIS_CHANNELS.guestOrder.eventName1,
            businessId,
            REDIS_CHANNELS.guestOrder.eventName2,
            tableId
          )
        );
      }
    };
  }, [socket, businessId]);
};

export default useGuestWebsocket;
