import React from "react";
import { createDOBucketName } from "utils/DO-Spaces";
import { setUserBusiness } from "redux/slices/userStore";
import { ROUTE_NAME } from "utils/constants/routes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./SelectUserBusiness.scss";


const SelectUserBusiness = ({ userBusinesses }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSelectUserBusiness = async (selectedBusiness) => {
    dispatch(
      setUserBusiness({
        businessId: selectedBusiness.id,
        menuId: selectedBusiness.menu.id,
      })
    );
    await navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.adminOrderDashboard}`);
  };
  return (
    <div className="SelectBusinessModalBody">
      <h3 className="SemiBold">{t("business.selectTitle")}</h3>
      <p>{t("business.selectSubTitle")}</p>
      <ul className="UserBusinessLists">
        {userBusinesses.length > 0 &&
          userBusinesses?.map((business) => (
            <li
              key={business.id}
              onClick={() => handleSelectUserBusiness(business)}
            >
              <ImageWithPlaceholder
                imageSource={createDOBucketName(business.images.logo)}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt="business-logo"
              />
              <h3 className="SemiBold">{business.name}</h3>
            </li>
          ))}
      </ul>
    </div>
  );
};

SelectUserBusiness.propTypes = {
  userBusinesses: PropTypes.array,
};

export default SelectUserBusiness;
