import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { findMenuItemIndexFromOrders } from "utils/helpers";

export const ACTIONS = {
  resetFavoriteItems: "RESET_FAVORITE_ITEMS",
  resetOrderItems: "RESET_ORDER_ITEMS",
};

const initialState = {
  order: {
    priceSummary: {
      promo: null,
    },
    guests: [],
  },
  isLoading: false,
  error: null,
};

export const basketStore = createSlice({
  name: STORE_NAMES.basket,
  initialState: initialState,
  reducers: {
    setBasketGuestId: (state, { payload }) => {
      const nullGuest = state.order.guests.find((guest) => !guest.person.id);
      if (nullGuest) {
        return {
          ...state,
          order: {
            ...state.order,
            guests: [{ ...nullGuest, person: { id: payload.guestId } }],
          },
        };
      } else {
        const guestBasket = state.order.guests.find(
          (guest) => guest.person.id === payload.guestId
        );
        if (guestBasket) {
          return {
            ...state,
            order: {
              ...state.order,
              guests: [guestBasket],
            },
          };
        }
        return {
          ...state,
          order: {
            ...state.order,
            guests: [],
          },
        };
      }
    },
    updateBasket: (state, action) => {
      const { userId, menuItem, count } = action.payload;

      const guestIndex = state.order.guests.findIndex(
        (guest) => guest.person.id === userId
      );
      if (guestIndex === -1) {
        const newGuest = {
          person: { id: userId },
          name: "Guest",
          favoriteItems: [],
          orderItems: [{ item: menuItem, count: count }],
          orderMessage: "",
        };

        return {
          ...state,
          order: { ...state.order, guests: [...state.order.guests, newGuest] },
        };
      } else {
        const guestsCopy = state.order.guests.map((guest) => {
          if (guest.person.id === userId) {
            const isMenuItemOrdered = guest.orderItems.find(
              (orderItem) => menuItem.id === orderItem.item.id
            );
            let orderItems;

            if (isMenuItemOrdered) {
              const orderItemIndex = findMenuItemIndexFromOrders(
                menuItem,
                guest.orderItems
              );

              if (orderItemIndex === -1) {
                orderItems = [
                  ...guest.orderItems,
                  { item: menuItem, count: count },
                ];
              } else {
                const updatedCount = count
                  ? guest.orderItems[orderItemIndex].count + count
                  : -1;
                if (updatedCount > 0) {
                  orderItems = guest.orderItems.map((orderItem, index) => {
                    if (index === orderItemIndex) {
                      return {
                        item: {
                          ...menuItem,
                        },
                        count: updatedCount,
                      };
                    }
                    return orderItem;
                  });
                } else {
                  orderItems = guest.orderItems.filter(
                    (orderItem, index) => index !== orderItemIndex
                  );
                }
              }
            } else {
              orderItems = [
                ...guest.orderItems,
                { item: menuItem, count: count },
              ];
            }
            return {
              ...guest,
              orderItems: orderItems,
            };
          }
          return guest;
        });

        return {
          ...state,
          order: { ...state.order, guests: guestsCopy },
        };
      }
    },
    deleteModificationFromItem: (state, { payload }) => {
      const { guestId, menuItemId, modificationId } = payload;

      const updatedGuests = state.order.guests.map((guest) => {
        if (guest.person.id === guestId) {
          const updatedOrderItems = guest.orderItems.map((orderItem) => {
            if (orderItem.item.id === menuItemId) {
              const updatedModifications = orderItem.item.modifications.filter(
                (modification) => modification.id !== modificationId
              );
              return {
                ...orderItem,
                item: {
                  ...orderItem.item,
                  modifications: updatedModifications,
                },
              };
            }
            return orderItem;
          });
          return {
            ...guest,
            orderItems: updatedOrderItems,
          };
        }
        return guest;
      });

      return {
        ...state,
        order: {
          ...state.order,
          guests: updatedGuests,
        },
      };
    },
    updateFavorites: (state, action) => {
      const { userId, menuItemID } = action.payload;
      const guestIndex = state.order.guests.findIndex(
        (guest) => guest.person.id === userId
      );
      if (guestIndex === -1) {
        const newGuest = {
          person: { id: userId },
          name: "Guest",
          favoriteItems: [menuItemID],
          orderItems: [],
          orderMessage: "",
        };
        return {
          ...state,
          order: {
            ...state.order,
            guests: [...state.order.guests, newGuest],
          },
        };
      } else {
        const guestsCopy = state.order.guests.map((guest, index) => {
          if (index === guestIndex) {
            const favoriteItems = guest.favoriteItems.includes(menuItemID)
              ? guest.favoriteItems.filter((id) => id !== menuItemID)
              : [...guest.favoriteItems, menuItemID];

            return {
              ...guest,
              favoriteItems: favoriteItems,
            };
          }
          return guest;
        });

        return {
          ...state,
          order: {
            ...state.order,
            guests: guestsCopy,
          },
        };
      }
    },
    updateOrderItemByIndex: (state, action) => {
      const { userId, menuItem, count, basketIndex } = action.payload;
      const guestIndex = state.order.guests.findIndex(
        (guest) => guest.person.id === userId
      );
      if (guestIndex !== -1) {
        const guestsCopy = state.order.guests.map((guest) => {
          if (guest.person.id === userId) {
            let orderItems = guest.orderItems.map((orderItem, index) => {
              if (index === basketIndex) {
                return { item: menuItem, count: count };
              }
              return orderItem;
            });
            return {
              ...guest,
              orderItems: orderItems,
            };
          }
          return guest;
        });

        return {
          ...state,
          order: { ...state.orders, guests: guestsCopy },
        };
      }
    },
    updateOrderMessage: (state, action) => {
      const { userId, message } = action.payload;

      const guestIndex = state.order.guests.findIndex(
        (guest) => guest.person.id === userId
      );
      if (guestIndex !== -1) {
        const guestsCopy = state.order.guests.map((guest) => {
          if (guest.person.id === userId) {
            return {
              ...guest,
              orderMessage: message,
            };
          }
          return guest;
        });

        return {
          ...state,
          order: { ...state.order, guests: guestsCopy },
        };
      }
    },
    applyPromo: (state, action) => {
      const promo = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          priceSummary: { ...state.order.priceSummary, promo: promo },
        },
      };
    },
    removePromo: (state) => {
      return {
        ...state,
        order: {
          ...state.order,
          priceSummary: { ...state.order.priceSummary, promo: null },
        },
      };
    },
    resetItems: (state, action) => {
      const { userId, actionType } = action.payload;

      const guestIndex = state.order.guests.findIndex(
        (guest) => guest.person.id === userId
      );
      if (guestIndex !== -1) {
        const updatedGuests = state.order.guests.map((guest) => {
          if (guest.person.id === userId) {
            return {
              ...guest,
              favoriteItems:
                actionType === ACTIONS.resetFavoriteItems
                  ? []
                  : guest.favoriteItems,
              orderItems:
                actionType === ACTIONS.resetOrderItems ? [] : guest.orderItems,
              orderMessage:
                actionType === ACTIONS.resetOrderItems
                  ? ""
                  : guest.orderMessage,
            };
          }
          return guest;
        });

        return {
          ...state,
          order: { ...state.order, guests: updatedGuests },
        };
      }
    },
    resetBasketStore: () => {
      return { ...initialState };
    },
    setLoading: (state, action) => {
      const status = action.payload;
      return { ...state, isLoading: status };
    },
  },
});

export const {
  updateBasket,
  updateFavorites,
  updateOrderItemByIndex,
  updateOrderMessage,
  applyPromo,
  removePromo,
  resetItems,
  resetBasketStore,
  deleteModificationFromItem,
  setLoading,
  setBasketGuestId,
} = basketStore.actions;

export default basketStore.reducer;
