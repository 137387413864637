import React, { useState } from "react";
import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import AdminNavbarController from "components/admin-navbar-controller/AdminNavbarController";
import { ReactComponent as ClocheIcon } from "assets/icons/cloche/cloche.svg";
import { ReactComponent as ArrowIconLeft } from "assets/icons/arrows/ArrowLeft.svg";
import { ReactComponent as ArrowIconRight } from "assets/icons/arrows/Right-Arrow.svg";
import AdminOrderDetail from "../admin-order-detail/AdminOrderDetail";
import PropTypes from "prop-types";
import cx from "classnames";
import { getGuestsWithUnconfirmedItems } from "utils/helpers";

import "./AdminOrderDetailSidebar.scss";

const AdminOrderDetailSidebar = ({
  selectedOrder,
  selectedOrderItem,
  setSelectedOrderItem,
  isEnableToAddOrder,
  setIsEnableToAddOrder,
  pendingData,
  setPendingData,
  formData,
  setFormData,
  handleOnUpdateOrder,
  setOpenSlideOrderItem,
  openSlideOrderItem,
  mainElementRefOrderItem,
}) => {
  const { isScreenSize } = useWindowSize();
  const isTabletScreen = isScreenSize(BREAKPOINT_NAMES.tablet);
  const initialNavbarState = !isTabletScreen;
  const [isNavbarOpen, setIsNavbarOpen] = useState(initialNavbarState);
  const notificationActions = formData.actions.filter((action) => action.value);

  const hasNotifications =
    notificationActions.length > 0 ||
    getGuestsWithUnconfirmedItems(formData).length > 0;
  const hasPendingGuestsOrUsers =
    pendingData.guests.length > 0 || pendingData.users.length > 0;
  const buttonClass = hasNotifications
    ? "AdminOrderDetailSidebarButton AdminOrderDetailSidebarButtonAnimationGreen"
    : hasPendingGuestsOrUsers
    ? "AdminOrderDetailSidebarButton AdminOrderDetailSidebarButtonAnimationBlue"
    : "AdminOrderDetailSidebarButton";

  const hamburgerMenu = (
    <button
      onClick={() => setIsNavbarOpen(true)}
      className={buttonClass}
      type="button"
    >
      <div className="buttonCircle ">
        <ClocheIcon />
      </div>
      <div className={"ArrowIcon"}>
        <ArrowIconLeft width={10} />
      </div>
    </button>
  );
  return (
    isTabletScreen && (
      <AdminNavbarController
        isNavbarOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
        isTabletScreen={isTabletScreen}
        hamburgerMenu={hamburgerMenu}
      >
        <div
          className={cx(
            "AdminOrderDetailSidebar",
            { isDesktopBarClosed: !isNavbarOpen && !isTabletScreen },
            { isMobileBarClosed: !isNavbarOpen && isTabletScreen },
            { isTabletScreen: isTabletScreen }
          )}
        >
          <AdminOrderDetail
            selectedOrder={selectedOrder}
            selectedOrderItem={selectedOrderItem}
            setSelectedOrderItem={setSelectedOrderItem}
            isEnableToAddOrder={isEnableToAddOrder}
            setIsEnableToAddOrder={setIsEnableToAddOrder}
            pendingData={pendingData}
            setPendingData={setPendingData}
            formData={formData}
            setFormData={setFormData}
            handleOnUpdateOrder={handleOnUpdateOrder}
            openSlideOrderItem={openSlideOrderItem}
            setOpenSlideOrderItem={setOpenSlideOrderItem}
            mainElementRefOrderItem={mainElementRefOrderItem}
          />
          <button
            className={cx(
              "CloseSidebarButton",
              { isDesktopBarClosed: !isNavbarOpen && !isTabletScreen },
              { isMobileBarClosed: !isNavbarOpen && isTabletScreen },
              { isTabletScreen: isTabletScreen }
            )}
            onClick={() => setIsNavbarOpen(false)}
            type="button"
          >
            <ArrowIconRight />
          </button>
        </div>
      </AdminNavbarController>
    )
  );
};

AdminOrderDetailSidebar.propTypes = {
  /**
   * The selected order to display in the modal
   */
  selectedOrder: PropTypes.object,

  /**
   * The function to set the selected order
   */
  setSelectedOrder: PropTypes.func,

  selectedOrderItem: PropTypes.object,
  setSelectedOrderItem: PropTypes.func,
  isEnableToAddOrder: PropTypes.bool,
  setIsEnableToAddOrder: PropTypes.func,

  pendingData: PropTypes.object,
  setPendingData: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleOnUpdateOrder: PropTypes.func,
  setOpenSlideOrderItem: PropTypes.func,
  openSlideOrderItem: PropTypes.bool,
  mainElementRefOrderItem: PropTypes.object,
};

export default AdminOrderDetailSidebar;
