import { createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "utils/api/services/user";
import { STORE_NAMES } from "utils/constants/redux";

export const signin = createAsyncThunk(
  `${STORE_NAMES.user}/signin`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.signin(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendCode = createAsyncThunk(
  `${STORE_NAMES.user}/resend-code`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.resendCode(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyUser = createAsyncThunk(
  `${STORE_NAMES.user}/verify-user`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.verifyUser(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signup = createAsyncThunk(
  `${STORE_NAMES.user}/signup`,
  async ({ userData, method }, { rejectWithValue }) => {
    try {
      const response = await api.signup(userData, method);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  `${STORE_NAMES.user}/update-user`,
  async (
    { businessId, userData, userId, method, sendPinCode },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.updateUser(
        businessId,
        userData,
        userId,
        method,
        sendPinCode
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  `${STORE_NAMES.user}/getUsers`,
  async ({ businessId }, { rejectWithValue }) => {
    try {
      const response = await api.getUsers(businessId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
