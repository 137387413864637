import React from "react";
import PropTypes from "prop-types";

import IconButton, {
  ENUMS as ENUMS_ICON_BUTTON,
} from "components/buttons/icon-button/IconButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ReactComponent as IconThreeDots } from "assets/icons/dot/ThreeDots.svg";

import "./ThreeDots.scss";

export const ENUMS = {
  name: "ThreeDots",
};

const ThreeDots = ({ options }) => {
  const [
    openSlideThreeDots,
    setOpenSlideThreeDots,
    mainElementRefThreeDots,
    toggleBtnRef,
  ] = useOutsideClick();

  const handleOnClick = (e) => {
    e.stopPropagation();
    setOpenSlideThreeDots(!openSlideThreeDots);
  };

  const handleOnDropdownItemClick = (e, callback) => {
    e.stopPropagation();
    callback();
    setOpenSlideThreeDots(false);
  };

  return (
    <div className="ThreeDots">
      <div ref={toggleBtnRef} className="ThreeDotsButton">
        <IconButton
          onClick={handleOnClick}
          svgComponent={<IconThreeDots />}
          type={ENUMS_ICON_BUTTON.types.TYPE_SM}
          className="ThreeDotsButtonIcon"
        />
      </div>

      {openSlideThreeDots && (
        <div ref={mainElementRefThreeDots} className="ThreeDotsDropdown">
          {options.map((option) => (
            <h6
              key={option.name}
              className="h7 ThreeDotsDropdownItem"
              onClick={(e) => handleOnDropdownItemClick(e, option.action)}
            >
              {option.name}
            </h6>
          ))}
        </div>
      )}
    </div>
  );
};

ThreeDots.propTypes = {
  /**
   * The options of dropdown
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    })
  ),
};

export default ThreeDots;
