import React, { useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import { Controller, useFormContext } from "react-hook-form";
import 'react-international-phone/style.css';
import cx from "classnames";

import RadioSelection, {
  ENUMS as ENUMS_RADIO_SELECTION,
} from "components/admin/forms/radio-selection/RadioSelection";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import { AVAILABLE_COUNTRIES } from "utils/constants/data/base";

import "./EmailOrPhone.scss";

export const ENUMS = {
  name: "EmailOrPhone",
  defaultCountry: "az",
  plusSign: "+",
};


const EmailOrPhone = ({
  className,
  readOnly,
  signInMethod,
  setSignInMethod,
}) => {
  const { t } = useTranslation();
  const { register, control, clearErrors, formState: { errors } } = useFormContext();

  const handlePhoneInputChange = (phone, meta, onChange) => {
    if (phone && phone !== ENUMS.plusSign + meta.country.dialCode) {
      onChange(phone);
    } else {
      onChange("");
    }
  };

  // const countries = defaultCountries.filter((country) => {
  //   const { iso2 } = parseCountry(country);
  //   return AVAILABLE_COUNTRIES.includes(iso2);
  // });

  useEffect(() => {
    clearErrors(SIGN_IN_METHOD.phoneNumber);
    clearErrors(SIGN_IN_METHOD.email);
  }, [signInMethod]);

  return (
    <div className={className}>
      <div className="EmailOrPhoneSignInMethod">
        <h5 className="SemiBold">{t("login.signIn.method")}</h5>
        <div className="EmailOrPhoneSignInMethodSelection">
          <RadioSelection
            value={SIGN_IN_METHOD.phoneNumber}
            label={t("inputs.phoneNumber")}
            name="signInMethod"
            isChecked={signInMethod === SIGN_IN_METHOD.phoneNumber}
            onChange={() => setSignInMethod(SIGN_IN_METHOD.phoneNumber)}
            type={ENUMS_RADIO_SELECTION.types.TYPE_YELLOW}
            isDisabled={readOnly}
          />
          <RadioSelection
            value={SIGN_IN_METHOD.email}
            label={t("inputs.email")}
            name="signInMethod"
            isChecked={signInMethod === SIGN_IN_METHOD.email}
            onChange={() => setSignInMethod(SIGN_IN_METHOD.email)}
            type={ENUMS_RADIO_SELECTION.types.TYPE_YELLOW}
            isDisabled={readOnly}
          />
        </div>
      </div>
      {signInMethod === SIGN_IN_METHOD.phoneNumber ? (
        <>
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <div >
                <PhoneInput
                  value={value}
                  onChange={(phone, meta) => {
                    handlePhoneInputChange(phone, meta, onChange);
                  }}
                  disabled={readOnly}
                  placeholder={t("inputs.phoneNumber")}
                  defaultCountry={ENUMS.defaultCountry}
                  forceDialCode
                  //TODO: Enable dropdown and list of countries when the SMS verification is implemented globally
                  hideDropdown
                  // countries={countries}
                  className={cx("phone-input", {
                    hasError: errors.phoneNumber,
                  })}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </div>
            )}
          />
          <div className="EmailOrPhoneSignInMethodError">
            {errors.phoneNumber && (
              <ErrorMessage
                errors={errors}
                name="phoneNumber"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            )}
          </div>
        </>
      ) : (
        <InputControl
          type="text"
          placeholder="Email"
          name="email"
          readOnly={readOnly}
          {...register("email", {
            setValueAs: (value) => value || "",
          })}
          hasError={errors.email}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          noLabelFloating
          error={
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
      )}
    </div>
  );
};

EmailOrPhone.propTypes = {
  /*
   ** Class name for the component
   */
  className: PropTypes.string,

  /*
   ** Boolean to make the input field readOnly
   */
  readOnly: PropTypes.bool,

  /*
   ** String containing the sign in method
   */
  signInMethod: PropTypes.string,

  /*
   ** Function to set the sign in method
   */
  setSignInMethod: PropTypes.func,
};

export default EmailOrPhone;
