import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./MenuItemDescription.scss";

export const ENUMS = {
  name: "MenuItemDescription",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const MenuItemDescription = ({ description, height, type }) => {
  return (
    <p
      style={{ height: `${height}px` }}
      className={cx(
        "MenuItemDescription",
        { typeA: type === ENUMS.types.TYPE_A },
        { typeB: type === ENUMS.types.TYPE_B }
      )}
    >
      {description}
    </p>
  );
};

MenuItemDescription.propTypes = {
  /**
   * The description of the component
   */
  description: PropTypes.string.isRequired,

  /**
   * The height of the component
   */
  height: PropTypes.number,

  /**
   * The type of the component
   */
  type: PropTypes.oneOf([ENUMS.types.TYPE_A, ENUMS.types.TYPE_B]),
};

export default MenuItemDescription;
