import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { setBusinessIdAndQrId } from "redux/slices/qrScanStore";
import { resetMenuFilters, setGuestId } from "redux/slices/guestStore";
import { STORE_NAMES } from "utils/constants/redux";
import { getGuestMetaDataAsync } from "redux/actions/metaDataAction";
import { createOrderGuest } from "utils/api/services/order";
import useAsync from "utils/hooks/useAsync";
import { setCurrency, setShowAccountInfoModal } from "redux/slices/userStore";
import {
  resetReduxStoresForGuest,
  resetReduxStoresIfOrderNotFound,
  resetReduxStoresWhenBusinessChange,
} from "utils/general";
import {
  MENU_COLOR_OPTIONS,
  RADIUS_OPTIONS,
} from "pages/admin/admin-pages/admin-menu/admin-menu-settings/data";
import {
  resetOrderDeletedFlag,
  resetOrderPaymentFinishedFlag,
  setOrReplaceOrder,
} from "redux/slices/ordersStore";
import { detectColorBrightness } from "utils/helpers";
import CompletedOrder from "components/elements/completed-order/CompletedOrder";
import useSetUserLanguage from "utils/hooks/useSetUserLanguage";
import WelcomeClient from "components/welcome-client/WelcomeClient";
import useTimeout from "utils/hooks/useTimeout";
import { setBasketGuestId } from "redux/slices/basketStore";
import useOutsideClick from "utils/hooks/useOutsideClick";
import GuestAccountInfoModal from "components/guest-account-info-modal/GuestAccountInfoModal";
import {
  browserEvents,
  BrowserEventsValue,
} from "../../utils/constants/browserEvents";

const ClientPagesController = () => {
  useSetUserLanguage();
  const { t } = useTranslation();
  const { businessId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const qrId = searchParams.get(QUERY_PARAMS.qrId);
  const dispatch = useDispatch();
  const { table } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const navigate = useNavigate();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const guestName = useSelector((state) => state[STORE_NAMES.guest]?.name);
  const shouldShowGuestAccountInfoModal = useSelector(
    (state) =>
      state[STORE_NAMES.user]?.userUIPreferences
        ?.shouldShowGuestAccountInfoModal
  );
  const [openSlideGuestAccountInfoModal, setOpenSlideGuestAccountInfoModal] =
    useOutsideClick();
  const {
    enums: { orderStatus },
  } = useSelector((state) => state[STORE_NAMES.app]);
  const order = useSelector((state) => state[STORE_NAMES.orders].orders[0]);
  const isOrderPaymentConfirmed = useSelector(
    (state) => state[STORE_NAMES.orders].isFinished
  );
  const isOrderDeleted = useSelector(
    (state) => state[STORE_NAMES.orders].isDeleted
  );

  const [isVisible, setIsVisible] = useState(true);
  const [showOfflineMenu, setShowOfflineMenu] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === BrowserEventsValue.visible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
        setWelcomeIsVisible(true);
      }
    };
    window.addEventListener(
      browserEvents.visibilitychange,
      handleVisibilityChange
    );
    return () => {
      window.removeEventListener(
        browserEvents.visibilitychange,
        handleVisibilityChange
      );
    };
  }, []);

  useEffect(() => {
    if (isOrderDeleted || isOrderPaymentConfirmed) {
      searchParams.delete(QUERY_PARAMS.showHamburgerMenu);
      setSearchParams(searchParams, { replace: true });
    }
  }, [isOrderPaymentConfirmed, isOrderDeleted]);

  const isTableEmpty = Object.keys(table).length === 0;

  useTimeout(() => {
    // Checks if there is a guest without name yet, it is the first time showing the modal, and guest is connected to the table
    if (
      guestId &&
      !guestName &&
      shouldShowGuestAccountInfoModal &&
      !isTableEmpty
    ) {
      setOpenSlideGuestAccountInfoModal(true);
    }
  }, 5000);

  const { business } = useSelector((state) => state[STORE_NAMES.business]);
  const { table: tableStore } = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isStoreReset, setIsStoreReset] = useState(false);

  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const preferredColor = useSelector(
    (state) => state[STORE_NAMES.menu].data?.primaryColor
  );
  const preferredRadius = useSelector(
    (state) => state[STORE_NAMES.menu].data?.borderRadius
  );

  const { execute: executeCreateOrder } = useAsync(createOrderGuest, {
    onSuccess: async (result) => {
      //!TODO should be tested in other devices
      if (!guestId && result.data.ordererId) {
        dispatch(setGuestId(result.data.ordererId)); // TODO this line also
        dispatch(setBasketGuestId({ guestId: result.data.ordererId })); // TODO this line also
      }
      await dispatch(setOrReplaceOrder([result.data]));
      await dispatch(resetMenuFilters());
    },
    onError: async () => {
      await resetReduxStoresIfOrderNotFound(dispatch);
    },
  });

  useEffect(() => {
    resetReduxStoresForGuest(dispatch, qrId).finally(() =>
      setIsStoreReset(true)
    );
  }, []);

  useEffect(() => {
    dispatch(setCurrency(menuCurrency));
  }, [menuCurrency]);

  useEffect(() => {
    if (preferredColor) {
      let selectedOption;
      if (preferredColor?.length === 2) {
        selectedOption = MENU_COLOR_OPTIONS.find(
          (el) =>
            el?.[0]?.name == preferredColor?.[0] &&
            el?.[1]?.name == preferredColor?.[1]
        );
        document.documentElement.style.setProperty(
          "--color-preferred-primary-bg",
          selectedOption?.[0]?.code
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-bg",
          selectedOption?.[1]?.code
        );
      } else {
        selectedOption = MENU_COLOR_OPTIONS.find(
          (el) => el?.[0]?.name == preferredColor?.[0] && el?.length === 1
        );
        document.documentElement.style.setProperty(
          "--color-preferred-primary-bg",
          selectedOption?.[0]?.code
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-bg",
          selectedOption?.[0]?.code
        );
      }
      if (preferredColor?.length === 2) {
        const primaryTextColor =
          detectColorBrightness(selectedOption?.[0]?.code) >= 50
            ? "#1f1f1f"
            : "#fff";
        const secondaryTextColor =
          detectColorBrightness(selectedOption?.[1]?.code) >= 50
            ? "#1f1f1f"
            : "#fff";

        document.documentElement.style.setProperty(
          "--color-preferred-primary-text",
          primaryTextColor
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-text",
          secondaryTextColor
        );
      }
      if (preferredColor?.length === 1) {
        const textColor =
          detectColorBrightness(selectedOption?.[0]?.code) >= 50
            ? "#1f1f1f"
            : "#fff";
        document.documentElement.style.setProperty(
          "--color-preferred-primary-text",
          textColor
        );
        document.documentElement.style.setProperty(
          "--color-preferred-secondary-text",
          textColor
        );
      }
    }
  }, [preferredColor]);

  useEffect(() => {
    if (preferredRadius) {
      const selectedOption = RADIUS_OPTIONS.find(
        (option) => option.type === preferredRadius
      );
      for (let key in selectedOption.size) {
        if (key === "MR") {
          document.documentElement.style.setProperty(
            `--margin-preferred-margin${key}`,
            selectedOption.size[key] + "px"
          );
        } else {
          document.documentElement.style.setProperty(
            `--radius-preferred-border-radius${key}`,
            selectedOption.size[key] + "px"
          );
        }
      }
    }
  }, [preferredRadius]);

  useEffect(() => {
    if (business?.id && parseInt(businessId) !== business.id) {
      resetReduxStoresWhenBusinessChange(dispatch);
    }
  }, [business]);

  useEffect(() => {
    if (order?.status === orderStatus?.finished) {
      resetReduxStoresIfOrderNotFound(dispatch);
    }
  }, [order]);

  const fetchMetadataAndOrder = async () => {
    const parsedBusinessId = parseInt(businessId, 10);
    const parsedQrId = qrId ? parseInt(qrId, 10) : undefined;
    const qrData = {
      businessId: parsedBusinessId,
      qrId: parsedQrId ?? undefined,
    };
    if (businessId) {
      dispatch(setBusinessIdAndQrId(qrData));
      return dispatch(
        getGuestMetaDataAsync({
          ...qrData,
          onSuccess: async (response) => {
            if (!response.business?.goLive) {
              setShowOfflineMenu(true);
              window.location.replace(
                `${ROUTE_NAME.client}${ROUTE_NAME.offlineMenu}`
              );
              return;
            }
            let orderData;
            //check metadata return table
            if (response.qr.table) {
              orderData = {
                tableId: response.qr.table.id,
                guest: guestId ? { person: { id: guestId } } : {},
                assigneeId: response.qr.table.assignee?.id,
                orderId: order?.id,
              };
              //if not then check tableStore
            } else if (tableStore?.id) {
              orderData = {
                tableId: tableStore.id,
                guest: guestId ? { person: { id: guestId } } : {},
                assigneeId: tableStore.assignee?.id,
                orderId: order?.id,
              };
            }

            if (orderData) {
              await executeCreateOrder(businessId, orderData);
            }
            setIsLoading(false);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (isStoreReset && isVisible) {
      fetchMetadataAndOrder()
        .then(() => {
          searchParams.delete(QUERY_PARAMS.qrId);
          setSearchParams(searchParams, { replace: true });
          dispatch(resetOrderPaymentFinishedFlag());
          dispatch(resetOrderDeletedFlag());
          if (
            location.pathname ===
            `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}`
          ) {
            navigate(
              `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
              { replace: true }
            );
          }
        })
        .catch(() => {
          navigate(`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`, {
            replace: true,
          });
        });
    }
  }, [isStoreReset, isVisible]);

  const handleCloseConfirmedOrder = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`,
      { replace: true }
    );
    dispatch(resetOrderPaymentFinishedFlag());
  };

  const handleCloseDeletedOrder = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`,
      { replace: true }
    );
    dispatch(resetOrderDeletedFlag());
  };

  const [welcomeIsVisible, setWelcomeIsVisible] = useState(true);

  useTimeout(() => setWelcomeIsVisible(false), isVisible ? 2000 : null);

  if ((isLoading || welcomeIsVisible) && !showOfflineMenu) {
    return <WelcomeClient />;
  }

  if (isOrderPaymentConfirmed) {
    return (
      <CompletedOrder
        onClick={() => handleCloseConfirmedOrder()}
        hasConfettiEffect
        hasSmileyFaceIcon
        title={t("payment.paymentConfirmedTitle")}
        description={t("payment.paymentConfirmedDescription")}
      />
    );
  }

  if (isOrderDeleted) {
    return (
      <CompletedOrder
        onClick={() => handleCloseDeletedOrder()}
        title={t("basket.order.deletedOrderTitle")}
        description={t("basket.order.deletedOrderDescription")}
      />
    );
  }

  return (
    <div className="ClientPagesController">
      {openSlideGuestAccountInfoModal && (
        <GuestAccountInfoModal
          openSlide={openSlideGuestAccountInfoModal}
          setOpenSlide={setOpenSlideGuestAccountInfoModal}
          onClose={() => {
            setOpenSlideGuestAccountInfoModal(false);
            dispatch(setShowAccountInfoModal(false));
          }}
        />
      )}
      <Outlet />
    </div>
  );
};

export default ClientPagesController;
