import { useRef, useCallback } from "react";

const useMouseDragHorizontal = () => {
  const pos = useRef({ top: 0, left: 0, x: 0, y: 0 });
  const sliderRef = useRef(null);

  const handleMouseMoveHandler = useCallback((e) => {
    sliderRef.current.classList.add("isDragging");

    const dx = e.clientX - pos.current.x;
    const dy = e.clientY - pos.current.y;
    if (Math.abs(pos.current.left - dx) > 15) {
      sliderRef.current.classList.add("isBlockedByDragging");
    }
    sliderRef.current.scrollTop = pos.current.top - dy;
    sliderRef.current.scrollLeft = pos.current.left - dx;
  }, []);

  const handleMouseUpHandler = useCallback(() => {
    sliderRef.current.classList.remove("isDragging", "isBlockedByDragging");
    sliderRef.current.removeEventListener("mousemove", handleMouseMoveHandler);
    sliderRef.current.removeEventListener("mouseup", handleMouseUpHandler);
    sliderRef.current.removeEventListener("mouseleave", handleMouseUpHandler);
  }, [handleMouseMoveHandler]);

  const handleMouseDownHandler = useCallback(
    (e) => {
      pos.current = {
        left: sliderRef.current.scrollLeft,
        top: sliderRef.current.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };
      sliderRef.current.addEventListener("mousemove", handleMouseMoveHandler);
      sliderRef.current.addEventListener("mouseleave", handleMouseUpHandler);
      sliderRef.current.addEventListener("mouseup", handleMouseUpHandler);
    },
    [handleMouseMoveHandler, handleMouseUpHandler]
  );

  return { sliderRef, handleMouseDownHandler };
};

export default useMouseDragHorizontal;
