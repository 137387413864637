import React from "react";
import PropTypes from "prop-types";
import Price, { ENUMS as PRICE_ENUMS } from "components/elements/price/Price";

import "./MenuItemPrice.scss";

export const ENUMS = {
  name: "MenuItemPrice",
};

const MenuItemPrice = ({ menuItemPrice, discountPrice }) => {
  return (
    <div className="MenuItemPrice">
      <Price
        value={
          discountPrice !== null ? menuItemPrice + discountPrice : menuItemPrice
        }
        type={PRICE_ENUMS.types.PRICE_L}
      />
      {discountPrice !== null && (
        <Price
          value={menuItemPrice}
          type={PRICE_ENUMS.types.PRICE_LINE_THROUGH}
        />
      )}
    </div>
  );
};

MenuItemPrice.propTypes = {
  /**
   * The price of the menu item
   */
  menuItemPrice: PropTypes.number.isRequired,

  /**
   * The discounted Price of the menu item
   */
  discountPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default MenuItemPrice;
