import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateMenu } from "utils/api/services/menu";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import {
  calculateCategoriesOrder,
  reorderItems,
  sortCategoriesAndMenuItems,
} from "utils/general";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddItemModal from "components/admin/cards/add-item-modal/AddItemModal";
import AddMenuItemModal from "pages/admin/admin-pages/admin-menu/admin-menu-controller/add-menu-item-modal/AddMenuItemModal";
import SectionHeader from "components/admin/elements/section-header/SectionHeader";
import ADD_ICON from "assets/icons/math-operators/add/PlusAdmin.svg";
import PREVIEW_ICON from "assets/icons/preview/Preview.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/DropdownArrowDown.svg";
import DRAG_DROP_ICON from "assets/icons/drag-drop/DragDrop.svg";
import ICON_EMPTY_MENU from "assets/icons/menu/admin-empty-menu.svg";
import ICON_CLOSED_EYE from "assets/icons/eye/EyeCloseBlack.svg";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import DraggableListVertical from "components/drag-drop/DraggableListVertical";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import useLanguage from "utils/hooks/useLanguage";
import { ENUMS as ENUMS_DRAGGABLE_CARD } from "components/drag-drop/DraggableCard";
import EditButton, {
  ENUMS as ENUMS_EDIT,
} from "components/admin/buttons/edit-button/EditButton";
import EmptyState from "components/admin/empty-state/EmptyState";
import AddTagButton, {
  ENUMS as ENUMS_ADD_TAG_BUTTON,
} from "components/admin/buttons/add-tag-button/AddTagButton";
import DeleteButton, {
  ENUMS as ENUMS_DELETE_BUTTON,
} from "components/buttons/delete-button/DeleteButton";
import {
  createCategory,
  deleteCategory,
  updateCategory,
} from "utils/api/services/category";
import Item from "components/admin/cards/item/Item";
import useAsync from "utils/hooks/useAsync";
import {
  createMenuItem,
  deleteMenuItem,
  updateMenuItem,
} from "utils/api/services/menuItem";
import { STORE_NAMES } from "utils/constants/redux";
import {
  getTranslationPropertyRequestBody,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import {
  duplicateImageInDO,
  createUniqueImageName,
  removeImageFromDO,
} from "utils/DO-Spaces";
import { DO_FILE_TYPES } from "utils/constants/DOSpaces";
import { filterArchivedCategories } from "utils/helpersMenu";
import { addPartialMenuUpdate } from "redux/slices/menuStore";
import isEqual from "lodash/isEqual";
import Spinner from "components/elements/spinner/Spinner";

import "./AdminMenuController.scss";

const AdminMenuController = () => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const initialCategories = {
    name: [
      { value: " Hamısı", languageCode: "AZ" },
      { value: " All", languageCode: "EN" },
      { value: " Alle", languageCode: "DE" },
      { value: " Все", languageCode: "RU" },
      { value: " Hepsi", languageCode: "TR" },
    ],
    id: 0,
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const [, setOpenDeleteModal] = useOutsideClick();
  const [positions, setPositions] = useState([]);
  const [initialPositions, setInitialPositions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const oldCategoryRef = useRef(null);
  const [hasUnsavedPositionChanges, setHasUnsavedPositionChanges] =
    useState(false);
  const [activeCategoryDropdown, setActiveCategoryDropdown] =
    useState(initialCategories);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandItemView, setExpandItemView] = useState(true);

  const dispatch = useDispatch();
  const { data: menu, isLoading: isMenuLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );
  const menuId = useSelector(
    (state) => state[STORE_NAMES.business].business?.menu?.id
  );
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const categories = filterArchivedCategories(menu?.categories) || [];
  const sortedCategories = sortCategoriesAndMenuItems(
    categories,
    "placeInTheList"
  );
  const [allCategories, setAllCategories] = useState(sortedCategories);

  useEffect(() => {
    const sortedCategories = sortCategoriesAndMenuItems(
      categories,
      "placeInTheList"
    );

    const categoryPositions = sortedCategories.map((category, index) => ({
      id: category.id,
      position: index + 1,
      menuItemPositions: category.menuItems.map((item, index) => ({
        id: item.id,
        position: index + 1,
      })),
    }));

    setInitialPositions(categoryPositions);

    setPositions(categoryPositions);

    // setActiveCategoryDropdown(null);
    setAllCategories(sortedCategories);
    setHasUnsavedPositionChanges(false);
  }, [menu]);

  const handleOnAsyncSuccessForMenuItem = async (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideMenuItem(false);
    });

    const oldCategoryId = oldCategoryRef?.current?.id ?? null;
    const menuItem = response.data;
    const menuCategory = response.data.category;
    menuCategory.menuItems = [menuItem];
    // if menuItem category change, we send extra 2 params to setOrReplaceMenu func ( oldCategoryId and menuItemId ) for delete menuItem to old category and add it to new category.
    if (oldCategoryId && oldCategoryId !== menuCategory.id) {
      return dispatch(
        addPartialMenuUpdate({
          categories: [menuCategory],
          oldCategoryId,
          menuItemId: menuItem.id,
        })
      );
    }
    await dispatch(addPartialMenuUpdate({ categories: [menuCategory] }));
  };
  const handleOnAsyncSuccessForCategory = async (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlideCategory(false);
    });
    await dispatch(addPartialMenuUpdate({ categories: [response.data] }));
  };
  const handleOnAsyncErrorForMenu = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };

  const { execute: executeCreateCategory, loading: isLoadingCreateCategory } =
    useAsync(createCategory, {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) =>
        handleOnAsyncSuccessForCategory(
          t("toastMessages.success.createCategory"),
          response
        ),
    });
  const { execute: executeUpdateCategory, loading: isLoadingUpdateCategory } =
    useAsync(updateCategory, {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) =>
        handleOnAsyncSuccessForCategory(
          t("toastMessages.success.updateCategory"),
          response
        ),
    });
  const { execute: executeDeleteCategory } = useAsync(deleteCategory, {
    onError: (error) => handleOnAsyncErrorForMenu(error.response.data.message),
    onSuccess: (response) =>
      handleOnAsyncSuccessForCategory(
        t("toastMessages.success.deleteCategory"),
        response
      ),
  });

  const { execute: executeCreateMenuItem, loading: isLoadingCreateMenuItem } =
    useAsync(createMenuItem, {
      onError: (err) =>
        handleOnAsyncErrorForMenu(err.response.data.message.message.join()),
      onSuccess: (response) =>
        handleOnAsyncSuccessForMenuItem(
          t("toastMessages.success.createMenuItem"),
          response
        ),
    });
  const { execute: executeUpdateMenuItem, loading: isLoadingUpdateMenuItem } =
    useAsync(updateMenuItem, {
      onError: (err) =>
        handleOnAsyncErrorForMenu(err.response.data.message.message.join()),
      onSuccess: (response) =>
        handleOnAsyncSuccessForMenuItem(
          t("toastMessages.success.updateMenuItem"),
          response
        ),
    });

  const { execute: executeDeleteMenuItem } = useAsync(deleteMenuItem, {
    onError: (error) => handleOnAsyncErrorForMenu(error.response.data.message),
    onSuccess: (response) => {
      const removedItemImages =
        response?.data?.categories[0].menuItems[0].otherImagesSrc || [];
      removedItemImages.map((image) => removeImageFromDO(image));
      return handleOnAsyncSuccessForMenuItem(
        t("toastMessages.success.deleteMenuItem"),
        response
      );
    },
  });

  const { execute: executeDuplicateMenuItem } = useAsync(createMenuItem, {
    onError: (err) =>
      handleOnAsyncErrorForMenu(err.response.data.message.message.join()),
    onSuccess: (response) =>
      handleOnAsyncSuccessForMenuItem(
        t("toastMessages.success.duplicateMenuItem"),
        response
      ),
  });

  useEffect(() => {
    if (hasUnsavedPositionChanges) {
      setSearchParams({
        ...searchParams,
        [QUERY_PARAMS.unsavedChanges]: true,
      });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);
    }
  }, [hasUnsavedPositionChanges]);

  const handleOnAsyncSuccessForMenu = async (successMessage, response) => {
    handleOnAsyncSuccess(successMessage, () => {
      setHasUnsavedPositionChanges(false);
    });
    await dispatch(addPartialMenuUpdate(response.data));
  };

  const { execute: executeUpdateMenu, loading: isLoadingUpdateMenu } = useAsync(
    updateMenu,
    {
      onError: () => handleOnAsyncErrorForMenu(),
      onSuccess: (response) =>
        handleOnAsyncSuccessForMenu(
          t("toastMessages.success.updateMenu"),
          response
        ),
    }
  );

  const [
    openSlideMenuItem,
    setOpenSlideMenuItem,
    mainElementRefMenuItem,
    ,
    ,
    setOutsideClickAction,
  ] = useOutsideClick();

  const [
    openSlideCategory,
    setOpenSlideCategory,
    mainElementRefCategory,
    ,
    ,
    setOutsideClickActionCategory,
  ] = useOutsideClick();

  const handleOnEditCategory = (id) => {
    setSelectedCategory(allCategories.find((category) => category.id === id));
    setOpenSlideCategory(true);
  };

  const handleOnEditItem = (item, category) => {
    setOpenSlideMenuItem(true);
    setSelectedItem(item);
    setSelectedCategory(category);
    oldCategoryRef.current = category;
  };

  const handleOnAddCategory = () => {
    setOpenSlideCategory(true);
    setSelectedCategory(null);
  };

  const handleOnAddItem = (category) => {
    setOpenSlideMenuItem(true);
    setSelectedCategory(category);
    setSelectedItem(null);
  };

  const handleOnDuplicate = async (item, category) => {
    // eslint-disable-next-line no-unused-vars
    const { id, ...duplicatedItem } = item;
    let duplicatedCoverImageSrc;
    const duplicatedOtherImagesSrc = await Promise.all(
      duplicatedItem.otherImagesSrc.map((photo) => {
        const uniqueImageName = createUniqueImageName(
          DO_FILE_TYPES.MENU,
          businessId
        );
        return new Promise((resolve) => {
          duplicateImageInDO(photo, uniqueImageName, (location) => {
            if (duplicatedItem.coverImageSrc === photo) {
              duplicatedCoverImageSrc = location;
            }
            resolve(location);
          });
        });
      })
    );
    const modifiedModifications = duplicatedItem.modifications.map(
      (modification) => {
        const updatedModificationName = getTranslationPropertyRequestBody(
          modification.name
        );
        // eslint-disable-next-line no-unused-vars
        const { id, ...modifiedModification } = modification;
        const modifiedOptions = modifiedModification.options.map(
          // eslint-disable-next-line no-unused-vars
          ({ id, ...option }) => {
            const updatedOptionName = getTranslationPropertyRequestBody(
              option.name
            );
            return {
              ...option,
              name: updatedOptionName,
            };
          }
        );

        return {
          ...modifiedModification,
          name: updatedModificationName,
          options: modifiedOptions,
        };
      }
    );
    const duplicatedItemName = duplicatedItem.name.map((item) => ({
      ...item,
      value: `${item.value} ${t("menu.item.duplicate")}`,
    }));
    const requestBody = {
      ...duplicatedItem,
      name: getTranslationPropertyRequestBody(duplicatedItemName),
      description: getTranslationPropertyRequestBody(
        duplicatedItem.description
      ),
      tags: duplicatedItem.tags.map((tag) => tag.id),
      modifications: modifiedModifications,
      category: category.id,
      unit: duplicatedItem.unit?.id,
      placeInTheList: category.menuItems.length + 1,
      coverImageSrc: duplicatedCoverImageSrc,
      otherImagesSrc: duplicatedOtherImagesSrc,
    };
    delete requestBody.lastUpdateDate;
    delete requestBody.createDate;
    await executeDuplicateMenuItem(businessId, menuId, requestBody);
  };

  const handleOnSaveMenuItem = async (data, id) => {
    const menuItem = {
      ...data,
      placeInTheList: selectedCategory.menuItems.length + 1,
    };
    if (id) {
      await executeUpdateMenuItem(businessId, menuId, data, id);
    } else {
      await executeCreateMenuItem(businessId, menuId, menuItem);
    }
  };

  const handleOnDeleteMenuItem = async (id) => {
    await executeDeleteMenuItem(businessId, menuId, id);
  };

  const handleOnSaveCategory = async (data, id) => {
    const updatedName = getTranslationPropertyRequestBody(data.name);
    const category = {
      ...data,
      name: updatedName,
      placeInTheList: categories.length + 1,
    };
    if (id) {
      await executeUpdateCategory(businessId, menuId, category, id);
    } else {
      await executeCreateCategory(businessId, menuId, category);
    }
  };

  const handleOnDeleteCategory = async (id) => {
    await executeDeleteCategory(businessId, menuId, id);
  };

  const showItemsByCategory = (categoryId) => {
    const category = allCategories.find(
      (category) => category.id === categoryId
    );

    return (
      <div className="AdminMenuControllerItems">
        <div className="AdminMenuControllerItemsAddContainer">
          <AddTagButton
            onClick={() => handleOnAddItem(category)}
            type={ENUMS_ADD_TAG_BUTTON.types.TYPE_C}
            className="AdminMenuControllerItemsAddButton"
          />
        </div>
        {category &&
          category.menuItems.length > 0 &&
          category.menuItems.map((item) => {
            return (
              <Item
                key={item.id}
                item={item}
                onClick={() => handleOnEditItem(item, category)}
                onDelete={() => handleOnDeleteMenuItem(item.id)}
                onDuplicate={() => handleOnDuplicate(item, category)}
              />
            );
          })}
      </div>
    );
  };

  const handleDropdownChange = (value) => {
    if (value) {
      setActiveCategoryDropdown(value);
      const selectedCategory = categories.find(
        (category) => category.id === value.id
      );
      if (selectedCategory) {
        setSelectedCategory(selectedCategory);
        setAllCategories([selectedCategory]);
      } else {
        setSelectedCategory(null);
        setAllCategories(sortedCategories);
      }
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    const updatePositions = (updatedItems, positionKey) => {
      let newPosition;

      if (positionKey === "menuItemPositions") {
        newPosition = updatedItems.map((category) => {
          return {
            id: category.id,
            position: category.placeInTheList,
            menuItemPositions: category.menuItems.flatMap(
              (menuItem, index) => ({
                id: menuItem.id,
                position: index + 1,
              })
            ),
          };
        });

        newPosition = newPosition.flat();
      } else {
        newPosition = updatedItems.map((item, index) => ({
          id: item.id,
          position: index + 1,
          menuItemPositions: item.menuItems.flatMap((menuItem, indexItem) => ({
            id: menuItem.id,
            position: indexItem + 1,
          })),
        }));
      }

      setPositions(() => {
        setHasUnsavedPositionChanges(!isEqual(initialPositions, newPosition));
        return newPosition;
      });
    };

    if (type === "droppableItem") {
      const sortedCategories = reorderItems(
        allCategories,
        source.index,
        destination.index
      );
      setAllCategories(sortedCategories);
      updatePositions(sortedCategories, "categoryPositions");
    } else if (type.startsWith("droppableSubItem")) {
      const parentId = parseInt(type.split("-")[1]);
      const sortedCategoriesByListOrder =
        calculateCategoriesOrder(allCategories);

      const category = sortedCategoriesByListOrder.find(
        (cat) => cat.id === parentId
      );

      const sortedItems = reorderItems(
        category.menuItems,
        source.index,
        destination.index
      );
      const updatedCategories = sortedCategoriesByListOrder.map((cat) =>
        cat.id === parentId ? { ...cat, menuItems: sortedItems } : cat
      );

      setAllCategories(updatedCategories);
      updatePositions(updatedCategories, "menuItemPositions");
    }
  };

  const handleOnPreviewMenu = () => {
    window.open(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`,
      "_blank"
    );
  };

  const renderHeader = () => (
    <div className="AdminMenuControllerHeaderContainer">
      <SectionHeader
        className="AdminMenuControllerSectionHeader"
        title={t("navbarRoutes.pageTitles.category")}
      />
      <div className="AdminMenuControllerHeaderActionButtons">
        <PrimaryButton
          text={t("menu.preview")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
          icon={PREVIEW_ICON}
          className="AdminMenuControllerCategoriesPreviewButton"
          onClick={handleOnPreviewMenu}
        />
        <PrimaryButton
          text={t("menu.category.add")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
          icon={ADD_ICON}
          className="AdminMenuControllerCategoriesAddButton"
          onClick={handleOnAddCategory}
        />
        <Dropdown
          className="AdminMenuControllerCategoriesDropdown"
          name="category"
          value={activeCategoryDropdown}
          options={[initialCategories, ...categories]}
          onChange={handleDropdownChange}
          isMultiLanguage
          placeholder={t("menu.category.categories")}
        />
      </div>
    </div>
  );

  const renderCategory = (item, index) => {
    const numberOfItems = item?.menuItems.length || "";
    return {
      id: `category-${item.id}`,
      headerComponent: (
        <>
          <div className="DraggableCardDragAndLabel">
            <h6 className="DraggableCardLabel Medium">{index + 1}</h6>
            <img
              src={DRAG_DROP_ICON}
              alt="Drag and drop icon"
              className="DragDropIcon"
            />
          </div>
          <div className="DraggableCardItemNameContainer">
            <h1 className="Medium DraggableCardItemName">
              {displayDataByLanguage(item.name)}{" "}
              {numberOfItems && `(${numberOfItems})`}
            </h1>
            {!item?.isPublished && (
              <div className="AdminMenuControllerCategoryPublishStatusContainer">
                <img src={ICON_CLOSED_EYE} alt="Unpublished status" />
                <h6 className="AdminMenuControllerCategoryPublishStatusText">
                  {t("common.unPublished")}
                </h6>
              </div>
            )}
          </div>
          <div className="DraggableCardActionButtons">
            <DeleteButton
              setOpenSlide={setOpenDeleteModal}
              onClick={() => handleOnDeleteCategory(item.id)}
              type={ENUMS_DELETE_BUTTON.types.TYPE_A}
              className="AdminMenuControllerDeleteButton"
            />
            <EditButton
              type={ENUMS_EDIT.types.TYPE_C}
              className="AdminMenuControllerEditButton"
              onClick={() => handleOnEditCategory(item.id)}
            />
            <CTAButton
              type={CTA_BUTTON_ENUMS.types.TYPE_Z}
              icon={<ArrowIcon />}
              onClick={() => setExpandItemView(!expandItemView)}
              className={cx("AdminMenuControllerExpandButton", {
                isExpanded: expandItemView,
              })}
            />
          </div>
        </>
      ),
      bodyComponent: expandItemView && (
        <>
          <div
            className={cx("DraggableCardLeftContainer", {
              hasNoItem: item.menuItems.length === 0,
            })}
          >
            {showItemsByCategory(item.id)}
          </div>
          {item.menuItems.length === 0 ? (
            <EmptyState
              description={t("menu.item.emptyAdminMenuItemDescription")}
              icon={ICON_EMPTY_MENU}
              isAdmin
              className="AdminMenuControllerEmptyMenuItemState"
            />
          ) : (
            <div className="DraggableCardRightContainer">
              <Droppable
                droppableId={`category-${item.id.toString()}`}
                type={`droppableSubItem-${item.id.toString()}`}
              >
                {(provided) => (
                  <div ref={provided.innerRef}>
                    <DraggableListVertical
                      items={item.menuItems
                        .map((menuItem, index) => ({
                          id: `menuItem-${menuItem.id}`,
                          headerComponent: (
                            <>
                              <div className="DraggableCardDragAndLabel">
                                <h6 className="DraggableCardLabel Medium">
                                  {index + 1}
                                </h6>
                                <img
                                  src={DRAG_DROP_ICON}
                                  alt="Drag and drop icon"
                                  className="DragDropIcon"
                                />
                              </div>
                              <h6 className="Medium DraggableCardItemName">
                                {displayDataByLanguage(menuItem.name)}
                              </h6>
                            </>
                          ),
                        }))
                        .filter(Boolean)}
                      sizeVariant={ENUMS_DRAGGABLE_CARD.types.SIZE_S}
                      canDragWholeCard
                      isLoadingUpdateMenu={isLoadingUpdateMenu}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </>
      ),
    };
  };

  return (
    <div className="AdminMenuController">
      {isMenuLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="AdminMenuControllerTop">{renderHeader()}</div>
          {!categories || categories.length === 0 ? (
            <EmptyState
              description={t("menu.category.emptyAdminCategoryDescription")}
              icon={ICON_EMPTY_MENU}
              isAdmin
              className="AdminMenuControllerEmptyState"
            />
          ) : (
            <div>
              <DragDropContext
                onDragEnd={(result) => {
                  if (isLoadingUpdateMenu) return;
                  handleDragEnd(result);
                }}
              >
                <Droppable droppableId="allCategories" type="droppableItem">
                  {(provided) => (
                    <div
                      className="AdminMenuControllerCategories"
                      ref={provided.innerRef}
                    >
                      <DraggableListVertical
                        sizeVariant={ENUMS_DRAGGABLE_CARD.types.SIZE_L}
                        items={allCategories.map(renderCategory)}
                        isLoadingUpdateMenu={isLoadingUpdateMenu}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </>
      )}
      {hasUnsavedPositionChanges && (
        <div className="AdminMenuControllerBottomContainer">
          <PrimaryButton
            type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
            className="AdminMenuControllerSaveButton"
            onClick={() => executeUpdateMenu(businessId, menuId, { positions })}
            isLoading={isLoadingUpdateMenu}
          />
        </div>
      )}
      <AddItemModal
        mainElementRef={mainElementRefCategory}
        setOpenSlide={setOpenSlideCategory}
        title={t("menu.category.name")}
        item={selectedCategory}
        hasPublishMode
        openSlide={openSlideCategory}
        onSave={handleOnSaveCategory}
        onDelete={handleOnDeleteCategory}
        isMultiLanguage
        hasSchedule
        setOutsideClickAction={setOutsideClickActionCategory}
        isLoading={isLoadingCreateCategory || isLoadingUpdateCategory}
      />
      <AddMenuItemModal
        item={selectedItem}
        mainElementRef={mainElementRefMenuItem}
        title={t("menu.item.item")}
        setOpenSlide={setOpenSlideMenuItem}
        openSlide={openSlideMenuItem}
        onSave={handleOnSaveMenuItem}
        onDelete={handleOnDeleteMenuItem}
        selectedCategory={{
          id: selectedCategory?.id,
          name: selectedCategory?.name,
        }}
        setOutsideClickAction={setOutsideClickAction}
        isLoading={isLoadingCreateMenuItem || isLoadingUpdateMenuItem}
      />
    </div>
  );
};

export default AdminMenuController;
