import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as IconArrowRight } from "assets/icons/arrows/ArrowUpRight.svg";

import "./FooterNavButton.scss";

const FooterNavButton = ({ title, onClick }) => {
  return (
    <button className="FooterNavButton" onClick={onClick} type="button">
      <div className="FooterNavButtonIcon">
        <IconArrowRight />
      </div>
      <div className="FooterNavButtonTitle">
        <h6 className="Medium">{title}</h6>
      </div>
    </button>
  );
};

FooterNavButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default FooterNavButton;
