import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CallButton from "components/buttons/call-button/CallButton";
import MascotDashboardButton from "components/buttons/mascot-dashboard-button/MascotDashboardButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
// import { ReactComponent as IconReservation } from "assets/icons/mascot/MascotReservation.svg";
import { ReactComponent as IconDashboard } from "assets/icons/client-dashboard/dashboard.svg";
import { ReactComponent as IconChat } from "assets/icons/chat/Messages.svg";
import { ReactComponent as IconMenu } from "assets/icons/menu/restaurant-menu.svg";
import { ReactComponent as IconAboutUs } from "assets/icons/business/store.svg";
import { STORE_NAMES } from "utils/constants/redux";
import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";
import GuestAccountInfoModal from "components/guest-account-info-modal/GuestAccountInfoModal";
import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import EditButton, {
  ENUMS as ENUMS_EDIT_BUTTON,
} from "components/admin/buttons/edit-button/EditButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import LanguagesModal from "components/mascot/languages-modal/LanguagesModal";
import { updateOrderActionAsync } from "redux/actions/orderActions";
import { handleOnAsyncError } from "utils/helpers";
import PaymentModal from "components/payment-modal/PaymentModal";
import WifiModal from "components/mascot/wifi-modal/WifiModal";
import ICON_WIFI from "assets/icons/other/WiFi.svg";
import { getBrandName, getBrandMainLogoLight } from "utils/branding-helper";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import {
  calculateConfirmedOrderItemsCount,
  resetAllReduxStores,
} from "utils/general";
import { setGuestInfo } from "redux/slices/guestStore";
import { setBusinessIdAndQrId } from "redux/slices/qrScanStore";
import { getGuestMetaDataAsync } from "redux/actions/metaDataAction";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";

import "./MascotDashboard.scss";

const brandName = getBrandName();
const MainLogoLight = getBrandMainLogoLight();

const MascotDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLanguages, setOpenLanguages, mainElementRef] = useOutsideClick();
  const [openSlideWifi, setOpenSlideWifi, mainElementRefWifi] =
    useOutsideClick();
  const [openPayment, setOpenPayment, mainElementRefPayment] =
    useOutsideClick();
  const [openSlideGuestAccountInfoModal, setOpenSlideGuestAccountInfoModal] =
    useOutsideClick();
  const [menuItemCount, setMenuItemCount] = useState(0);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const { table } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const availableLanguages = useSelector(
    (state) => state[STORE_NAMES.menu].data?.availableLanguages
  );

  const showWifiButton = business?.wifi?.length > 0 || false;
  const showLanguagesModal = availableLanguages?.length > 1 || false;
  const tableId = table?.id;
  const isTableEmpty = Object.keys(table).length === 0;

  let [searchParams, setSearchParams] = useSearchParams();
  const showHamburgerMenu = Boolean(
    searchParams.get(QUERY_PARAMS.showHamburgerMenu)
  );
  useEffect(() => {
    if (showHamburgerMenu) return document.body.classList.add("OverflowHidden");
    document.body.classList.remove("OverflowHidden");
  }, [showHamburgerMenu]);

  const handleCloseHamburgerMenu = () => {
    searchParams.delete(QUERY_PARAMS.showHamburgerMenu);
    setSearchParams(searchParams);
  };

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const guestId = guest.id;
  const order = orders?.find((order) => order.table.id === tableId);
  const currentGuest = order?.guests.find(
    (guest) => guest.person.id === guestId
  );
  const currentZone = zones?.find((zone) =>
    zone.tables.find((table) => table.id === tableId)
  );
  const isWaiterCalled = order?.actionWaiter;
  const isBillAsked = order?.actionBill;

  const allOrderers = order
    ? [
        currentGuest,
        ...(order.guests.filter((guest) => guest.person.id !== guestId) || []),
        ...(order.users || []),
      ]
    : [];

  const isAskForBillDisabled =
    allOrderers
      .flatMap((guest) => guest?.orderItems)
      .filter((item) => item?.isConfirmed !== false).length === 0;

  const isOrderEmpty = !order;

  const handleOnChangeAction = async (actionName) => {
    const findUpdatedAction = order.actions.find(
      (action) => action.name === actionName
    );

    const updatedAction = {
      ...findUpdatedAction,
      value: !findUpdatedAction.value,
    };

    const actionTypeKey = (actionName) => {
      switch (actionName) {
        case ORDER_ACTIONS_TYPE.bill:
          return "actionBill";
        default:
          return "actionWaiter";
      }
    };

    const actionType = actionTypeKey(updatedAction.name);

    const updatedOrder = {
      action: updatedAction,
      [actionType]: updatedAction.value,
    };
    const response = await dispatch(
      updateOrderActionAsync({ businessId, order: updatedOrder, id: order.id })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    }
  };

  useEffect(() => {
    setMenuItemCount(calculateConfirmedOrderItemsCount(allOrderers));
  }, [allOrderers]);

  const handleMenuButtonClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
    );
  };

  const handleDashboardClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.dashboard}`
    );
  };

  // const handleFavoritesAndBasketClick = () => {
  //   navigate(
  //     `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.basket}`
  //   );
  // };

  const handleChatClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.chat}`
    );
  };

  const handleAboutUsButtonClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.info}`
    );
  };

  const showGuestName = () => {
    if (guest.name) {
      return guest.name;
    } else if (guestId) {
      return t("dashboard.guest.guest") + " " + guestId;
    } else {
      return t("dashboard.guest.guest");
    }
  };

  const handleOnConnectToWiFi = () => {
    setOpenSlideWifi(true);
  };

  if (!showHamburgerMenu) {
    return;
  }

  const fetchGuestMetadata = async () => {
    const parsedBusinessId = parseInt(businessId, 10);
    const qrData = {
      businessId: parsedBusinessId,
      qrId: undefined,
    };

    if (businessId) {
      dispatch(setBusinessIdAndQrId(qrData));
      return dispatch(
        getGuestMetaDataAsync({
          ...qrData,
          onSuccess: async () => {
            dispatch(
              setGuestInfo({
                id: guestId,
                name: guest.name,
                phoneNumber: guest.phoneNumber,
                profilePicture: guest.profilePicture,
              })
            );
          },
        })
      );
    }
  };

  const handleFixProblem = async () => {
    navigate("/");
    await resetAllReduxStores(dispatch);
    await fetchGuestMetadata();
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  return (
    <>
      <div className="MascotDashboard">
        <header className="MascotDashboardHeader">
          <div className="MascotDashboardLeftArea">
            <div
              className="MascotDashboardGuestWrapper"
              onClick={
                !isTableEmpty
                  ? () => setOpenSlideGuestAccountInfoModal(true)
                  : null
              }
            >
              <GuestProfileWithIcon
                image={guest.profilePicture}
                hasImage={!!guest.profilePicture}
                className="MascotDashboardGuestImageContainer"
                actionButton={
                  !isTableEmpty && (
                    <EditButton
                      className="MascotDashboardGuestEditButton"
                      type={ENUMS_EDIT_BUTTON.types.TYPE_B}
                      onClick={() => setOpenSlideGuestAccountInfoModal(true)}
                    />
                  )
                }
              />
            </div>
            <div className="MascotDashboardGuestDetailsContainer">
              <h4 className="SemiBold MascotDashboardGuestName">
                {showGuestName()}
              </h4>
              {currentZone && (
                <div className="MascotDashboardGuestZoneAndTable">
                  <h6>{order?.table?.name}</h6>
                  <h5>{"("}</h5>
                  <h6>{currentZone?.name}</h6>
                  <h5>{")"}</h5>
                </div>
              )}
            </div>
          </div>
          <div
            className="CloseMascotDashboard"
            onClick={handleCloseHamburgerMenu}
          >
            <IconClose />
          </div>
        </header>
        <div className="MascotDashboardMain">
          <div className="MascotDashboardMainTop">
            <div className="MascotDashboardSections">
              <div>
                <MascotDashboardButton
                  isActive={false}
                  imgSrc={selectedLanguage?.imgSrc}
                  title={selectedLanguage?.code}
                  subTitle={t("language.language")}
                  className="CustomDashboardButton"
                  onClick={() => setOpenLanguages(showLanguagesModal)}
                  arrow={showLanguagesModal}
                />
                {showWifiButton && (
                  <button
                    className="MascotDashboardConnectToWiFi"
                    onClick={handleOnConnectToWiFi}
                  >
                    <img src={ICON_WIFI} alt="wiFi" />
                    <h5 className="SemiBold">{t("common.wifi")}</h5>
                  </button>
                )}
              </div>
            </div>
            {!isOrderEmpty && (
              <div className="MascotCallButtonsContainer">
                <CallButton
                  isChecked={isWaiterCalled || false}
                  onChange={() =>
                    handleOnChangeAction(ORDER_ACTIONS_TYPE.waiter)
                  }
                  title={t("waiter.call")}
                  isDisabled={isOrderEmpty}
                  className="MascotCallButtonsContainerCallButton"
                />
                <CallButton
                  isChecked={isBillAsked || false}
                  onChange={() =>
                    isBillAsked
                      ? handleOnChangeAction(ORDER_ACTIONS_TYPE.bill)
                      : setOpenPayment(true)
                  }
                  title={t("waiter.bill")}
                  isDisabled={isAskForBillDisabled}
                  className="MascotCallButtonsContainerCallButton"
                />
              </div>
            )}
            <div className={"MascotDashboardSections"}>
              <div>
                <MascotDashboardButton
                  isActive={false}
                  icon={<IconMenu />}
                  title={t("navbarRoutes.menu")}
                  className="CustomDashboardButton"
                  onClick={handleMenuButtonClick}
                />
                {!isOrderEmpty && (
                  <MascotDashboardButton
                    isActive={false}
                    icon={<IconDashboard />}
                    title={t("basket.order.myOrders")}
                    onClick={handleDashboardClick}
                  />
                )}
                {/*<MascotDashboardButton*/}
                {/*  isActive={false}*/}
                {/*  icon={<IconBasketAndFavorites />}*/}
                {/*  title={t("basket.basketAndFavorites")}*/}
                {/*  onClick={handleFavoritesAndBasketClick}*/}
                {/*  className="CustomDashboardButton"*/}
                {/*/>*/}
                {!isOrderEmpty && business?.chat && (
                  <MascotDashboardButton
                    isActive={false}
                    icon={<IconChat />}
                    title={t("chat.chat")}
                    onClick={handleChatClick}
                  />
                )}
                <MascotDashboardButton
                  isActive={false}
                  isTruncated
                  icon={<IconAboutUs />}
                  title={business?.name}
                  onClick={handleAboutUsButtonClick}
                  className="CustomDashboardAboutButton"
                />

                {
                  //TODO Implement Fun Zone
                  /* <MascotDashboardButton
                                                                        isActive={false}
                                                                        icon={<IconFunZone />}
                                                                        title={t("navbarRoutes.funZone")}
                                                                        onClick={null}
                                                                      /> */
                }
                {/*<MascotDashboardButton*/}
                {/*  isActive={false}*/}
                {/*  icon={<IconReservation />}*/}
                {/*  title={t("navbarRoutes.reservation")}*/}
                {/*  subTitle={t("mascot.dashboard.reserveTable")}*/}
                {/*  onClick={() =>*/}
                {/*    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.reservation}`)*/}
                {/*  }*/}
                {/*/>*/}
              </div>
            </div>
            <div className="MascotDashboardFixProblem">
              <p className="h7 Medium">
                {t("mascot.dashboard.fixProblemText")}
              </p>
              <button
                className="MascotDashboardFixProblemButton"
                onClick={handleFixProblem}
              >
                <h6>{t("mascot.dashboard.fixProblemButton")}</h6>
              </button>
            </div>
            <div className="MascotDashboardCopyRight">
              <div className="MascotDashboardLogoContainer">
                <MainLogoLight />
                <h4 className="SemiBold">{brandName}</h4>
              </div>
              <div className="h7 Medium">
                {t("general.copyRight", { value: brandName })}
              </div>
            </div>
          </div>
        </div>
        <LanguagesModal
          onClose={() => setOpenLanguages(false)}
          openSlide={openLanguages}
          mainElementRef={mainElementRef}
        />
        <PaymentModal
          mainElementRef={mainElementRefPayment}
          openSlide={openPayment}
          onClose={() => setOpenPayment(false)}
          className={"MascotDashboardPaymentModal"}
          closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
          selectedGuests={allOrderers}
          menuItemCount={menuItemCount}
          tableName={order?.table?.name}
        />
        <WifiModal
          mainElementRef={mainElementRefWifi}
          openSlide={openSlideWifi}
          onClose={() => setOpenSlideWifi(false)}
          closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
        />
        <GuestAccountInfoModal
          openSlide={openSlideGuestAccountInfoModal}
          setOpenSlide={setOpenSlideGuestAccountInfoModal}
          onClose={() => setOpenSlideGuestAccountInfoModal(false)}
          canEditGuestProfile
          guestInfo={
            guestId
              ? {
                  name: guest.name,
                  phoneNumber: guest.phoneNumber,
                  profilePicture: guest.profilePicture,
                }
              : {}
          }
        />
      </div>
      <div className="MascotDashboardUnderlay"></div>
    </>
  );
};

export default MascotDashboard;
