import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./StepContent.scss";

const StepContent = ({ children, activeStep }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(true);
    return () => {
      setIsVisible(false);
    };
  }, [activeStep]);

  const handleAnimationEnd = () => {
    setIsVisible(false);
  };
  return (
    <div
      onAnimationEnd={handleAnimationEnd}
      className={`StepContent ${isVisible ? "slideFromLeft" : ""}`}
    >
      {children}
    </div>
  );
};

StepContent.propTypes = {
  children: PropTypes.node.isRequired,
  activeStep: PropTypes.object,
};

export default StepContent;
