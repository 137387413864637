import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import AddButton, {
  ENUMS as ADD_BUTTON_ENUMS,
} from "components/admin/buttons/add-button/AddButton";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import AddLanguageModal from "components/admin/modal/add-language-modal/AddLanguageModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import Confirm from "components/admin/cards/confirm/Confirm";
import { STORE_NAMES } from "utils/constants/redux";
import { findLanguagesByProperty } from "utils/helpers";

import "./Language.scss";

export const ENUMS = {
  name: "Tab",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const Language = ({
  type,
  content,
  setActiveLanguageCode,
  activeLanguageCode,
  properties,
  setProperties,
}) => {
  const { t } = useTranslation();
  const [removedLanguageCode, setRemovedLanguageCode] = useState(null);
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();
  const [
    openSlideAddTabModal,
    setOpenSlideAddTabModal,
    mainElementRefAddTabModal,
  ] = useOutsideClick();
  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const languages = findLanguagesByProperty(properties[0], allLanguages);

  const languageCodes = languages.flatMap((language) => language.code);
  const addLanguageModalLanguages = allLanguages.filter(
    (language) => !languageCodes.includes(language.code)
  );
  const handleOnAddLanguage = (language) => {
    setActiveLanguageCode(language.code);
    setProperties(
      properties.map((property) => [
        ...property,
        { value: "", languageCode: language.code },
      ])
    );
  };

  const handleOnRemoveLanguage = (code) => {
    if (languages.length === 1) {
      toast.error(t("errorMessages.lastLanguage"));
    } else {
      if (activeLanguageCode === code) {
        setActiveLanguageCode(
          properties[0].find((p) => p.languageCode !== code).languageCode
        );
      }
      setProperties(
        properties.map((property) => {
          return property.map((p) => {
            if (p.languageCode === code) {
              return {
                ...p,
                value: null,
              };
            }
            return p;
          });
        })
      );
    }
  };

  const handleOnCancelRemoveLanguage = () => {
    setOpenSlideConfirm(false);
    setRemovedLanguageCode(null);
  };

  const handleOnConfirmRemoveLanguage = (code) => {
    setOpenSlideConfirm(false);
    setRemovedLanguageCode(null);
    handleOnRemoveLanguage(code);
  };

  const handleOnClickCloseButton = (e, code) => {
    e.stopPropagation();
    setOpenSlideConfirm(true);
    setRemovedLanguageCode(code);
  };

  return (
    <div
      className={cx(
        "Tab",
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        }
      )}
    >
      <div className="Tabs">
        <div className="TabsAdd">
          <AddButton
            onClick={() => setOpenSlideAddTabModal(true)}
            type={
              type === ENUMS.types.TYPE_A
                ? ADD_BUTTON_ENUMS.types.TYPE_C
                : ADD_BUTTON_ENUMS.types.TYPE_D
            }
          />
        </div>
        <div className="TabsExisting">
          {languages.map((language, index) => (
            <div
              onClick={() => setActiveLanguageCode(language.code)}
              key={index}
              className={cx("TabItemContainer", {
                isActive: activeLanguageCode === language.code,
              })}
            >
              <div className="TabItem">
                <div className="TabItemLeft">
                  {language.imgSrc && (
                    <img
                      src={language.imgSrc}
                      className="TabItemIcon"
                      alt={language.code}
                    />
                  )}
                  <h6 className="Medium">{language.code}</h6>
                </div>
                <IconClose
                  className="TabItemClose"
                  onClick={(e) => handleOnClickCloseButton(e, language.code)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="TabContent">{content}</div>

      <AddLanguageModal
        mainElementRef={mainElementRefAddTabModal}
        setOpenSlide={setOpenSlideAddTabModal}
        openSlide={openSlideAddTabModal}
        onSave={handleOnAddLanguage}
        languages={addLanguageModalLanguages}
      />
      <Confirm
        title={t("modal.deleteModalTitle")}
        description={t("modal.deleteModalDescription")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={handleOnCancelRemoveLanguage}
        onConfirm={() => handleOnConfirmRemoveLanguage(removedLanguageCode)}
      />
    </div>
  );
};

Language.propTypes = {
  /**
   * Style type of the language
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * Content to be displayed in the language
   */
  content: PropTypes.node,

  /**
   * Callback function when a language is clicked
   */
  onClick: PropTypes.func,

  /**
   * Code of the currently active language
   */
  activeLanguageCode: PropTypes.string,

  /**
   * Function to set the active language ID
   */
  setActiveLanguageCode: PropTypes.func,

  /**
   * Array of language properties
   */
  properties: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    )
  ),

  /**
   * Function to set language properties
   */
  setProperties: PropTypes.func,
};

Language.defaultProps = {
  type: ENUMS.types.TYPE_A,
};

export default Language;
