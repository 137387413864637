import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CloseButton from "components/buttons/close-button/CloseButton";
import PropTypes from "prop-types";
import Modal from "components/modal/Modal";
import Review from "components/elements/review/Review";
import { updateOrderAsync } from "redux/actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
// import useTimeout from "utils/hooks/useTimeout";

import { setLoading } from "redux/slices/ordersStore";

import "./FeedBackModal.scss";

const FeedBackModal = ({
  onClose,
  closeButtonType,
  mainElementRef,
  openSlide,
}) => {
  const { t } = useTranslation();
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const currentGuest = order?.guests.find(
    (guest) => guest.person.id === guestId
  );

  const dispatch = useDispatch();
  const businessId = qrScanStore.businessId;
  const [isReviewRequestSuccessful, setIsReviewRequestSuccessful] =
    useState(null);

  const handleOnSubmitReview = async (data) => {
    dispatch(setLoading(true));
    const updatedOrder = {
      ...order,
      guests: [
        {
          ...currentGuest,
          ...data,
        },
      ],
    };
    const response = await dispatch(
      updateOrderAsync({ businessId, order: updatedOrder, id: order.id })
    );

    if (response.error) {
      setIsReviewRequestSuccessful(false);
    } else {
      setIsReviewRequestSuccessful(true);
    }
    dispatch(setLoading(false));
    // onClose();
  };

  // useTimeout(
  //   () => setIsReviewRequestSuccessful(null),
  //   isReviewRequestSuccessful ? 1000 : null
  // );

  const FeedBackModalHeader = (
    <div className="PaymentHeader">
      <h2 className="Bold">{t("dashboard.guest.review.feedBack")}</h2>
      <CloseButton onClick={onClose} type={closeButtonType || undefined} />
    </div>
  );
  const FeedBackModalBody = (
    <div>
      <Review
        guest={currentGuest}
        onSubmit={handleOnSubmitReview}
        isReviewRequestSuccessful={isReviewRequestSuccessful}
      />
    </div>
  );
  return (
    <Modal
      header={FeedBackModalHeader}
      body={FeedBackModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
      className={"MascotDashboardWifeModal"}
    />
  );
};
FeedBackModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  mainElementRef: PropTypes.object,
  onClose: PropTypes.func,
  closeButtonType: PropTypes.string,
};
export default FeedBackModal;
