import React from "react";
import PropTypes from "prop-types";

import "./Stepper.scss";

const Stepper = ({ children }) => {
  return <div className="Stepper">{children}</div>;
};

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Stepper;
