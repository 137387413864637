import React from "react";
import PropTypes from "prop-types";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";

import "./GuestPageHeader.scss";

export const ENUMS = {
  name: "GuestPageHeader",
};

const GuestPageHeader = ({ pageTitle, onGoBack, onMenuButtonClick }) => {
  return (
    <div className="GuestPageHeader">
      <BackButton onClick={onGoBack} type={BACK_BUTTON_ENUMS.types.TYPE_C} />
      <h2 className="SemiBold">{pageTitle} </h2>
      <HamburgerButton onClick={onMenuButtonClick} />
    </div>
  );
};

GuestPageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
};

export default GuestPageHeader;
