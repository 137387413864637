import React from "react";
import PropTypes from "prop-types";

import "./StatsNumberData.scss";

export const ENUMS = {
  name: "LineChart",
};

const StatsNumberData = ({ title, amount }) => {
  return (
    <div className="CardInsetShadow-v1 StatsNumberData">
      <div className="StatsNumberDataInfo">
        <h6 className="Medium StatsNumberDataInfoTitle">{title}</h6>
        <h3 className="SemiBold StatsNumberDataInfo">{amount}</h3>
      </div>
    </div>
  );
};

StatsNumberData.propTypes = {
  /**
  - The title of the line chart
  */
  title: PropTypes.string.isRequired,

  /**
  - The amount value
  */
  amount: PropTypes.string.isRequired,
  /**
   - Key
   */
};

export default StatsNumberData;
