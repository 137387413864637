import { useEffect, useState } from "react";

function useTimer(startTime) {
  const [timeDifference, setTimeDifference] = useState(0);

  useEffect(() => {
    let intervalId;

    if (startTime !== null) {
      const updateTimer = () => {
        const currentTime = new Date().getTime();
        const elapsedTime = Math.floor(
          (currentTime - new Date(startTime)) / 1000
        );
        setTimeDifference(elapsedTime);
      };
      updateTimer();
      intervalId = setInterval(updateTimer, 1000);
    }
    return () => clearInterval(intervalId);
  }, [startTime]);

  const hours = Math.floor(timeDifference / 3600);
  const minutes = Math.floor((timeDifference % 3600) / 60);
  const seconds = timeDifference % 60;

  return {
    hours,
    minutes,
    seconds,
  };
}

export default useTimer;
