import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import OrderInfoCardItem from "components/admin/cards/order-info-card-item/OrderInfoCardItem";
import TimerWithColon from "components/admin/elements/timer-with-colon/TimerWithColon";
import OrderGuestCommentWrapper from "components/admin/elements/order-comments/OrderGuestCommentWrapper";

import "./OrderInfoCard.scss";

const OrderInfoCard = ({
  order,
  table,
  onOrderItemClick,
  bottomMargin,
  index,
}) => {
  const { id, createDate: createdAt, guests, users } = order;
  const orderItems = [
    ...guests.flatMap((guest) => guest.orderItems),
    ...users.flatMap((user) => user.orderItems),
  ];

  const hasGuestMessages = guests?.some(
    (guest) => guest.orderMessage && guest.orderMessage.length > 0
  );

  const tableAndZoneName = table?.split("/");
  const tableName = tableAndZoneName[1]?.trim();
  const zoneName = tableAndZoneName[0]?.trim();

  return (
    <Draggable draggableId={order.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className="OrderInfoCard"
            style={{
              marginBottom: bottomMargin,
            }}
          >
            <div
              className="OrderInfoCardHeader"
              style={{ backgroundColor: snapshot.isDragging && "#1f1f1f" }}
            >
              <div className="OrderInfoCardDate">
                <h6 className="Medium">#{id}</h6>
                <span>
                  <TimerWithColon startTime={createdAt} twoDigitsEnabled />
                </span>
              </div>
              <div className="OrderInfoCardTable">
                <h6
                  className="Medium h7 OrderInfoCardZoneAndTableName"
                  title={`${tableName} (${zoneName})`}
                >
                  {`${tableName} (${zoneName})`}
                </h6>
              </div>
            </div>

            <div className="OrderInfoCardItems">
              {orderItems?.map((orderItem, index) => (
                <OrderInfoCardItem
                  key={`${orderItem.id}-${orderItem.isKitchenDone}`}
                  orderItem={orderItem}
                  onClick={() => onOrderItemClick(orderItem.id)}
                  className={
                    hasGuestMessages && index === orderItems.length - 1
                      ? "lastOrderInfoCardItem"
                      : ""
                  }
                />
              ))}

              {hasGuestMessages && <OrderGuestCommentWrapper guests={guests} />}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

OrderInfoCard.propTypes = {
  order: PropTypes.object.isRequired,
  table: PropTypes.string.isRequired,
  onOrderItemClick: PropTypes.func.isRequired,
  bottomMargin: PropTypes.number,
  index: PropTypes.number.isRequired,
};

export default OrderInfoCard;
