import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddNewButton from "components/admin/buttons/add-new-button/AddNewButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useAsync from "utils/hooks/useAsync";
import AdminUserModal from "pages/admin/admin-pages/admin-user/admin-user-modal/AdminUserModal";
import {
  convertSnakeCaseToTitleCase,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { deleteUser } from "utils/api/services/user";
import Table from "components/admin/elements/table/Table";
import { getUsers } from "redux/actions/userAction";
import { toast } from "react-toastify";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";

import "./AdminUser.scss";

const AdminUser = () => {
  const dispatch = useDispatch();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const { showFallbackError } = useAPIErrorStatusCodeHelper();

  const { users } = useSelector((state) => state[STORE_NAMES.business]);
  const { user, userWithPin } = useSelector((state) => state[STORE_NAMES.user]);

  useEffect(() => {
    dispatch(getUsers({ businessId }));
  }, []);

  const handleOnAsyncSuccessForUser = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      dispatch(getUsers({ businessId }));
      setOpenSlide(false);
    });
  };
  // const handleOnAsyncErrorForUser = (errorMessage) => {
  //   handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  // };

  const handleOnAsyncErrorForUser = (error) => {
    showFallbackError(error.data);
  };

  const { execute: executeDeleteUser } = useAsync(deleteUser, {
    onError: (error) => handleOnAsyncErrorForUser(error.response),
    onSuccess: () => {
      handleOnAsyncSuccessForUser(t("toastMessages.success.deleteUser"));
    },
  });
  const [
    openSlide,
    setOpenSlide,
    mainElementRef,
    ,
    ,
    setOutsideClickActionUser,
  ] = useOutsideClick();
  const [selectedUser, setSelectedUser] = useState(null);
  const { t } = useTranslation();
  const handleOnAddUser = () => {
    setOpenSlide(!openSlide);
    setSelectedUser(null);
  };

  const handleOnEditUser = (id) => {
    setOpenSlide(!openSlide);
    setSelectedUser(users.find((user) => user.id === id));
  };

  const handleOnDeleteUser = async (id) => {
    if (id === user.id) {
      return toast.error(t("user.userDeleteError"));
    }
    if (id === userWithPin?.id) {
      console.log(userWithPin);
      return toast.error(t("user.userDeleteError"));
    }
    await executeDeleteUser(businessId, id);
  };

  const tableColumns = [
    {
      key: "name",
      name: t("inputs.name"),
      isFilterable: false,
    },
    {
      key: "contact",
      name: t("inputs.contact"),
      isFilterable: false,
    },
    // {
    //   key: "email",
    //   name: t("inputs.email"),
    //   isFilterable: false,
    // },
    {
      key: "role",
      name: t("inputs.role"),
      isFilterable: false,
    },
    {
      key: "pinCode",
      name: t("inputs.pinCode"),
      isFilterable: false,
    },
  ];

  const tableItems = [...(users || [])]
    .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
    .map((user) => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      contact: (user.phoneNumber && `+${user.phoneNumber}`) || user.email,
      // email: user.email,
      role: user.roles
        .map((role) => convertSnakeCaseToTitleCase(role?.name))
        .join(", "),
      pinCode: user.pinCode.userPinCode,
    }));

  return (
    <div className="AdminUser">
      <h2 className="SemiBold AdminUserTitleText">{t("navbarRoutes.pageTitles.users")}</h2>
      <div className="AdminUserTitle">
        <div className="AdminUserTitleLeft">
          <h4 className="SemiBold">
            {`${t("user.employee")} (${tableItems.length})`}
          </h4>
        </div>
        <AddNewButton onClick={handleOnAddUser} label={t("buttons.addNew")} />
      </div>
      <Table
        columns={tableColumns}
        items={tableItems}
        hasEditColumnTitle
        onEdit={(id) => handleOnEditUser(id)}
        classname="AdminUserTable"
      />
      <AdminUserModal
        setOpenSlide={setOpenSlide}
        mainElementRef={mainElementRef}
        selectedUser={selectedUser}
        openSlide={openSlide}
        onDelete={handleOnDeleteUser}
        setOutsideClickActionUser={setOutsideClickActionUser}
      />
    </div>
  );
};

export default AdminUser;
