import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Checkbox from "components/admin/forms/checkbox/Checkbox";
import useLanguage from "utils/hooks/useLanguage";

import "./DropdownOption.scss";

export const ENUMS = {
  name: "DropdownOption",
};

const DropdownOption = ({
  option,
  value,
  handleOptionClick,
  isMultiple,
  isGrouped,
  dropdownWithIcon,
  isOptionRequired,
  isMultiLanguage,
  hideDescription,
  showCodeColumnInTitle,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const { t } = useTranslation();
  const isActive = isMultiple
    ? value?.some((selectedOption) => selectedOption.id === option.id)
    : value?.id === option.id;
  const optionName = isMultiLanguage
    ? displayDataByLanguage(option.name)
    : option.name;

  const handleOnClickOption = () => {
    if (value?.id === option.id && !isOptionRequired) {
      handleOptionClick(null);
    } else {
      handleOptionClick(option);
    }
  };
  const hasCurrentDropdownOption = optionName?.includes(t("table.current"));

  return (
    <div
      className={cx("DropdownOption", {
        isActive: isActive,
        DropdownOptionGrouped: isGrouped,
        isDisabled: option.isDisabled,
        isDropdownWithIcon: dropdownWithIcon,
        hasCurrentDropdownOption: hasCurrentDropdownOption,
      })}
      onClick={handleOnClickOption}
    >
      {dropdownWithIcon ? (
        <div className="DropdownOptionWithIcon">
          {option.imgSrc && <img src={option.imgSrc} alt={optionName} />}
          {option.symbol && (
            <h5 className="DropdownOptionWithIconSymbol">{option.symbol}</h5>
          )}

          <div className="DropdownOptionWithIconTitle">
            {!showCodeColumnInTitle && (
              <h6 className="Medium OptionName WithIconOptionName">
                {optionName}
              </h6>
            )}
            {showCodeColumnInTitle && option.code && (
              <h6 className="Medium OptionName WithIconOptionName">
                {option.code}
              </h6>
            )}
            {!hideDescription && option.description && (
              <p className="DropdownOptionDescription">{option.description}</p>
            )}
          </div>
        </div>
      ) : (
        <h6 className="Medium OptionName">{optionName}</h6>
      )}
      {isMultiple && <Checkbox isChecked={isActive} />}
    </div>
  );
};

DropdownOption.propTypes = {
  /**
   * The option object for this dropdown item
   */
  option: PropTypes.object.isRequired,

  /**
   * The currently selected value in the dropdown
   */
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),

  /**
   * Callback function to handle the click event for this option
   */
  handleOptionClick: PropTypes.func.isRequired,

  /**
   * Determines if multiple selections are allowed in the dropdown
   */
  isMultiple: PropTypes.bool,

  /**
   * Determines if the options in the dropdown are grouped
   */
  isGrouped: PropTypes.bool,

  /**
   * Determines if the dropdown has an icon
   */
  dropdownWithIcon: PropTypes.bool,

  /**
   * Indicates whether the dropdown supports multiple languages
   */
  isMultiLanguage: PropTypes.bool,

  /**
   * Determines if the description of the option is hidden
   */
  hideDescription: PropTypes.bool,

  /**
   * Determines if the option is required
   */
  isOptionRequired: PropTypes.bool,
  /**
   * Indicates whether the dropdown options represent currencies
   */
  showCodeColumnInTitle: PropTypes.bool,
};

export default DropdownOption;
