import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import IconButton from "components/buttons/icon-button/IconButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import AddNewButton, {
  ENUMS as ENUMS_ADD_NEW_BUTTON,
} from "components/admin/buttons/add-new-button/AddNewButton";
import ModificationOption from "components/admin/cards/modification-option/ModificationOption";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Language from "components/admin/elements/language/Language";
import { getActiveLanguageValue } from "utils/helpers";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_SINGLE_SELECTION from "assets/images/selection/SingleSelection.png";
import IMAGE_MULTI_SELECTION from "assets/images/selection/MultiSelection.png";
import RadioSelection from "components/admin/forms/radio-selection/RadioSelection";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import isEqual from "lodash/isEqual";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import { useValidationSchema } from "utils/hooks/useValidationSchema";

import "./ModificationSettingsModal.scss";

export const ENUMS = {
  name: "ModificationSettingsModal",
};

const ModificationSettingsModal = ({
  item,
  openSlide,
  setOpenSlide,
  mainElementRef,
  onSave,
  setOutsideClickAction,
}) => {
  const { t } = useTranslation();
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);

  const schemas = useValidationSchema(t);
  const {
    requiredStringSchemaForMenuItem,
    optionalNumberSchema,
    optionalNumberSchemaForModificationOption,
  } = schemas;

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const menuLanguage = menu?.language;
  const initialFormData = {
    name: [{ value: "", languageCode: menuLanguage.code }],
    isMultiSelect: false,
    isRequired: false,
    options: [],
  };
  const [activeLanguageCode, setActiveLanguageCode] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [formDataInitial, setFormDataInitial] = useState(initialFormData);
  const isEditable = !!item;

  useFormOutsideClickHandler({
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    if (item) {
      setFormData({
        id: item.id,
        name: item.name,
        isMultiSelect: item.isMultiSelect,
        isRequired: item.isRequired,
        options: item.options?.map((option) => ({
          id: option.id,
          name: option.name,
          priceSell: option.priceSell !== 0 ? option.priceSell : "",
          priceCost:
            option.priceCost !== 0 && option.priceCost ? option.priceCost : "",
          isCountable: option.isCountable || false,
          defaultValue: option.defaultValue || false,
          maxNumber: parseInt(option.maxNumber) || 1,
        })),
      });
      setFormDataInitial({
        id: item.id,
        name: item.name,
        isMultiSelect: item.isMultiSelect,
        isRequired: item.isRequired,
        options: item.options?.map((option) => ({
          id: option.id,
          name: option.name,
          priceSell: option.priceSell !== 0 ? option.priceSell : "",
          priceCost:
            option.priceCost !== 0 && option.priceCost ? option.priceCost : "",
          isCountable: option.isCountable || false,
          defaultValue: option.defaultValue || false,
          maxNumber: parseInt(option.maxNumber) || 1,
        })),
      });
      setActiveLanguageCode(item.name[0].languageCode);
    } else {
      setFormData(initialFormData);
      setFormDataInitial(initialFormData);
      setActiveLanguageCode(initialFormData.name[0].languageCode);
    }

    reset();
  }, [item, openSlide]);

  useEffect(() => {
    reset({
      formData,
    });
  }, [reset, formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "isMultiSelect") {
      setFormData({
        ...formData,
        isMultiSelect: value === "true",
      });
    } else if (name === "name") {
      setFormData({
        ...formData,
        name: formData.name.map((property) => {
          if (property.languageCode === activeLanguageCode) {
            return {
              ...property,
              value: value,
            };
          }
          return property;
        }),
      });
    }
  };

  const handleOnOptionChange = (updatedOption) => {
    const updatedOptions = formData.options.map((option) =>
      option.id === updatedOption.id
        ? updatedOption
        : {
            ...option,
            defaultValue: updatedOption.defaultValue
              ? false
              : option.defaultValue,
          }
    );
    setFormData({
      ...formData,
      options: updatedOptions,
    });
  };

  const handleAddNewOption = () => {
    const newOption = {
      id: Date.now(),
      name: [{ value: "", languageCode: menuLanguage.code }],
      priceSell: "",
      priceCost: "",
      isCountable: false,
      defaultValue: false,
      maxNumber: 1,
    };

    setFormData({
      ...formData,
      options: [newOption, ...formData.options],
    });
  };

  const handleOnCloseModal = () => {
    if (!isEqual(formData, formDataInitial)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
    setFormData(initialFormData);
  };

  const handleNameInputWithMultipleLanguage = (names, inputName) => {
    const nameError = names
      .map((name) => {
        try {
          if (name.value.trim() === "") {
            setError(inputName, {
              type: "manual",
              message: t("errorMessages.input.generalRequired"),
            });
            setActiveLanguageCode(name.languageCode);
            return name.languageCode;
          }
          requiredStringSchemaForMenuItem.parse(name.value);
          return null;
        } catch (error) {
          setError(inputName, {
            type: "manual",
            message: t("errorMessages.input.maxCharacter").replace(
              "{{max}}",
              "100"
            ),
          });

          setActiveLanguageCode(name.languageCode);
          return name.languageCode;
        }
      })
      .filter((name) => name !== null);

    return nameError;
  };

  const validateMaxNumber = (price, type, id) => {
    try {
      optionalNumberSchema.parse(price);
      return null;
    } catch (error) {
      console.log(error.issues[0].message);
      setError(`${type}${id}`, {
        type: "manual",
        message: error.issues[0].message,
      });
      return error;
    }
  };

  const validatePrice = (price, type, id) => {
    try {
      optionalNumberSchemaForModificationOption.parse(price);
      return null;
    } catch (error) {
      console.log(error.issues[0].message);
      setError(`${type}${id}`, {
        type: "manual",
        message: error.issues[0].message,
      });
      return error;
    }
  };

  const handleOnSave = async () => {
    const nameError = handleNameInputWithMultipleLanguage(
      formData.name,
      "name"
    );
    if (nameError.length > 0) {
      return;
    }

    const options = formData.options.map((option) => {
      return {
        id: option.id,
        name: option.name,
      };
    });
    const isOptionNameNull = options
      .flat((option) => option.name)
      .filter((name) => name?.value?.trim() === "");

    if (isOptionNameNull?.length > 0) {
      return toast.error(
        t("errorMessages.required.customMessage").replace(
          "{{name}}",
          `Option name ${isOptionNameNull[0].languageCode}`
        )
      );
    }

    const optionNameError = options
      .map((option) =>
        handleNameInputWithMultipleLanguage(
          option.name,
          `optionName${option.id}`
        )
      )
      .flat();
    if (optionNameError.length > 0) {
      return;
    }

    const errors = formData.options.map((option) => {
      let error = null;
      if (option.priceSell) {
        error = validatePrice(option.priceSell, "priceSell", option.id);
      }
      if (!error && option.priceCost) {
        error = validatePrice(option.priceCost, "priceCost", option.id);
      }
      if (!error && option.maxNumber) {
        error = validateMaxNumber(option.maxNumber, "maxNumber", option.id);
      }
      return error;
    });

    const hasErrors = errors.some((error) => error !== null);
    if (hasErrors) return;

    if (isEditable) {
      onSave(formData, item.id);
    } else {
      onSave(formData);
    }
    setOpenSlide(false);
  };

  const handleOnOptionRemove = (id) => {
    setFormData({
      ...formData,
      options: formData.options.filter((option) => option.id !== id),
    });
  };

  const setFormDataName = (properties) => {
    const newProperties = properties.map((property) => {
      return property.filter((item) => item.value !== null);
    });
    setFormData({ ...formData, name: newProperties[0] });
  };

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const nameContent = (
    <InputControl
      labelType={ENUMS_INPUT_CONTROL.types.TYPE_A}
      type="text"
      name="name"
      value={getActiveLanguageValue(formData.name, activeLanguageCode)}
      required
      placeholder={t("inputs.name")}
      error={
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <p className="h7 error-message">{message}</p>
          )}
        />
      }
      hasError={errors.name}
      func={{
        ...register("name", {
          onChange: handleInputChange,
        }),
      }}
    />
  );

  const ModificationSettingsModalHeader = (
    <div className="ModificationSettingsModalHeader">
      <h3 className="ModificationSettingsModalHeaderTitle SemiBold">
        {isEditable
          ? t("modification.editModification")
          : t("modification.addModification")}
      </h3>
      <IconButton onClick={handleOnCloseModal} svgComponent={<IconClose />} />
    </div>
  );

  const ModificationSettingsModalBody = (
    <div className="ModificationSettingsModalBody">
      <form
        className="ModificationSettingsModalBodyForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <h5 className="ModificationSettingsModalBodyFormTitle Light">
          {t("modification.modificationName")}
        </h5>
        <Language
          content={nameContent}
          activeLanguageCode={activeLanguageCode}
          setActiveLanguageCode={setActiveLanguageCode}
          properties={[formData.name]}
          setProperties={setFormDataName}
        ></Language>
      </form>
      <div className="ModificationSettingsModalBodySelection">
        <h5 className="ModificationSettingsModalBodyFormTitle Light">
          {t("modification.selection.types")}
        </h5>
        <form className="ModificationSettingsModalBodySelectionForm">
          <RadioSelection
            value={false}
            label={t("modification.selection.singleSelection")}
            name="isMultiSelect"
            isChecked={!formData.isMultiSelect}
            onChange={handleInputChange}
            imgSrc={IMAGE_SINGLE_SELECTION}
          />
          <RadioSelection
            value={true}
            label={t("modification.selection.multiSelection")}
            name="isMultiSelect"
            isChecked={formData.isMultiSelect}
            onChange={handleInputChange}
            imgSrc={IMAGE_MULTI_SELECTION}
          />
        </form>
      </div>
      <div className="ModificationSettingsModalBodyOptions">
        <div className="ModificationSettingsModalBodyOptionsTitle">
          <h5 className="Light">
            {t("modification.selection.option.options")}
          </h5>
          <AddNewButton
            onClick={handleAddNewOption}
            type={ENUMS_ADD_NEW_BUTTON.types.TYPE_B}
          />
        </div>
        {formData.options.length > 0 ? (
          <div className="ModificationSettingsModalBodyOptionsList">
            {formData.options.map((option) => (
              <ModificationOption
                key={option.id}
                item={option}
                handleInputChange={handleOnOptionChange}
                handleOptionRemove={handleOnOptionRemove}
                errors={errors}
                register={register}
              />
            ))}
          </div>
        ) : (
          <h6 className="Medium AddMenuItemModalBodyNoModifications">
            {t("modification.noModificationOption")}
          </h6>
        )}
      </div>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );

  const ModificationSettingsModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSave)}
      text={t("buttons.save")}
    />
  );

  return (
    <Modal
      header={ModificationSettingsModalHeader}
      body={ModificationSettingsModalBody}
      footer={ModificationSettingsModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

ModificationSettingsModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The item data to display and edit
   */
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string,
    name: PropTypes.array.isRequired,
    isMultiSelect: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.array.isRequired,
        priceSell: PropTypes.number,
        priceCost: PropTypes.number,
        isCountable: PropTypes.bool.isRequired,
      })
    ),
  }),

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the modification
   */
  onSave: PropTypes.func,

  /**
   * The schema for the required string
   * */
  requiredStringSchema: PropTypes.object,

  /**
   * The schema for the required number
   * */
  requiredNumberSchema: PropTypes.object,

  /**
   * The schema for the optional number
   *  */
  optionalNumberSchema: PropTypes.object,

  setOutsideClickAction: PropTypes.func,
};

export default ModificationSettingsModal;
