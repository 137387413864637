import React from "react";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as IconPrinter } from "assets/icons/printer/PrinterV2.svg";

import "./AdminPrints.scss";

const AdminPrints = () => {
  const { kitchens } = useSelector((state) => state[STORE_NAMES.kitchens]);

  return (
    <div className="AdminOrderPrints">
      {kitchens.map((kitchen, index) => {
        return (
          <button key={index} className="AdminOrderPrintBtn" type="button">
            <IconPrinter />
            <h5 className="Medium">{kitchen.name}</h5>
          </button>
        );
      })}
    </div>
  );
};

export default AdminPrints;
