import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";

import { createDOBucketName } from "utils/DO-Spaces";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import FavoriteCard, {
  ENUMS,
} from "components/cards/favorite-card/FavoriteCard";
import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";

import "./BasketHeroSection.scss";

const BasketHeroSection = ({ favoriteItems, onClick, onClose, onAdd }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const businessId = qrScanStore.businessId;

  const onGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  const favoriteItemsWithAWSBucketName = favoriteItems.map((item) => ({
    ...item,
    coverImageSrc: createDOBucketName(item.coverImageSrc),
    otherImagesSrc: item.otherImagesSrc?.map((image) =>
      createDOBucketName(image)
    ),
  }));

  let [searchParams, setSearchParams] = useSearchParams();
  const hamburgerClickHandler = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showHamburgerMenu]: true,
    });
  };

  return (
    <div
      className={cx("BasketHeroSection", {
        isFavoritesEmpty: favoriteItems.length === 0,
      })}
    >
      <div className="BasketHeader">
        <BackButton type={BACK_BUTTON_ENUMS.types.TYPE_C} onClick={onGoBack} />
        <h2 className="BasketHeaderTitle SemiBold">{t("basket.basket")}</h2>
        <HamburgerButton onClick={hamburgerClickHandler} hasBackground />
      </div>
      {favoriteItemsWithAWSBucketName &&
        favoriteItemsWithAWSBucketName.length > 0 && (
          <>
            <div className="BasketFavoriteItemsContainer">
              <h5>{t("common.yourLikes")}</h5>
              <div className="BasketFavoriteItemsWrapper">
                {favoriteItemsWithAWSBucketName &&
                  favoriteItemsWithAWSBucketName.map((favItem) => (
                    <FavoriteCard
                      key={favItem.id}
                      menuItem={favItem}
                      onClick={onClick}
                      onClose={onClose}
                      onAdd={onAdd}
                      type={ENUMS.types.TYPE_B}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

BasketHeroSection.propTypes = {
  /**
   * An array of favorite items
   */
  favoriteItems: PropTypes.array,

  /**
   * Function to handle item click
   */
  onClick: PropTypes.func,

  /**
   * Function to close an item
   */
  onClose: PropTypes.func,

  /**
   * Function to add an item
   */
  onAdd: PropTypes.func,
};
export default BasketHeroSection;
