import React from "react";
import PropTypes from "prop-types";

import "./FooterSocial.scss";

const FooterSocial = ({ SocialIcon, onClick }) => {
  return (
    <div className="FooterSocial" onClick={onClick}>
      <img src={SocialIcon} alt="logo" />
    </div>
  );
};
FooterSocial.propTypes = {
  /**
   * The SVG component to be displayed as the social's content.
   */
  SocialIcon: PropTypes.node,
  /**
   * Function to handle the click event.
   */
  onClick: PropTypes.func.isRequired,
};
export default FooterSocial;
