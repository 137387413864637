import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { REDUX_STORE } from "redux/store";
import AppRouter from "pages/AppRouter";

function App() {
  return (
    <div>
      <Provider store={REDUX_STORE}>
        <AppRouter />
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Provider>
    </div>
  );
}

export default App;
