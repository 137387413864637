import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { SIGN_IN_METHOD } from "../sign-in/SignIn";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import Stepper from "components/stepper/Stepper";
import Steps from "components/stepper/Steps/Steps";
import StepContent from "components/stepper/step-content/StepContent";
import SignUpForm from "./sign-up-form/SignUpForm";
import ConfirmPassword from "./confirm-password/ConfirmPassword";
import SetUserPin from "./set-user-pin/SetUserPin";
import { resetAllReduxStores } from "utils/general";
import { ROUTE_NAME } from "utils/constants/routes";
import Login from "components/admin/elements/login/Login";
import { signup } from "redux/actions/userAction";
import { handleOnAsyncError } from "utils/helpers";

import "./SignUp.scss";

const SignUp = () => {
  const { t } = useTranslation();
  const schemas = useValidationSchema(t);

  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);

  const methods = useForm({
    resolver: zodResolver(schemas.signUpSchema(signInMethod)),
    criteriaMode: "all",
  });

  const { getValues } = methods;

  const dispatch = useDispatch();
  const userCountryCode = useSelector(
    (state) => state[STORE_NAMES.user].userGeolocation?.country_code
  );

  const STEP_TYPES = {
    userDetail: "userDetails",
    passwordVerification: "passwordVerification",
    setPinCode: "pinCode",
  };

  const steps = [
    {
      title: "stepper.userDetail",
      subTitle: t("login.signUp.alreadyHaveAccount"),
      type: STEP_TYPES.userDetail,
    },
    {
      title: "stepper.passwordVerification",
      subTitle: t("login.signUp.passwordSentTo"),
      type: STEP_TYPES.passwordVerification,
    },
    {
      title: "stepper.setPinCode",
      subTitle: t("login.signUp.setPinSubTitle"),
      type: STEP_TYPES.setPinCode,
    },
  ];

  const [activeStep, setActiveStep] = useState(steps[0]);
  const [isUserExist, setIsUserExist] = useState(false);

  const handleNext = () => {
    const currentIndex = steps.findIndex(
      (step) => step.title === activeStep.title
    );
    setActiveStep(steps[currentIndex + 1]);
  };

  const handlePrev = () => {
    const currentIndex = steps.findIndex(
      (step) => step.title === activeStep.title
    );
    setActiveStep(steps[currentIndex - 1]);
  };

  useEffect(() => {
    resetAllReduxStores(dispatch);
  }, []);

  const handleSignupFormSubmit = async () => {
    const formData = getValues();
    delete formData.agreeTerms;
    delete formData.rememberMe;

    try {
      const response = await dispatch(
        signup({
          userData: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailOrPhone:
              signInMethod === SIGN_IN_METHOD.email
                ? formData.email
                : formData.phoneNumber.replace(/\+/g, ""),
          },
          method: signInMethod,
        })
      );
      if (response.error) {
        if (response.payload.statusCode === 409) {
          setIsUserExist(true);
          return handleNext();
        }
        handleOnAsyncError();
      } else {
        setIsUserExist(false);
        handleNext();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderStepContent = () => {
    switch (activeStep.type) {
      case STEP_TYPES.userDetail:
        return (
          <FormProvider {...methods}>
            <SignUpForm
              handleSignupFormSubmit={handleSignupFormSubmit}
              signInMethod={signInMethod}
              setSignInMethod={setSignInMethod}
            />
          </FormProvider>
        );
      case STEP_TYPES.passwordVerification:
        return (
          <ConfirmPassword
            handleNext={handleNext}
            signInMethod={signInMethod}
            formData={getValues()}
            isUserExist={isUserExist}
            userCountryCode={userCountryCode}
          />
        );
      case STEP_TYPES.setPinCode:
        return <SetUserPin signInMethod={signInMethod} />;
      default:
        return null;
    }
  };

  return (
    <div className="SignUp">
      <Login
        title={t("login.signUp.title")}
        subTitle={
          activeStep.type === STEP_TYPES.userDetail
            ? t("login.signUp.alreadyHaveAccount")
            : null
        }
        currentStep={activeStep.title}
        link={
          activeStep.type === STEP_TYPES.userDetail
            ? t("login.signIn.title")
            : null
        }
        linkHref={ROUTE_NAME.signIn}
        hasSSO
        handlePrev={handlePrev}
        isPrevButtonVisible={
          activeStep.title === STEP_TYPES.passwordVerification
        }
      >
        <Stepper>
          <Steps steps={steps} activeStep={activeStep} />
          <StepContent activeStep={activeStep}>
            {renderStepContent()}
          </StepContent>
        </Stepper>
      </Login>
    </div>
  );
};

export default SignUp;
