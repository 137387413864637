import React from "react";
import PropTypes from "prop-types";
import IconButton, {
  ENUMS as ICON_BUTTON_ENUMS,
} from "../buttons/icon-button/IconButton";
import { ReactComponent as IconClose } from "assets/icons/close/CloseBig.svg";
import Modal from "components/modal/Modal";
import { useTranslation } from "react-i18next";

import "./PolicyModal.scss";

const PolicyModal = ({
  open,
  onClose,
  mainElementRefPolicy,
  policyModalTranslateFileKey,
}) => {
  const { t, i18n } = useTranslation();

  const dataFromTranslateFile = i18n.getResourceBundle(
    i18n.language,
    "translation"
  ).policy[policyModalTranslateFileKey];

  const transformData = (data) => {
    return Object.keys(data).map((key) => {
      const section = data[key];
      const contentArray = Object.keys(section.content).map(
        (contentKey) => section.content[contentKey]
      );
      return {
        title: section.title,
        content: contentArray,
      };
    });
  };

  const termsData = transformData(dataFromTranslateFile);

  const handleOnModalClose = () => {
    onClose();
  };

  const PolicyModalHeader = (
    <div className="AddItemModalHeader">
      <h3 className="SemiBold AddItemModalHeaderTitle">
        {t("login.signUp.termsAndPrivacy")}
      </h3>
      <IconButton
        onClick={handleOnModalClose}
        svgComponent={<IconClose />}
        className="AddItemModalHeaderCloseButton"
        type={ICON_BUTTON_ENUMS.types.TYPE_MEDIUM}
      />
    </div>
  );

  const PolicyModalBody = (
    <div className="PolicyModalBody">
      {termsData.map((section, index) => (
        <div key={index} className="TermsSection">
          <h5 className="SemiBold">{section.title}</h5>
          <ul>
            {section.content.map((item, contentIndex) => (
              <li key={contentIndex}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );

  return (
    <Modal
      openSlide={open}
      header={PolicyModalHeader}
      body={PolicyModalBody}
      onClose={handleOnModalClose}
      mainElementRef={mainElementRefPolicy}
    />
  );
};

PolicyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRefPolicy: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  policyModalTranslateFileKey: PropTypes.string.isRequired,
};

export default PolicyModal;
