import { AXIOS } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createCategory = async (businessId, menuId, category) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}`,
    category
  );
export const updateCategory = async (businessId, menuId, category, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}/${id}`,
    category
  );
export const deleteCategory = async (businessId, menuId, id) =>
  AXIOS.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuCategories}/${id}`
  );
