import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as ICON_CONFIRM } from "assets/icons/confirm/confirm.svg";
import { ReactComponent as ICON_CLEAR } from "assets/icons/trash/clear.svg";
import { setUserWithPin } from "redux/slices/userStore";
import { setIsAdminDashboardPinLocked } from "redux/slices/appStore";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import { ADMIN_PIN_CODE_MAX_LENGTH } from "utils/constants/global";
import { getBrandConstructionLogo } from "utils/branding-helper";

const Logo = getBrandConstructionLogo();

import "./LockScreen.scss";

const LockScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state[STORE_NAMES.business]);
  const { t } = useTranslation();
  const [passCode, setPassCode] = useState("");
  const [passCodeIsValid, setPassCodeIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSetError = ({ isValid, errorMessage }) => {
    setPassCodeIsValid(isValid);
    setErrorMessage(errorMessage);
  };

  const handleOnClickKeyboardNumber = (value) => {
    if (passCode.length < ADMIN_PIN_CODE_MAX_LENGTH) {
      setPassCode((prev) => prev + value);
      handleSetError({ isValid: true, errorMessage: null });
    }
  };

  const handleOnClickClearBtn = () => {
    handleSetError({ isValid: true, errorMessage: null });
    setPassCode("");
  };

  const confirmPin = () => {
    const user = users.find((u) => passCode === u.pinCode.userPinCode);
    if (user) {
      handleSetError({ isValid: true, errorMessage: null });
      dispatch(setUserWithPin(user));
      dispatch(setIsAdminDashboardPinLocked(false));
      navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.adminOrderDashboard}`);
    } else {
      handleSetError({
        isValid: false,
        errorMessage: t("errorMessages.input.pinCode.incorrect"),
      });
    }
  };

  return (
    <div className="LockScreen">
      <div className="LockScreenContainer">
        {/*<Logo className="LockScreenLogo" />*/}
        <div className={`LockScreenDisplay ${!passCodeIsValid && "hasError"}`}>
          <h3 className="LockScreenDisplayValue">
            {t("login.pin")}
            {passCode && ": " + "*".repeat(passCode.length)}
          </h3>
          {!passCodeIsValid && (
            <h6 className="LockScreenErrorMessage">{errorMessage}</h6>
          )}
        </div>
        <div className="LockScreenKeyboard">
          {[...Array(9)].map((_, index) => {
            return (
              <button
                className="LockScreenKeyboardBtn"
                key={index}
                onClick={() => handleOnClickKeyboardNumber(index + 1)}
                type="button"
              >
                <h3 className="Medium">{index + 1}</h3>
              </button>
            );
          })}
          <button
            className="LockScreenKeyboardBtn LockScreenKeyboardBtnClear"
            onClick={handleOnClickClearBtn}
            type="button"
          >
            <ICON_CLEAR />
          </button>
          <button
            className="LockScreenKeyboardBtn"
            onClick={() => handleOnClickKeyboardNumber(0)}
            type="button"
          >
            <h3 className="Medium">0</h3>
          </button>
          <button
            className="LockScreenKeyboardBtn LockScreenKeyboardBtnConfirm"
            onClick={confirmPin}
            type="button"
          >
            <ICON_CONFIRM />
          </button>
        </div>
        <h3 className="LockScreenAlternative Medium">{t("login.or")}</h3>
        <div className="LockScreenSignIn">
          <h4>{t("login.signUp.alreadyHaveAccount")}</h4>
          <Link
            to={ROUTE_NAME.signIn}
            className="LockScreenSignInLink SemiBold"
          >
            {t("login.signIn.title")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LockScreen;
