import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "./GuestProfiles.scss";

export const ENUMS = {
  name: "GuestProfiles",
};

const GuestProfiles = ({ guests, onClick, selectedGuests, guestId }) => {
  const { t } = useTranslation();

  let allGuests = [
    ...guests.filter((guest) => guest.person.id === guestId),
    ...guests.filter((guest) => guest.person.id !== guestId),
  ];

  const handleGuestClick = (guestId) => {
    onClick(guestId);
  };

  return (
    <div className="GuestProfiles">
      {allGuests.map((guest, index) => {
        const isActive = selectedGuests.find(
          (selectedGuest) => guest.person.id === selectedGuest.person.id
        );
        return (
          <div
            key={`${guest.person.id}-${index}`}
            className={cx("GuestProfile", {
              isActive: isActive,
            })}
            onClick={() => handleGuestClick(guest.person.id)}
          >
            <img alt={guest.name} src={guest.person.image} />
            <div className="GuestProfileIdentity">
              <h6 className={"Medium"}>
                {guest.person.id === guestId
                  ? t("dashboard.guest.me")
                  : guest.person.title}
              </h6>
              <h6 className={"h7"}>{!guest.name && guest.person.originalId}</h6>
            </div>
          </div>
        );
      })}
    </div>
  );
};

GuestProfiles.propTypes = {
  /**
   * An array of guest objects.
   */
  guests: PropTypes.array.isRequired,
  /**
   * A callback function to handle click events on guest profiles.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * An array of selected guest objects.
   */
  selectedGuests: PropTypes.array.isRequired,

  /**
   * The id of the guest
   */
  guestId: PropTypes.string,
};

export default GuestProfiles;
