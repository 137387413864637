import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  commonAsyncErrorMessage,
  KITCHEN_STAGE,
} from "utils/constants/data/base";
import AdminKitchenColumn from "./AdminKitchenColumn";
import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import { updateOrderAsync } from "redux/actions/orderActions";
import { STORE_NAMES } from "utils/constants/redux";
import { handleOnAsyncError } from "utils/helpers";

import "./AdminKithcen.scss";

const AdminKitchen = () => {
  const { t } = useTranslation();
  const initialColumns = {
    [KITCHEN_STAGE.NEW]: {
      id: KITCHEN_STAGE.NEW,
      title: t("kitchen.stageNew"),
      orders: [],
    },
    [KITCHEN_STAGE.PROGRESS]: {
      id: KITCHEN_STAGE.PROGRESS,
      title: t("kitchen.stageProgress"),
      orders: [],
    },
    [KITCHEN_STAGE.DONE]: {
      id: KITCHEN_STAGE.DONE,
      title: t("kitchen.stageDone"),
      orders: [],
    },
  };
  const [columns, setColumns] = useState(initialColumns);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { isScreenSize } = useWindowSize();
  const isTabletScreen = isScreenSize(BREAKPOINT_NAMES.tablet);
  const dispatch = useDispatch();

  useEffect(() => {
    separateOrders(orders, [
      KITCHEN_STAGE.NEW,
      KITCHEN_STAGE.PROGRESS,
      KITCHEN_STAGE.DONE,
    ]);
  }, [orders]);

  const separateOrders = (orders, stages) => {
    const updatedColumns = { ...columns };

    stages.forEach((stage) => {
      updatedColumns[stage] = {
        ...updatedColumns[stage],
        orders: orders.filter((order) => order.kitchenStage === stage),
      };
    });

    setColumns(updatedColumns);
  };

  const handleOrderDrag = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // check if the columns and their orders exist
    if (!columns[source.droppableId] || !columns[destination.droppableId]) {
      return;
    }

    const updatedColumns = { ...columns };
    const sourceColumn = updatedColumns[source.droppableId];
    const sourceItems = Array.from(sourceColumn.orders);

    if (source.droppableId === destination.droppableId) {
      const [removed] = sourceItems.splice(source.index, 1);
      sourceItems.splice(destination.index, 0, removed);

      // Update the source column with the new order
      updatedColumns[source.droppableId] = {
        ...sourceColumn,
        orders: sourceItems,
      };
      setColumns(updatedColumns);
    } else {
      const updatedOrders = orders.map((order) => {
        if (order.id === parseInt(result.draggableId)) {
          return {
            ...order,
            kitchenStage: destination.droppableId,
          };
        }
        return order;
      });

      separateOrders(updatedOrders, [
        KITCHEN_STAGE.NEW,
        KITCHEN_STAGE.PROGRESS,
        KITCHEN_STAGE.DONE,
      ]);

      const response = await dispatch(
        updateOrderAsync({
          businessId,
          order: { kitchenStage: destination.droppableId },
          id: result.draggableId,
        })
      );
      if (response.error) {
        handleOnAsyncError(t(commonAsyncErrorMessage));
        separateOrders(orders, [
          KITCHEN_STAGE.NEW,
          KITCHEN_STAGE.PROGRESS,
          KITCHEN_STAGE.DONE,
        ]);
      }
    }
  };

  return (
    <>
      {isTabletScreen && (
        <div className=" AdminKitchenHeaderTitle">
          <h3 className="SemiBold">{t("navbarRoutes.departments")}</h3>
        </div>
      )}
      <div className="AdminKitchenPage">
        <DragDropContext onDragEnd={handleOrderDrag}>
          <div className="AdminKitchenColumns">
            {Object.values(columns).map((stage) => (
              <AdminKitchenColumn
                key={stage.id}
                droppableId={stage.id}
                title={stage.title}
                orders={stage.orders}
              />
            ))}
          </div>
        </DragDropContext>
      </div>
    </>
  );
};

export default AdminKitchen;
