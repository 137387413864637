import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getMenuAsync } from "redux/actions/menuAction";
import {
  getAdminMetaDataAsync,
  getGuestMetaDataAsync,
} from "redux/actions/metaDataAction";
import {
  addLanguageImageToTheMenu,
  filterAndSortMenu,
} from "utils/helpersMenu";
import { mergeMenus } from "utils/helper-functions/menuPartialUpdateHelper";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const menuStore = createSlice({
  name: STORE_NAMES.menu,
  initialState: initialState,
  reducers: {
    addPartialMenuUpdate: (state, { payload: partialMenuUpdate }) => {
      const updatedMenu = mergeMenus(state.data, partialMenuUpdate);

      if (partialMenuUpdate.oldCategoryId) {
        updatedMenu.categories = updatedMenu.categories.reduce(
          (acc, category) => {
            if (category.id === partialMenuUpdate.oldCategoryId) {
              acc.push({
                ...category,
                menuItems: category.menuItems.filter(
                  (item) => item.id !== partialMenuUpdate.menuItemId
                ),
              });
            } else {
              acc.push(category);
            }
            return acc;
          },
          []
        );
      }

      state.data = addLanguageImageToTheMenu(filterAndSortMenu(updatedMenu));
      state.isLoading = false;
    },

    setMenu: (state, { payload }) => {
      return {
        ...state,
        data: addLanguageImageToTheMenu(filterAndSortMenu(payload)),
      };
    },

    resetMenuStore: (state) => {
      return {
        ...initialState,
        data: { availableLanguages: state?.data?.availableLanguages || [] },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getMenuAsync.rejected, (state, { payload }) => {
      return { ...state, isLoading: false, error: payload };
    });
    builder.addCase(getMenuAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        data: addLanguageImageToTheMenu(filterAndSortMenu(payload)),
      };
    });
    builder.addCase(getGuestMetaDataAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getGuestMetaDataAsync.rejected, (state, { payload }) => {
      return { ...state, isLoading: false, error: payload };
    });
    builder.addCase(getGuestMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        data: addLanguageImageToTheMenu(filterAndSortMenu(payload.menu)),
      };
    });
    builder.addCase(getAdminMetaDataAsync.pending, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isLoading: true,
      };
    });
    builder.addCase(getAdminMetaDataAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    });
    builder.addCase(getAdminMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        data: addLanguageImageToTheMenu(filterAndSortMenu(payload.menu)),
      };
    });
  },
});

export const { resetMenuStore, addPartialMenuUpdate, setMenu } =
  menuStore.actions;

export default menuStore.reducer;
