import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import AdminBodyController from "pages/admin/admin-body/AdminBodyController";
import useAdminMetaDataUpdater from "utils/hooks/useAdminMetaDataUpdater";
import useInventoryUpdater from "utils/hooks/useInventoryUpdater";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import AdminSidebarController from "./admin-sidebar/AdminSidebarController";
import useTopicUpdater from "utils/hooks/useTopicUpdater";
import useOrderNotificationSound from "utils/hooks/useOrderNotificationSound";
import AudioPlayer from "components/elements/audio-player/AudioPlayer";
import useSetUserLanguage from "utils/hooks/useSetUserLanguage";

import "./AdminController.scss";

const AdminController = () => {
  useAdminMetaDataUpdater();
  useInventoryUpdater();
  useTopicUpdater();
  useSetUserLanguage();
  const notification = useOrderNotificationSound();

  const { isScreenSize } = useWindowSize();
  const isMobile = isScreenSize(BREAKPOINT_NAMES.mobile);

  const { isAdminDashboardPinLocked } = useSelector(
    (state) => state[STORE_NAMES.app]
  );

  const navigate = useNavigate();

  useEffect(() => {
    isAdminDashboardPinLocked &&
      navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.lockScreen}`);
  }, [isAdminDashboardPinLocked]);

  return (
    <div className={`AdminController ${isMobile ? "isMobile" : "isDesktop"}`}>
      {notification && (
        <AudioPlayer
          audioSrc={notification.src}
          isPlaying={true}
          deps={[notification.id]}
        />
      )}
      <AdminSidebarController />
      <AdminBodyController />
    </div>
  );
};

export default AdminController;
