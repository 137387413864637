import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useWindowSize, { BREAKPOINT_NAMES } from "utils/hooks/useWindowSize";
import ICON_FILTER from "assets/icons/filter/OrderByColumn.svg";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import EditButton from "components/admin/buttons/edit-button/EditButton";
import Spinner from "components/elements/spinner/Spinner";
import { QUERY_VALUES } from "utils/constants/api";
import { ReactComponent as SortArrowDesc } from "assets/icons/admin-dashboard/sortArrowDesc.svg";
import { ReactComponent as SortArrowAsc } from "assets/icons/admin-dashboard/sortArrowAsc.svg";

import "./Table.scss";

export const ENUMS = {
  name: "Table",
};

const Table = ({
  columns,
  items,
  hasDeleteButton,
  hasEditColumnTitle,
  onDelete,
  onEdit,
  dashboardClassname,
  classname,
  tableControlsClassName,
  hasEditColumn = true,
  hasEditRow = true,
  columnClick,
  setColumnClick,
  sortType,
  setSortType,
  customFilterIcon,
  setColumnClickCount,
  isDataLoading,
}) => {
  const { t } = useTranslation();
  const handleOnFilter = (column, columnIndex) => {
    if (columnIndex === columnClick) {
      setSortType((prevSortType) =>
        prevSortType === QUERY_VALUES.sortDirDESC
          ? QUERY_VALUES.sortDirASC
          : QUERY_VALUES.sortDirDESC
      );
      setColumnClickCount((prev) => {
        const newCount = prev + 1;
        if (newCount === 3) {
          setColumnClick(null);
          setSortType("");
          return 0;
        }
        return newCount;
      });
    } else {
      setColumnClick(columnIndex);
      setSortType(QUERY_VALUES.sortDirDESC);
      setColumnClickCount(1);
    }
  };

  const { isScreenSize } = useWindowSize();
  const isTablet = isScreenSize(BREAKPOINT_NAMES.tablet);
  return (
    <div className={`TableData ${dashboardClassname}  ${classname}`}>
      <div className="TableHeader">
        {columns.map((column, columnIndex) => {
          return (
            <React.Fragment key={column.key}>
              {column.hasCustomColumn ? (
                <div
                  className={cx(
                    "h7 SemiBold TableColumnItemClass TableCustomColumnItem",
                    {
                      isFilterable: column.isFilterable,
                      [column.columnClassName]: column.columnClassName,
                    }
                  )}
                  onClick={(e) =>
                    column.isFilterable && items?.length > 1
                      ? handleOnFilter(e, columnIndex)
                      : undefined
                  }
                  style={{
                    width: `calc((100% - 100px) / ${columns.length})`,
                    maxWidth: `calc((100% - 100px) / ${columns.length})`,
                  }}
                >
                  {column.name}

                  {column.isFilterable &&
                    (customFilterIcon && sortType === "" ? (
                      <>{customFilterIcon}</>
                    ) : columnIndex === columnClick &&
                      sortType === QUERY_VALUES.sortDirDESC ? (
                      <SortArrowAsc className="TableDataSortArrowAsc" />
                    ) : columnIndex === columnClick &&
                      sortType === QUERY_VALUES.sortDirASC ? (
                      <SortArrowDesc />
                    ) : customFilterIcon ? (
                      <>{customFilterIcon}</>
                    ) : (
                      <img src={ICON_FILTER} alt="filter" />
                    ))}
                </div>
              ) : (
                <h6
                  className={cx("h7 SemiBold TableColumnItemClass", {
                    isFilterable: column.isFilterable,
                    [column.columnClassName]: column.columnClassName,
                  })}
                  onClick={(e) =>
                    column.isFilterable && items?.length > 1
                      ? handleOnFilter(e, columnIndex)
                      : undefined
                  }
                  style={{
                    width: `calc((100% - 100px) / ${columns.length})`,
                    maxWidth: `calc((100% - 100px) / ${columns.length})`,
                  }}
                >
                  {column.name}

                  {column.isFilterable &&
                    (customFilterIcon && sortType === "" ? (
                      <>{customFilterIcon}</>
                    ) : columnIndex === columnClick &&
                      sortType === QUERY_VALUES.sortDirDESC ? (
                      <SortArrowAsc className="TableDataSortArrowAsc" />
                    ) : columnIndex === columnClick &&
                      sortType === QUERY_VALUES.sortDirASC ? (
                      <SortArrowDesc />
                    ) : customFilterIcon ? (
                      <>{customFilterIcon}</>
                    ) : (
                      <img src={ICON_FILTER} alt="filter" />
                    ))}
                </h6>
              )}
            </React.Fragment>
          );
        })}

        {hasEditColumn ? (
          <div className="h7 SemiBold TableEditColumn">
            <h6 className="h7 SemiBold">
              {hasEditColumnTitle && t("buttons.edit")}
            </h6>
          </div>
        ) : (
          ""
        )}
      </div>

      {isDataLoading ? (
        <div className="TableDataListLoading">
          <Spinner className="TableLoading" />
        </div>
      ) : (
        <>
          {items?.map((item) => (
            <div className="TableDataList" key={item.id}>
              {columns.map((column) => {
                return column.hasCustomRow ? (
                  <div
                    key={column.key}
                    className="h7 Medium TableCustomRowItem"
                    style={{
                      width: isTablet
                        ? `calc((100% - 100px) / ${columns.length})`
                        : `calc((100% - 100px) / ${columns.length})`,
                      maxWidth: isTablet
                        ? `calc((100% - 100px) / ${columns.length})`
                        : `calc((100% - 100px) / ${columns.length})`,
                    }}
                    title={
                      typeof item[column.key] !== "object"
                        ? item[column.key]
                        : undefined
                    }
                  >
                    {item[column.key]}
                  </div>
                ) : (
                  <h6
                    key={column.key}
                    className="h7 Medium TableCustomRowItem"
                    style={{
                      width: isTablet
                        ? `calc((100% - 100px) / ${columns.length})`
                        : `calc((100% - 100px) / ${columns.length})`,
                      maxWidth: isTablet
                        ? `calc((100% - 100px) / ${columns.length})`
                        : `calc((100% - 100px) / ${columns.length})`,
                    }}
                    title={
                      typeof item[column.key] !== "object"
                        ? item[column.key]
                        : undefined
                    }
                  >
                    {item[column.key]}
                  </h6>
                );
              })}

              {hasEditRow ? (
                <h6
                  className={`h7 Medium TableControls ${tableControlsClassName}`}
                >
                  {hasDeleteButton && (
                    <DeleteButton
                      onClick={() => {
                        onDelete(item.id);
                      }}
                    />
                  )}
                  <EditButton
                    onClick={() => {
                      onEdit(item.id);
                    }}
                    editButtonType
                  />
                </h6>
              ) : (
                ""
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

Table.propTypes = {
  /**
   * An array representing the columns of the table
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      isFilterable: PropTypes.bool,
    })
  ).isRequired,

  /**
   * An array of items to be displayed in the table
   */
  items: PropTypes.array.isRequired,

  /**
   * Flag indicating whether the table has a delete button column
   */
  hasDeleteButton: PropTypes.bool,

  /**
   * Flag indicating whether the table has an edit column title
   */
  hasEditColumnTitle: PropTypes.bool,

  /**
   * Function to be called when a delete button is clicked
   */
  onDelete: PropTypes.func,

  /**
   * Function to be called when an edit button is clicked
   */
  onEdit: PropTypes.func,

  sortType: PropTypes.string,
  setSortType: PropTypes.func,
  columnClick: PropTypes.number,
  setColumnClick: PropTypes.func,
  classname: PropTypes.string,
  tableControlsClassName: PropTypes.string,
  hasEditColumn: PropTypes.bool,
  hasEditRow: PropTypes.bool,
  dashboardClassname: PropTypes.string,
  customFilterIcon: PropTypes.element,
  setColumnClickCount: PropTypes.func,
  isDataLoading: PropTypes.bool,
};

export default Table;
