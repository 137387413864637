import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

// Create the context
const ImageVisibilityContext = createContext();

// Create a provider component
export const ImageVisibilityProvider = ({ children }) => {
  const [visibleImages, setVisibleImages] = useState({});

  const setImageVisible = (src) => {
    setVisibleImages((prev) => ({ ...prev, [src]: true }));
  };

  return (
    <ImageVisibilityContext.Provider value={{ visibleImages, setImageVisible }}>
      {children}
    </ImageVisibilityContext.Provider>
  );
};
ImageVisibilityProvider.propTypes = {
  children: PropTypes.node,
};

// Custom hook to use the ImageVisibilityContext
export const useImageVisibility = () => {
  return useContext(ImageVisibilityContext);
};
