import React from "react";
import useLanguage from "utils/hooks/useLanguage";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { findModificationById, findOptionById } from "utils/helpers";

const OrderItemModificationList = ({
  modification,
  className,
  hasPriceInfo = false,
  menuItemModificationsInRedux,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const modificationInRedux =
    findModificationById(menuItemModificationsInRedux, modification.id) ||
    modification;
  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencySymbol = menuCurrency?.symbol;
  const renderOption = (option) => {
    const optionInRedux =
      findOptionById(modificationInRedux.options, option.id) || option;
    const optionName = displayDataByLanguage(optionInRedux.name);
    const priceInfo = hasPriceInfo
      ? ` (${
          Math.sign(option.priceSell) === -1 ? "-" : "+"
        }${currencySymbol}${Math.abs(option.priceSell)})`
      : "";
    return option.count > 1
      ? `${option.count}x ${optionName}${priceInfo}`
      : `${optionName}${priceInfo}`;
  };

  return (
    <li className={className}>
      <h6 className="h7">
        {displayDataByLanguage(modificationInRedux.name)}:{" "}
        {modification.options.map(renderOption).join(", ")}
      </h6>
    </li>
  );
};

OrderItemModificationList.propTypes = {
  modification: PropTypes.object.isRequired,
  className: PropTypes.string,
  hasPriceInfo: PropTypes.bool,
  menuItemModificationsInRedux: PropTypes.array,
};

export default OrderItemModificationList;
