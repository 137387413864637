import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { STORE_NAMES } from "utils/constants/redux";

import "./CalendarRangePicker.scss";

const CalendarRangePicker = ({
  maxDateProp,
  setFromDateToDateProps,
  fromDate,
  setFromDate,
  toDate,
  className,
}) => {
  const [maxDate, setMaxDate] = useState(maxDateProp || new Date());
  const [minDate, setMinDate] = useState(null);

  const businessCreateTime = useSelector(
    (state) => state[STORE_NAMES.business].business?.createDate
  );

  useEffect(() => {
    if (businessCreateTime) {
      const businessStartDate = new Date(businessCreateTime);
      businessStartDate.setDate(businessStartDate.getDate() - 2);

      if (fromDate < businessStartDate) {
        setFromDate(businessStartDate);
      }

      setMinDate(businessStartDate);
    }
  }, [businessCreateTime, fromDate]);

  let flatpickrInstance;

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setFromDateToDateProps(selectedDates);

      const selectedMinDate = selectedDates[0];
      let calculatedMaxDate = new Date(selectedMinDate);
      calculatedMaxDate.setMonth(calculatedMaxDate.getMonth() + 1);
      calculatedMaxDate.setDate(selectedMinDate.getDate());
      const today = new Date();
      if (calculatedMaxDate > today) {
        calculatedMaxDate = today;
      }

      setMaxDate(calculatedMaxDate);

      if (flatpickrInstance) {
        flatpickrInstance.set("maxDate", calculatedMaxDate);
      }
    }
  };

  const formattedFromDate = fromDate ? new Date(fromDate) : null;
  const formattedToDate = toDate ? new Date(toDate) : null;

  return (
    <div className={`CalendarRangePicker ${className}`}>
      <Flatpickr
        options={{
          mode: "range",
          dateFormat: "d-m-Y",
          minDate: minDate,
          maxDate: maxDate,
          onClose: handleDateChange,
        }}
        value={[formattedFromDate, formattedToDate]}
        onChange={handleDateChange}
        onReady={(selectedDates, dateStr, instance) => {
          flatpickrInstance = instance;
        }}
      />
    </div>
  );
};

CalendarRangePicker.propTypes = {
  maxDateProp: PropTypes.instanceOf(Date),
  setFromDateToDateProps: PropTypes.func.isRequired,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  setFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string,
};

export default CalendarRangePicker;
