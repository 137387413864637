import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Chat from "components/elements/chat/Chat";
import { STORE_NAMES } from "utils/constants/redux";
import { AdminWebsocketContext } from "utils/context-api/AdminWebsocketContext";
import useChatControllerHelper from "utils/hooks/websocket/useChatControllerHelper";

import "./AdminChat.scss";

const AdminChat = ({ selectedTopic, chatHeader }) => {
  const author = useSelector((state) => state[STORE_NAMES.user].user);
  const topicId = selectedTopic?.id;
  const businessId = author.business.id;
  const { publishTableChatMessage } = useContext(AdminWebsocketContext);
  const currentTopic =
    useSelector((state) => state[STORE_NAMES.chat].topics)?.find(
      (topic) => topic.id === topicId
    )

  const processedAuthor = {
    id: author.id,
    name: author.name,
    role: { name: author.roles[0].name },
  };

  const {
    authorLastReadTime,
    handleNewMessage,
    updateUserLastReadTimeWithArgs,
  } = useChatControllerHelper({
    author: processedAuthor,
    onPublishMessage: publishTableChatMessage,
    businessId: businessId,
    orderId: topicId,
    userId: author.id,
  });

  return (
    <div className="AdminChat">
      <Chat
        author={processedAuthor}
        messages={currentTopic?.messages || []}
        onNewText={handleNewMessage}
        authorLastReadTime={authorLastReadTime}
        updateUserLastReadTime={updateUserLastReadTimeWithArgs}
        chatHeader={chatHeader}
        topicId={currentTopic?.orderId}
      />
    </div>
  );
};

AdminChat.propTypes = {
  selectedTopic: PropTypes.object,
  setNewMessage: PropTypes.func,
  chatHeader: PropTypes.node,
};

export default AdminChat;
