import React from "react";
import Calendar from "react-calendar";
import PropTypes from "prop-types";

import Modal from "components/modal/Modal";
import CloseButton from "components/buttons/close-button/CloseButton";

import "./CalendarPicker.scss";

const CalendarPicker = ({
  minDate,
  maxDate,
  value,
  openCalendar,
  refCalendar,
  setOpenCalendar,
  handleDateChange,
}) => {
  const calendarModalHeader = (
    <div className="CalendarModalHeader">
      <CloseButton onClick={() => setOpenCalendar(false)} />
    </div>
  );

  const calendarModalBody = (
    <div className="CalendarModalBody">
      <Calendar
        onChange={handleDateChange}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        className="CalendarPicker"
        tileClassName="CalendarPickerTile"
      />
    </div>
  );

  return (
    <div className="CalendarModalContainer">
      <Modal
        header={calendarModalHeader}
        body={calendarModalBody}
        mainElementRef={refCalendar}
        openSlide={openCalendar}
      />
    </div>
  );
};

CalendarPicker.propTypes = {
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date || Boolean),
  value: PropTypes.string,
  openCalendar: PropTypes.bool.isRequired,
  refCalendar: PropTypes.object.isRequired,
  setOpenCalendar: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default CalendarPicker;
