import { useEffect } from "react";

const useCssRootVars = () => {
  const keyHandler = (event) => {
    // Disable keyboard shortcuts for Windows and Linux
    if (
      event.ctrlKey &&
      event.shiftKey &&
      (event.key === "I" || event.key === "i")
    ) {
      event.preventDefault();
    }

    // Disable keyboard shortcuts for MacOS
    if (
      event.metaKey &&
      event.altKey &&
      event.shiftKey &&
      (event.key === "I" || event.key === "i")
    ) {
      event.preventDefault();
    }
    if (event.metaKey && event.altKey && event.key === "∆") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_BROWSER_INSPECT_MODE !== "false") {
      // Disable right-click context menu
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });

      // Disable keyboard shortcuts for MacOS, Windows and Linux
      document.addEventListener("keydown", keyHandler);
      document.addEventListener("keyup", keyHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyHandler);
      document.removeEventListener("keyup", keyHandler);
    };
  }, []);
};

export default useCssRootVars;
