import React from "react";
// TODO uncomment only when needed. Suspect to cause Pipeline errors because of npm package
// import GooglePayButton from "@google-pay/button-react";

const PayButton = () => {
  return (
      <h5>Google Pay disabled</h5>
    // <GooglePayButton
    //   environment="TEST"
    //   paymentRequest={{
    //     apiVersion: 2,
    //     apiVersionMinor: 0,
    //     allowedPaymentMethods: [
    //       {
    //         type: "CARD",
    //         parameters: {
    //           allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
    //           allowedCardNetworks: ["MASTERCARD", "VISA"],
    //         },
    //         tokenizationSpecification: {
    //           type: "PAYMENT_GATEWAY",
    //           parameters: {
    //             gateway: "example",
    //             gatewayMerchantId: "exampleGatewayMerchantId",
    //           },
    //         },
    //       },
    //     ],
    //     merchantInfo: {
    //       merchantId: "12345678901234567890",
    //       merchantName: "Demo Merchant",
    //     },
    //     transactionInfo: {
    //       totalPriceStatus: "FINAL",
    //       totalPriceLabel: "Total",
    //       totalPrice: "100.00",
    //       currencyCode: "USD",
    //       countryCode: "US",
    //     },
    //   }}
    //   onLoadPaymentData={(paymentRequest) => {
    //     console.log("load payment data", paymentRequest);
    //   }}
    // />
  );
};

export default PayButton;
