import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserGeoLocation } from "redux/slices/userStore";

import { getGeolocation } from "utils/api/services/geolocation";

const useGeoLocation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getGeolocation().then((data) => {
      dispatch(setUserGeoLocation(data));
    });
  }, []);
};

export default useGeoLocation;
