import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as IconCallWaiter } from "assets/icons/waiter/CallWaiter.svg";
import EmptyZoneIcon from "assets/icons/qr/Table.svg";
import NoOrderIcon from "assets/icons/other/NoOrder.svg";
import EmptyState from "components/admin/empty-state/EmptyState";
import IconAskBill from "components/elements/icon-ask-bill/IconAskBill";
import { ReactComponent as IconNewChat } from "assets/icons/waiter/chat.svg";
import { ReactComponent as IconNewOrder } from "assets/icons/waiter/newOrder.svg";
import { ORDER_ACTION_STATE } from "utils/constants/data/menu-model";
import { findZoneOfTable } from "utils/helpers";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import StatsPieChartWithNeedleWrapper from "components/admin/charts/chart-wrappers/stats-pieChartWithNeedle-wrapper/StatsPieChartWithNeedleWrapper";
import { ReactComponent as ChatIcon } from "assets/icons/chat/ChatMessage.svg";
import { QUERY_PARAMS } from "utils/constants/routes";

import "./AdminOrderMap.scss";

const AdminOrderMap = ({ onClickTable }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  // const sliderRef = useRef(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const user = useSelector((state) => state[STORE_NAMES.user].user);
  const isTableFull = (tableID) => {
    return orders.find((order) => order.table.id === tableID);
  };
  let initialZone = {
    name: t("buttons.fullTables"),
    id: 0,
  };

  const [activeZone, setActiveZone] = useState(initialZone);
  const selectFullTables = () => {
    const allTables = [];
    zones.forEach((zone) => {
      zone.tables.forEach((table) => {
        isTableFull(table.id) && allTables.push(table);
      });
    });
    return {
      ...initialZone,
      tables: allTables,
    };
  };

  const activeZoneTables =
    zones?.find((zone) => {
      return zone.id === activeZone?.id;
    }) || selectFullTables();

  const activeTables = activeZoneTables.tables
    .filter((table) => {
      if (selectedAction) {
        return orders.some(
          (order) => order.table.id === table.id && order[selectedAction.id]
        );
      }
      return true;
    })
    .map((el) => {
      const selectedOrder = orders.find((o) => o.table.id === el.id);
      const currentTopic = chat?.find(
        (topic) => topic.id === selectedOrder?.id
      );
      const lastMessageTime =
        currentTopic?.messages[currentTopic.messages.length - 1]?.dateTime;
      const lastReadTime = currentTopic?.usersLastReadTime.find(
        (item) => item.userId === user.id
      )?.dateTime;
      let hasNewMessage;
      if (lastMessageTime && lastReadTime) {
        hasNewMessage = lastReadTime < lastMessageTime;
      } else if (lastMessageTime && !lastReadTime) {
        hasNewMessage = true;
      }
      const hasNewOrder = selectedOrder?.hasNewOrder;
      const askForBill = selectedOrder?.actionBill;
      const callWaiter = selectedOrder?.actionWaiter;
      return { ...el, askForBill, callWaiter, hasNewMessage, hasNewOrder };
    });

  useEffect(() => {
    if (activeZone.id === initialZone.id) {
      setActiveZone({
        ...initialZone,
        name: `${t("buttons.fullTables")} (${activeZoneTables.tables.length})`,
        tables: activeZoneTables,
      });
    }
  }, [activeZone.id, activeZoneTables.tables.length, t]);

  const handleOnFilterOrdersByAction = (action) => {
    setSelectedAction((prevSelectedAction) =>
      prevSelectedAction?.id === action.id ? null : action
    );
  };
  const handleZoneChange = (zone) => {
    zone && setActiveZone(zone);
  };

  const getOrdersLengthByAction = (name) => {
    const filteredOrders =
      orders
        ?.filter((order) =>
          activeZoneTables?.tables?.some((table) => table.id === order.table.id)
        )
        .filter((order) => order[name]) || [];
    return filteredOrders.length;
  };

  const orderFilterOptions = [
    {
      id: ORDER_ACTION_STATE.actionBill,
      name: t("waiter.bill"),
    },
    {
      id: ORDER_ACTION_STATE.actionWaiter,
      name: t("waiter.call"),
    },
  ];
  const askForBillCount = getOrdersLengthByAction(
    ORDER_ACTION_STATE.actionBill
  );
  const callWaiterCount = getOrdersLengthByAction(
    ORDER_ACTION_STATE.actionWaiter
  );

  const allTablesLength = zones.flatMap((zone) => zone.tables).length;

  const allTablesOfActiveZone =
    activeZone.id === initialZone.id
      ? allTablesLength
      : activeZoneTables.tables.length;

  const fullTablesOfActiveZone = activeZoneTables.tables.filter(({ id }) =>
    isTableFull(id)
  );

  const fullTablesIdByActiveZone = fullTablesOfActiveZone.flatMap(
    (table) => table.id
  );
  const totalGuestsOfOrdersByActiveZone = orders
    .filter((order) => fullTablesIdByActiveZone.includes(order.table.id))
    .reduce((acc, order) => {
      return acc + (order?.guests?.length || 0);
    }, 0);

  const messageCountOfOrdersByActiveZone = activeTables.filter(
    (table) => table.hasNewMessage
  ).length;
  return (
    <div className="AdminOrderMapSection">
      <div className="AdminOrderMapTitle">
        <div className="AdminOrderMapTitleInfoAndActionButtonsWrapper">
          <div className="AdminOrderMapTitleInfo">
            <div className="AdminOrderFullTables">
              <Dropdown
                onChange={handleZoneChange}
                name="zones"
                value={activeZone}
                options={[initialZone, ...zones]}
              />
            </div>
          </div>
          <div className="AdminOrderMapTitleActionButtons">
            <div className="AdminOrderAskCall">
              {orderFilterOptions.map((option) => {
                return (
                  <div
                    key={option.id}
                    className={`AdminOrderOption ${
                      selectedAction?.id === option.id ? "isActive" : ""
                    }`}
                    onClick={() => handleOnFilterOrdersByAction(option)}
                  >
                    {option.id === ORDER_ACTION_STATE.actionBill && (
                      <IconAskBill />
                    )}
                    {option.id === ORDER_ACTION_STATE.actionWaiter && (
                      <IconCallWaiter />
                    )}
                    <h6 className="h7 Medium">{option.name}</h6>
                    {askForBillCount > 0 &&
                      option.id === ORDER_ACTION_STATE.actionBill &&
                      selectedAction?.id !== option.id && (
                        <div className="AdminOrderButtonNotifications">
                          <h5 className="SemiBold">{askForBillCount}</h5>
                        </div>
                      )}
                    {callWaiterCount > 0 &&
                      option.id === ORDER_ACTION_STATE.actionWaiter &&
                      selectedAction?.id !== option.id && (
                        <div className="AdminOrderButtonNotifications">
                          <h5 className="SemiBold">{callWaiterCount}</h5>
                        </div>
                      )}
                  </div>
                );
              })}
              {business?.chat && (
                <div
                  className="AdminOrderChatButton"
                  onClick={() => {
                    setSearchParams({
                      ...searchParams,
                      [QUERY_PARAMS.showDetailedChat]: true,
                    });
                  }}
                >
                  <ChatIcon />
                  <h6 className="h7 Medium">{t("chat.chat")}</h6>
                  {messageCountOfOrdersByActiveZone > 0 && (
                    <div className="AdminOrderButtonNotifications">
                      <h5 className="SemiBold">
                        {messageCountOfOrdersByActiveZone}
                      </h5>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <StatsPieChartWithNeedleWrapper
            totalGuestsOfOrdersByActiveZone={totalGuestsOfOrdersByActiveZone}
            fullTablesOfActiveZone={fullTablesOfActiveZone}
            allTablesOfActiveZone={allTablesOfActiveZone}
          />
        </div>
      </div>
      <div className="AdminOrderMapSectionTables">
        {activeTables.map((table) => {
          if (isTableFull(table.id)) {
            return (
              <div
                key={table.id}
                className={`AdminOrderMapSectionTableItem hasOrder${
                  table.askForBill ||
                  table.callWaiter ||
                  table.hasNewMessage ||
                  table.hasNewOrder
                    ? " newNotification"
                    : ""
                }`}
                onClick={() => onClickTable(table)}
              >
                <div>
                  <h3 className="TableText Bold AdminOrderMapSectionTableItemTitle">
                    {table.name}
                  </h3>
                  {activeZone.id === initialZone.id && (
                    <h5 className="ZoneText LetterSpacingS Medium">
                      {`${findZoneOfTable(zones, table.id).name}`}
                    </h5>
                  )}
                </div>
                <div className={"AdminOrderMapSectionTableItemActionsWrapper"}>
                  <div className="AdminOrderMapSectionTableItemActions">
                    <IconAskBill isActive={table.askForBill} />
                    {business?.chat && (
                      <IconNewChat
                        className={`${table.hasNewMessage && "isActive"}`}
                      />
                    )}
                  </div>
                  <div className="AdminOrderMapSectionTableItemActions">
                    <IconCallWaiter
                      className={`${table.callWaiter && "isActive"}`}
                    />
                    <IconNewOrder
                      className={`${table.hasNewOrder && "isActive"}`}
                    />
                  </div>
                </div>
              </div>
            );
          }
        })}
        {activeTables.map((table) => {
          if (!isTableFull(table.id)) {
            return (
              <div
                key={table.id}
                className={`AdminOrderMapSectionTableItem`}
                onClick={() => onClickTable(table)}
              >
                <h3 className="Bold AdminOrderMapSectionTableItemTitle">
                  {table.name}
                  {!activeZone && ` / ${findZoneOfTable(zones, table.id).name}`}
                </h3>
              </div>
            );
          }
        })}
        {allTablesOfActiveZone === 0 && (
          <EmptyState
            icon={EmptyZoneIcon}
            description={t("emptyTable.emptyZoneDescription")}
            isAdmin={true}
          />
        )}
        {allTablesOfActiveZone !== 0 && activeTables.length === 0 && (
          <EmptyState
            icon={NoOrderIcon}
            description={t("emptyStates.noOrders")}
            isAdmin={true}
          />
        )}
      </div>
    </div>
  );
};
AdminOrderMap.propTypes = {
  /**
   * Function to handle click table
   */
  onClickTable: PropTypes.func,
  /**
   * Array for newMessage
   */
  newMessage: PropTypes.array,
};

export default AdminOrderMap;
