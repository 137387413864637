import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ImageWithPlaceholder = ({
  imageSource,
  placeholder,
  className,
  alt,
  onClick,
}) => {
  const [imgSrc, setImgSrc] = useState(imageSource || placeholder);

  const handleError = () => {
    setImgSrc(placeholder);
  };

  useEffect(() => {
    setImgSrc(imageSource || placeholder);
  }, [imageSource]);

  return (
    <img
      src={imgSrc}
      className={className}
      alt={alt}
      onError={handleError}
      onClick={onClick}
    />
  );
};

ImageWithPlaceholder.propTypes = {
  imageSource: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

export default ImageWithPlaceholder;
