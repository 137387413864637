import { AXIOS, AXIOS_NO_AUTH } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createReservationGuest = async (businessId, reservation) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}`,
    reservation
  );

export const createReservationAdmin = async (businessId, reservation) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}`,
    reservation
  );

export const updateReservationGuest = async (businessId, reservation, id) =>
  AXIOS_NO_AUTH.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}/${id}`,
    reservation
  );

export const updateReservationAdmin = async (businessId, reservation, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}/${id}`,
    reservation
  );

export const deleteReservation = async (businessId, id) =>
  AXIOS.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.reservations}/${id}`
  );
