import io from "socket.io-client";

export const getRedisWebsocket = () =>
  io(`${process.env.REACT_APP_BACKEND_URL}`, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
  });

export const REDIS_STREAM_CONSTANTS = {
  subscribes: {
    joinBusinessChatChannel: "xJoinBusinessChatChannel",
    joinOrderChannel: "xJoinOrderChannel",
    sendOrderChatMessage: "xSendOrderChatMessage",
  },
  events: {
    CONNECT: "connect",
    DISCONNECT: "disconnect",
    newChatMessage: "xNewChatMessage",
    oldChatMessages: "xOldChatMessages",
    oldBusinessOrderMessages: "xOldBusinessOrderMessages",
  },
};

export const REDIS_CHANNELS = {
  split: ":",
  guestOrder: {
    eventName1: "GUEST_ORDER_EVENT",
    eventName2: "GUEST_ORDER_TABLE",
    join: "GUEST_ORDER_JOIN",
  },
  adminOrder: {
    inboundEventName: "ADMIN_ORDER_EVENT",
    join: "ADMIN_ORDER_JOIN",
  },
};

export const generateDynamicWSEventName = (eventName, dynamicPart) => {
  return `${eventName}${REDIS_CHANNELS.split}${dynamicPart}`;
};

export function generateDynamicWSEventName4(
  eventName1,
  dynamicPart1,
  eventName2,
  dynamicPart2
) {
  return `${eventName1}${REDIS_CHANNELS.split}${dynamicPart1}/${eventName2}${REDIS_CHANNELS.split}${dynamicPart2}`;
}
