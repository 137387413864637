import { compose, configureStore } from "@reduxjs/toolkit";
import persistState from "redux-localstorage";

import appReducer from "redux/slices/appStore";
import userReducer from "redux/slices/userStore";
import menuReducer from "redux/slices/menuStore";
import basketReducer from "redux/slices/basketStore";
import guestReducer from "redux/slices/guestStore";
import businessReducer from "redux/slices/businessStore";
import zoneReducer from "redux/slices/zoneStore";
import qrScanReducer from "redux/slices/qrScanStore";
import qrReducer from "redux/slices/qrStore";
import ordersReducer from "redux/slices/ordersStore";
import inventoryReducer from "redux/slices/inventoryStore";
import kitchenReducer from "redux/slices/kitchenStore";
import chatReducer from "redux/slices/chatStore";

import { STORE_NAMES, STORE_VALUES } from "utils/constants/redux";

const filterMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();

  const filteredState = JSON.parse(JSON.stringify(state));

  const keysToDelete = [STORE_VALUES.isLoading, STORE_VALUES.thunkAPIStates];

  Object.keys(filteredState).forEach((slice) => {
    keysToDelete.forEach((key) => {
      delete filteredState[slice][key];
    });
  });

  localStorage.setItem(STORE_NAMES.redux, JSON.stringify(filteredState));

  return result;
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORE_NAMES.redux);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const preloadedState = loadState();

const enhancerLocalStorage = compose(persistState());

export const REDUX_STORE = configureStore({
  reducer: {
    [STORE_NAMES.app]: appReducer,
    [STORE_NAMES.user]: userReducer,
    [STORE_NAMES.menu]: menuReducer,
    [STORE_NAMES.basket]: basketReducer,
    [STORE_NAMES.orders]: ordersReducer,
    [STORE_NAMES.guest]: guestReducer,
    [STORE_NAMES.business]: businessReducer,
    [STORE_NAMES.zones]: zoneReducer,
    [STORE_NAMES.qrScan]: qrScanReducer,
    [STORE_NAMES.qr]: qrReducer,
    [STORE_NAMES.inventory]: inventoryReducer,
    [STORE_NAMES.kitchens]: kitchenReducer,
    [STORE_NAMES.chat]: chatReducer,
  },
  preloadedState,
  enhancers: (defaultEnhancers) => [...defaultEnhancers, enhancerLocalStorage],
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    filterMiddleware,
  ],
});
