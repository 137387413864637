import { useEffect } from "react";
import isEqual from "lodash/isEqual";

export default function useFormOutsideClickHandler({
  formData,
  formDataInitial,
  setOpenSlide,
  setOpenSlideConfirmCloseModal,
  setOutsideClickAction,
  coverImageLoading = false,
}) {
  useEffect(() => {
    // Function for modal outside click, when unsaved changes yes then open confirmation modal
    const handleOutsideClick = () => {
      if (coverImageLoading || !isEqual(formData, formDataInitial)) {
        setOpenSlide(true);
        setOpenSlideConfirmCloseModal(true);
      } else {
        setOpenSlideConfirmCloseModal(false);
        setOpenSlide(false);
      }
    };

    setOutsideClickAction && setOutsideClickAction(handleOutsideClick);

    return () => {
      setOutsideClickAction && setOutsideClickAction(() => {});
    };
  }, [
    formData,
    formDataInitial,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
    coverImageLoading,
  ]);
}
