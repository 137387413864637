import { AXIOS, AXIOS_NO_AUTH } from "utils/api/axios";
import { API_PATH_PARAMS, API_PATH_HELPERS } from "utils/constants/api";

export const signin = (user, method) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.login}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const verifyUser = (user, method) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.verifyUser}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const signup = (user, method) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.signUp}/${API_PATH_PARAMS.admin}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const resendCode = (user, method) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.auth}/${API_PATH_PARAMS.resendCode}?${API_PATH_HELPERS.method}=${method}`,
    user
  );

export const getUsers = async (businessId) =>
  AXIOS.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}`
  );
export const createUser = async (businessId, user, method) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}?${API_PATH_HELPERS.method}=${method}`,
    user
  );
export const updateUser = async (businessId, user, id, method, sendPinCode) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${id}?${API_PATH_HELPERS.method}=${method}&${API_PATH_HELPERS.sendPinCode}=${sendPinCode}`,
    user
  );
export const deleteUser = async (businessId, id) =>
  AXIOS.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.users}/${id}`
  );
