import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch } from "react-redux";
import useCountdown from "utils/hooks/useCountdown";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { verifyUser, resendCode } from "redux/actions/userAction";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { handleOnAsyncError } from "utils/helpers";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import PropTypes from "prop-types";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import ResendPassword from "components/admin/elements/resend-password/ResendPassword";
import { useTranslation } from "react-i18next";
import { setUserBusiness } from "redux/slices/userStore";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { useKeyPress } from "utils/hooks/useKeyPress";
import { EVENT_CODES } from "utils/constants/events";

import "./ConfirmPassword.scss";

const ConfirmPassword = ({
  handleNext,
  signInMethod,
  formData,
  isUserExist,
  userCountryCode,
}) => {
  const { t } = useTranslation();
  const passwordSchema = useValidationSchema(t).confirmPasswordSchema;
  const [counter, setCounter] = useCountdown(0);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const dispatch = useDispatch();
  const passwordInputRef = useRef(null);

  const isLoadingVerifyUser = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.verifyUser;
  const isLoadingResend = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.resendCode;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({
    resolver: zodResolver(passwordSchema),
    criteriaMode: "all",
  });

  const { ref, ...rest } = register("password");
  useImperativeHandle(ref, () => passwordInputRef.current);

  const handleConfirmPassword = async () => {
    try {
      const response = await dispatch(
        verifyUser({
          userData: {
            emailOrPhone: signInMethod === SIGN_IN_METHOD.email ? formData.email : formData.phoneNumber.replace(/\+/g, ""),
            password: passwordInputRef.current.value,
            businessName: formData.businessName,
            planId: parseInt(formData.plan.id),
            userCountryCode: userCountryCode,
          },
          method: signInMethod,
        })
      );
      if (response.error) {
        const errorData = handleAPIErrorMessage(response.payload);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }

      } else {
        await dispatch(
          setUserBusiness({
            businessId: response.payload.business.businessId,
            menuId: response.payload.business.menuId,
          })
        );
        handleNext();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const enterPressed = useKeyPress(EVENT_CODES.enter);
  useEffect(() => {
    if (enterPressed) {
      handleSubmit(handleConfirmPassword)();
    }
  }, [enterPressed]);

  const handleResendPassword = async () => {
    try {
      const response = await dispatch(
        resendCode({
          userData: {
            emailOrPhone: signInMethod === SIGN_IN_METHOD.email ? formData.email : formData.phoneNumber.replace(/\+/g, ""),
          },
          method: signInMethod,
        })
      );
      if (response.error) {
        handleOnAsyncError();
      } else {
        setCounter(60);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const subTitle = isUserExist
    ? t("login.signUp.subTitleForUserExist")
    : t("login.signUp.subTitleForUserNotExist").replace(
      "{{emailOrPhone}}",
      `${formData[signInMethod]}`
    );

  return (
    <div className="ConfirmPassword">
      <p className="Subtitle">{subTitle}</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputControl
          placeholder="Password"
          type="password"
          ref={passwordInputRef}
          {...rest}
          required
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          hasError={errors.password}
          error={
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <ResendPassword
          onClick={handleResendPassword}
          isUserExist={isUserExist}
          isLoading={isLoadingResend}
          counter={counter}
        />
        <PrimaryButton
          onClick={handleSubmit(handleConfirmPassword)}
          text={t("buttons.verify")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
          className="ConfirmPasswordButton"
          isLoading={isLoadingVerifyUser}
        />
      </form>
    </div>
  );
};

ConfirmPassword.propTypes = {
  handleNext: PropTypes.func,
  signInMethod: PropTypes.string,
  formData: PropTypes.object,
  isUserExist: PropTypes.bool,
  userCountryCode: PropTypes.string,
};

export default ConfirmPassword;
