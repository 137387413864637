import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createOrderAdmin,
  deleteOrder,
  getOrderById,
  getOrders,
  updateOrderAction,
  updateOrderAdmin,
  updateOrderAssignee,
  updateOrderTable,
} from "utils/api/services/order";
import { STORE_NAMES } from "utils/constants/redux";

export const getOrdersAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/getOrders`,
  async ({ businessId }) => {
    const response = await getOrders(businessId);
    return response.data;
  }
);

export const getOrderByIdAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/getOrderById`,
  async ({ businessId, orderId }) => {
    const response = await getOrderById(businessId, orderId);
    return response.data;
  }
);

export const createOrderAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/createOrder`,
  async ({ businessId, order }) => {
    const response = await createOrderAdmin(businessId, order);
    return response.data;
  }
);

export const updateOrderAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/updateOrder`,
  async ({ businessId, order, id }) => {
    const response = await updateOrderAdmin(businessId, order, id);
    return response.data;
  }
);

export const updateOrderTableAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/updateOrderTable`,
  async ({ businessId, order, id }) => {
    const response = await updateOrderTable(businessId, order, id);
    return response.data;
  }
);

export const updateOrderAssigneeAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/updateOrderAssignee`,
  async ({ businessId, order, id }) => {
    const response = await updateOrderAssignee(businessId, order, id);
    return response.data;
  }
);

export const updateOrderActionAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/updateOrderAction`,
  async ({ businessId, order, id }) => {
    const response = await updateOrderAction(businessId, order, id);
    return response.data;
  }
);

export const deleteOrderAsync = createAsyncThunk(
  `${STORE_NAMES.orders}/deleteOrder`,
  async ({ businessId, id }) => {
    const response = await deleteOrder(businessId, id);
    return response.data;
  }
);
