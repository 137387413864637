import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

import { MENU_VIEW_ENUMS } from "utils/constants/data/base";

import "./MenuViewSelection.scss";

export const ENUMS = {
  name: "MenuViewSelection",
};
const MenuViewSelection = ({ currentOptionId, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="MenuViewSelection">
      {Object.keys(MENU_VIEW_ENUMS).map((viewType) => (
        <div
          key={MENU_VIEW_ENUMS[viewType].id}
          className={cx("MenuViewSelectionOption", {
            isActive: MENU_VIEW_ENUMS[viewType].id === currentOptionId,
          })}
          onClick={() => onClick(MENU_VIEW_ENUMS[viewType].id)}
        >
          {/*<img*/}
          {/*  src={*/}
          {/*    MENU_VIEW_ENUMS[viewType].id === currentOptionId*/}
          {/*      ? MENU_VIEW_ENUMS[viewType].activeIcon*/}
          {/*      : MENU_VIEW_ENUMS[viewType].icon*/}
          {/*  }*/}
          {/*  alt={t(MENU_VIEW_ENUMS[viewType].name)}*/}
          {/*/>*/}
          {/*< children={MENU_VIEW_ENUMS[viewType].icon}></>*/}
          {/*    {MENU_VIEW_ENUMS[viewType].icon}*/}
          {/*<Haha/>*/}
          <ReactSVG src={MENU_VIEW_ENUMS[viewType].icon} />
          <h6 className="MenuViewSelectionOptionName h7 Medium">
            {t(MENU_VIEW_ENUMS[viewType].name)}
          </h6>
        </div>
      ))}
    </div>
  );
};

MenuViewSelection.propTypes = {
  /**
   * The current option id of the component
   */
  currentOptionId: PropTypes.number.isRequired,

  /**
   * Click event when click selection
   */
  onClick: PropTypes.func.isRequired,
};

MenuViewSelection.defaultProps = {
  currentOptionId: MENU_VIEW_ENUMS.SCROLL.id,
};

export default MenuViewSelection;
