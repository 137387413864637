import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { WEEKDAYS } from "utils/constants/global";

import "./WeekdaysProgressChart.scss";

const WeekdaysProgressChart = ({ data, total, countName }) => {
  const { t } = useTranslation();

  const statsData = {};
  Object.keys(data).forEach((weekday) => {
    const eachDataCount = data[weekday][countName];

    // Calculate percentage and store it as a string
    statsData[weekday] = isNaN((eachDataCount / total) * 100)
      ? 0
      : ((eachDataCount / total) * 100).toFixed(2);
  });

  return (
    <div className="WeekdaysProgressChart">
      {Object.keys(WEEKDAYS).map((key, index) => {
        const weekName = WEEKDAYS[key];
        const weekValue = statsData[WEEKDAYS[key]];
        return (
          <div key={index} className="WeekdaysProgressBarChart">
            <div className="weekDayOnly">
              <div className="WeekDayName">
                <h6 className="Medium">{t(`weekdays.${weekName}`)} </h6>
              </div>
            </div>

            <div className="WrapperOfProgressAndPercent">
              <div
                className={cx(
                  "WeekDayOuterProgressBar",
                  { isMonday: weekName === WEEKDAYS.monday },
                  { isTuesday: weekName === WEEKDAYS.tuesday },
                  { isWednesday: weekName === WEEKDAYS.wednesday },
                  { isThursday: weekName === WEEKDAYS.thursday },
                  { isFriday: weekName === WEEKDAYS.friday },
                  { isSaturday: weekName === WEEKDAYS.saturday },
                  { isSunday: weekName === WEEKDAYS.sunday },
                  { isDefault: !(weekValue > 0) }
                )}
              >
                <div
                  className={cx(
                    "WeekDayInnerProgress",
                    { isMonday: weekName === WEEKDAYS.monday },
                    { isTuesday: weekName === WEEKDAYS.tuesday },
                    { isWednesday: weekName === WEEKDAYS.wednesday },
                    { isThursday: weekName === WEEKDAYS.thursday },
                    { isFriday: weekName === WEEKDAYS.friday },
                    { isSaturday: weekName === WEEKDAYS.saturday },
                    { isSunday: weekName === WEEKDAYS.sunday }
                  )}
                  style={{ width: weekValue + "%" }}
                ></div>
              </div>

              <div className="WeekDayPercent">
                <h6 className="Medium">{weekValue} %</h6>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

WeekdaysProgressChart.propTypes = {
  // data: PropTypes.shape({
  //   monday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  //   tuesday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  //   wednesday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  //   thursday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  //   friday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  //   saturday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  //   sunday: PropTypes.shape({
  //     sale: PropTypes.number.isRequired,
  //     revenue: PropTypes.number.isRequired,
  //   }).isRequired,
  // }).isRequired,
  data: PropTypes.object,
  total: PropTypes.number,
  countName: PropTypes.string.isRequired,
};

export default WeekdaysProgressChart;
