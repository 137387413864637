import React from "react";
import PropTypes from "prop-types";

import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import FavoritesIconButton, {
  ENUMS as FAV_ENUMS,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import "./MenuItemHeader.scss";

export const ENUMS = {
  name: "MenuItemHeader",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const MenuItemHeader = ({
  itemId,
  onGoBack,
  onClose,
  onFavorite,
  favoriteItems = [],
  classNameDefault,
  classNameScroll,
  type = ENUMS.types.TYPE_A,
}) => {
  return (
    <div
      className={`MenuItemHeaderContainer ${classNameDefault} ${classNameScroll}`}
    >
      {type === ENUMS.types.TYPE_B ? (
        <>
          <FavoritesIconButton
            onClick={onFavorite}
            id={itemId}
            value={favoriteItems?.includes(itemId)}
            type={FAV_ENUMS.types.TYPE_E}
          />
          <CloseButton
            onClick={onClose}
            type={ENUMS_CLOSE_BUTTON.types.TYPE_G}
          />
        </>
      ) : (
        <>
          <BackButton
            type={BACK_BUTTON_ENUMS.types.TYPE_C}
            onClick={onGoBack}
          />
          <FavoritesIconButton
            onClick={onFavorite}
            id={itemId}
            value={favoriteItems?.includes(itemId)}
            type={FAV_ENUMS.types.TYPE_D}
          />
        </>
      )}
    </div>
  );
};

MenuItemHeader.propTypes = {
  /**
   * The item of the component
   */
  itemId: PropTypes.number.isRequired,

  /**
   * The callback function when favorite button is clicked
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * The callback function when go back button is clicked
   */
  onGoBack: PropTypes.func.isRequired,
  /**
   * An array of favorite items
   */
  favoriteItems: PropTypes.array,

  /**
   * The callback function for closing menu item
   */

  onClose: PropTypes.func,

  /**
   * The type of the component
   */

  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  classNameDefault: PropTypes.string,

  classNameScroll: PropTypes.string,
};

export default MenuItemHeader;
