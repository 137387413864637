import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import useTimeout from "utils/hooks/useTimeout";
import CallWaiterAudio from "assets/audios/CallWaiter.mp3";
import AskForBillAudio from "assets/audios/askForBill.mp3";
import NewOrderAudio from "assets/audios/CallWaiter.mp3";

export const NOTIFICATION_SOUND_OPTIONS = {
  hasNewOrder: NewOrderAudio,
  actionBill: AskForBillAudio,
  actionWaiter: CallWaiterAudio,
};

const getTotalOrderItems = (orders) => {
  const orderItemsLength =
    orders?.reduce((total, order) => {
      const orderItemsCount =
        order?.guests?.reduce((count, guest) => {
          return count + (guest.orderItems ? guest.orderItems.length : 0);
        }, 0) || 0;
      return total + order.hasNewOrder ? orderItemsCount : 0;
    }, 0) || 0;

  return orderItemsLength;
};

const activeRoutes = [ROUTE_NAME.adminOrderDashboard];
const useOrderNotificationSound = () => {
  const { pathname } = useLocation();
  const isActiveRoute = activeRoutes
    .map((route) => ROUTE_NAME.admin + route)
    .includes(pathname);
  const { orders } = useSelector((state) => state[STORE_NAMES.orders]);

  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const askForBillSound = business?.askForBillSound || false;
  const callWaiterSound = business?.callWaiterSound || false;
  const orderSound = business?.orderSound || false;

  const getActionDataFromOrders = useCallback(
    (orders) =>
      orders
        .map((order) => {
          if (callWaiterSound || askForBillSound) {
            return {
              orderId: order.id,
              actions: {
                ...(callWaiterSound
                  ? { actionWaiter: order.actionWaiter }
                  : {}),
                ...(askForBillSound ? { actionBill: order.actionBill } : {}),
              },
            };
          }
          return null;
        })
        .filter(Boolean),
    [askForBillSound, callWaiterSound]
  );

  const findDifference = useCallback(
    (prevArray, newArray) => {
      let change = null;

      if (newArray.orderItemsCount > prevArray.orderItemsCount && orderSound) {
        return { action: "hasNewOrder", id: new Date().getTime() };
      }

      const prevMap = _.keyBy(prevArray.actions, "orderId");
      const newMap = _.keyBy(newArray.actions, "orderId");

      _.forOwn(newMap, (newOrder) => {
        const prevOrder = prevMap[newOrder.orderId];

        if (prevOrder && prevOrder.actions) {
          _.forOwn(newOrder.actions, (newActionValue, actionKey) => {
            const prevActionValue = prevOrder.actions[actionKey];

            if (!prevActionValue || prevActionValue !== newActionValue) {
              if (newActionValue === true) {
                change = {
                  action: actionKey,
                  id: new Date().getTime(),
                };
              }
            }
          });
        } else {
          _.forOwn(newOrder.actions, (newActionValue, actionKey) => {
            if (newActionValue === true) {
              change = {
                action: actionKey,
                id: new Date().getTime(),
              };
            }
          });
        }
      });

      return change;
    },
    [orderSound]
  );

  const prevActionsRef = useRef({
    actions: getActionDataFromOrders(orders),
    orderItemsCount: getTotalOrderItems(orders),
  });

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const prevActions = prevActionsRef.current;
    const newActions = {
      actions: getActionDataFromOrders(orders),
      orderItemsCount: getTotalOrderItems(orders),
    };

    const newNotification = findDifference(prevActions, newActions);

    if (newNotification) {
      setNotification(newNotification);
    }

    prevActionsRef.current = newActions;
  }, [orders]);

  useTimeout(() => setNotification(null), notification ? 1500 : null);

  return notification && isActiveRoute
    ? {
        src: NOTIFICATION_SOUND_OPTIONS[notification.action],
        id: notification.id,
      }
    : null;
};

export default useOrderNotificationSound;
