import { AXIOS } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const updateGuestInfo = async (guestInfo, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}/${id}`,
    guestInfo
  );
export const getAllGuests = async () =>
  AXIOS.get(`/${API_PATH_PARAMS.users}/${API_PATH_PARAMS.guests}`);
