import { APPLICATION_BRANDING } from "utils/constants/global";
import { ReactComponent as ILoyalFooterLogo } from "assets/icons/logo/iLoyal/footer-logo.svg";
import { ReactComponent as IMenuFooterLogo } from "assets/icons/logo/iMenu/footer-logo.svg";
import { ReactComponent as ILoyalMainLogo } from "assets/icons/logo/iLoyal/logo.svg";
import { ReactComponent as IMenuMainLogo } from "assets/icons/logo/iMenu/logo.svg";
import { ReactComponent as ILoyalMainLogoLight } from "assets/icons/logo/iLoyal/logo-light.svg";
import { ReactComponent as IMenuMainLogoLight } from "assets/icons/logo/iMenu/logo-light.svg";
import { ReactComponent as ILoyalMainLogoDark } from "assets/icons/logo/iLoyal/logo-dark.svg";
import { ReactComponent as IMenuMainLogoDark } from "assets/icons/logo/iMenu/logo-dark.svg";
import { ReactComponent as ILoyalConstructionLogo } from "assets/icons/logo/iLoyal/construction-logo.svg";
import { ReactComponent as IMenuConstructionLogo } from "assets/icons/logo/iMenu/construction-logo.svg";
import { ReactComponent as ILoyalLoadingLogo } from "assets/icons/logo/iLoyal/loading-logo.svg";
import { ReactComponent as IMenuLoadingLogo } from "assets/icons/logo/iMenu/loading-logo.svg";
import { ReactComponent as ILoyalLoadingText } from "assets/icons/logo/iLoyal/loading-text.svg";
import { ReactComponent as IMenuLoadingText } from "assets/icons/logo/iMenu/loading-text.svg";

const BrandData = {
  [APPLICATION_BRANDING.iloyal]: {
    brandName: "iLoyal",
    URL: { name: "www.iloyal.app", address: "https://iloyal.app" },
    MainLogo: ILoyalMainLogo,
    MainLogoLight: ILoyalMainLogoLight,
    MainLogoDark: ILoyalMainLogoDark,
    FooterLogo: ILoyalFooterLogo,
    ConstructionLogo: ILoyalConstructionLogo,
    LoadingLogo: ILoyalLoadingLogo,
    LoadingText: ILoyalLoadingText,
  },
  [APPLICATION_BRANDING.imenu]: {
    brandName: "iMenu",
    URL: { name: "www.iloyal.app", address: "https://iloyal.app" },
    MainLogo: IMenuMainLogo,
    MainLogoLight: IMenuMainLogoLight,
    MainLogoDark: IMenuMainLogoDark,
    FooterLogo: IMenuFooterLogo,
    ConstructionLogo: IMenuConstructionLogo,
    LoadingLogo: IMenuLoadingLogo,
    LoadingText: IMenuLoadingText,
  },
};

const currentBrand = BrandData[process.env.REACT_APP_NAME_BRANDING];

export const getBrandName = () => currentBrand.brandName;

export const getBrandURL = () => currentBrand.URL;
export const getBrandFooterLogo = () => currentBrand.FooterLogo;
export const getBrandMainLogo = () => currentBrand.MainLogo;
export const getBrandMainLogoLight = () => currentBrand.MainLogoLight;
export const getBrandMainLogoDark = () => currentBrand.MainLogoDark;
export const getBrandConstructionLogo = () => currentBrand.ConstructionLogo;
export const getBrandLoadingLogo = () => currentBrand.LoadingLogo;
export const getBrandLoadingText = () => currentBrand.LoadingText;
