import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import { deleteTopicForOrder } from "redux/slices/chatStore";

const useTopicUpdater = () => {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state[STORE_NAMES.chat].topics);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);

  useEffect(() => {
    const currentOrders = orders.flatMap((order) => order.id);
    const currentTopics = topics.flatMap((topic) => topic.orderId);

    const deletedTopic = currentTopics.find(
      (topic) => !currentOrders.includes(topic)
    );

    if (deletedTopic) {
      dispatch(deleteTopicForOrder({ orderId: deletedTopic }));
    }
  }, [orders.length]);
};

export default useTopicUpdater;
