import React from "react";

import AdminOrderInfo from "../admin-order-info/AdminOrderInfo";
import {
  findMenuItemIndexFromOrders,
  getGuestsWithConfirmed,
  getUsersWithConfirmed,
  mergeUserData,
} from "utils/helpers";
import MenuDisplay, {
  ENUMS as MENU_DISPLAY_ENUMS,
} from "pages/client/menu/menu-display/MenuDisplay";
import AdminChat from "pages/admin/admin-pages/admin-order/admin-chat/AdminChat";
import AdminPrints from "../admin-prints/AdminPrints";
import PropTypes from "prop-types";
import { MENU_TABS_ENUMS } from "../admin-order-page/AdminOrderPage";
import { ADMIN_MENU_ITEM_TYPE } from "../admin-order-menu/AdminOrderMenu";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";

import "./AdminOrderTabContent.scss"

const AdminOrderTabContent = ({
  handleOnUpdateOrder,
  formData,
  activeTabs,
  setSelectedOrderItem,
  setIsEnableToAddOrder,
  pendingData,
  setPendingData,
  selectedOrder,
  setOpenSlideOrderItem,
}) => {
  const activeUser = useSelector((state) => state[STORE_NAMES.user]).user;
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);
  const handleOnOrderMenuItemClick = (item) => {
    const filteredModifications = item.modifications
      .map((modification) => {
        const defaultOptions = modification.options.filter(
          (option) => option.defaultValue === true
        );

        if (defaultOptions.length > 0) {
          return {
            ...modification,
            options: defaultOptions.map((option) => {
              return {
                ...option,
                count: 1,
              };
            }),
          };
        } else {
          return null;
        }
      })
      .filter((modification) => modification !== null);

    setSelectedOrderItem({
      item: { ...item, modifications: filteredModifications },
      count: 1,
    });
    setOpenSlideOrderItem(true);
    setIsEnableToAddOrder(true);
  };

  const handleOnAddToOrder = ({ item, count }) => {
    const waiterIndex = pendingData.users.findIndex(
      (user) => user.person.id === activeUser.id
    );

    const createNewWaiter = () => ({
      person: {
        id: activeUser.id,
        name: activeUser.name,
      },
      orderItems: [],
    });

    const waiter =
      waiterIndex !== -1
        ? { ...pendingData.users[waiterIndex] }
        : createNewWaiter();

    const findExistingItemIndex = () =>
      waiter.orderItems.findIndex((orderItem) => orderItem.item.id === item.id);

    const findExistingItemModifications = () =>
      findMenuItemIndexFromOrders(item, waiter.orderItems);

    const updateItemIfExists = () => {
      waiter.orderItems = waiter.orderItems
        .map((orderItem) => {
          if (
            item.id === orderItem.item.id &&
            findMenuItemIndexFromOrders(item, [orderItem]) !== -1
          ) {
            return {
              ...orderItem,
              isConfirmed: true,
              isPendingList: true,
            };
          }
        })
        .filter((orderItem) => orderItem !== undefined);
    };

    const addNewItemToOrder = () => {
      waiter.orderItems = [
        {
          id: new Date().getTime(),
          item: item,
          count: count,
          isConfirmed: true,
          isPendingList: true,
        },
      ];
    };

    const updateUsersData = () => {
      const updatedUsers = mergeUserData([waiter], pendingData["users"]);
      setPendingData({
        ...pendingData,
        users: updatedUsers,
      });
    };

    const existingItemIndex = findExistingItemIndex();
    const existingItemModifications = findExistingItemModifications();
    if (existingItemIndex !== -1 && existingItemModifications !== -1) {
      updateItemIfExists();
    } else {
      addNewItemToOrder();
    }

    updateUsersData();
  };

  switch (activeTabs) {
    case MENU_TABS_ENUMS.tabType.HOME:
      return (
        <div className="AdminOrderHomeDisplay">
          <AdminOrderInfo
            guests={getGuestsWithConfirmed(formData)}
            users={getUsersWithConfirmed(formData)}
            handleOnUpdateOrder={handleOnUpdateOrder}
            formData={formData}
            pendingData={pendingData}
            setPendingData={setPendingData}
          />
        </div>
      );
    case MENU_TABS_ENUMS.tabType.MENU:
      return (
        <div className="AdminOrderMenuDisplay">
          <div className="AdminOrderMenuDisplayContainer">
            <MenuDisplay
              menuViewType={ADMIN_MENU_ITEM_TYPE}
              onClick={handleOnOrderMenuItemClick}
              onAdd={handleOnAddToOrder}
              distanceLeft={16}
              type={MENU_DISPLAY_ENUMS.types.ADMIN}
              hasSearchInput
              isOrderSection
              menu={menu}
            />
          </div>
        </div>
      );
    case MENU_TABS_ENUMS.tabType.CHAT:
      return (
        <div className="AdminOrderChatDisplay">
          <AdminChat selectedTopic={selectedOrder} />
        </div>
      );
    case MENU_TABS_ENUMS.tabType.PRINTS:
      return (
        <div className="AdminOrderPrintsDisplay">
          <AdminPrints />
        </div>
      );
    default:
      return <div></div>;
  }
};

AdminOrderTabContent.propTypes = {
  handleOnUpdateOrder: PropTypes.func,
  formData: PropTypes.object,
  activeTabs: PropTypes.string,
  setSelectedOrderItem: PropTypes.func,
  setIsEnableToAddOrder: PropTypes.func,
  pendingData: PropTypes.object,
  setPendingData: PropTypes.func,
  selectedOrder: PropTypes.object,
  setOpenSlideOrderItem: PropTypes.func,
};

export default AdminOrderTabContent;
