import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import CallButton from "components/buttons/call-button/CallButton";
import { ORDER_ACTIONS_TYPE } from "utils/constants/data/menu-model";
import { STORE_NAMES } from "utils/constants/redux";
import { updateOrderActionAsync } from "redux/actions/orderActions";
import { handleOnAsyncError } from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";
import PaymentModal from "components/payment-modal/PaymentModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";
import { ReactComponent as IconChat } from "assets/icons/chat/Messages.svg";
import { ReactComponent as IconMenu } from "assets/icons/menu/restaurant-menu.svg";
import { ReactComponent as IconFeedBack } from "assets/icons/emoji/FeedBack.svg";
import MascotDashboardButton from "components/buttons/mascot-dashboard-button/MascotDashboardButton";
import FeedBackModal from "components/mascot/feedBack-modal/FeedBackModal";

import "./DashboardHeroSection.scss";

const DashboardHeroSection = ({ selectedGuests, menuItemCount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPayment, setOpenPayment, mainElementRef] = useOutsideClick();
  const [openSlideFeedBack, setOpenSlideFeedBack, mainElementRefFeedBack] =
    useOutsideClick();
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const { table, businessId } = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  );
  const tableId = table?.id;

  const currentZone = zones?.find((zone) =>
    zone.tables.find((table) => table.id === tableId)
  );

  const order = orders?.find((order) => order.table.id === table.id);
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  const currentTopic = chat?.find((topic) => topic.id === order.id);
  const messages = currentTopic?.messages || [];
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const guestId = guest.id;
  const currentGuest = order?.guests.find(
    (guest) => guest.person.id === guestId
  );
  const authorLastReadTime = currentTopic?.usersLastReadTime.find(
    (item) => item.userId === guestId
  )?.dateTime;
  const isWaiterCalled = order?.actions?.find(
    (action) => action.name === ORDER_ACTIONS_TYPE.waiter
  ).value;
  const isBillAsked = order?.actions?.find(
    (action) => action.name === ORDER_ACTIONS_TYPE.bill
  ).value;
  const { t } = useTranslation();

  const allOrderers = order
    ? [
      currentGuest,
      ...(order.guests.filter((guest) => guest.person.id !== guestId) || []),
      ...(order.users || []),
    ]
    : [];
  const isAskForBillDisabled =
    allOrderers
      .flatMap((guest) => guest.orderItems)
      .filter((item) => item.isConfirmed !== false).length === 0;
  const isCallWaiterDisabled = !order;
  const handleOnChangeAction = async (actionName) => {
    const findUpdatedAction = order.actions.find(
      (action) => action.name === actionName
    );

    const updatedAction = {
      ...findUpdatedAction,
      value: !findUpdatedAction.value,
    };

    const actionTypeKey = (actionName) => {
      switch (actionName) {
        case ORDER_ACTIONS_TYPE.bill:
          return "actionBill";
        default:
          return "actionWaiter";
      }
    };

    const actionType = actionTypeKey(updatedAction.name);

    const updatedOrder = {
      action: updatedAction,
      [actionType]: updatedAction.value,
    };
    const response = await dispatch(
      updateOrderActionAsync({ businessId, order: updatedOrder, id: order.id })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    }
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const hamburgerClickHandler = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showHamburgerMenu]: true,
    });
  };

  const unReadMessages = messages.filter(
    (message) =>
      message.author.id !== guestId &&
      (authorLastReadTime ? authorLastReadTime < message.dateTime : true)
  );
  const handleOnFeedBack = () => {
    setOpenSlideFeedBack(true);
  };

  return (
    <div className="DashboardHeroSection">
      <header>
        {currentZone && (
          <div className="DashboardHeroSectionZoneTableName">
            <h4 className="SemiBold">{order?.table?.name}</h4>
            <h3>{"("}</h3>
            <h4 className="SemiBold">{currentZone?.name}</h4>
            <h3>{")"}</h3>
          </div>
        )}
        <HamburgerButton onClick={hamburgerClickHandler} onDashboard />
      </header>
      <div className="ButtonsContainer">
        <CallButton
          isChecked={isWaiterCalled || false}
          onChange={() => handleOnChangeAction(ORDER_ACTIONS_TYPE.waiter)}
          title={t("waiter.call")}
          isDisabled={isCallWaiterDisabled}
        />
        <CallButton
          isChecked={isBillAsked || false}
          onChange={() =>
            isBillAsked
              ? handleOnChangeAction(ORDER_ACTIONS_TYPE.bill)
              : setOpenPayment(true)
          }
          title={t("waiter.bill")}
          isDisabled={isAskForBillDisabled}
        />
      </div>
      <div className="DashboardHeroButtons">
        <MascotDashboardButton
          isActive={false}
          icon={<IconMenu />}
          title={t("navbarRoutes.menu")}
          className="CustomDashboardButton"
          onClick={() =>
            navigate(
              `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
            )
          }
        />
        {business?.chat && (
          <div className="DashBoardChatButton">
            <MascotDashboardButton
              isActive={false}
              icon={<IconChat />}
              title={t("chat.chat")}
              onClick={() =>
                navigate(
                  `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.chat}`
                )
              }
            />
            {unReadMessages.length > 0 && (
              <div className="ChatButtonCount">
                <h6 className="h7 SemiBold">{unReadMessages.length}</h6>
              </div>
            )}
          </div>
        )}

        <MascotDashboardButton
          isActive={false}
          icon={<IconFeedBack />}
          title={t("dashboard.guest.review.feedBack")}
          className="CustomDashboardButton"
          onClick={handleOnFeedBack}
        />
      </div>
      <FeedBackModal
        mainElementRef={mainElementRefFeedBack}
        openSlide={openSlideFeedBack}
        onClose={() => setOpenSlideFeedBack(false)}
        closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
      />
      <PaymentModal
        mainElementRef={mainElementRef}
        openSlide={openPayment}
        onClose={() => setOpenPayment(false)}
        closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
        selectedGuests={selectedGuests}
        totalPrice={order?.totalPrice}
        menuItemCount={menuItemCount}
        tableName={table.name}
      />
    </div>
  );
};

DashboardHeroSection.propTypes = {
  selectedGuests: PropTypes.array,
  menuItemCount: PropTypes.number,
};

export default DashboardHeroSection;
