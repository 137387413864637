import { createAsyncThunk } from "@reduxjs/toolkit";

import { STORE_NAMES } from "../../utils/constants/redux";
import { getQRCodes } from "utils/api/services/QR";

export const getAllQrAsync = createAsyncThunk(
  `${STORE_NAMES.qr}/getAllQr`,
  async (businessId) => {
    const response = await getQRCodes(businessId);
    return response.data;
  }
);
