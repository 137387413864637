import { createAsyncThunk } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getMenuById } from "utils/api/services/menu";

export const getMenuAsync = createAsyncThunk(
  `${STORE_NAMES.menu}/getMenuById`,
  async ({ businessId, menuId }) => {
    const response = await getMenuById(businessId, menuId);
    return response.data;
  }
);
