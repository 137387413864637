import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "assets/icons/edit/Edit.svg";

import "./EditButton.scss";

export const ENUMS = {
  name: "EditButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
    TYPE_F: "TYPE_F",
  },
};

const EditButton = ({ type, onClick, className }) => {
  const { t } = useTranslation();

  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      onClick={(e) => handleOnClick(e)}
      type="button"
      className={cx(
        "EditButton",
        className,
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        },
        {
          typeC: type === ENUMS.types.TYPE_C,
        },
        {
          typeD: type === ENUMS.types.TYPE_D,
        },
        {
          typeE: type === ENUMS.types.TYPE_E,
        },
        {
          typeF: type === ENUMS.types.TYPE_F,
        }
      )}
    >
      <EditIcon className="EditButtonIcon" />
      {type === ENUMS.types.TYPE_D && (
        <h6 className="Medium">{t("buttons.edit")}</h6>
      )}
    </button>
  );
};

EditButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
    ENUMS.types.TYPE_D,
    ENUMS.types.TYPE_E,
    ENUMS.types.TYPE_F,
  ]),

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  className: PropTypes.string,
};

EditButton.defaultProps = {
  type: ENUMS.types.TYPE_A, // Default type is TYPE_A
};

export default EditButton;
