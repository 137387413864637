import React, { useEffect, useState } from "react";

import { getBrandMainLogoDark } from "utils/branding-helper";

import "./LogoSpinner.scss";

const Logo = getBrandMainLogoDark();

const LogoSpinner = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive((prevIsActive) => !prevIsActive);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="LogoSpinnerContainer">
      <Logo className={`LogoSpinner ${isActive ? "isActive" : ""}`} />
    </div>
  );
};

export default LogoSpinner;
