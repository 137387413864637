import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as IconRight } from "assets/icons/arrows/ArrowRight.svg";

import "./MascotDashboardButton.scss";

export const ENUMS = {
  name: "MascotDashboardButton",
};

const MascotDashboardButton = ({
  title,
  subTitle,
  icon,
  imgSrc,
  onClick,
  isActive,
  className,
  arrow,
  isTruncated,
}) => {
  return (
    <button
      className={cx("MascotDashboardButton", { isActive }, className)}
      onClick={onClick}
      type="button"
    >
      <div className="MascotDashboardButtonIcon">
        {imgSrc ? <img src={imgSrc} alt="image" /> : icon}
      </div>
      <div className="MascotDashboardButtonInfo">
        <h6
          className={cx("SemiBold", {
            isTruncated,
          })}
        >
          {title}
        </h6>
        {subTitle && <p className="h8">{subTitle}</p>}
      </div>
      {arrow && (
        <div className="MascotDashboardRightArrow">
          <IconRight />
        </div>
      )}
    </button>
  );
};

MascotDashboardButton.propTypes = {
  /**
   * The title to be displayed.
   */
  title: PropTypes.string,
  /**
   * The subtitle to be displayed.
   */
  subTitle: PropTypes.string,
  /**
   * The icon element to be displayed.
   */
  icon: PropTypes.node,
  /**
   * The Image source to be displayed.
   */
  imgSrc: PropTypes.string,
  /**
   * The callback function to be called when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Indicates whether the button is active.
   */
  isActive: PropTypes.bool,

  /**
   * Additional class name(s) for styling.
   */
  className: PropTypes.string,
  /**
   * Right arrow visibility
   */
  arrow: PropTypes.bool,

  /**
   * Truncate the text
   */
  isTruncated: PropTypes.bool,
};

export default MascotDashboardButton;
