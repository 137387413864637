import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ReservationForm from "components/reservation-form/ReservationForm";
import GuestPageHeader from "components/elements/guest-page-header/GuestPageHeader";
import CTAButton, {
  ENUMS as CTA_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import { getDestructuredReservation } from "utils/helpers";
import { createReservationGuest } from "utils/api/services/reservation";
import useAsync from "utils/hooks/useAsync";
import { useSelector } from "react-redux";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";

import "./Reservation.scss";

const Reservation = () => {
  const { execute: executeCreateReservation } = useAsync(
    createReservationGuest,
    {
      onSuccess: () => navigate(`${ROUTE_NAME.client}${ROUTE_NAME.dashboard}`),
    }
  );
  const { businessId } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const initialFormData = {
    guestName: "",
    guestNumber: "",
    note: "",
    guestsCount: 1,
    requestDateTime: null,
    reservationDay: new Date().toDateString(),
    startTime: "10:00",
    endTime: "11:00",
    tableId: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConfirmReservation = async () => {
    const reservationData = getDestructuredReservation(formData);
    await executeCreateReservation(businessId, reservationData);
  };

  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
    );
  };

  return (
    <>
      <div className="PageContainer">
        <div className="Reservation">
          <div className="ReservationPageHeader">
            <GuestPageHeader
              onGoBack={handleGoBack}
              onMenuButtonClick={() => {}}
              pageTitle={t("navbarRoutes.reservation")}
            />
          </div>

          <div className="ReservationPageBody">
            <ReservationForm data={formData} onDataUpdate={setFormData} />
          </div>

          <div className="GuestPageReservationFooter">
            <CTAButton
              className="ReservationFooterButton"
              onClick={handleConfirmReservation}
              name={t("reservation.reserve")}
              type={CTA_ENUMS.types.TYPE_E}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
