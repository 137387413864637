import React from "react";
import PropTypes from "prop-types";
import OrderMenuItem from "components/admin/cards/order-menu-item/OrderMenuItem";

import "./OrderMenuItemWrapper.scss";

export const ENUMS = {
  name: "OrderMenuItemWrapper",
};

const OrderMenuItemWrapper = ({ items, onClick, onAdd }) => {
  return (
    <div className="OrderMenuItemWrapper">
      {items.map((item) => (
        <OrderMenuItem
          key={item.id}
          item={item}
          onClick={onClick}
          onAdd={onAdd}
        />
      ))}
    </div>
  );
};

OrderMenuItemWrapper.propTypes = {
  /**
   * An array of items to display.
   */
  items: PropTypes.array.isRequired,

  /**
   * Click event handler when the component is clicked.
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The onAdd function to be called when adding an item.
   */
  onAdd: PropTypes.func,
};

export default OrderMenuItemWrapper;
