import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { REDIS_STREAM_CONSTANTS } from "utils/api/redis";
import {
  addMessageToOrder,
  deleteTopicForOrder,
  addMessagesToOrder,
} from "redux/slices/chatStore";
import {
  convertSocketChatDataToReduxChatData,
  publishOrderChatMessage,
} from "utils/helper-functions/redis-helper";

const useGuestWebsocketContextChatHelper = ({ socket, businessId }) => {
  const dispatch = useDispatch();
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [prevMessages, setPrevMessages] = useState([]);
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const orderId = orders[0]?.id;
  const chat = useSelector((state) => state[STORE_NAMES.chat].topics);
  const messages = chat?.find((topic) => topic.id === orderId)?.messages || [];
  const authorId = useSelector((state) => state[STORE_NAMES.guest].id);

  useEffect(() => {
    if (socket && businessId && orderId && authorId) {
      socket.emit(REDIS_STREAM_CONSTANTS.subscribes.joinOrderChannel, {
        businessId: businessId,
        orderId: orderId,
        userId: authorId,
      });
      socket.on(
        REDIS_STREAM_CONSTANTS.events.oldChatMessages,
        handleSocketOnOldChatMessages
      );
      socket.on(
        REDIS_STREAM_CONSTANTS.events.newChatMessage,
        handleOnNewChatMessage
      );
    }
    return () => {
      dispatch(deleteTopicForOrder({ orderId: orderId }));
      if (socket) {
        socket.off(
          REDIS_STREAM_CONSTANTS.events.oldChatMessages,
          handleSocketOnOldChatMessages
        );
        socket.off(
          REDIS_STREAM_CONSTANTS.events.newChatMessage,
          handleOnNewChatMessage
        );
      }
    };
  }, [socket, businessId, orderId, authorId]);

  useEffect(() => {
    const otherUserMessages = messages?.filter(
      (message) => message.author.id !== guest.id
    );
    const hasNewMessages = prevMessages.length < otherUserMessages?.length;
    setHasNewMessage(hasNewMessages);

    if (hasNewMessages) {
      setPrevMessages(otherUserMessages);
      const timeoutId = setTimeout(() => {
        setHasNewMessage(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [messages]);

  const handleSocketOnOldChatMessages = (allData) => {
    if (allData.length > 0) {
      const messages = [];
      allData.forEach((data) => {
        messages.push(convertSocketChatDataToReduxChatData(data).message);
      });
      const topic = {
        id: allData[0].orderId,
        orderId: allData[0].orderId,
        businessId: allData[0].businessId,
        messages,
        usersLastReadTime: [],
      };
      dispatch(addMessagesToOrder([topic]));
    }
  };

  const handleOnNewChatMessage = (data) => {
    const reduxConsumableData = convertSocketChatDataToReduxChatData(data);
    dispatch(addMessageToOrder(reduxConsumableData));
  };

  const publishTableChatMessage = (data) => {
    if (socket && data.orderId) {
      publishOrderChatMessage(socket, data);
    }
  };

  return {
    messages,
    hasNewMessage,
    publishTableChatMessage,
  };
};

export default useGuestWebsocketContextChatHelper;
