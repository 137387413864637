import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectMenuView } from "redux/slices/guestStore";
import { updateBasket } from "redux/slices/basketStore";

import BasketButton from "components/buttons/basket-button/BasketButton";
import Footer from "components/elements/footer/Footer";

import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
import useWindowSize from "utils/hooks/useWindowSize";
import { filterMenuByOptions, findMostExpensivePrice } from "utils/helpers";
import MenuHeroSection from "pages/client/menu/menu-hero-section/MenuHeroSection";
import MenuDisplay, {
  ENUMS as MENU_DISPLAY_ENUMS,
} from "pages/client/menu/menu-display/MenuDisplay";
import { ReactComponent as IconCart } from "assets/icons/basket/Cart.svg";
import WelcomeClient from "components/welcome-client/WelcomeClient";

import "./Menu.scss";

const Menu = () => {
  const { size } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessId } = useParams();
  const { data: menu, isLoading } = useSelector(
    (state) => state[STORE_NAMES.menu]
  );
  const selectedMenuViewId = useSelector(
    (state) => state[STORE_NAMES.guest].selectedMenuView
  );
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const guests = basketOrders ? basketOrders.guests : [];
  const orderItems = guests.flatMap((guest) => guest.orderItems);
  const orderItemsCount = orderItems?.reduce(
    (sum, orderItem) => sum + orderItem.count,
    0
  );
  const { priceRange, selectedTags } = useSelector(
    (state) => state[STORE_NAMES.guest].menuFilters
  );

  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const filteredMenu = filterMenuByOptions({
    menu,
    priceRange,
    selectedTags,
  });

  const handleBasketClick = () =>
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.basket}`
    );
  const handleMenuViewSelection = (id) => {
    dispatch(selectMenuView(id));
  };

  useEffect(() => {
    const menuItems = menu.categories
      .map((category) => category.menuItems.flat())
      .flat();

    orderItems.map(({ item }) => {
      const findItem = menuItems.find(
        (menuItem) => menuItem.id === item.id && menuItem.isPublished
      );

      if (!findItem) {
        dispatch(
          updateBasket({
            menuItem: {
              ...item,
            },
            count: null,
            userId: guestId,
          })
        );
      }
    });
  }, [menu]);

  if (isLoading) {
    return <WelcomeClient />;
  }

  return (
    <div className="Menu">
      <MenuHeroSection
        onClick={handleMenuViewSelection}
        currentOptionId={
          selectedMenuViewId ||
          menu.defaultMenuView ||
          MENU_VIEW_ENUMS[Object.keys(MENU_VIEW_ENUMS)[0]].id
        }
      />

      <MenuDisplay
        menuViewType={
          selectedMenuViewId ||
          menu.defaultMenuView ||
          MENU_VIEW_ENUMS[Object.keys(MENU_VIEW_ENUMS)[0]].id
        }
        mostExpensivePrice={findMostExpensivePrice(menu)}
        menu={filteredMenu}
        distanceLeft={16}
        type={MENU_DISPLAY_ENUMS.types.GUEST}
        scrollElement={
          size.width >= 1000 ? document.querySelector(".screen") : window
        }
      />
      <BasketButton
        onClick={handleBasketClick}
        svgComponent={<IconCart />}
        count={orderItemsCount}
      />
      <Footer
        scrollElement={
          size.width >= 1000 ? document.querySelector(".screen") : window
        }
      />
    </div>
  );
};
export default Menu;
