import React from "react";
import PropTypes from "prop-types";

import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useTranslation } from "react-i18next";

import "./ResendPassword.scss";

const ResendPassword = ({ onClick, customSubTitle, customButtonText, isUserExist = false, isLoading, counter }) => {
  const { t } = useTranslation();

  const subTitle = isUserExist
    ? t("login.resendPassword.subTitleForUserExist")
    : t("login.resendPassword.subTitleForUserNotExist");

  const buttonText = isUserExist
    ? t("login.resendPassword.buttonTextForUserExist") + ` ${counter > 0 ? `(${counter})` : ""}`
    : t("login.resendPassword.buttonTextForUserNotExist") + ` ${counter > 0 ? `(${counter})` : ""}`;

  return (
    <div className="ResendPasswordContainer">
      <h6 className="Medium ResendPasswordDescription">{customSubTitle || subTitle}</h6>
      <PrimaryButton
        onClick={onClick}
        text={customButtonText || buttonText}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
        className="ResendPasswordButton"
        isLoading={isLoading}
        isDisabled={counter > 0}
      />
    </div>
  );
};

ResendPassword.propTypes = {
  /*
   ** Function to handle the resend password
   */
  onClick: PropTypes.func.isRequired,

  /*
    ** Custom subtitle
    */
  customSubTitle: PropTypes.string,

  /*
    ** Custom button text
    */
  customButtonText: PropTypes.string,

  /*
   ** State to check if the user exist
   */
  isUserExist: PropTypes.bool,

  /*
   ** Loading state for the button
   */
  isLoading: PropTypes.bool,

  /*
    ** Counter for the resend password
    */
  counter: PropTypes.number,
};

export default ResendPassword;
