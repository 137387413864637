import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {useTranslation} from "react-i18next";

import {ReactComponent as PlusIcon} from "assets/icons/math-operators/add/PlusAdmin.svg";

import "./AddNewButton.scss";

export const ENUMS = {
  name: "AddNewButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
  },
};

const AddNewButton = ({ type, label, onClick, hideIcon }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={cx("AddNewButton", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        typeC: type === ENUMS.types.TYPE_C,
        typeD: type === ENUMS.types.TYPE_D,
        typeE: type === ENUMS.types.TYPE_E,
      })}
      onClick={onClick}
    >
      {!hideIcon && <PlusIcon className="AddNewButtonIcon" />}
      {type === ENUMS.types.TYPE_A && <h5 className="SemiBold">{label}</h5>}
      {type === ENUMS.types.TYPE_B && (
        <>
          {label ? (
            <h5 className="Medium">{label}</h5>
          ) : (
            <h6 className="Medium">{t("buttons.add")}</h6>
          )}
        </>
      )}
      {(type === ENUMS.types.TYPE_E || type === ENUMS.types.TYPE_C) && (
        <h5 className="Medium">{label}</h5>
      )}
    </button>
  );
};

AddNewButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * The label text for the button
   */
  label: PropTypes.string,

  /**
   * A flag that controls the visibility of the plus icon in the button
   */
  hideIcon: PropTypes.bool,
};

AddNewButton.defaultProps = {
  type: ENUMS.types.TYPE_A,
  hideIcon: false,
};

export default AddNewButton;
