import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "i18next";

import Modal from "components/modal/Modal";
import RadioButton from "components/buttons/radio-button/RadioButton";
import { STORE_NAMES } from "utils/constants/redux";
import { setLanguage } from "redux/slices/userStore";

import "./LanguagesModal.scss";

const LanguagesModal = ({ openSlide, onClose, mainElementRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );
  const availableLanguages = useSelector((state) => state[STORE_NAMES.menu].data?.availableLanguages);

  const handleSelect = async (language) => {
    dispatch(setLanguage(language));
    onClose();
  };

  const LanguagesModalBody = (
    <ul className={"LanguagesModalBody"}>
      {availableLanguages?.map((language) => {
        return (
          <li
            className="LanguagesModalOption"
            onClick={() => handleSelect(language)}
            key={language.id}
          >
            <div className="LanguageDetails">
              <img alt="language" src={language.imgSrc} />
              <div className="LanguagesModalOptionNameContainer">
                <h5 className="SemiBold LanguagesModalOptionName">
                  {t(language.name)}
                </h5>
                <p className="h7">{language.description}</p>
              </div>
            </div>
            <RadioButton count={selectedLanguage?.id === language.id} />
          </li>
        );
      })}
    </ul>
  );
  return (
    <Modal
      body={LanguagesModalBody}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

LanguagesModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainElementRef: PropTypes.object,
};
export default LanguagesModal;
