const TRANSLATION = {
  welcome: "welcome (English)",
  common: {
    settings: "Settings",
    about: "About",
    home: "Home",
    logo: "Logo",
    shape: "Shape",
    selected: "Selected",
    create: "Create",
    notFound: "Not Found",
    applied: "Applied",
    yourLikes: "Your likes",
    details: "Details",
    published: "Published",
    unPublished: "Unpublished",
    general: "General",
    address: "Address",
    wifi: "Wi-Fi",
    copied: "Copied",
    contact: "Contact",
    pages: "Pages",
    kcal: "kcal",
    currency: "Currency",
    connect: "Connect",
    qr: "QR",
    zoneAndTable: "Zone & Table",
    connectToWifi: "Connected to Wi-Fi",
    disconnectFromWifi: "Disconnected from Wi-Fi",
    name: "Name",
    date: {
      date: "Date",
      time: "Time",
      createdAt: "Created at",
      dateCreated: "Date created",
      dateSelection: "Date Selection",
      schedule: "Operational during scheduled periods",
    },
    time: {
      hour: "hour",
      minute: "minute",
      second: "second",
      from: "From",
      to: "To",
      since: "Since start",
      min: "min",
      shortVersion: {
        hour: "h",
        minute: "min",
        second: "s",
      },
    },
    days: {
      days: "Days",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      everyday: "Everyday",
      everyWeekday: "Every Weekday",
      weekend: "Weekend",
    },
  },
  navbarRoutes: {
    statistics: "Statistics",
    salesStatistics: "Sale Statistics",
    orderStatistics: "Order Statistics",
    allOrders: "All Orders",
    menuStatistics: "Menu Statistics",
    allMenus: "All Menus",
    staff: "Staff",
    weeklyStatistics: "Weekly Statistics",
    tables: "Tables",
    menu: "Menu",
    business: "Business",
    infra: "Infra",
    users: "Users",
    qrAndTable: "QR & Table",
    table: "Table",
    qr: "QR",
    reservation: "Reservation",
    map: "Map",
    orders: "Orders",
    departments: "Departments",
    superAdmin: "Super Admin",
    inventory: "Inventory",
    funZone: "Fun Zone",
    home: "Home",
    guests: "Guests",
    pageTitles: {
      superAdmin: "SUPER ADMIN",
      infra: "INFRA",
      guests: "GUESTS",
      weeklyStatistics: "WEEKLY STATISTICS",
      salesStatistics: "SALE STATISTICS",
      orderStatistics: "ORDER STATISTICS",
      menuStatistics: "MENU STATISTICS",
      allOrders: "ALL ORDERS",
      allMenus: "ALL MENUS",
      category: "CATEGORY",
      settings: "SETTINGS",
      business: "BUSINESS",
      users: "USERS",
      zones: "ZONES",
      tables: "TABLES",
      qrList: "QR LIST",
    },
  },
  emptyTable: {
    emptyTableDescription: "You don’t have any zones yet.",
    emptyZoneDescription: "There are no tables in this zone.",
    emptyQR: "You don’t have any QR codes yet.",
  },
  language: {
    language: "Language",
    english: "English",
    russian: "Russian",
    azerbaijani: "Azerbaijani",
    german: "German",
  },

  buttons: {
    addItem: "Add item",
    addQR: "Add QR",
    back: "Back",
    createQR: "Create QR",
    createZone: "Create Zone",
    createOrder: "Create Order",
    edit: "Edit",
    delete: "Delete",
    confirm: "Confirm",
    finish: "Finish",
    discard: "Discard",
    change: "Change",
    deny: "Deny",
    save: "Save",
    close: "Close",
    saveChanges: "Save changes",
    all: "All",
    seeAll: "See All",
    seeLess: "See Less",
    add: "Add",
    addNew: "Add New",
    reset: "Clear",
    resend: "Resend",
    clearFilters: "Clear Filters",
    pay: "Pay",
    clear: "Clear All",
    apply: "Apply",
    defaultLanguage: "Default Language",
    defaultCurrency: "Default Currency",
    availableLanguages: "Available Languages",
    currency: "Default Currency",
    link: "Link",
    links: "Links",
    unlink: "Unlink",
    addBasket: "Add to Basket",
    addToOrder: "Add to Order",
    clickToAdd: "Click to add",
    clickToAddImage: "Click to add image",
    clickToUpload: "Click to upload",
    logOut: "Log out",
    login: "Login",
    lockScreen: "Lock",
    goToDashboard: "Go to Dashboard",
    rememberMe: "Remember Me",
    forgotPassword: "Forgot Password?",
    payWith: "Pay with",
    seeMenu: "See the menu",
    addOrder: "New Order",
    readMore: "Read More",
    serve: "Serve",
    confirmOrder: "Confirm Order",
    backToMenu: "Back to Menu",
    send: "Send",
    tryAgain: "Try again",
    update: "Update",
    continue: "Continue",
    verify: "Verify",
    applyDiscount: "Apply Discount",
    print: "Print",
    export: "Export",
    closeOrder: "Finish order",
    printBill: "Print the bill",
    fullTables: "Full Tables",
    editForModal: "Edit {{title}}",
    addForModal: "Add {{title}}",
    successfullySent: "Successfully Sent",
    sent: "Sent",
    skip: "Skip",
  },
  inputs: {
    name: "Name",
    username: "Username",
    description: "Description",
    price: "Price",
    discount: "Discount",
    role: "Role",
    phoneNumber: "Phone Number",
    pinCode: "PIN Code",
    pinCodeConfirm: "Confirm Pin Code",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    newPassword: "New Password",
    businessName: "Business Name",
    city: "City",
    zipCode: "Zip Code",
    street: "Street",
    map: "Google maps link",
    serviceFee: "Service Fee",
    search: "Search",
    enterPromoCode: "Enter promo code",
    link: "Add link",
    instagram: "Instagram Link",
    phone: "Add phone",
    contactPhone: "Phone Number",
    whatsapp: "Whatsapp Number",
    mail: "Add email address",
    contactMail: "Email Address",
    tableName: "Table name",
    usernameExample: "@example",
    chooseCurrent: "Choose Current",
    seats: "Seats",
    minDeposit: "Min. Deposit",
    qrName: "QR Name",
    wifiName: "Wi-Fi Name",
    sell: "Sell Price",
    cost: "Cost",
    calories: "Calories",
    time: "Time",
    amount: "Amount",
    unit: "Unit",
    addComment: "Add Comment",
    warningAmount: "Warning Amount",
    number: "Number",
    notFixed: "Not Fixed",
    fixed: "Fixed",
    maxNumber: "Max Number",
    deposit: "Deposit",
    yourName: "Your Name",
    contact: "Contact",
  },
  image: {
    images: "Images",
    selectCover: "click on image to set as cover-image",
    background: "Background Image",
    other: "Other Photos",
    addPhoto: "Add Photo",
    addImage: "Add Image",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Modification Name",
    modification: "Modification",
    modifications: "Modifications",
    noModifications: "No modifications have been added yet.",
    noModificationOption: "No options have been added yet.",
    addModification: "Add modification",
    editModification: "Edit modification",
    selection: {
      selection: "Selection",
      types: "Selection Type",
      singleSelection: "Single",
      singleSelectionDescription: "only one option can be selected",
      multiSelection: "Multiple",
      multiSelectionDescription: "multiple options can be selected",
      option: {
        options: "Options",
        onlyOnce: "Only once",
        manyTimes: "Many times",
        default: "Default selection",
        addLanguage: "Add language",
      },
    },
  },

  user: {
    staff: "Staff",
    // listOfEmployees: "{{count}} employees",
    employee: "Employee",
    addEmployee: "Add Employee",
    editEmployee: "Edit Employee",
    sendAllDetails: "Generate a new password and send to the user",
    userDeleteError: "You can’t delete the account you are signed in with",
  },
  guests: {
    guest: "Guest",
    guests: "Guests",
    allBusinesses: "All Businesses",
  },
  table: {
    connected: "Connected with QR",
    tablesLowercase: "tables",
    tables: "Tables",
    table: "Table",
    addTable: "Add Table",
    editTable: "Edit Table",
    selectTable: "Select Table",
    busy: "tables busy",
    available: "tables available",
    full: "full",
    noTablesVisible: "No tables are currently visible on the map.",
    map: "Map",
    current: "current",
  },
  zone: {
    zone: "Zone",
    tables: "Tables",

    zones: "Zones",
    name: "Zone Name",
    allZones: "All zones",
  },
  map: {
    removeFromMap: "Remove from map",
    shapes: {
      rectangle: "Rectangle",
      circle: "Circle",
    },
  },
  qr: {
    qr: "QR",
    qrCode: "qr code",
    name: "QR Name",
    list: "QR List",
    id: "Qr ID",
    type: "QR Code Type",
    status: "Status",
    unlinked: "Unlinked",
    linked: "Linked",
    qrIsNotAdded: "QR is not added",
    connected: "Connected to",
    notConnected: "Not connected",
    scanToConnect: "or scan to connect",
    types: {
      qrType: "QR Type",
      wifi: "Wi-Fi",
      menu: "Menu",
    },
  },
  modal: {
    deleteModalTitle: "Are you sure to delete?",
    deleteModalDescription: "This action can’t be undone",
    warningModalTitleFinishOrder: "Are you sure you want to finish this order?",
    warningModalTitleChangeZone: "Are you sure you want to change this zone?",
    warningModalTitleLogout: "Are you sure you want to log out?",
    warningModalTitleNewOrder:
      "You will create a new order on {{zone}}/{{table}}. Are you sure?",
    warningModalDescriptionNewOrder:
      "Please click the yes button to create a new order",
    warningModalTitleUnsavedChanges:
      "Are you sure you want to leave without saving?",
    warningModalDescription: "Your edits will be unsaved",
    yes: "Yes",
    no: "No",
    close: "Close",
    apply: "Apply",
    id: "id",
  },
  menu: {
    preview: "Preview Menu",
    tag: {
      tag: "Tag",
      tags: "Tags",
      name: "Tag Name",
    },
    category: {
      category: "Category",
      categories: "Categories",
      add: "Add Category",
      all: "All Categories",
      name: "Category Name",
      mealCategories: "Meal Categories",
      emptyGuestMenuTitle: "Menu is Empty",
      emptyGuestMenuDescription:
        "It appears that the business hasn't added anything to the menu yet",
      emptyAdminCategoryDescription: "You don’t have any categories yet.",
    },
    item: {
      items: "Items",
      item: "Item",
      itemLowerCase: "item",
      itemsLowerCase: "items",
      selected: "Selected items",
      deleteItem: "Delete Item",
      duplicateItem: "Duplicate Item",
      duplicate: "Duplicate",
      scheduleDiscount: "Schedule Discount",
      emptyAdminMenuItemDescription: "You don’t have any items yet.",
    },
    view: {
      selection: "Default Menu View ",
      scroll: "Scroll",
      list: "List",
      threeD: "3D",
      poker: "Poker",
      grid: "Grid",
    },
    filter: {
      filters: "Filters",
      priceRange: "Price Range",
      noResult: "No Results Match",
      noResultDesc: "The active filters are hiding all menu items.",
    },
    settings: {
      color: "Color",
      radius: "Border Radius",
      roundness: "Roundness",
      currency: "Currency",
      colors: {
        primary: "PrimaryColors",
        red: "Red",
        gold: "Gold",
        purple: "Purple",
        blue: "Blue",
      },
      curves: {
        elementCurves: "Element Curves",
        none: "None",
        small: "Small",
        medium: "Medium",
        large: "Large",
      },
      changeProperties: "Change Properties",
      details: "Details of Menu",
    },
  },
  business: {
    businesses: "Businesses",
    list: " List of businesses",
    staffList: "{{count}} employee",
    selectTitle: "Select the business",
    selectSubTitle: "Please select the business you want to enter and continue",
    contact: "Contact",
    contactWith: "Contact with",
    workingHours: "Working Hours",
    needToKnow: "Need to know",
    addNew: "Add Business",
    edit: "Edit Business",
    plan: "Plan",
    selectPlan: "Select plan",
    creationDate: "Creation Date",
    status: "Status",
    verified: "Verified",
    unverified: "Unverified",
    businessStatus: "Business status",
    expirationDate: "Expiration Date",
    country: "Country",
    printers: "Printers",
    wiFi: "Wi-Fi",
    printer: "Printer",
    active: "Active",
    inActive: "Inactive",
    connectedTo: "Connected To",
    settings: {
      goLive: "Go Live",
      chat: "Chat",
      realTimeOrder: "Real-Time",
      menuItemCalorie: "Calorie",
      menuItemTime: "Time",
      menuItemAmount: "Amount",
      reservation: "Reservation",
      reservationMap: "Show map in reservation",
      chatSound: "Chat",
      orderSound: "Order",
      askForBillSound: "Ask For Bill",
      callWaiterSound: "Call Waiter",
      menu: "Menu",
      sound: "Sound",
      general: "General",
      order: "Order",
    },
  },
  orderItem: {
    denied: "Denied",
    deleted: "Deleted",
    empty: "Empty",
  },
  basket: {
    basket: "Basket",
    favorites: "Favorites",
    basketAndFavorites: "Basket & Favorites",
    addMessage: "Add message for the restaurant",
    messagePlaceholder: "Special request, allergies, dietary restrictions?",
    itemAdded: "{{count}} item added to cart",
    order: {
      info: "Order Info",
      subtotal: "Subtotal",
      total: "Total",
      tax: "Tax",
      promoCode: "Promo code",
      totalCost: "Total cost",
      orders: "Orders",
      myOrders: "My Order",
      ordersLowerCase: "orders",
      orderLowerCase: "order",
      order: "Order",
      bill: "Bill",
      noOrder: "There is no available order",
      noOrders: "No orders.",
      deletedOrderTitle: "Your order is deleted",
      deletedOrderDescription:
        "We're sorry, your order has been deleted by our admin/waiter",
      notifications: "Notifications",
      updatesToSave: "Changes",
      newOrder: "New Order",
      deleteOrder: "Delete Order",
      finishOrder: "Finish Order",
      done: "Done",
      preparing: "Preparing",
      numberOfGuests: "Number of guests",
      fullNessOfTable: "Fullness of table",
      acceptAll: "Accept All",
    },
    emptyBasketTitle: "Your Basket is Empty",
    emptyBasketDescription:
      "Looks like you haven’t added anything to your basket yet",
  },
  orders: {
    orders: "Orders",
    order: "Order",
    guests: "Guests",
    guest: "Guest",
    fullTables: "Full Tables",
  },

  dashboard: {
    dashboard: "Dashboard",
    sales: "Sales",
    salesAnalytics: "Total Sales / Hour",
    weekStatistics: "Week Statistics",
    statistics: "Statistics",
    totalSales: "Total Sales",
    paymentMethodAnalysis: "Payment Method Analysis",
    byNumberOfOrders: "By number of orders",
    byTheAmountOfSales: "By the amount of sales",
    byTheNumberOfRatings: "By the number of ratings",
    bestselling: "Bestselling",
    worstSelling: "Worst-selling",
    processing: "Processing",
    billReady: "Bill will be ready",
    unSelect: "Unselect",
    denied: "Denied",
    showMore: "Show More",
    showLess: "...Show Less",
    bestSellingCategory: "Best selling category",
    topPerformer: "Top Performer",
    lowPerformer: "Low Performer",
    noResultForThisPeriodMsg: "There are no results for this period",
    all: "All",
    yourTable: "Your Table",
    totalGuests: "Total Guests",
    totalItems: "Total Items",
    deleted: "Deleted",
    weeklySaleStatistics: "Weekly Sale Statistics",
    weeklyOrderStatistics: "Weekly Order Statistics",
    legends: {
      sale: "Sale",
      revenue: "Revenue",
      orders: "Orders",
      orderItems: "Order Items",
      totalSale: "Total Sale",
    },
    guest: {
      guests: "Guests",
      guest: "Guest",
      me: "Me",
      review: {
        meal: "Meal",
        service: "Service",
        review: "Review",
        sent: "Sent",
        feedBack: "Feedback",
      },
    },
    staffStatistics: {
      sales: "Sales",
      orders: "Orders",
      rating: "Rating",
    },
    orderStatistics: {
      totalSale: "Total Sale",
      revenue: "Revenue",
      averageBill: "Average Bill",
      orderCount: "Order Count",
      guestsServed: "Guests Served",
      averageTime: "Average Time",
      from: "from yesterday",
      orders: "Orders",
      sale: "Sale",
      in: "In",
      out: "Out",
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Staff",
        dates: "Dates",
        item: "Item",
        subTotal: "Subtotal",
        discount: "Discount",
        total: "Total",
        zone: "Zone",
        table: "Table",
        service: "Service",
        fee: "Fee",
        orderDetails: "Order Details",
        receipt: "Receipt",
      },
      menuStatisticsTable: {
        menuItem: "Menu Item",
        category: "Category",
        cost: "Cost",
        revenue: "Revenue",
        total: "Total",
        order: "Order",
        count: "Count",
        price: "Price",
        item: "Item",
      },
    },
    pagination: {
      prev: "Prev",
      next: "Next",
    },
    prevNext: {
      previous: "Previous",
      next: "Next",
    },
    placeholder: {
      zone: "Zone",
      orderId: "Order ID",
      staff: "Staff",
      itemName: "Item name",
      categories: "Categories",
    },
  },
  login: {
    or: "or",
    facebook: "With Facebook",
    google: "With Google",
    pin: "pin",
    signIn: {
      title: "Sign In",
      subTitle: "Don’t have an account?",
      method: "Sign-in method",
    },
    signUp: {
      title: "Sign Up",
      subTitleForUserExist:
        "You already have an account. Please enter your existing password.",
      subTitleForUserNotExist:
        "Please enter the password sent to: {{emailOrPhone}}",
      alreadyHaveAccount: "Have an account already?",
      passwordSentTo: "Password has sent to",
      setPinSubTitle: "Set a PIN Code for the easiest sign-in",
      agree: "By continuing, you agree to the",
      termsAndPrivacy: "Terms & Privacy Policy",
      enterPassword: "Enter your password",
      setPinSubtitle: "Set a PIN Code for the easiest sign-in",
    },
    forgotPassword: {
      title: "Forgot Password?",
      subTitle: "Enter your phone number and we’ll send your password again.",
      sendPassword: "Send Password",
    },
    resetPassword: {
      title: "Change Password",
      subTitle: "Welcome back, you’ve been missed!",
    },
    resendPassword: {
      title: "Resend Password",
      subTitleForUserExist: "Forgot your existing password?",
      subTitleForUserNotExist: "Didn't receive the password?",
      buttonTextForUserExist: "Send new password",
      buttonTextForUserNotExist: "Resend",
    },
  },
  payment: {
    payment: "Payment",
    method: "Payment Method",
    pageTitle: "How do you want to pay?",
    pageSubtitle: "Select a method that you want to make your payment",
    cash: "Cash",
    POSTerminal: "POS-Terminal",
    ready: "Ready in a minute !",
    confirmPOSTerminal:
      "Confirmed request. POS-Terminal will be brought by the waiter soon",
    confirmCash:
      "Confirmed request. Your bill will be brought by the waiter soon",
    totalCostFor: "Total cost for",
    printsAndBill: "Prints and bill",
    paymentConfirmedTitle: "Your order is finished. Thanks for choosing us.",
    paymentConfirmedDescription:
      "It was our pleasure to host you! See you next time 👋 Have a nice day 😍",
  },
  waiter: {
    waiter: "Waiter",
    waiterLowercase: "waiter",
    call: "Call Waiter",
    bill: "Ask for Bill",
    quickActions: "Quick Actions",
    actions: "Actions",
  },
  mascot: {
    dashboard: {
      support: "Support and Shortcuts",
      eatIn: "Eat In",
      eatInHall: "Eat In hall",
      reserveTable: "Reserve a table",
      letUs: "Let us .. for you",
      learnMore: "Learn more ",
      fixProblemText: "Do you face any problems with the app?",
      fixProblemButton: "Click here to fix",
    },
  },
  errorMessages: {
    selectTableToCreateOrder: "Choose a table to create an order",
    selectWaiterToCreateOrder: "Choose a table to create an order",
    lastLanguage: "You can not remove last language",
    orderAssignee: "You have to select a assignee",
    required: {
      password: "Password is required",
      confirmPassword: "Confirm Password is required",
      customMessage: "{{name}} is required",
    },
    image: "Image upload failed",
    addUnit: "Add unit",
    confirmOrders:
      "You have incomplete work. Please save it before continuing.",
    input: {
      maxCharacter: "*Please enter no more than {{max}} characters",
      minCharacter: "*Please enter at least {{min}} characters",
      maxNumber: "*Please enter a number less than {{max}}",
      minNumber: "*Please enter a number greater than 0",
      invalid: "*Invalid {{name}}",
      required: "*{{name}} is required",
      generalRequired: "*This field is required",
      menu: {
        fixedDiscount: "*Discount can not be greater than sell price",
        unFixedDiscount: "*Discount can not be greater than 100%",
        requiredUnit: "*Please select a unit for the given amount",
      },
      menuSettings: {
        requiredDefaultLanguage: "*Please select a default language",
        requiredAvailableLanguages: "*Please select at least one language",
      },
      pinCode: {
        maxDigits: "*Pin code should be less than 6 digits",
        alreadyExists: "*This pin code already exists",
        notNumeric: "*Pin code should only contain digits",
        skipOrSetNow: "*Set a pin code now, or skip this step",
        notMatch: "*Pin codes do not match",
        incorrect: "*The PIN code is incorrect",
      },
      email: {
        alreadyExists: "*This email already exists",
        incorrect: "*Please enter a valid email address",
        notExists: "*This email does not exist",
      },
      password: {
        incorrect: "*Incorrect password",
        notNumeric: "*Password should only contain digits",
        incorrectLength: "*Password should be 6 digits",
      },
      phoneNumber: {
        incorrectLength: "*Phone number should be 12 digits",
        notNumeric: "*Phone number should only contain digits",
        alreadyExists: "*This phone number already exists",
        notExists: "*This phone number does not exist",
        incorrect: "*Please enter a valid phone number",
      },
      business: {
        serviceFeeMax: "*Service fee should be less than 100%",
        serviceFeeMin: "*Service fee should be more than 0%",
        url: "*Please enter a valid URL",
      },
      qrAndTable: {
        maxSeat: "The number of seats must be an integer",
      },
      contact: {
        instagram: "*Please enter a valid Instagram address",
        // phone: "*Please enter a valid phone number",
        // whatsapp: "*Please enter a valid whatsapp number",
        // gmail: "*Please enter a valid gmail address",
      },
    },
  },

  toastMessages: {
    success: {
      login: "Login successful!",
      updateBusinessProfile: "Business profile updated successfully",
      updateBusinessSettings: "Business settings updated successfully",
      updateTag: "Tag updated successfully",
      createTag: "Tag created successfully",
      deleteTag: "Tag deleted successfully",
      createReservation: "Reservation created successfully",
      deleteReservation: "Reservation deleted successfully",
      updateReservation: "Reservation updated successfully",
      updateCategory: "Category updated successfully",
      createCategory: "Category created successfully",
      deleteCategory: "Category deleted successfully",
      updateMenuItem: "Menu item updated successfully",
      updateMenu: "Menu updated successfully",
      createMenuItem: "Menu item created successfully",
      deleteMenuItem: "Menu item deleted successfully",
      duplicateMenuItem: "Menu item duplicated successfully",
      updateZone: "Zone updated successfully",
      createZone: "Zone created successfully",
      deleteZone: "Zone deleted successfully",
      updateTable: "Table updated successfully",
      updateMenuSettings: "Menu settings updated successfully",
      createTable: "Table created successfully",
      deleteTable: "Table deleted successfully",
      updateUser: "User updated successfully",
      createUser: "User created successfully",
      deleteUser: "User deleted successfully",
      updateQR: "QR updated successfully",
      createQR: "QR created successfully",
      deleteQR: "QR deleted successfully",
      updateOrder: "Order updated successfully",
      createOrder: "Order created successfully",
      deleteOrder: "Order deleted successfully",
      updateInventoryCategory: "Inventory category updated successfully",
      createInventoryCategory: "Inventory category created successfully",
      deleteInventoryCategory: "Inventory category deleted successfully",
      updateInventoryItem: "Inventory item updated successfully",
      createInventoryItem: "Inventory item created successfully",
      deleteInventoryItem: "Inventory item deleted successfully",
      updateBusiness: "Business updated successfully",
      createBusiness: "Business created successfully",
      deleteBusiness: "Business deleted successfully",
      updateLanguage: "Language changed successfully",
    },
    error: {
      common: "Something went wrong! Please try again",
      zones: "You can’t delete this zone because it has a table",
      unselectedTable: "You have to select a table",
      unselectedZone: "You have to select a zone",
    },
  },
  reservation: {
    reserve: "Reserve",
    reservationTime: "Reservation Time",
    reservationInfo: "Reservation Info",
    contactInfo: "Contact Info",
    enterName: "Name",
    enterPhoneNumber: "Phone Number",
    bookingList: "Booking List",
    booked: "booked",
    pendingBooks: "Pending Books",
    createReservation: "Create reservation",
    editReservation: "Edit reservation",
    waiting: "Waiting",
  },
  socials: {
    instagram: "Instagram",
    email: "Email",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  weekdays: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  periods: {
    hourly: "Hourly",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
  },

  chat: {
    typeHere: "Type here...",
    chat: "Chat",
    allMessages: "All Messages",
    unreadMessages: "Unread Messages",
    selectTopic: "Please select topic.",
    noMessages: "No messages.",
  },
  kitchen: {
    stageNew: "New",
    stageProgress: "Preparing",
    stageDone: "Done",
  },
  inventory: {
    productList: "Product List",
    product: "product",
    stock: "Stock",
    warningAmountInfo:
      "Select minimum amount to notify when it is nearly to finish",
  },
  emptyStates: {
    noPrinters: "No printers have been added yet",
    noWiFi: "No wifi have been added yet.",
    noOption: "There is no option",
    noOrder: "There is no order yet",
    noItem: "There is no item yet",
    noLanguage: "There is no languages to add",
    noTableData: "There is no data on this page",
    noOrderedCategory: "There are no ordered item results in this category",
    noChat: "You don't have any chats due to the absence of orders",
    noUnread: "No unread messages.",
    noSearchResults: "No Search Results",
    noOrders: "No Orders",
    noGuests: "No Guests",
  },
  info: {
    itemAmount:
      "The number refers to the quantity of the item, specified in units such as kilograms (kg), grams (g), liters (l), or milliliters (ml). ",
    itemModificationOptionMaxNumberInfo:
      "The modification option max limit refers to the maximum number of these options that can be applied.",
    general: "General info",
    detail: "Detail info",
  },
  stepper: {
    userDetail: "User Details",
    passwordVerification: "Password Verification",
    setPinCode: "PIN Code",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    allRightReserved: "All rights reserved.",
    copyRight: "© 2024 {{value}}.",
    joinUs: "Join us {{emoji}}",
    madeWith: "Made with {{emoji}} by",
    madeBy: "Made by <strong>iLoyal</strong>",
    scanQR: "Scan QR for new order",
    name: "Let your friends know it’s you! ",
    profilePicture: "Your profile picture",
    comingSoon: "COMING SOON",
    underConstruction: "UNDER CONSTRUCTION",
  },
  optionTypes: {
    None: "None",
    S: "S",
    M: "M",
    L: "L",
  },
  businesses: {
    allBusinesses: "All businesses",
    specialOffers: "Special Offers",
    mostPopular: "MostPopular",
    coffeeShop: "Coffee Shop",
    nationalCuisine: "National Cuisine",
    seaside: "Seaside",
  },
  policy: {
    termsDataInfo:
      "By continuing, you agree to the <strong> Terms of Service & Privacy Policy</strong>",
    joinUsTermsData: {
      introduction: {
        title: "1. Introduction",
        content: {
          point1:
            "By using our services, you agree to these Terms of Services and our Privacy Policy. Please read them carefully.",
        },
      },
      userConsent: {
        title: "2. User Consent",
        content: {
          point1:
            "By accessing or using our services, you consent to the collection, use, and sharing of your information as described in this Terms of Services & Privacy Policy. If you do not agree with these terms, please do not use our services.",
        },
      },
      dataCollection: {
        title: "3. Data Collection",
        content: {
          point1:
            "We collect information you provide directly to us, such as when you create an account, make a purchase, or contact us for support. We may also collect information automatically, such as your IP address, browser type, and usage data.",
        },
      },
      dataUse: {
        title: "4. Data Use",
        content: {
          point1:
            "Your data is used to provide, maintain, and improve our services. This includes processing transactions, sending communications, and customizing your experience.",
        },
      },
      dataProtection: {
        title: "5. Data Protection",
        content: {
          point1:
            "We are committed to protecting your data. We implement various security measures to safeguard your information from unauthorized access, alteration, disclosure, or destruction.",
        },
      },
      dataSharing: {
        title: "6. Data Sharing",
        content: {
          point1:
            "We do not sell your personal data. We may share information with trusted third parties who assist us in operating our website, conducting our business, or providing services to you, so long as those parties agree to keep this information confidential.",
        },
      },
      userRights: {
        title: "7. User Rights",
        content: {
          point1:
            "You have the right to access, correct, or delete your personal information. You can also opt-out of certain uses and disclosures of your data.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "By using our services, you acknowledge that you have read and understood these terms and agree to be bound by them.",
        },
      },
    },

    signUpTermsData: {
      agreement: {
        title: "1. Terms of the Agreement",
        content: {
          point1:
            "1.1 The licensor must provide the software license under the terms of this agreement, and the customer must pay for this license.",
          point2:
            "1.2 The monthly license (subscription) fee is determined based on the variety of features provided by iLoyal according to the customer's needs. Changes to the license (subscription) fee can only be made based on a mutual agreement between the Customer and iLoyal. The monthly license (subscription) period is considered to begin from the date the agreement is signed.",
        },
      },
      license: {
        title: "2. Terms of the License",
        content: {
          point1:
            "2.1 The licensor grants the following rights under the terms of a simple license:",
          point2:
            "2.1.1 To ensure the use of the software and determine its operation for the intended purpose.",
          point3: "2.2 The following are PROHIBITED for the license:",
          point4: "2.2.1 Extraction of the software code by the user.",
          point5:
            "2.2.2 Reproduction by the user, i.e., making one or more copies of the software.",
          point6: "2.2.3 Resale of the software and its copies.",
          point7: "2.2.4 Making the software publicly available.",
        },
      },
      payment: {
        title: "3. License Payment",
        content: {
          point1:
            "3.1 The customer agrees to pay the amount specified in the invoice for the license(s) outlined in this agreement.",
          point2:
            "3.2 The prices of the licenses are listed on the licensor’s official website.",
          point3:
            "3.3 In the event of termination of the agreement between the parties, the license fee is non-negotiable and non-refundable.",
          point4:
            "3.4 After payment of the license fee according to the invoice issued by the licensor, you are considered to have agreed to the terms of the current License Agreement, and this agreement is deemed to be concluded between you and the licensor.",
          point5:
            "3.5 The licensor reserves the right to unilaterally modify this agreement.",
          point6:
            "3.6 Once the relevant amounts are deposited into the licensor's bank account, all payment obligations of the customer are considered fulfilled.",
        },
      },
      dataProtection: {
        title: "4. Data Protection",
        content: {
          point1: "4.1 All customer information is protected by the licensor.",
          point2:
            "4.2 Customer data stored in our cloud accounts is not shared with third parties.",
        },
      },
    },
  },
};

export default TRANSLATION;
