import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "assets/icons/edit/EditPrimary.svg";

import "./EditButtonPrimary.scss";

export const ENUMS = {
  name: "EditButtonPrimary",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
  },
};

const EditButtonPrimary = ({ type, onClick }) => {
  const { t } = useTranslation();

  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      onClick={(e) => handleOnClick(e)}
      className={cx(
        "EditButtonPrimary",
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        },
        {
          typeC: type === ENUMS.types.TYPE_C,
        }
      )}
      type="button"
    >
      <EditIcon className="EditButtonPrimaryIcon" />
      {type === ENUMS.types.TYPE_D && (
        <h6 className="Medium">{t("buttons.edit")}</h6>
      )}
    </button>
  );
};

EditButtonPrimary.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
  ]),

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,
};

EditButtonPrimary.defaultProps = {
  type: ENUMS.types.TYPE_A, // Default type is TYPE_A
};

export default EditButtonPrimary;
