import React from "react";
import PropTypes from "prop-types";
import MenuViewScroll from "components/homepage-views/menu-view-scroll/MenuViewScroll";
import useMouseDragHorizontal from "utils/hooks/useMouseDragHorizontal";

import "./MenuViewScrollWrapper.scss";

export const ENUMS = {
  name: "MenuViewScrollWrapper",
};

const MenuViewScrollWrapper = ({
  menuItems,
  onAdd,
  onFavorite,
  onClick,
  favoriteItems = [],
  orderItems = [],
}) => {
  const { sliderRef, handleMouseDownHandler } = useMouseDragHorizontal();
  return (
    <div
      className="MenuViewScrollWrapper"
      ref={sliderRef}
      onMouseDown={handleMouseDownHandler}
    >
      {menuItems.map((item, index) => (
        <MenuViewScroll
          key={index}
          onAdd={onAdd}
          onFavorite={onFavorite}
          onClick={onClick}
          menuItem={item}
          favoriteItems={favoriteItems}
          orderItems={orderItems}
        />
      ))}
    </div>
  );
};

MenuViewScrollWrapper.propTypes = {
  /**
   * Menu items array
   */
  menuItems: PropTypes.array.isRequired,

  /**
   * Add action
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Favorite action
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * Component click action
   */
  onClick: PropTypes.func.isRequired,
  /**
   * An array of favorite items.
   */
  favoriteItems: PropTypes.array,
  /**
   * An array of order items.
   */
  orderItems: PropTypes.array,
};

export default MenuViewScrollWrapper;
