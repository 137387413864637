import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PrimaryButton, {
  ENUMS as ENUMS_PRIMARY_BUTTON,
} from "components/admin/buttons/primary-button/PrimaryButton";

import "./FooterButtons.scss";

export const ENUMS = {
  name: "ModalFooter",
};

const FooterButtons = ({
  onCancel,
  onConfirm,
  cancelButtonIcon,
  confirmButtonIcon,
  cancelButtonText,
  confirmButtonText,
  cancelButtonType,
  confirmButtonType,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ModalFooterContainer">
      <PrimaryButton
        icon={cancelButtonIcon}
        type={cancelButtonType}
        onClick={onCancel}
        text={cancelButtonText || t("buttons.discard")}
      />
      <PrimaryButton
        type={confirmButtonType}
        icon={confirmButtonIcon}
        onClick={onConfirm}
        text={confirmButtonText || t("buttons.save")}
      />
    </div>
  );
};

FooterButtons.propTypes = {
  /**
   * The function called when the Cancel button is clicked
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * The function called when the Confirm button is clicked
   */
  onConfirm: PropTypes.func.isRequired,

  /**
   * The icon for the Cancel button
   */
  cancelButtonIcon: PropTypes.node,

  /**
   * The icon for the Confirm button
   */
  confirmButtonIcon: PropTypes.node,

  /**
   * The text to display on the Cancel button
   */
  cancelButtonText: PropTypes.string,

  /**
   * The text to display on the Confirm button
   */
  confirmButtonText: PropTypes.string,

  /**
   * The type of the Cancel button
   */
  cancelButtonType: PropTypes.string,

  /**
   * The type of the Confirm button
   */
  confirmButtonType: PropTypes.string,
};

FooterButtons.defaultProps = {
  cancelButtonType: ENUMS_PRIMARY_BUTTON.types.TYPE_B,
  confirmButtonType: ENUMS_PRIMARY_BUTTON.types.TYPE_A,
};

export default FooterButtons;
