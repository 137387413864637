import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useCountdown from "utils/hooks/useCountdown";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import IconButton from "components/buttons/icon-button/IconButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import ResendPassword from "components/admin/elements/resend-password/ResendPassword";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import { STORE_NAMES } from "utils/constants/redux";
import {
  convertSnakeCaseToTitleCase,
  convertTitleCaseToSnakeCase,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { getUsers, resendCode } from "redux/actions/userAction";
import { createUser, updateUser } from "utils/api/services/user";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import useAsync from "utils/hooks/useAsync";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { updateUserInformation } from "redux/slices/userStore";
import isEqual from "lodash/isEqual";
import { ROLES } from "utils/constants/global";

import "./AdminUserModal.scss";

export const ENUMS = {
  name: "AddItem",
};

const AdminUserModal = ({
  mainElementRef,
  openSlide,
  setOpenSlide,
  selectedUser,
  onDelete,
  setOutsideClickActionUser,
}) => {
  const isEditable = !!selectedUser;

  const [counter, setCounter] = useCountdown(0);
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const [signInMethod, setSignInMethod] = useState(SIGN_IN_METHOD.phoneNumber);

  const initialUserSignInMethod = selectedUser?.email
    ? SIGN_IN_METHOD.email
    : SIGN_IN_METHOD.phoneNumber;

  const { t } = useTranslation();
  const schemas = useValidationSchema(t);

  const dispatch = useDispatch();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const userRole = useSelector(
    (state) => state[STORE_NAMES.user].user?.roles[0]?.name
  );

  const isLoadingResend = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.resendCode;

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setOpenSlide(false);
    setOpenSlideConfirmCloseModal(false);
  };

  const methods = useForm({
    resolver: zodResolver(schemas.userModalSchema(signInMethod)),
    criteriaMode: "all",
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    setError,
    watch,
    formState: { errors },
  } = methods;

  const initialData = {
    firstName: selectedUser?.firstName || "",
    lastName: selectedUser?.lastName || "",
    email: selectedUser?.email || "",
    phoneNumber: selectedUser?.phoneNumber
      ? `+${selectedUser?.phoneNumber}`
      : "",
    pinCode: selectedUser?.pinCode?.userPinCode || "",
    role:
      selectedUser?.roles?.[0]?.name &&
      convertSnakeCaseToTitleCase(selectedUser?.roles?.[0].name.toLowerCase()),
  };

  watch();

  const { email, firstName, lastName, phoneNumber, pinCode, role } =
    getValues();

  const updatedFormData = {
    email: email || "",
    firstName,
    lastName,
    phoneNumber,
    pinCode,
    role: role?.name && convertSnakeCaseToTitleCase(role?.name?.toLowerCase()),
  };

  useFormOutsideClickHandler({
    formData: updatedFormData,
    formDataInitial: initialData,
    setOpenSlide: setOpenSlide,
    setOpenSlideConfirmCloseModal: setOpenSlideConfirmCloseModal,
    setOutsideClickAction: setOutsideClickActionUser,
  });

  const roleEnums = Object.values(
    useSelector((state) => state[STORE_NAMES.app].enums)?.roleName
  );

  const roles = roleEnums
    .map((role, index) => {
      if (
        (userRole === ROLES.admin.name &&
          (role === ROLES.admin.name || role === ROLES.guest.name)) ||
        (userRole === ROLES.owner.name &&
          (role === ROLES.admin.name || role === ROLES.guest.name)) ||
        (userRole !== ROLES.admin.name && userRole !== ROLES.owner.name)
      ) {
        return null;
      }

      return {
        id: index,
        name: convertSnakeCaseToTitleCase(role),
      };
    })
    .filter((role) => role !== null);

  useEffect(() => {
    if (selectedUser && openSlide) {
      reset({
        ...selectedUser,
        pinCode: selectedUser.pinCode.userPinCode || "",
        phoneNumber: selectedUser.phoneNumber
          ? `+${selectedUser.phoneNumber}`
          : "",
      });

      setSignInMethod(initialUserSignInMethod);
      setCounter(0);
    } else {
      reset({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: {},
        pinCode: "",
        password: "",
      });

      setSignInMethod(SIGN_IN_METHOD.phoneNumber);
    }
  }, [selectedUser, openSlide, reset]);

  const handleOnAsyncSuccessForUser = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      dispatch(getUsers({ businessId }));
      setOpenSlide(false);
    });
  };

  const handleOnAsyncErrorForUser = (error) => {
    const errorData = handleAPIErrorMessage(error.data);
    if (errorData) {
      const { field, errorMessage } = errorData;
      setError(field, {
        type: "manual",
        message: errorMessage,
      });
    }
  };

  const { execute: executeCreateUser, loading: isLoadingCreateUser } = useAsync(
    createUser,
    {
      onError: (error) => handleOnAsyncErrorForUser(error.response),
      onSuccess: () => {
        handleOnAsyncSuccessForUser(t("toastMessages.success.createUser"));
      },
    }
  );
  const { execute: executeUpdateUser, loading: isLoadingUpdateUser } = useAsync(
    updateUser,
    {
      onError: (error) => handleOnAsyncErrorForUser(error.response),
      onSuccess: (res) => {
        dispatch(updateUserInformation(res.data));
        handleOnAsyncSuccessForUser(t("toastMessages.success.updateUser"));
      },
    }
  );

  const handleOnSaveUser = async (data, id, method, sendPinCode) => {
    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailOrPhone:
        signInMethod === SIGN_IN_METHOD.email
          ? data.email
          : data.phoneNumber.replace(/\+/g, ""),
      pinCode: data.pinCode,
      role: convertTitleCaseToSnakeCase(data.role.name),
    };
    if (data.password !== "") {
      user["password"] = data.password;
    }
    if (id) {
      await executeUpdateUser(businessId, user, id, method, sendPinCode);
    } else {
      await executeCreateUser(businessId, user, method);
    }
  };

  const handleOnSubmit = () => {
    const formData = getValues();
    if (formData.phoneNumber) {
      formData.phoneNumber = formData.phoneNumber.replace(/\+/g, "");
    }
    const pinCode = {
      ...selectedUser?.pinCode,
      userPinCode: formData.pinCode || "",
    };
    handleOnSaveUser(
      { ...formData, pinCode },
      selectedUser?.id,
      signInMethod,
      true
    );
  };

  const handleResendPassword = async () => {
    try {
      const response = await dispatch(
        resendCode({
          userData: {
            emailOrPhone:
              signInMethod === SIGN_IN_METHOD.email
                ? selectedUser.email
                : selectedUser.phoneNumber.replace(/\+/g, ""),
          },
          method: signInMethod,
        })
      );

      if (response.error) {
        handleOnAsyncError();
      } else {
        setCounter(60);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickCloseButton = () => {
    if (!isEqual(updatedFormData, initialData)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setOpenSlide(false);
  };

  const AdminUserModalHeader = (
    <div className="AdminUserModalTitle">
      <h3 className="SemiBold">
        {selectedUser ? t("user.editEmployee") : t("user.addEmployee")}
      </h3>
      <div className="AdminUserModalTitleRight">
        {isEditable && (
          <DeleteButton
            setOpenSlide={setOpenSlide}
            onClick={() => {
              onDelete(selectedUser.id);
            }}
          />
        )}
        <IconButton
          onClick={handleClickCloseButton}
          svgComponent={<IconClose />}
        />
      </div>
    </div>
  );

  const AdminUserModalBody = (
    <div className="AdminUserModalBody">
      <form className="AdminUserModalBodyForm">
        <InputControl
          name="firstName"
          required
          type="text"
          placeholder={t("inputs.firstName")}
          {...register("firstName")}
          hasError={errors.firstName}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          error={
            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <InputControl
          name="lastName"
          placeholder={t("inputs.lastName")}
          required
          type="text"
          {...register("lastName")}
          hasError={errors.lastName}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          error={
            <ErrorMessage
              errors={errors}
              name="lastName"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <Controller
          name="role"
          control={control}
          defaultValue={
            selectedUser?.roles[0]
              ? {
                  ...selectedUser?.roles[0],
                  name: convertSnakeCaseToTitleCase(
                    selectedUser?.roles[0]?.name
                  ),
                }
              : {}
          }
          render={({ field: { value, onChange } }) => (
            <Dropdown
              onChange={onChange}
              required
              isOptionRequired
              placeholder={t("inputs.role")}
              name="role"
              value={value}
              options={roles}
              error={
                <ErrorMessage
                  errors={errors}
                  name="role"
                  render={({ message }) => (
                    <p className="h7 error-message">{message}</p>
                  )}
                />
              }
              hasError={errors.role}
            />
          )}
        />
        <InputControl
          name="pinCode"
          type="password"
          placeholder={t("inputs.pinCode")}
          {...register("pinCode")}
          hasError={errors.pinCode}
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          error={
            <ErrorMessage
              errors={errors}
              name="pinCode"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
        <div className="AdminUserModalSignInDetails">
          <FormProvider {...methods}>
            <EmailOrPhone
              signInMethod={signInMethod}
              setSignInMethod={setSignInMethod}
            />
          </FormProvider>
          {/*{!isEditable && (*/}
          {/*  <InputControl*/}
          {/*    {...register("password")}*/}
          {/*    hasError={errors.password}*/}
          {/*    labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}*/}
          {/*    error={*/}
          {/*      <ErrorMessage*/}
          {/*        errors={errors}*/}
          {/*        name="password"*/}
          {/*        render={({ message }) => (*/}
          {/*          <p className="h7 error-message">{message}</p>*/}
          {/*        )}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    name="password"*/}
          {/*    placeholder={t("inputs.password")}*/}
          {/*    type="password"*/}
          {/*  />*/}
          {/*)}*/}
          {signInMethod === initialUserSignInMethod &&
            initialData[signInMethod] === updatedFormData[signInMethod] &&
            initialData[signInMethod] !== "" && (
              <ResendPassword
                onClick={handleResendPassword}
                customButtonText={
                  t("buttons.send") + ` ${counter > 0 ? `(${counter})` : ""}`
                }
                customSubTitle={t("user.sendAllDetails")}
                isLoading={isLoadingResend}
                counter={counter}
              />
            )}
        </div>
      </form>
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleUnsavedChanges")}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        description={t("modal.warningModalDescription")}
        onCancel={(e) => handleOnCancelCloseModal(e)}
        onConfirm={(e) => handleOnConfirmCloseModal(e)}
        openSlide={openSlideConfirmCloseModal}
      />
    </div>
  );

  const AdminUserModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={t("buttons.save")}
      isLoading={isLoadingUpdateUser || isLoadingCreateUser}
    />
  );

  return (
    <Modal
      header={AdminUserModalHeader}
      body={AdminUserModalBody}
      footer={AdminUserModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    />
  );
};

AdminUserModal.propTypes = {
  /**
   * The ref for the main element of the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The currently selected user
   */
  selectedUser: PropTypes.object,

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the data.
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item.
   */
  onDelete: PropTypes.func,

  setOutsideClickActionUser: PropTypes.func,
};

export default AdminUserModal;
