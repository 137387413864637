import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getGeneralMetaDataAsync } from "redux/actions/metaDataAction";

const useGeneralMetaDataUpdater = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGeneralMetaDataAsync());
  }, []);
};

export default useGeneralMetaDataUpdater;
