import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getInventoryCategoriesAsync } from "../actions/inventoryActions";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const inventoryStore = createSlice({
  name: STORE_NAMES.inventory,
  initialState: initialState,
  reducers: {
    resetInventoryStore: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInventoryCategoriesAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(
      getInventoryCategoriesAsync.rejected,
      (state, { payload }) => {
        return { ...state, isLoading: false, error: payload };
      }
    );
    builder.addCase(
      getInventoryCategoriesAsync.fulfilled,
      (state, { payload }) => {
        return { ...state, isLoading: false, data: payload };
      }
    );
  },
});

export const { resetInventoryStore } = inventoryStore.actions;

export default inventoryStore.reducer;
