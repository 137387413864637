import React from "react";

import { TAG_OPTIONS } from "components/elements/tag-with-icon/tagOptions";
import PropTypes from "prop-types";
import cx from "classnames";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./TagWithIcon.scss";

export const ENUMS = {
  name: "TagWithIcon",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const TagWithIcon = ({
  items,
  type,
  isActiveOnly,
  activeTags,
  setActiveTags,
}) => {
  const handleTagClick = (itemId) => {
    setActiveTags((prevActiveTags) => {
      if (prevActiveTags.includes(itemId)) {
        return prevActiveTags.filter((id) => id !== itemId);
      } else {
        return [...prevActiveTags, itemId];
      }
    });
  };

  return (
    <>
      {items.map((item, index) => {
        const tagOption = TAG_OPTIONS.find((tag) => tag.name === item.name);
        const isActive = isActiveOnly ? activeTags.includes(item.id) : false;
        return (
          <div
            className={cx("TagWithIcon", {
              typeA: type === ENUMS.types.TYPE_A,
              typeB: type === ENUMS.types.TYPE_B,
              active: isActive,
            })}
            key={index}
            onClick={() => handleTagClick(item.id)}
          >
            {(type === ENUMS.types.TYPE_A || type === ENUMS.types.TYPE_B) && (
              <ImageWithPlaceholder
                imageSource={
                  (isActive && tagOption?.active) ||
                  createDOBucketName(item.icon)
                }
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt={item.name}
                className="tagIcon"
              />
            )}
            {type === ENUMS.types.TYPE_B && <h5>{item.name}</h5>}
          </div>
        );
      })}
    </>
  );
};
TagWithIcon.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  isActiveOnly: PropTypes.bool,
  activeTags: PropTypes.array,
  setActiveTags: PropTypes.func,
};
export default TagWithIcon;
