import ICON_TAGS_1 from "assets/icons/tag/Bio.svg";
import ICON_TAGS_2 from "assets/icons/tag/Organic.svg";
import ICON_TAGS_3 from "assets/icons/tag/GlutenFree.svg";
import ICON_TAGS_4 from "assets/icons/tag/Halal.svg";
import ICON_TAGS_5 from "assets/icons/tag/Spicy.svg";
import ICON_TAGS_6 from "assets/icons/tag/Vegeterian.svg";
import ICON_TAGS_7 from "assets/icons/tag/Vegan.svg";
import ICON_TAGS_8 from "assets/icons/tag/NonGMO.svg";
import ICON_TAGS_9 from "assets/icons/tag/DairyFree.svg";
import ICON_TAGS_9_Active from "assets/icons/tag/DairyFreeActive.svg";
import ICON_TAGS_10_Active from "assets/icons/tag/SugarFreeActive.svg";
import ICON_TAGS_10 from "assets/icons/tag/SugarFree.svg";
import ICON_TAGS_11 from "assets/icons/tag/WholeGrain.svg";

export const TAG_OPTIONS = [
  {
    src: ICON_TAGS_1,
    name: "Bio",
  },
  {
    src: ICON_TAGS_2,
    name: "Organic",
  },
  {
    src: ICON_TAGS_3,
    name: "Gluten free",
  },
  {
    src: ICON_TAGS_4,
    name: "Halal",
  },
  {
    src: ICON_TAGS_5,
    name: "Spicy",
  },
  {
    src: ICON_TAGS_6,
    name: "Vegetarian",
  },
  {
    src: ICON_TAGS_7,
    name: "Vegan",
  },
  {
    src: ICON_TAGS_8,
    name: "Non-GMO",
  },
  {
    src: ICON_TAGS_9,
    name: "Dairy free",
    active: ICON_TAGS_9_Active,
  },
  {
    src: ICON_TAGS_10,
    name: "Sugar free",
    active: ICON_TAGS_10_Active,
  },
  {
    src: ICON_TAGS_11,
    name: "Whole grain",
  },
];
