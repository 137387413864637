import React from "react";
import PropTypes from "prop-types";

import EditButton, {
  ENUMS as ENUMS_EDIT,
} from "components/admin/buttons/edit-button/EditButton";

import "./QrZoneItem.scss";

const QrZoneItem = ({ title, status, onEdit, onClick, zone }) => {
  return (
    <div className="QrZoneItem" onClick={onClick}>
      <div>
        <h6 className="QrZoneItemTitle SemiBold">
          {title} {zone?.tables.length > 0 ? `(${zone.tables.length})` : ""}
        </h6>
        {status && (
          <h6 className={`QrZoneItemStatus h8 Medium ${status.type}`}>
            {status.text}
          </h6>
        )}
      </div>
      <EditButton onClick={onEdit} type={ENUMS_EDIT.types.TYPE_E} />
    </div>
  );
};
QrZoneItem.propTypes = {
  /**
   * The title to display
   */
  title: PropTypes.string,

  /**
   * The status of type
   */
  status: PropTypes.object,
  /**
   * Handle event when click edit button
   */
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  zone: PropTypes.object,
};

export default QrZoneItem;
