import React from "react";
import { ReactComponent as CompleteIcon } from "assets/icons/confirm/confirm.svg";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./Steps.scss";

const Steps = ({ steps, activeStep }) => {
  const { t } = useTranslation();
  const activeIndex = steps.findIndex(
    (step) => step.title === activeStep.title
  );

  const getStepClass = (index) => {
    if (index === 0) return "Step";
    return index % 2 !== 0 ? "Step AlignStepToCenter" : "Step AlignStepToRight";
  };
  return (
    <div className="Steps">
      {steps.map((step, index) => (
        <div key={index}>
          <div className={getStepClass(index)}>
            <div className="StepperRadioButtonContainer">
              <input
                id={step.title}
                type="radio"
                checked={activeStep.type === step.type}
                className="StepRadioButton"
                readOnly
              />
              <div
                className={
                  index < activeIndex
                    ? "StepRadioButtonSelectionCircle StepRadioButtonSelectionCircleComplete"
                    : "StepRadioButtonSelectionCircle"
                }
              >
                {index < activeIndex && <CompleteIcon />}
              </div>
            </div>
            <label htmlFor={step.title} className="StepTitle">
              {t(step.title)}
            </label>
          </div>
          {index !== steps.length - 1 && (
            <div
              className={cx("StepperProgressLine", {
                StepperProgressLineGreen: index < activeIndex,
                StepperProgressLineGray:
                  activeIndex === 0 &&
                  steps.length - index === steps.length - 1,
              })}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.object,
};

export default Steps;
