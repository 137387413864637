import { createSlice } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getAllQrAsync } from "../actions/qrAction";
import { getAdminMetaDataAsync } from "../actions/metaDataAction";

const initialState = {
  isLoading: false,
  qrs: [],
  error: null,
};

export const qrStore = createSlice({
  name: STORE_NAMES.qr,
  initialState: initialState,
  reducers: {
    resetQrStore: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllQrAsync.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(getAllQrAsync.rejected, (state, { payload }) => {
      return { ...state, isLoading: false, error: payload };
    });
    builder.addCase(getAllQrAsync.fulfilled, (state, { payload }) => {
      return { ...state, isLoading: false, qrs: payload };
    });
    builder.addCase(getAdminMetaDataAsync.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getAdminMetaDataAsync.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    });
    builder.addCase(getAdminMetaDataAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        qrs: payload.qrs,
        isLoading: false,
      };
    });
  },
});

export const { resetQrStore } = qrStore.actions;
export default qrStore.reducer;
