import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import { formatShortDate, formatTimeToHHMM } from "utils/helpers";
import AdminReservationEditorModal from "../admin-reservation-modal/AdminReservationEditorModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import CloseButton from "components/buttons/close-button/CloseButton";
import { EDITOR_TYPE } from "pages/admin/data/constants/general";

import "./AdminPendingBooksModal.scss";

const AdminPendingBooksModal = ({
  mainElementRef,
  openSlide,
  setOpenSlide,
  data,
  title,
}) => {
  const [
    openPendingReservation,
    setOpenPendingReservation,
    mainElementRefPendingReservation,
  ] = useOutsideClick();
  const [selectedReservation, setSelectedReservation] = useState(null);
  const { t } = useTranslation();

  const displayReservationTime = (startDateTime, endDateTime) => {
    const startTime = new Date(startDateTime);
    const endTime = new Date(endDateTime);
    const formattedStartTime = formatTimeToHHMM(startTime);
    const formattedEndTime = formatTimeToHHMM(endTime);
    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const openReservationDetailsModal = (reservation) => {
    setSelectedReservation(reservation);
    setOpenPendingReservation(true);
  };

  const pendingBooksModalHead = (
    <div className="AdminPendingBooksModalHeader">
      <h3 className="SemiBold">{title}</h3>
      <CloseButton
        onClick={() => {
          setOpenSlide(false);
        }}
      />
    </div>
  );

  const pendingBooksModalBody = (
    <>
      <div className="AdminPendingBooksModalBody">
        <div className="AdminPendingReservations">
          {data.length === 0 && <h5>There is no pending books</h5>}
          {data?.map((reservation) => (
            <div
              key={reservation.id}
              className="AdminPendingReservationCard"
              onClick={() => {
                openReservationDetailsModal(reservation);
              }}
            >
              <h6 className="Bold AdminPendingReservationCardTitle">
                {reservation.guestName}
              </h6>
              <div className="AdminPendingReservationCardTime">
                <p>
                  {t("dashboard.guest.guest")}:
                  <span>{reservation.guestsCount}</span>
                </p>
                <p>
                  {t("common.date.date")}:
                  <span>{formatShortDate(reservation.startDateTime)}</span>
                </p>
                <p>
                  {t("common.date.time")}:
                  <span>
                    {displayReservationTime(
                      reservation.startDateTime,
                      reservation.endDateTime
                    )}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AdminReservationEditorModal
        data={selectedReservation}
        mainElementRef={mainElementRefPendingReservation}
        openSlide={openPendingReservation}
        setOpenSlide={setOpenPendingReservation}
        title={t("reservation.waiting")}
        formType={EDITOR_TYPE.CONFIRM}
      />
    </>
  );

  return (
    <>
      <div className="AdminPendingBooksModal">
        <Modal
          header={pendingBooksModalHead}
          body={pendingBooksModalBody}
          mainElementRef={mainElementRef}
          openSlide={openSlide}
        />
      </div>
    </>
  );
};

AdminPendingBooksModal.propTypes = {
  mainElementRef: PropTypes.object.isRequired,
  openSlide: PropTypes.bool.isRequired,
  setOpenSlide: PropTypes.func.isRequired,
  data: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default AdminPendingBooksModal;
