import React, { useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as IconArrow } from "assets/icons/arrows/ArrowDown.svg";

import "./AccordionContainer.scss";

export const ENUMS = {
  name: "AccordionContainer",
};

const AccordionContainer = ({ name, bodyComponent }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className="AccordionContainer">
      <div
        className="AccordionContainerHeader"
        onClick={() => setIsVisible((prev) => !prev)}
      >
        <h6 className="SemiBold">{name}</h6>
        <div className="AccordionContainerIconArrowContainer">
          <IconArrow className={isVisible ? "isAccordionArrowRotated" : ""} />
        </div>
      </div>
      <div
        className={`AccordionContainerBody ${
          isVisible ? "isAccordionBodyVisible" : ""
        }`}
      >
        {bodyComponent}
      </div>
    </div>
  );
};

AccordionContainer.propTypes = {
  /**
   * The name of the accordion section
   */
  name: PropTypes.string,
  /**
   * The component that represents the body content of the accordion section
   */
  bodyComponent: PropTypes.node,
};

export default AccordionContainer;
