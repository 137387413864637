import { createAsyncThunk } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import { getAdminMetadata, getGeneralMetadata } from "utils/api/services/app";
import { getClientMetadata } from "utils/api/services/QR";

export const getGeneralMetaDataAsync = createAsyncThunk(
  `${STORE_NAMES.app}/getGeneralMetadata`,
  async () => {
    const response = await getGeneralMetadata();
    return response.data;
  }
);

export const getAdminMetaDataAsync = createAsyncThunk(
  `${STORE_NAMES.app}/getAdminMetadata`,
  async ({ businessId }) => {
    const response = await getAdminMetadata(businessId);
    const chatData = [
      {
        tableId: 1,
        messages: [],
      },
      {
        tableId: 3,
        messages: [],
      },
    ];

    const zones = response.data.zones.map((zone) => {
      const tablesWithChatData = zone.tables.map((table) => {
        const matchingChatData = chatData.find(
          (chat) => chat.tableId === table.id
        );

        if (matchingChatData) {
          return { ...table, messages: matchingChatData.messages };
        }

        return table;
      });

      return { ...zone, tables: tablesWithChatData };
    });
    return { ...response.data, zones };
  }
);

export const getGuestMetaDataAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/getClientMetadata`,
  async ({ businessId, qrId, onSuccess }) => {
    try {
      const response = await getClientMetadata(businessId, qrId);
      if (typeof onSuccess === "function") {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
