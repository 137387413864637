import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import EmailOrPhone from "components/elements/email-or-phone/EmailOrPhone";
import useOutsideClick from "utils/hooks/useOutsideClick";
import PropTypes from "prop-types";
import { STORE_NAMES } from "utils/constants/redux";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import PolicyModal from "components/policy-modal/PolicyModal";
import { useTranslation } from "react-i18next";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import { useKeyPress } from "utils/hooks/useKeyPress";
import { EVENT_CODES } from "utils/constants/events";

import "./SignUpForm.scss";

const SignUpForm = ({
  handleSignupFormSubmit,
  signInMethod,
  setSignInMethod,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [openSlidePolicy, setOpenSlidePolicy, mainElementRefPolicy] =
    useOutsideClick();

  const { plans } = useSelector((state) => state[STORE_NAMES.app]);
  const isLoadingSignUp = useSelector((state) => state[STORE_NAMES.user])
    .thunkAPIStates?.signup;

  const handleOnModalClose = () => {
    setOpenSlidePolicy(false);
  };
  const enterPressed = useKeyPress(EVENT_CODES.enter);
  useEffect(() => {
    if (enterPressed) {
      handleSubmit(handleSignupFormSubmit)();
    }
  }, [enterPressed]);

  return (
    <div className="SignUpForm">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="SignUpFormInputGroups">
          <InputControl
            type="text"
            placeholder={t("inputs.firstName")}
            required
            name="firstName"
            func={{ ...register("firstName") }}
            hasError={errors.firstName}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            error={
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <InputControl
            type="text"
            placeholder={t("inputs.lastName")}
            required
            name="lastName"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{ ...register("lastName") }}
            hasError={errors.lastName}
            error={
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
        </div>
        <div className="SignUpFormInputGroups">
          <InputControl
            type="text"
            placeholder={t("inputs.businessName")}
            required
            name="businessName"
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            func={{ ...register("businessName") }}
            hasError={errors.businessName}
            error={
              <ErrorMessage
                errors={errors}
                name="businessName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <Controller
            name="plan"
            control={control}
            defaultValue={{}}
            render={({ field: { value, onChange } }) => (
              <Dropdown
                options={plans || []}
                onChange={onChange}
                placeholder={t("business.plan")}
                value={value}
                isOptionRequired
                isDisabled={plans.length === 0}
                name="plan"
                required
                error={
                  <ErrorMessage
                    errors={errors}
                    name="plan"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
                hasError={errors.plan}
              />
            )}
          />
        </div>

        <EmailOrPhone
          signInMethod={signInMethod}
          setSignInMethod={setSignInMethod}
        />
        {/*<div*/}
        {/*  className={cx("SignUpFormTermsAndConditions", {*/}
        {/*    isChecked: formData.agreeTerms,*/}
        {/*    hasError: !!errors.agreeTerms,*/}
        {/*  })}*/}
        {/*>*/}
        {/*  <Controller*/}
        {/*    name="agreeTerms"*/}
        {/*    control={control}*/}
        {/*    defaultValue={false}*/}
        {/*    render={({ field: { value } }) => (*/}
        {/*      <Checkbox*/}
        {/*        type={CHECK_ENUMS.types.TYPE_B}*/}
        {/*        isChecked={value}*/}
        {/*        onClick={() => setOpenSlidePolicy(true)}*/}
        {/*        onChange={() => setValue("agreeTerms", !value)}*/}
        {/*        value={value}*/}
        {/*        name="agreeTerms"*/}
        {/*        label={t("login.signUp.agree")}*/}
        {/*        labelModal={t("login.signUp.termsAndPrivacy")}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</div>*/}
        <p className="SignUpFormTermsAndConditionsText">
          {t("login.signUp.agree")}{" "}
          <span onClick={() => setOpenSlidePolicy(true)}>
            {t("login.signUp.termsAndPrivacy")}
          </span>
        </p>
        <PrimaryButton
          onClick={handleSubmit(handleSignupFormSubmit)}
          text={t("buttons.continue")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_A}
          isLoading={isLoadingSignUp}
        />
      </form>

      <PolicyModal
        open={openSlidePolicy}
        onClose={handleOnModalClose}
        mainElementRefPolicy={mainElementRefPolicy}
        policyModalTranslateFileKey={"signUpTermsData"}
      />
    </div>
  );
};

SignUpForm.propTypes = {
  handleSignupFormSubmit: PropTypes.func,
  signInMethod: PropTypes.string,
  setSignInMethod: PropTypes.func,
};

export default SignUpForm;
