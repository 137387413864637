import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import Table from "components/admin/elements/table/Table";
import useAsync from "utils/hooks/useAsync";
import { getAllGuests } from "utils/api/services/guest";
import { createDOBucketName } from "utils/DO-Spaces";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import EmptyState from "components/admin/empty-state/EmptyState";
import NoGuest from "assets/icons/other/Users.svg";
import SearchIcon from "assets/icons/chat/search.svg";
import Spinner from "components/elements/spinner/Spinner";

import "./SuperAdminGuests.scss";

const SuperAdminGuests = () => {
  const { t } = useTranslation();
  const [searchGuests, setSearchGuests] = useState("");
  const { result: allGuests, loading } = useAsync(getAllGuests, {
    immediate: true,
  });
  const [filteredGuests, setFilteredGuests] = useState([]);

  const tableColumns = [
    {
      key: "name",
      name: t("inputs.name"),
      isFilterable: false,
    },
    {
      key: "contact",
      name: t("inputs.contact"),
      isFilterable: false,
    },
  ];

  useEffect(() => {
    if (allGuests) {
      filterGuests();
    }
  }, [searchGuests, allGuests]);

  const filterGuests = () => {
    if (!allGuests) return;
    const lowercasedQuery = searchGuests.toLowerCase();
    const result = allGuests.filter(
      ({ name, phoneNumber }) =>
        (name && name.toLowerCase().includes(lowercasedQuery)) ||
        (phoneNumber && phoneNumber.includes(searchGuests))
    );
    setFilteredGuests(result);
  };

  const handleSearchChange = (value) => {
    setSearchGuests(value.trimStart());
  };

  const tableItems = filteredGuests.map((guest) => ({
    id: guest.id,
    name: (
      <div className="GuestInfo">
        <ImageWithPlaceholder
          imageSource={createDOBucketName(guest.profilePic)}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={guest.name || "Guest"}
          className="GuestProfile"
        />
        {guest.name || "Guest"}
      </div>
    ),
    contact: guest.phoneNumber || "-",
  }));
  if (loading || !allGuests) {
    return <Spinner />;
  }
  return (
    <div className="SuperAdminGuests">
      <div className="SuperAdminGuestsTitle">
        <div className="SuperAdminGuestsInfo">
          <h4 className="SemiBold SuperAdminGuestsInfoTitle">
            {t("navbarRoutes.pageTitles.guests")}
          </h4>
          <h6 className="Medium">
            {t("guests.guest")} ({tableItems?.length})
          </h6>
        </div>
        <div className="SuperAdminGuestsFilters">
          <Search
            onChange={handleSearchChange}
            value={searchGuests}
            placeholder={t("inputs.search")}
            type={SEARCH_ENUMS.types.TYPE_B}
            className="SuperAdminFiltersSearch"
          />
        </div>
      </div>
      {tableItems?.length > 0 ? (
        <Table
          columns={tableColumns}
          items={tableItems}
          hasEditColumn={false}
          hasEditRow={false}
        />
      ) : searchGuests?.length > 0 ? (
        <EmptyState
          icon={SearchIcon}
          description={t("emptyStates.noSearchResults")}
          isAdmin={true}
        />
      ) : (
        <EmptyState
          icon={NoGuest}
          description={t("emptyStates.noGuests")}
          isAdmin={true}
        />
      )}
    </div>
  );
};

export default SuperAdminGuests;
