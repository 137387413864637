import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ChartPeriodSelectButton, {
  CHART_PERIODS,
} from "components/buttons/chart-period-select-button/ChartPeriodSelectButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";

import "./StatsCardChartWithPeriodWrapper.scss";

const StatsCardChartWithPeriodWrapper = ({
  activeButton,
  setActiveButton,
  title,
  chartToRender,
  legendData,
  className,
  disableMonthlyButton,
}) => {
  const [period, setPeriod] = useState("period");
  const handleOnDropdownChange = (period) => {
    setPeriod(period);
    setActiveButton(period?.name.toLowerCase());
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const chartPeriodsArray = Object.keys(CHART_PERIODS).map((key, index) => ({
    id: index + 1,
    name: capitalizeFirstLetter(CHART_PERIODS[key]),
  }));

  return (
    <div
      className={cx(
        "StatsCardChartWithPeriodWrapper",
        "CardInsetShadow-v1",
        className
      )}
    >
      <div className="titleAndPeriod">
        <h3 className="SemiBold title">{title}</h3>

        {activeButton && setActiveButton && (
          <ChartPeriodSelectButton
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            disableMonthlyButton={disableMonthlyButton}
            className={"CustomChartPeriodSelectButton"}
          />
        )}
        {activeButton && setActiveButton && (
          <Dropdown
            className={"PeriodDropDown"}
            value={period}
            onChange={(period) => handleOnDropdownChange(period)}
            placeholder={activeButton}
            options={chartPeriodsArray}
          />
        )}
      </div>

      {legendData?.length > 1 && (
        <div className="WrapperOfIconAndText">
          {legendData.map((metaItem, index) => {
            return (
              <div key={index} className="IconAndText">
                <div
                  className="circle"
                  style={{ background: metaItem.fillColor }}
                ></div>
                <h6 className="title Medium">{metaItem.legendName} </h6>
              </div>
            );
          })}
        </div>
      )}
      <div className="ChartWrapper">{chartToRender}</div>
    </div>
  );
};

StatsCardChartWithPeriodWrapper.propTypes = {
  activeButton: PropTypes.oneOf([
    CHART_PERIODS.hourly,
    CHART_PERIODS.daily,
    CHART_PERIODS.weekly,
    CHART_PERIODS.monthly,
  ]),
  setActiveButton: PropTypes.func,
  title: PropTypes.string.isRequired,
  chartToRender: PropTypes.element.isRequired,
  legendData: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      legendName: PropTypes.string.isRequired,
      fillColor: PropTypes.string.isRequired,
      strokeColor: PropTypes.string.isRequired,
      stackId: PropTypes.string.isRequired,
    })
  ),
  /**
   * Additional class name for custom styling
   */
  className: PropTypes.string,
  disableMonthlyButton: PropTypes.bool,
};

export default StatsCardChartWithPeriodWrapper;
