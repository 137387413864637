import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./ActionNotificationButton.scss";

export const ENUMS = {
  name: "ActionNotificationButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const ActionNotificationButton = ({
  type,
  label,
  count,
  onClick,
  isSelected,
}) => {
  return (
    <button
      onClick={onClick}
      className={cx("ActionNotificationButton", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        isActive: count > 0,
        isSelected: isSelected && count > 0,
        isDisabled: count === 0,
      })}
      type="button"
    >
      <h6 className="SemiBold">{label}</h6>
      <div className="ActionNotificationButtonCount">
        <h2 className="SemiBold">{count}</h2>
      </div>
    </button>
  );
};

ActionNotificationButton.propTypes = {
  /**
   * The type of the component
   */
  type: PropTypes.oneOf([ENUMS.types.TYPE_A, ENUMS.types.TYPE_B]),

  /**
   * The label to display on the button
   */
  label: PropTypes.string.isRequired,

  /**
   * The count to display on the button
   */
  count: PropTypes.number.isRequired,

  /**
   * The click event handler function
   */
  onClick: PropTypes.func,

  /**
   * Indicates whether the button is selected
   */
  isSelected: PropTypes.bool,
};

ActionNotificationButton.defaultProps = {
  type: ENUMS.types.TYPE_A,
};
export default ActionNotificationButton;
