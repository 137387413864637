import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ICON_CLOSE from "assets/icons/close/Close.svg";
import Confirm from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useIsAdminRoute from "utils/hooks/useIsAdminRoute";
import { STORE_NAMES } from "utils/constants/redux";

import "./Tag.scss";

export const ENUMS = {
  name: "Tag",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
  },

  tagItemType: {
    calorie: "calorie",
    time: "time",
    amount: "amount",
  },
};

const Tag = ({ items, type, onRemove, isWrapped, activeTagIds, onClick }) => {
  const { t } = useTranslation();
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();
  const [removedItem, setRemovedItem] = useState(null);
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const isAdminRoute = useIsAdminRoute();


  const handleOnRemove = (e, item) => {
    e.stopPropagation();
    setOpenSlideConfirm(true);
    setRemovedItem(item);
  };

  const handleOnCancel = () => {
    setOpenSlideConfirm(false);
  };

  const handleOnConfirm = () => {
    setOpenSlideConfirm(false);
    onRemove(removedItem);
  };

  const filteredItems = isAdminRoute
    ? items
    : items.filter((item) => {
      switch (item.type) {
        case ENUMS.tagItemType.calorie:
          return business.menuItemCalorie;
        case ENUMS.tagItemType.time:
          return business.menuItemTime;
        case ENUMS.tagItemType.amount:
          return business.menuItemAmount;
        default:
          return false;
      }
    });

  return (
    <>
      <div
        className={cx("TagWrapper", {
          isWrapped: isWrapped,
          typeB: type === ENUMS.types.TYPE_B,
          typeD: type === ENUMS.types.TYPE_D,
          typeE: type === ENUMS.types.TYPE_E,
        })}
      >
        {type === ENUMS.types.TYPE_A &&
          filteredItems.map((item, index) => (
            <h6 key={index} className="TagItem h8 Medium">
              {item.label || item.name}
            </h6>
          ))}
        {(type === ENUMS.types.TYPE_B || type === ENUMS.types.TYPE_C) &&
          filteredItems.map((item, index) => (
            <h6
              key={index}
              className={cx("Tag Medium", {
                typeC: type === ENUMS.types.TYPE_C,
                isActive: activeTagIds.includes(item.id),
              })}
              onClick={() => onClick && onClick(item)}
            >
              {item.label || item.name}
              {type === ENUMS.types.TYPE_C && (
                <img
                  src={ICON_CLOSE}
                  alt="close"
                  className="TagClose"
                  onClick={(e) => onRemove && handleOnRemove(e, item)}
                />
              )}
            </h6>
          ))}

        {type === ENUMS.types.TYPE_D &&
          filteredItems.map((item, index) => (
            <div
              key={index}
              className={cx("Tag", {
                typeD: type === ENUMS.types.TYPE_D,
                isActive: activeTagIds.includes(item.id),
              })}
              onClick={() => onClick && onClick(item.id)}
            >
              <p className="h7">{item.name}</p>
            </div>
          ))}
        {type === ENUMS.types.TYPE_E &&
          filteredItems.map((item, index) => (
            <h5 key={index} className="TagItem">
              {item.label || item.name}
            </h5>
          ))}
      </div>
      <Confirm
        title={t("modal.deleteModalTitle")}
        description={t("modal.deleteModalDescription")}
        mainElementRefConfirm={mainElementRefConfirm}
        openSlide={openSlideConfirm}
        onCancel={handleOnCancel}
        onConfirm={handleOnConfirm}
      />
    </>
  );
};

Tag.propTypes = {
  /**
   * The items of the component
   */
  items: PropTypes.array.isRequired,

  /**
   * The type of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
    ENUMS.types.TYPE_D,
    ENUMS.types.TYPE_E,
  ]),

  /**
   * Callback function to handle removal of an item
   */
  onRemove: PropTypes.func,

  /**
   * Indicates whether the tags should be wrapped
   */
  isWrapped: PropTypes.bool,

  /**
   * The ids of the active tags
   */
  activeTagIds: PropTypes.array,

  /**
   * Callback function to handle clicking on a tag
   */
  onClick: PropTypes.func,
};

Tag.defaultProps = {
  activeTagIds: [],
};

export default Tag;
