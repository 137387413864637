import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cx from "classnames";

import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import Spinner from "components/elements/spinner/Spinner";
import EditButton from "components/admin/buttons/edit-button/EditButton";
import AddTagButton, {
  ENUMS as ENUMS_ADD_TAG_BUTTON,
} from "components/admin/buttons/add-tag-button/AddTagButton";

import "./AddGuestPhoto.scss";

export const ENUMS = {
  name: "AddGuestPhoto",
};

const AddGuestPhoto = ({ onFileSelect, image, hasImage }) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (e) => {
    const selectedFiles = e.target.files[0];
    if (selectedFiles) {
      try {
        setLoading(true);
        await onFileSelect(selectedFiles);
      } catch (error) {
        toast.error(t("errorMessages.image"));
      }
      setLoading(false);
      e.target.value = "";
    }
  };

  return (
    <div
      className={cx("GuestAccountInfoModalBodyProfile", {
        isLoading: loading,
      })}
    >
      {loading ? (
        <div className="GuestAccountInfoModalBodyAvatarWrapper">
          <Spinner className="UserProfilePictureSpinner" />
        </div>
      ) : (
        <GuestProfileWithIcon
          image={image}
          hasImage={hasImage}
          loading={loading}
          handleOnClick={handleButtonClick}
          actionButton={
            hasImage ? (
              <EditButton
                className="GuestAccountInfoModalProfileButton"
                onClick={handleButtonClick}
              />
            ) : (
              <AddTagButton
                type={ENUMS_ADD_TAG_BUTTON.types.TYPE_B}
                className="GuestAccountInfoModalProfileButton"
                onClick={() => {}}
              />
            )
          }
        />
      )}
      <input
        type="file"
        accept="image/*"
        className="AddPhotoButtonFileInput"
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
    </div>
  );
};

AddGuestPhoto.propTypes = {
  /**
   * The function called when a file is selected
   */
  onFileSelect: PropTypes.func.isRequired,

  /**
   * An array of images (if multiple) or an object (if single)
   */
  image: PropTypes.string,

  /**
   * Boolean to check if image is present
   */
  hasImage: PropTypes.bool,
};

export default AddGuestPhoto;
