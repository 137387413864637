import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setLoading } from "redux/slices/ordersStore";
import AdminOrderNotifications from "../admin-order-notifications/AdminOrderNotifications";
import {
  findMenuItemIndexFromOrders,
  getGuestsWithUnconfirmedItems,
  mergeGuestData,
  mergeUserData,
  getUsersWithConfirmedAndRejected,
  getGuestsWithConfirmedAndRejected,
} from "utils/helpers";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AdminOrderPendingItems from "../admin-order-pending-items/AdminOrderPendingItems";
import AdminOrderList from "../admin-order-list/AdminOrderList";
import { toast } from "react-toastify";
import AdminOrderItemModal from "../admin-order-item-modal/AdminOrderItemModal";
import { calculateAllOrderItemsCount } from "utils/general";
import NO_ORDER_ICON from "assets/icons/waiter/Order.svg";
import EmptyState from "components/admin/empty-state/EmptyState";

import "./AdminOrderDetail.scss";

export const KEY_NAMES = {
  USERS: "users",
  GUESTS: "guests",
};

export const DATA_TYPES = {
  FORM_DATA: "formData",
  PENDING_DATA: "pendingData",
};

const AdminOrderDetail = ({
  selectedOrder,
  selectedOrderItem,
  setSelectedOrderItem,
  isEnableToAddOrder,
  setIsEnableToAddOrder,
  pendingData,
  setPendingData,
  formData,
  setFormData,
  handleOnUpdateOrder,
  setOpenSlideOrderItem,
  openSlideOrderItem,
  mainElementRefOrderItem,
  setOutsideClickAction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(false);
  const [itemPrevModification, setItemPrevModification] = useState(null);
  const [editedDataType, setEditedDataType] = useState(null);
  const activeUser = useSelector((state) => state[STORE_NAMES.user]).user;

  const handleOnUpdateOrderItem = ({ count, orderItemId, item, isUser }) => {
    const key = isUser ? KEY_NAMES.USERS : KEY_NAMES.GUESTS;
    const data =
      editedDataType === DATA_TYPES.PENDING_DATA ? pendingData : formData;
    let guestId = data[key].find((guest) => {
      return guest.orderItems.some((orderItem) => orderItem.id === orderItemId);
    }).person.id;
    const updatedItems = data[key]
      .map((guest) => {
        if (guest.person.id === guestId) {
          return {
            ...guest,
            orderItems: guest.orderItems
              .map((orderItem) => {
                if (orderItem.id === orderItemId) {
                  return {
                    ...orderItem,
                    isPendingList: true,
                    count: count,
                    item: item ? item : orderItem.item,
                  };
                }
              })
              .filter((orderItem) => orderItem !== undefined),
          };
        }
        return guest;
      })
      .filter((guest) => guest.person.id === guestId);
    const mergedData = mergeGuestData(updatedItems, pendingData[key]);
    setPendingData({ ...pendingData, [key]: mergedData });
    setEditedDataType(null);
  };

  const handleOnRemoveOrderItem = (orderItemId, guestId, isUser) => {
    const key = isUser ? KEY_NAMES.USERS : KEY_NAMES.GUESTS;
    const removedGuestItems = formData[key]
      .map((guest) => {
        if (guest.person.id === guestId) {
          const updatedOrderItems = guest.orderItems
            .map((orderItem) => {
              if (orderItem.id === orderItemId) {
                return {
                  ...orderItem,
                  isConfirmed: false,
                  isPendingList: true,
                  isArchived: true,
                };
              }
            })
            .filter((orderItem) => orderItem !== undefined);
          return {
            ...guest,
            orderItems: [...updatedOrderItems],
          };
        }
        return guest;
      })
      .filter((guest) => guest.person.id === guestId);

    const mergedData = mergeGuestData(removedGuestItems, pendingData[key]);

    setPendingData({ ...pendingData, [key]: mergedData });
  };

  const handleOnSaveOrder = async () => {
    dispatch(setLoading(true));
    const updatedUsers = pendingData.users.map((user) => {
      const updatedOrderItems = user.orderItems.map((orderItem) => {
        if (
          selectedOrder.users.some((existingUser) =>
            existingUser.orderItems.some(
              (existingOrderItem) => existingOrderItem.id === orderItem.id
            )
          )
        ) {
          return orderItem;
        } else {
          // eslint-disable-next-line no-unused-vars
          const { id, ...orderItemWithoutId } = orderItem;
          return orderItemWithoutId;
        }
      });

      return {
        ...user,
        orderItems: updatedOrderItems,
      };
    });
    const guestsWithoutOrderMessage = pendingData.guests.map(
      // eslint-disable-next-line no-unused-vars
      ({ orderMessage, ...restOfGuest }) => restOfGuest
    );
    const hasNewOrder =
      calculateAllOrderItemsCount(getGuestsWithUnconfirmedItems(formData)) > 0;

    if (pendingData.assignee) {
      await handleOnUpdateOrder(
        {
          guests: guestsWithoutOrderMessage,
          users: updatedUsers,
          actions: pendingData.actions,
          hasNewOrder,
        },
        () =>
          setPendingData({
            ...pendingData,
            users: [],
            guests: [],
          })
      );
    } else {
      toast.error(t("errorMessages.orderAssignee"));
    }
    dispatch(setLoading(false));
  };

  const handleOnEditOrderItem = (orderItem, isUser, dataType) => {
    setIsUser(isUser);
    setIsEnableToAddOrder(false);
    setSelectedOrderItem(orderItem);
    setOpenSlideOrderItem(true);
    setItemPrevModification(orderItem.item.modifications);
    setEditedDataType(
      dataType === DATA_TYPES.PENDING_DATA
        ? DATA_TYPES.PENDING_DATA
        : DATA_TYPES.FORM_DATA
    );
  };

  const handleOnSaveOrderItem = ({ item, count, orderItemId }) => {
    handleOnUpdateOrderItem({
      count,
      orderItemId,
      itemPrevModification,
      item,
      isUser,
    });
  };

  const handleOnAddToOrder = ({ item, count }) => {
    const waiterIndex = pendingData.users.findIndex(
      (user) => user.person.id === activeUser.id
    );

    const createNewWaiter = () => ({
      person: {
        id: activeUser.id,
        name: activeUser.name,
      },
      orderItems: [],
    });

    const waiter =
      waiterIndex !== -1
        ? { ...pendingData.users[waiterIndex] }
        : createNewWaiter();

    const findExistingItemIndex = () =>
      waiter.orderItems.findIndex((orderItem) => orderItem.item.id === item.id);

    const findExistingItemModifications = () =>
      findMenuItemIndexFromOrders(item, waiter.orderItems);

    const updateItemIfExists = () => {
      waiter.orderItems = waiter.orderItems
        .map((orderItem) => {
          if (
            item.id === orderItem.item.id &&
            findMenuItemIndexFromOrders(item, [orderItem]) !== -1
          ) {
            return {
              ...orderItem,
              isConfirmed: true,
              isPendingList: true,
            };
          }
        })
        .filter((orderItem) => orderItem !== undefined);
    };

    const addNewItemToOrder = () => {
      waiter.orderItems = [
        {
          id: new Date().getTime(),
          item: item,
          count: count,
          isConfirmed: true,
          isPendingList: true,
        },
      ];
    };

    const updateUsersData = () => {
      const updatedUsers = mergeUserData([waiter], pendingData["users"]);
      setPendingData({
        ...pendingData,
        users: updatedUsers,
      });
    };

    const existingItemIndex = findExistingItemIndex();
    const existingItemModifications = findExistingItemModifications();
    if (existingItemIndex !== -1 && existingItemModifications !== -1) {
      updateItemIfExists();
    } else {
      addNewItemToOrder();
    }

    updateUsersData();
  };
  const allPendingOrderItems = pendingData.guests
    .concat(pendingData.users)
    .flatMap((person) => person.orderItems);

  const groupedPendingOrderItems = {
    DELETE:
      allPendingOrderItems?.filter((orderItem) => orderItem.isArchived) || [],
    EDIT:
      allPendingOrderItems?.filter((orderItem) => !orderItem.isArchived) || [],
  };

  const handleOnUndoOrderItem = (orderItemId, guestId, isUser) => {
    const key = isUser ? KEY_NAMES.USERS : KEY_NAMES.GUESTS;
    const prevPendingDataExceptThis = pendingData[key].reduce((acc, item) => {
      if (item.person.id === guestId) {
        return acc.concat(item.orderItems);
      }
      return acc;
    }, []);

    const updatedGuests = formData[key]
      .map((item) => {
        if (item.person.id === guestId) {
          const moveOrderItemsToPendingList = item.orderItems
            .map((orderItem) => {
              if (orderItem.id === orderItemId) {
                return {
                  ...orderItem,
                  isPendingList: true,
                  isConfirmed: true,
                  isArchived: false,
                };
              }
              return null;
            })
            .filter(Boolean);

          const updatedOrderItems = [
            ...moveOrderItemsToPendingList,
            ...prevPendingDataExceptThis,
          ];

          return {
            ...item,
            orderItems: updatedOrderItems,
          };
        }

        return null;
      })
      .filter(Boolean);

    const prevPendingDataExceptThisUser = pendingData[key].filter(
      (user) => user.person.id !== guestId
    );

    setPendingData({
      ...pendingData,
      [key]: [...prevPendingDataExceptThisUser, ...updatedGuests],
    });
  };

  const isOrderItemExist =
    formData.guests.concat(formData.users).flatMap((user) => user.orderItems)
      .length > 0;

  return (
    <>
      <div className="AdminOrderNotificationsContainer">
        <AdminOrderNotifications
          setPendingData={setPendingData}
          pendingData={pendingData}
          formData={formData}
          setFormData={setFormData}
        />
      </div>
      <div className="AdminOrderPendingItemsContainer">
        <AdminOrderPendingItems
          pendingData={pendingData}
          setPendingData={setPendingData}
          formData={formData}
          setFormData={setFormData}
          onSaveOrder={handleOnSaveOrder}
          onEditOrderItem={handleOnEditOrderItem}
        />
      </div>
      {isOrderItemExist ? (
        <div className="AdminOrderDetailsOrders">
          <AdminOrderList
            guests={getGuestsWithConfirmedAndRejected(formData)}
            users={getUsersWithConfirmedAndRejected(formData)}
            isConfirmed
            onRemoveOrderItem={handleOnRemoveOrderItem}
            onUpdateOrderItem={handleOnUpdateOrderItem}
            onUndoOrderItem={handleOnUndoOrderItem}
            setIsEnableToAddOrder={setIsEnableToAddOrder}
            setSelectedOrderItem={setSelectedOrderItem}
            setOpenSlideOrderItem={setOpenSlideOrderItem}
            onEditOrderItem={handleOnEditOrderItem}
            dataType={DATA_TYPES.FORM_DATA}
            groupedPendingOrderItems={groupedPendingOrderItems}
          />
        </div>
      ) : (
        <EmptyState
          icon={NO_ORDER_ICON}
          description={t("emptyStates.noOrders")}
          isAdmin
        />
      )}

      {selectedOrderItem && (
        <AdminOrderItemModal
          orderItem={selectedOrderItem}
          mainElementRef={mainElementRefOrderItem}
          setOpenSlide={setOpenSlideOrderItem}
          openSlide={openSlideOrderItem}
          isEnableToAddOrder={isEnableToAddOrder}
          onAddToOrder={handleOnAddToOrder}
          onSave={handleOnSaveOrderItem}
          setOutsideClickAction={setOutsideClickAction}
        />
      )}
    </>
  );
};

AdminOrderDetail.propTypes = {
  /**
   * The selected order to display in the modal
   */
  selectedOrder: PropTypes.object,

  /**
   * The function to set the selected order
   */
  setSelectedOrder: PropTypes.func,

  selectedOrderItem: PropTypes.object,
  setSelectedOrderItem: PropTypes.func,
  isEnableToAddOrder: PropTypes.bool,
  setIsEnableToAddOrder: PropTypes.func,

  pendingData: PropTypes.object,
  setPendingData: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleOnUpdateOrder: PropTypes.func,
  setOpenSlideOrderItem: PropTypes.func,
  openSlideOrderItem: PropTypes.bool,
  setOutsideClickAction: PropTypes.func,
  mainElementRefOrderItem: PropTypes.object,
};

export default AdminOrderDetail;
