import { STORE_NAMES } from "utils/constants/redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "utils/api/services/guest";

export const updateGuestInfoAsync = createAsyncThunk(
  `${STORE_NAMES.guest}/updateGuestInfo`,
  async ({ guestInfo, id }, { rejectWithValue }) => {
    try {
      const response = await api.updateGuestInfo(guestInfo, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
