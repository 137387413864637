import React from "react";
import PropTypes from "prop-types";

import "./PageTitle.scss";

const PageTitle = ({ title, body, children }) => {
  return (
    <div className="PageTitle">
      <div>
        <h2 className="SemiBold PageTitleText">{title}</h2>
        {body}
      </div>

      {children}
    </div>
  );
};

PageTitle.propTypes = {
  /**
   * The title of page
   */
  title: PropTypes.string,

  /**
   * The body of title
   */
  body: PropTypes.element,

  /**
   * Children components
   */
  children: PropTypes.node,
};

export default PageTitle;
