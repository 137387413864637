import React from 'react';
import DraggableCard from './DraggableCard';
import PropTypes from 'prop-types';
import './DraggableListVertical.scss';

const DraggableListVertical = ({ items, sizeVariant, canDragWholeCard, isLoadingUpdateMenu }) => {
    return (
        <div className='DraggableListVerticalContainer'>
            {items.map((item, index) => (
                <DraggableCard
                    key={item.id}
                    draggableId={`item-${item.id.toString()}`}
                    index={index}
                    headerComponent={item.headerComponent}
                    bodyComponent={item.bodyComponent}
                    sizeVariant={sizeVariant}
                    canDragWholeCard={canDragWholeCard}
                    isLoadingUpdateMenu={isLoadingUpdateMenu}
                />
            ))}
        </div >
    );
};


DraggableListVertical.propTypes = {
    /**
     * The items to be displayed in the list
    **/
    items: PropTypes.array.isRequired,

    /**
     * The size variant of the DraggableCard
     * */
    sizeVariant: PropTypes.string.isRequired,

    /**
     * Whether the whole card should be draggable
     * */
    canDragWholeCard: PropTypes.bool,

    /**
     * Whether the card is loading
     * */
    isLoadingUpdateMenu: PropTypes.bool,
}

export default DraggableListVertical