import React from "react";

import {
  getBrandLoadingLogo,
  getBrandLoadingText,
} from "utils/branding-helper";

const BrandLogo = getBrandLoadingLogo();
const BrandText = getBrandLoadingText();

import "./WelcomeClient.scss";
const WelcomeClient = () => {
  return (
    <div id="WelcomeClient">
      <div className="WelcomeClientContent">
        <div className="WelcomeClientContentLogoContainer">
          <BrandLogo className="WelcomeClientContentLogo" />
        </div>

        <div className="WelcomeClientContentTextContainer">
          <BrandText className="WelcomeClientContentText" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeClient;
