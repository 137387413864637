import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { resetAllReduxStores } from "utils/general";
import InputControl from "components/admin/forms/input-control/InputControl";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import Login from "components/admin/elements/login/Login";

import "./ResetPassword.scss";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    newPassword: { value: "", hasError: false },
    confirmPassword: { value: "", hasError: false },
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    resetAllReduxStores(dispatch);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newPasswordHasError = !formData.newPassword.value.trim();
    const confirmPasswordHasError = !formData.confirmPassword.value.trim();

    setFormData((prevData) => ({
      ...prevData,
      password: { ...prevData.newPassword, hasError: newPasswordHasError },
      confirmPassword: {
        ...prevData.confirmPassword,
        hasError: confirmPasswordHasError,
      },
    }));

    if (!newPasswordHasError && !confirmPasswordHasError) {
      // try {
      //     const response = await dispatch(
      //         signin({
      //             email: formData.email.value,
      //             password: formData.password.value,
      //         })
      //     );
      //     if (response.error) {
      //         handleOnAsyncError();
      //     } else {
      //         handleOnAsyncSuccess(t("toastMessages.success.login"));
      //         response.payload?.user?.roles[0]?.name === ROLES.admin.name
      //             ? navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.super}`)
      //             : navigate(`${ROUTE_NAME.admin}${ROUTE_NAME.dashboard}`);
      //     }
      // } catch (err) {
      //     console.log(err);
      // }
    }
  };

  const handleErrorState = (name, value) => {
    const hasError = value.trim() === "";
    setFormData((prevData) => ({
      ...prevData,
      [name]: { value, hasError },
    }));
  };
  const handleOnInputChange = (e) => {
    const { name, value } = e.target;
    handleErrorState(name, value);
  };

  const handleOnInputBlur = (e) => {
    const { name, value } = e.target;
    handleErrorState(name, value);
  };

  const handleOnInputFocus = (e) => {
    const { name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], hasError: false },
    }));
  };

  return (
    <div className="ResetPassword">
      <Login
        title={t("login.resetPassword.title")}
        subTitle={t("login.resetPassword.subTitle")}
      >
        <form
          className="ResetPasswordForm"
          onSubmit={(e) => e.preventDefault()}
        >
          <InputControl
            onChange={handleOnInputChange}
            name="newPassword"
            type="password"
            placeholder={t("inputs.newPassword")}
            value={formData.newPassword.value}
            className="ResetPasswordFormInput"
            errorMessage={t("errorMessages.required.password")}
            hasError={formData.newPassword.hasError}
            onBlur={handleOnInputBlur}
            onFocus={handleOnInputFocus}
            noLabelFloating
            hasLabel
          />
          <InputControl
            onChange={handleOnInputChange}
            name="confirmPassword"
            type="password"
            placeholder={t("inputs.confirmPassword")}
            value={formData.confirmPassword.value}
            className="ResetPasswordFormInput"
            errorMessage={t("errorMessages.required.confirmPassword")}
            hasError={formData.confirmPassword.hasError}
            onBlur={handleOnInputBlur}
            onFocus={handleOnInputFocus}
            noLabelFloating
            hasLabel
          />
          <div className="ResetPasswordFormSubmitButton">
            <PrimaryButton
              onClick={handleSubmit}
              text={t("buttons.continue")}
              type={PRIMARY_BUTTON_ENUMS.types.TYPE_F}
            />
          </div>
        </form>
      </Login>
    </div>
  );
};

export default ResetPassword;
