import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomTooltip from "components/admin/elements/chart-tooltip/CustomTooltip";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";

import "./VerticalBarChart.scss";

// const RenderCustomAxisTick = ({ x, y, payload, data }) => {
//   let path = '';
//
//   // Find the corresponding data item for the current tick
//   const currentItem = data.find(item => item.name === payload.value);
//
//   // Set the emoji path from the data
//   let emojiPath = currentItem ? currentItem.emoji : '';
//
//
//   console.log(emojiPath)
//   return (
//       <svg x={x - 12} y={y + 4} width={24} height={24} viewBox="0 0 1024 1024" fill="#666">
//         <path d={emojiPath} />
//       </svg>
//   );
// };
const RenderCustomAxisTickForMenuStats = ({ x, y, payload }) => {
  const text = payload.value;
  const maxLineLength = 8;

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      let firstWord = text.split(/[\\,]/)[0];

      if (firstWord.length > maxLength) {
        firstWord = firstWord.substring(0, maxLength);
      }
      return `${firstWord}...`;
    }
  };

  // Truncate text if it exceeds max length
  const truncatedText = truncateText(text, maxLineLength);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#1F1F1F">
        {truncatedText}
      </text>
    </g>
  );
};

RenderCustomAxisTickForMenuStats.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

const VerticalBarChart = ({
  chartData,
  barSize = 25,
  chartWidth,
  showCustomTick = false,
  showYaxisCurrencySymbol = false,
}) => {
  const { t } = useTranslation();
  const [leftMargin, setLeftMargin] = useState(0);
  const currency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencyFormatter = (value) => `${value}${currency?.symbol}`;

  const maxValue = Math.max(
    ...chartData.data.map((item) =>
      Math.max(...chartData.meta.data.map((meta) => item[meta.dataKey]))
    )
  );
  const minValue = Math.min(
    ...chartData.data.map((item) =>
      Math.max(...chartData.meta.data.map((meta) => item[meta.dataKey]))
    )
  );

  const nearestMaxValue = Math.ceil(maxValue / 10) * 10;

  useEffect(() => {
    const maxLabelLength = String(maxValue).length;
    if (+maxLabelLength > 5) {
      setLeftMargin(+maxLabelLength * 8);
    }
  }, [chartData]);

  const getBarStyle = (dataKey) => {
    if (dataKey === "orders" || dataKey === "revenue") {
      return { fill: "#676767" };
    }
    if (
      dataKey === "orderItems" ||
      dataKey === "sale" ||
      dataKey === "totalSale"
    ) {
      return { fill: "#FFB92A" };
    }
    return { fill: "#B3B3B3" };
  };

  return (
    <div className="VerticalBarChart" style={{ width: chartWidth }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData.data}
          barGap={20}
          margin={{ left: leftMargin }}
        >
          <defs>
            <linearGradient
              id="invoiceGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#FD0" />
              <stop offset="100%" stopColor="#FBB034" />
            </linearGradient>
          </defs>
          <CartesianGrid
            strokeDasharray="7"
            stroke="#B3B3B3"
            vertical={false}
            strokeWidth={0.5}
          />
          <XAxis
            dataKey={chartData.meta.xAxisDataKey}
            axisLine={{ stroke: "none" }}
            interval={0}
            tick={
              showCustomTick ? (
                <RenderCustomAxisTickForMenuStats />
              ) : (
                { fill: "#1F1F1F" }
              )
            }

            //  tick={{ fill: "#1F1F1F" }}
          />
          <YAxis
            axisLine={{ stroke: "none" }}
            tick={{ fill: "#1F1F1F" }}
            tickFormatter={
              showYaxisCurrencySymbol ? currencyFormatter : undefined
            }
            domain={[0, nearestMaxValue]}
          />
          <Tooltip
            content={
              <CustomTooltip
                title={chartData.meta.xAxisDataKey}
                currencySymbol={
                  showYaxisCurrencySymbol ? currency?.symbol : undefined
                }
                t={t}
              />
            }
            cursor={false}
          />
          {chartData.meta.data.map((metaDataItem, index) => {
            return (
              <Bar
                key={index}
                dataKey={metaDataItem.dataKey}
                barSize={barSize}
                minPointSize={minValue}
                fill={
                  metaDataItem.dataKey === "orderItem"
                    ? "url(#invoiceGradient)"
                    : metaDataItem.fillColor || "url(#invoiceGradient)"
                }
                activeBar={getBarStyle(metaDataItem.dataKey)}
                radius={[5, 5, 0, 0]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

VerticalBarChart.propTypes = {
  barSize: PropTypes.number,
  /**
   * The chartData of the VerticalBarChart
   */

  chartData: PropTypes.shape({
    meta: PropTypes.shape({
      type: PropTypes.string.isRequired,
      xAxisDataKey: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          dataKey: PropTypes.string.isRequired,
          legendName: PropTypes.string,
          fillColor: PropTypes.string,
          strokeColor: PropTypes.string,
          stackId: PropTypes.string,
        })
      ),
    }).isRequired,
    data: PropTypes.array,
  }).isRequired,
  chartWidth: PropTypes.string,
  showCustomTick: PropTypes.bool,
  showYaxisCurrencySymbol: PropTypes.bool,
};

export default VerticalBarChart;
