import { createSlice } from "@reduxjs/toolkit";
import { STORE_NAMES } from "utils/constants/redux";
import {
  resendCode,
  signin,
  signup,
  updateUser,
  verifyUser,
} from "redux/actions/userAction";

export const initialState = {
  thunkAPIStates: {
    signin: false,
    verifyUser: false,
    signup: false,
    resendCode: false,
    updateUser: false,
  },
  user: null,
  token: "",
  error: null,
  userGeolocation: null,
  userLanguage: null,
  userCurrency: null,
  userWithPin: null,
  userUIPreferences: {
    shouldShowGuestAccountInfoModal: true,
  },
};

export const userStore = createSlice({
  name: STORE_NAMES.user,
  initialState: initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      return { ...state, userLanguage: payload };
    },
    setCurrency: (state, { payload }) => {
      return { ...state, userCurrency: payload };
    },
    setUserGeoLocation: (state, action) => {
      return { ...state, userGeolocation: action.payload };
    },
    setUserBusiness: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          business: {
            id: action.payload.businessId,
            menuId: action.payload.menuId,
          },
        },
      };
    },
    resetUserBusiness: (state) => {
      return {
        ...state,
        user: {
          ...state.user,
          business: null,
        },
      };
    },
    resetUserStore: (state) => {
      return {
        ...initialState,
        userLanguage: state.userLanguage,
      };
    },
    logOut: (state) => {
      return {
        ...initialState,
        userLanguage: state.userLanguage,
        user: null,
        token: "",
      };
    },
    setUserWithPin: (state, action) => {
      return { ...state, userWithPin: action.payload };
    },
    setShowAccountInfoModal: (state, action) => {
      return {
        ...state,
        userUIPreferences: {
          ...state.userUIPreferences,
          shouldShowGuestAccountInfoModal: action.payload,
        },
      };
    },
    updateUserInformation: (state, { payload }) => {
      let updatedState = { ...state };

      if (updatedState.user?.id === payload.id) {
        updatedState.user = { ...updatedState.user, ...payload };
      }

      if (updatedState.userWithPin?.id === payload.id) {
        updatedState.userWithPin = { ...updatedState.userWithPin, ...payload };
      }

      return updatedState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signin.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, signin: true },
      };
    });
    builder.addCase(signin.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, signin: false },
      };
    });
    builder.addCase(signin.fulfilled, (state, { payload }) => {
      return {
        ...state,
        token: payload.access_token,
        user: {
          ...payload.user,
          business: state?.user?.business,
          businesses: undefined,
        },
        thunkAPIStates: { ...state.thunkAPIStates, signin: false },
      };
    });
    builder.addCase(verifyUser.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, verifyUser: true },
      };
    });
    builder.addCase(verifyUser.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, verifyUser: false },
      };
    });
    builder.addCase(verifyUser.fulfilled, (state, { payload }) => {
      return {
        ...state,
        token: payload.access_token,
        user: {
          ...payload.user,
          business: state?.user?.business,
        },
        thunkAPIStates: { ...state.thunkAPIStates, verifyUser: false },
      };
    });
    builder.addCase(signup.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, signup: true },
      };
    });
    builder.addCase(signup.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, signup: false },
      };
    });
    builder.addCase(signup.fulfilled, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, signup: false },
      };
    });
    builder.addCase(resendCode.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, resendCode: true },
      };
    });
    builder.addCase(resendCode.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, resendCode: false },
      };
    });
    builder.addCase(resendCode.fulfilled, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, resendCode: false },
      };
    });
    builder.addCase(updateUser.pending, (state) => {
      return {
        ...state,
        thunkAPIStates: { ...state.thunkAPIStates, updateUser: true },
      };
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      return {
        ...state,
        error: payload,
        thunkAPIStates: { ...state.thunkAPIStates, updateUser: false },
      };
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      return {
        ...state,
        user: {
          ...payload,
          business: state.user.business,
          businesses: undefined,
        },
        thunkAPIStates: { ...state.thunkAPIStates, updateUser: false },
      };
    });
  },
});

export const {
  setLanguage,
  setCurrency,
  setUserGeoLocation,
  setUserBusiness,
  setShowAccountInfoModal,
  resetUserBusiness,
  resetUserStore,
  logOut,
  setUserWithPin,
  updateUserInformation,
} = userStore.actions;

export default userStore.reducer;
