import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./GuestComments.scss";

const GuestComments = ({ guest, showGuestTitle = true, className }) => {
  const { t } = useTranslation();

  return (
    <li key={guest.person.id} className={`GuestComments ${className}`}>
      {showGuestTitle && (
        <h6 className="h7 SemiBold GuestCommentsGuestNameAndGuestId">
          {guest.person.name ? guest.person.name : t("dashboard.guest.guest")}
          {" " + guest.person.id}
        </h6>
      )}

      <ol className="GuestAllComment">
        {guest.orderMessage.map((comment, commentIndex) => (
          <div key={commentIndex} className="GuestAllCommentCountAndContent">
            {guest.orderMessage.length > 1 && (
              <h6 className="Regular GuestAllCommentCount">
                {commentIndex + 1}
              </h6>
            )}

            <li className="Regular GuestAllCommentContent">{comment}</li>
          </div>
        ))}
      </ol>
    </li>
  );
};

GuestComments.propTypes = {
  guest: PropTypes.object.isRequired,
  showGuestTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default GuestComments;
