import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";

const useAuthCheck = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state[STORE_NAMES.user]?.user);
  const token = useSelector((state) => state[STORE_NAMES.user]?.token);

  useEffect(() => {
    if (!user?.id || !token) {
      navigate(ROUTE_NAME.signIn);
    } else {
      setIsAuthenticated(true);
    }
  }, [user, token, navigate]);

  return isAuthenticated;
};

export default useAuthCheck;
