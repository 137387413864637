import React from "react";
import PropTypes from "prop-types";

import AddIconButton, {
  ENUMS as ENUMS_ADD_ICON_BUTTON,
} from "components/buttons/add-icon-button/AddIconButton";
import FavoritesIconButton, {
  ENUMS as ENUMS_FAVORITES_ICON_BUTTON,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import LazyImage from "components/lazy-image/LazyImage";

import "./MenuViewGrid.scss";

export const ENUMS = {
  name: "MenuViewGrid",
};

const MenuViewGrid = ({
  menuItem,
  onAdd,
  onFavorite,
  onClick,
  favoriteItems,
  orderItems,
}) => {
  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);

  const { displayDataByLanguage } = useLanguage();

  return (
    <div className="MenuViewGrid" onClick={() => onClick(menuItem.id)}>
      <LazyImage
        className="MenuViewGridImg"
        src={menuItem?.coverImageSrc}
        placeholder={IMAGE_ITEM_PLACEHOLDER}
        alt={displayDataByLanguage(menuItem.name)}
      />
      <div className="MenuViewGridTopIcons">
        <FavoritesIconButton
          id={menuItem.id}
          type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_C}
          value={favoriteItems.includes(menuItem.id)}
          onClick={() => onFavorite(menuItem.id)}
        />
      </div>
      <div className="MenuViewGridBottomIcon">
        <div className="MenuViewGridPrice">
          <Price
            type={ENUMS_PRICE.types.PRICE_S}
            value={
              discountPrice !== null
                ? menuItemPrice + discountPrice
                : menuItemPrice
            }
          />
          {discountPrice !== null && (
            <Price
              type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
              value={menuItemPrice}
            />
          )}
        </div>

        <AddIconButton
          id={menuItem.id}
          type={ENUMS_ADD_ICON_BUTTON.types.TYPE_C}
          value={orderItems.reduce(
            (sum, orderItem) =>
              sum + (orderItem.item.id === menuItem.id ? orderItem.count : 0),
            0
          )}
          onClick={() => onAdd(menuItem)}
        />
      </div>
    </div>
  );
};

MenuViewGrid.propTypes = {
  /**
   * Menu item
   */
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    description: PropTypes.array,
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array,
    priceSell: PropTypes.number.isRequired,
    tags: PropTypes.array,
    modifications: PropTypes.array,
    rate: PropTypes.object.isRequired,
    isFixed: PropTypes.bool,
  }).isRequired,

  /**
   * Click event when click component
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Click event when click add button
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Click event handler when the "Favorite" button is clicked.
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * An array of favorite items.
   */
  favoriteItems: PropTypes.array,

  /**
   * An array of order items.
   */
  orderItems: PropTypes.array,
};

export default MenuViewGrid;
