import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ICON_PLUS from "assets/icons/math-operators/add/AddGrayThin.svg";
import ICON_MINUS from "assets/icons/math-operators/minus/Minus.svg";

import "./AnimatedPlusMinusButton.scss";

export const ENUMS = {
  name: "AnimatedPlusMinusButton",
  types: {
    MODIFICATION: "TYPE_MODIFICATION",
  },
};

const AnimatedPlusMinusButton = ({
  count = 1,
  hasBorder,
  maxCount,
  onPlusClick,
  onMinusClick,
  doAnimate,
  disableMinusButtonAtOne = false,
  type,
}) => {
  const formattedCount = count;
  const [showAnimatedCount, setShowAnimatedCount] = useState(false);
  const buttonClicked = useRef(null);
  const shouldDisableMinusButton =
    (disableMinusButtonAtOne && count === 1) || count === 0;
  const shouldDisablePlusButton = count === maxCount;

  const handleCountChange = () => {
    setShowAnimatedCount(true);
    setTimeout(() => {
      setShowAnimatedCount(false);
      buttonClicked.current = null;
    }, 500);
  };

  const handleButtonClick = (e, buttonType) => {
    e.stopPropagation();

    // Disable the minus button click and animation
    if (buttonType === "minus" && shouldDisableMinusButton) {
      return;
    }

    buttonClicked.current = buttonType;

    if (buttonType === "plus") {
      onPlusClick(e);
    } else if (buttonType === "minus") {
      onMinusClick(e);
    }
    handleCountChange();
  };

  return (
    <div
      className={cx("AnimatedPlusMinusButtonContainer", {
        hasBorder: hasBorder,
        ["showAnimatedCount"]: showAnimatedCount,
        [type]: type,
      })}
    >
      <button
        className={cx({
          ["isActive"]: buttonClicked.current === "minus" && doAnimate,
        })}
        disabled={shouldDisableMinusButton}
        onClick={(e) => handleButtonClick(e, "minus")}
        type="button"
      >
        <img src={ICON_MINUS} alt="minus" />
      </button>

      {type !== ENUMS.types.MODIFICATION && (
        <h5 className="AnimatedPlusMinusButtonPrice Medium">
          {formattedCount}
        </h5>
      )}

      <button
        className={cx({
          ["isActive"]: buttonClicked.current === "plus" && doAnimate,
        })}
        disabled={shouldDisablePlusButton}
        onClick={(e) => handleButtonClick(e, "plus")}
        type="button"
      >
        <img src={ICON_PLUS} alt="plus" />
      </button>

      {doAnimate && showAnimatedCount && type !== ENUMS.types.MODIFICATION && (
        <div className="AnimatedPlusMinusButtonAnimate">
          <h5>{formattedCount}</h5>
        </div>
      )}
    </div>
  );
};

AnimatedPlusMinusButton.propTypes = {
  /**
   * The count of order in the component
   */
  count: PropTypes.number,
  /**
   * The max count of order in the component
   */
  maxCount: PropTypes.number,

  /**
   * The function called when minus button is clicked
   */

  onMinusClick: PropTypes.func.isRequired,

  /**
   * The function called when plus button is clicked
   */

  onPlusClick: PropTypes.func.isRequired,
  /**
   *  Indicates whether the component should have a border or not
   */

  hasBorder: PropTypes.bool,

  /**
   *  Indicates whether the component should show animated count or not
   */

  doAnimate: PropTypes.bool,

  /**
   * Indicates whether the component should disable the minus button when the count is one.
   */

  disableMinusButtonAtOne: PropTypes.bool,

  type: PropTypes.oneOf([ENUMS.types.MODIFICATION]),
};

AnimatedPlusMinusButton.defaultProps = {
  hasBorder: false,
  doAnimate: false,
};

export default AnimatedPlusMinusButton;
