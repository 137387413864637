import axios from "./index";

// export const getQR = (formData, size, type) => axios.post(`/qr?size=${size}&type=${type}`, formData);
import { BACKEND_BASE_URL } from "utils/api/axios";

export const getQR = async (formData, size, type) => {
  const params = new URLSearchParams();
  if (size !== "") {
    params.set("size", size);
  }
  params.set("type", type);

  const queryParams = params.toString();
  try {
    const response = await axios.post(
      `${BACKEND_BASE_URL}/qr?${queryParams}`,
      formData,
      {
        responseType: "arraybuffer",
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
