import { getLanguageImage } from "utils/helpers";

export const filterAndSortMenu = (menu) => {
  const filteredCategories = menu.categories.map((category) => {
    const filteredMenuItems = category.menuItems
      ? category.menuItems.filter((item) => {
          item.modifications = item.modifications
            ? item.modifications
                .filter((item) => !item.isArchived)
                .map((modification) => {
                  const filteredOptions = modification.options.filter(
                    (option) => !option.isArchived
                  );
                  const sortedOptions = filteredOptions.sort(
                    (a, b) => new Date(b.createDate) - new Date(a.createDate)
                  );

                  return {
                    ...modification,
                    options: sortedOptions,
                  };
                })
            : [];
          item.modifications.sort(
            (a, b) => new Date(b.createDate) - new Date(a.createDate)
          );
          item.modifications.map((modification) => {
            return modification.options.sort(
              (a, b) => new Date(b.createDate) - new Date(a.createDate)
            );
          });
          return item;
        })
      : [];
    const sortedMenuItems = filteredMenuItems.sort(
      (a, b) => new Date(b.createDate) - new Date(a.createDate)
    );
    return {
      ...category,
      menuItems: sortedMenuItems,
    };
  });
  const sortedTags = menu.tags.sort(
    (a, b) => new Date(b.createDate) - new Date(a.createDate)
  );
  const editedCategories = filteredCategories.sort(
    (a, b) => new Date(b.createDate) - new Date(a.createDate)
  );
  return { ...menu, categories: editedCategories, tags: sortedTags };
};

export const filterArchivedCategories = (categories) => {
  return categories
    ? categories.filter((category) => !category.isArchived)
    : null;
};

export const addLanguageImageToTheMenu = (menu) => ({
  ...menu,
  language: {
    ...menu.language,
    imgSrc: getLanguageImage(menu.language.code),
  },
  availableLanguages:
    menu?.availableLanguages &&
    menu.availableLanguages.map((language) => ({
      ...language,
      imgSrc: getLanguageImage(language.code),
    })),
});
