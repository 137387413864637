import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./SmallButton.scss";

export const ENUMS = {
  name: "SmallButton",
  types: {
    TYPE_SEMI_BOLD: "TYPE_SEMI_BOLD",
    TYPE_REGULAR: "TYPE_REGULAR",
    TYPE_MEDIUM: "TYPE_MEDIUM",
    TYPE_MEDIUM_SMALLER: "TYPE_MEDIUM_SMALLER",
    TYPE_MEDIUM_UNDERLINED: "TYPE_MEDIUM_UNDERLINED",
  },
};

const SmallButton = ({ name, onClick, type }) => {
  return (
    <button className="SmallButton" onClick={onClick} type="button">
      {type !== ENUMS.types.TYPE_MEDIUM_SMALLER &&
        type !== ENUMS.types.TYPE_MEDIUM_UNDERLINED && (
          <h6
            className={cx({
              SemiBold: type === ENUMS.types.TYPE_SEMI_BOLD,
              Medium: type === ENUMS.types.TYPE_MEDIUM,
              Regular: type === ENUMS.types.TYPE_REGULAR,
            })}
          >
            {name}
          </h6>
        )}
      {type === ENUMS.types.TYPE_MEDIUM_SMALLER && (
        <p className="Medium h7">{name}</p>
      )}
      {type === ENUMS.types.TYPE_MEDIUM_UNDERLINED && (
        <h3 className="Underlined Medium">{name}</h3>
      )}
    </button>
  );
};

SmallButton.propTypes = {
  /**
   * The label for the button, which should be a string
   */
  name: PropTypes.string,

  /**
   * Function to handle clicks on the component
   */
  onClick: PropTypes.func,

  /**
   * The type of the button
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
};
export default SmallButton;
