import React from "react";
import PropTypes from "prop-types";
import RenderedAdminOrderList from "pages/admin/admin-pages/admin-order/admin-order-list/RenderedOrderList";

import "./AdminOrderList.scss";

const AdminOrderList = ({
  guests,
  users,
  isConfirmed,
  onConfirm,
  onRemoveOrderItem,
  onUpdateOrderItem,
  onEditOrderItem,
  onUndoOrderItem,
  dataType,
  groupedPendingOrderItems,
}) => {
  return (
    <div className="AdminOrderList">
      {users && (
        <RenderedAdminOrderList
          guests={users}
          isUser={true}
          isConfirmed={isConfirmed}
          onConfirm={onConfirm}
          onRemoveOrderItem={onRemoveOrderItem}
          onUpdateOrderItem={onUpdateOrderItem}
          onEditOrderItem={onEditOrderItem}
          onUndoOrderItem={onUndoOrderItem}
          dataType={dataType}
          groupedPendingOrderItems={groupedPendingOrderItems}
        />
      )}
      <RenderedAdminOrderList
        guests={guests}
        isConfirmed={isConfirmed}
        onConfirm={onConfirm}
        onRemoveOrderItem={onRemoveOrderItem}
        onUpdateOrderItem={onUpdateOrderItem}
        onEditOrderItem={onEditOrderItem}
        onUndoOrderItem={onUndoOrderItem}
        dataType={dataType}
        groupedPendingOrderItems={groupedPendingOrderItems}
      />
    </div>
  );
};

AdminOrderList.propTypes = {
  /**
   * An array of guest objects
   */
  guests: PropTypes.array.isRequired,

  /**
   * An array of users objects
   */
  users: PropTypes.array,

  /**
   * A boolean indicating whether the order is confirmed
   */
  isConfirmed: PropTypes.bool,

  /**
   * Function to handle order confirmation
   */
  onConfirm: PropTypes.func,

  /**
   * Function to handle removal of an order item
   */
  onRemoveOrderItem: PropTypes.func.isRequired,

  /**
   * Function to handle removal of an order item
   */
  onUndoOrderItem: PropTypes.func,

  /**
   * Function to handle update of an order item
   */
  onUpdateOrderItem: PropTypes.func.isRequired,

  /**
   * Function to handle editing of an order item
   */
  onEditOrderItem: PropTypes.func,

  /**
   * edited data type
   */
  dataType: PropTypes.string,

  groupedPendingOrderItems: PropTypes.object,
};
export default AdminOrderList;
