import React from "react";

import { useTranslation } from "react-i18next";
import { createDOBucketName } from "utils/DO-Spaces";
import { useSelector } from "react-redux";

import Band from "../under-construction/Band/Band";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./OfflineMenu.scss";

const OfflineMenu = () => {
  const { t } = useTranslation();
  const { business } = useSelector((state) => state[STORE_NAMES.business]);

  return (
    <div className="OfflineMenu">
      <div className="OfflineMenuBody">
        {business.images.logo && (
          <div className="OfflineMenuBodyLogo">
            <ImageWithPlaceholder
              imageSource={createDOBucketName(business.images.logo)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt="logo"
              className="OfflineMenuBodyLogoImage"
            />
          </div>
        )}
        <h1 className="OfflineMenuBodyText Medium">{business.name}</h1>
      </div>
      <div className="Bands">
        {[...Array(5)].map((_, index) => {
          return <Band key={index} text={t("general.comingSoon")} />;
        })}
      </div>
    </div>
  );
};

export default OfflineMenu;
