import { AXIOS } from "utils/api/axios";
import { ENDPOINTS, QUERY_PARAMS } from "../../constants/api";

export const getStatsOrderSale = async (businessId, startDate, endDate) =>
  AXIOS.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}${ENDPOINTS.sales}?${QUERY_PARAMS.startDate}=${startDate}&${QUERY_PARAMS.endDate}=${endDate}`
  );

export const getStatsOrderHistory = async (businessId, startDate, endDate) =>
  AXIOS.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}/?${QUERY_PARAMS.startDate}=${startDate}&${QUERY_PARAMS.endDate}=${endDate}`
  );

export const getStatsOrderHistoryTable = async (
  businessId,
  startDate,
  endDate,
  pageSize,
  pageNumber,
  sortBy,
  sortDir,
  zone,
  staff,
  orderId
) => {
  let queryString = `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}${ENDPOINTS.history}/?`;
  const queryParams = [
    { key: QUERY_PARAMS.startDate, value: startDate },
    { key: QUERY_PARAMS.endDate, value: endDate },
    { key: QUERY_PARAMS.pagination.size, value: pageSize },
    { key: QUERY_PARAMS.pagination.number, value: pageNumber },
    { key: QUERY_PARAMS.sort.by, value: sortBy },
    { key: QUERY_PARAMS.sort.dir, value: sortDir },
    { key: QUERY_PARAMS.zone, value: zone },
    { key: QUERY_PARAMS.staff, value: staff },
    { key: QUERY_PARAMS.orderId, value: orderId },
  ];

  // Filter out empty or undefined parameters
  const filteredParams = queryParams.filter(
    (param) => param.value !== undefined && param.value !== ""
  );

  // Construct the query string
  queryString += filteredParams
    .map((param) => `${param.key}=${param.value}`)
    .join("&");

  return AXIOS.get(queryString);
};

export const getStatsMenuStatistics = async (businessId, startDate, endDate) =>
  AXIOS.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.menu}/?${QUERY_PARAMS.startDate}=${startDate}&${QUERY_PARAMS.endDate}=${endDate}`
  );
export const getStatsMenuStatisticsTableData = async (
    businessId,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    sortBy,
    sortDir,
    itemName,
    categoryId
) => {
  let queryString = `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.menu}${ENDPOINTS.items}/?`;

  const queryParams = [
    { key: QUERY_PARAMS.startDate, value: startDate },
    { key: QUERY_PARAMS.endDate, value: endDate },
    { key: QUERY_PARAMS.pagination.size, value: pageSize },
    { key: QUERY_PARAMS.pagination.number, value: pageNumber },
    { key: QUERY_PARAMS.sort.by, value: sortBy },
    { key: QUERY_PARAMS.sort.dir, value: sortDir },
    { key: QUERY_PARAMS.itemName, value: itemName },
    { key: QUERY_PARAMS.categoryId, value: categoryId },
  ];

  // Filter out empty or undefined parameters
  const filteredParams = queryParams.filter(
      (param) => param.value !== undefined && param.value !== ""
  );

  // Construct the query string
  queryString += filteredParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

  return AXIOS.get(queryString);
};

export const getStatsStaff = async (businessId) =>
  AXIOS.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}`
  );
