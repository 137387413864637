import React from "react";
import PropTypes from "prop-types";

import "./AdminNavbarController.scss";

const AdminNavbarController = ({
  children,
  isNavbarOpen,
  setIsNavbarOpen,
  isTabletScreen,
  hamburgerMenu,
}) => {
  return (
    <>
      {isTabletScreen && isNavbarOpen && (
        <div
          className="AdminSidebarBackdrop"
          onClick={() => setIsNavbarOpen(false)}
        />
      )}
      {isTabletScreen && hamburgerMenu}
      {children}
    </>
  );
};

AdminNavbarController.propTypes = {
  children: PropTypes.node,
  isNavbarOpen: PropTypes.bool,
  setIsNavbarOpen: PropTypes.func,
  isTabletScreen: PropTypes.bool,
  hamburgerMenu: PropTypes.element,
};

export default AdminNavbarController;
