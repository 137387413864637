import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./MenuItemStatsCard.scss";

const MenuItemStatsCard = ({
  sequenceNumber,
  imageSource,
  name,
  primaryData,
  primaryDataName,
  secondaryData,
  secondaryDataName,
  ternaryData,
  ternaryDataName,
}) => {
  const [imgSrc, setImgSrc] = useState(imageSource || IMAGE_ITEM_PLACEHOLDER);
  const handleError = () => {
    setImgSrc(IMAGE_ITEM_PLACEHOLDER);
  };

  useEffect(() => {
    setImgSrc(imageSource || IMAGE_ITEM_PLACEHOLDER);
  }, [imageSource]);

  return (
    <div className="MenuItemStatsCard">
      <div className="MenuItemStatsCardInformation">
        <div className="MenuItemImageAndName">
          <h6 className="Medium MenuItemStatsCardIndex">
            {sequenceNumber + 1}
          </h6>
          <div className="MenuItemStatsCardImage">
            <img src={imgSrc} alt="stats card image" onError={handleError} />
          </div>
          <h6 className="Medium MenuItemStatsCardItemName" title={name}>
            {name.length > 30 ? name.slice(0, 30) + "..." : name}
          </h6>
        </div>

        <div className="MenuItemSaleAndRevenueInformation">
          <div className="MenuItemStatsPriceDetails">
            <h6 className="h7 Medium PriceDetailTitle">{secondaryDataName}:</h6>
            <h6 className="h7 Medium PriceDetailCount">{secondaryData}</h6>
          </div>

          <div className="MenuItemStatsPriceDetails">
            <h6 className="h7 Medium PriceDetailTitle">{ternaryDataName}:</h6>
            <h6 className="h7 Medium PriceDetailCount">{ternaryData}</h6>
          </div>
        </div>
      </div>

      <div className="MenuItemStatsCardOrderInformation">
        <h6 className="Medium OrderInformationTitle">{primaryDataName}:</h6>
        <h3 className="SemiBold OrderInformationCount">{primaryData}</h3>
      </div>
    </div>
  );
};

MenuItemStatsCard.propTypes = {
  sequenceNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageSource: PropTypes.string,
  name: PropTypes.string,
  primaryData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  primaryDataName: PropTypes.string,
  secondaryData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondaryDataName: PropTypes.string,
  ternaryData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ternaryDataName: PropTypes.string,
};

export default MenuItemStatsCard;
