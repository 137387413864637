import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import PropTypes from "prop-types";

import { ROUTE_NAME } from "utils/constants/routes";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "./CategorizedSlider.scss";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const CategorizedSlider = ({ title, allBusinesses }) => {
  const navigate = useNavigate();
  const [seeAll, setSeeAll] = useState(false);

  const shuffledBusinesses = useMemo(() => {
    return allBusinesses ? shuffleArray([...allBusinesses]) : [];
  }, [allBusinesses]);

  return (
    <div
      className={seeAll ? "CategorizedSlider inActive" : "CategorizedSlider"}
    >
      {shuffledBusinesses?.length > 0 && (
        <div className="CategorizedSliderHeader">
          <h2 className="Medium">{title}</h2>
          {shuffledBusinesses?.length > 3 && (
            <h5
              onClick={() => setSeeAll((prev) => !prev)}
              className="CategorizedSliderHeaderSeeAll Medium"
            >
              {seeAll ? "See Less" : " See All"}
            </h5>
          )}
        </div>
      )}

      <div
        // spaceBetween={seeAll ? 0 : 16}
        // slidesPerView="auto"
        // centeredSlides={!seeAll && true}
        // centeredSlidesBounds={!seeAll && true}
        // enabled={!seeAll}
        className="CategorizedSliderBody"
      >
        {shuffledBusinesses?.map((business, index) => {
          return (
            <div key={index} className="CategorizedSliderCardContainer">
              <div
                className="CategorizedSliderCard"
                onClick={() =>
                  navigate(
                    `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business.id}${ROUTE_NAME.menu}`
                  )
                }
              >
                <ImageWithPlaceholder
                  imageSource={createDOBucketName(business?.images.logo)}
                  placeholder={IMAGE_ITEM_PLACEHOLDER}
                  alt="logo"
                  className="CategorizedSliderCardImage"
                />
                {business?.name.length > 0 && (
                  <h6 className="CategorizedSliderCardTitle Medium">
                    {business?.name}
                  </h6>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CategorizedSlider.propTypes = {
  title: PropTypes.string,
  allBusinesses: PropTypes.array,
};

export default CategorizedSlider;
