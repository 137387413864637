import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { handleOnAsyncError, handleOnAsyncSuccess } from "utils/helpers";
import { updateBusinessSettingsAsync } from "redux/actions/businessAction";
import { isEqual } from "lodash";

import PageTitle from "components/elements/page-title/PageTitle";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Switch from "components/forms/switch/Switch";

import usePageEvents from "utils/hooks/useMixPanelEvents";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { STORE_NAMES } from "utils/constants/redux";
import { QUERY_PARAMS } from "utils/constants/routes";

// import AddNewButton, {
//   ENUMS as ENUMS_ADD_NEW_BUTTON,
// } from "components/admin/buttons/add-new-button/AddNewButton";
// import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
// import useOutsideClick from "utils/hooks/useOutsideClick";
// import AddItemModal from "components/admin/cards/add-item-modal/AddItemModal";

import "./AdminBusinessSettings.scss";

const SETTINGS_OPTIONS = {
  general: {
    goLive: "goLive",
    chat: "chat",
    // reservation: "reservation",
    // reservationMap: "reservationMap",
  },
  // order: {
  //   realTimeOrder: "realTimeOrder",
  // },
  sound: {
    // chatSound: "chatSound",
    orderSound: "orderSound",
    askForBillSound: "askForBillSound",
    callWaiterSound: "callWaiterSound",
  },
  menu: {
    menuItemCalorie: "menuItemCalorie",
    menuItemTime: "menuItemTime",
    menuItemAmount: "menuItemAmount",
  },
};

const AdminBusinessSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { saveEvent } = usePageEvents();

  let [searchParams, setSearchParams] = useSearchParams();
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState(false);

  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const isLoadingUpdateBusiness = useSelector(
    (state) => state[STORE_NAMES.business].thunkAPIStates?.updateBusiness
  );

  const [formData, setFormData] = useState({
    [SETTINGS_OPTIONS.general.goLive]: business?.goLive,
    [SETTINGS_OPTIONS.general.chat]: business?.chat,
    // [SETTINGS_OPTIONS.general.reservation]: business?.reservation,
    // [SETTINGS_OPTIONS.general.reservationMap]: business?.reservationMap,
    [SETTINGS_OPTIONS.menu.menuItemCalorie]: business?.menuItemCalorie,
    [SETTINGS_OPTIONS.menu.menuItemTime]: business?.menuItemTime,
    [SETTINGS_OPTIONS.menu.menuItemAmount]: business?.menuItemAmount,
    // [SETTINGS_OPTIONS.sound.chatSound]: business?.chatSound,
    [SETTINGS_OPTIONS.sound.orderSound]: business?.orderSound,
    [SETTINGS_OPTIONS.sound.askForBillSound]: business?.askForBillSound,
    [SETTINGS_OPTIONS.sound.callWaiterSound]: business?.callWaiterSound,
    // [SETTINGS_OPTIONS.order.realTimeOrder]: business?.realTimeOrder,
  });

  const previousBusinessUpdateSettings = useMemo(() => {
    return {
      [SETTINGS_OPTIONS.general.goLive]: business?.goLive,
      [SETTINGS_OPTIONS.general.chat]: business?.chat,
      // [SETTINGS_OPTIONS.general.reservation]: business?.reservation,
      // [SETTINGS_OPTIONS.general.reservationMap]: business?.reservationMap,
      [SETTINGS_OPTIONS.menu.menuItemCalorie]: business?.menuItemCalorie,
      [SETTINGS_OPTIONS.menu.menuItemTime]: business?.menuItemTime,
      [SETTINGS_OPTIONS.menu.menuItemAmount]: business?.menuItemAmount,
      // [SETTINGS_OPTIONS.sound.chatSound]: business?.chatSound,
      [SETTINGS_OPTIONS.sound.orderSound]: business?.orderSound,
      [SETTINGS_OPTIONS.sound.askForBillSound]: business?.askForBillSound,
      [SETTINGS_OPTIONS.sound.callWaiterSound]: business?.callWaiterSound,
      // [SETTINGS_OPTIONS.order.realTimeOrder]: business?.realTimeOrder,
    };
  }, [business]);

  const handleOnSwitchSettings = (key) => {
    setFormData({
      ...formData,
      [key]: !formData[key],
    });
  };

  // const [openSlidePrinter, setOpenSlidePrinter, mainElementRefPrinter] =
  //   useOutsideClick();
  // const [selectedPrinter, setSelectedPrinter] = useState(null);

  // const handleAddNewPrint = () => {
  //   setOpenSlidePrinter(true);
  //   setSelectedPrinter(null);
  // };
  //
  // const handleOnEditPrinter = (option) => {
  //   setSelectedPrinter(option);
  //   setOpenSlidePrinter(true);
  // };

  // const handleOnDeletePrinter = (id) => {
  // setFormData((prevData) => ({
  //   ...prevData,
  //   printers: prevData.printers.filter((printer) => printer.id !== id),
  // }));
  // };
  // const handleOnSavePrinter = async (data, id) => {
  //   const newPrinter = { name: data.name };
  //   if (id) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       printers: prevData.printers.map((printer) =>
  //         printer.id === id ? newPrinter : printer
  //       ),
  //     }));
  //   } else {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       printers: [
  //         ...prevData.printers,
  //         { ...newPrinter, id: new Date().getTime() },
  //       ],
  //     }));
  //   }
  //   setOpenSlidePrinter(false);
  // };

  const handleOnSave = async () => {
    const response = await dispatch(
      updateBusinessSettingsAsync({ business: formData, id: business.id })
    );
    if (response.error) {
      handleOnAsyncError(t(commonAsyncErrorMessage));
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.updateBusinessSettings"));
      saveEvent("Business Settings Updated");
    }
  };

  useEffect(() => {
    const unsavedChanges = !isEqual(formData, previousBusinessUpdateSettings);

    setHasUnSavedChanges(unsavedChanges);
    if (unsavedChanges) {
      setSearchParams({ ...searchParams, [QUERY_PARAMS.unsavedChanges]: true });
    } else {
      searchParams.delete(QUERY_PARAMS.unsavedChanges);
      setSearchParams(searchParams);
    }
  }, [formData, previousBusinessUpdateSettings]);

  const SettingsSwitch = ({ label, isChecked = false, onChange }) => (
    <div className="AdminBusinessSettingsSwitch">
      <h5 className="Medium">{label}</h5>
      <div className="SwitchInfo">
        <Switch isChecked={isChecked} onChange={onChange} />
        <h6 className={`Medium h7 ${isChecked ? "isActive" : ""}`}>
          {isChecked ? "Active" : "Inactive"}
        </h6>
      </div>
    </div>
  );
  return (
    <div className="AdminBusinessSettings">
      <PageTitle title={t("navbarRoutes.pageTitles.settings")}>
        <div className="AdminBusinessSettingsSaveButton">
          {hasUnSavedChanges && (
            <PrimaryButton
              text={t("buttons.save")}
              onClick={handleOnSave}
              isLoading={isLoadingUpdateBusiness}
            />
          )}
        </div>
      </PageTitle>
      <div className="AdminBusinessSettingsInfo">
        {Object.entries(SETTINGS_OPTIONS).map(([category, options]) => (
          <div key={category} className="AdminBusinessSettingsCategory">
            <h2 className="AdminBusinessSettingsCategoryTitle SemiBold">
              {t(`business.settings.${category}`)}
            </h2>
            {Object.entries(options).map(([key, label]) => (
              <SettingsSwitch
                key={key}
                optionKey={key}
                label={t(`business.settings.${label}`)}
                isChecked={formData[key]}
                onChange={() => handleOnSwitchSettings(key)}
              />
            ))}
          </div>
        ))}
      </div>
      {/*
        <div className="AdminBusinessSettingsAddItems">
          <div className="AdminBusinessInfoAboutPrintersAndWiFi">
            <div className="AdminBusinessInfoAboutPrintersAndWiFiTitle">
              <h4 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("business.printers")}
              </h4>
              <AddNewButton
                type={ENUMS_ADD_NEW_BUTTON.types.TYPE_B}
                onClick={handleAddNewPrint}
              />
            </div>
            {formData.printers.length > 0 ? (
              <div className="AdminBusinessInfoAboutPrintersAndWiFiList">
                <Tag
                  items={formData.printers}
                  type={ENUMS_TAG.types.TYPE_C}
                  isWrapped
                  onClick={handleOnEditPrinter}
                  onRemove={(option) => handleOnDeletePrinter(option.id)}
                />
              </div>
            ) : (
              <h6 className="AdminBusinessInfoAboutPrintersAndWiFiListMessage Medium">
                {t("emptyStates.noPrinters")}
              </h6>
            )}
          </div>
          <div className="AdminBusinessInfoAboutPrintersAndWiFi">
            <div className="AdminBusinessInfoAboutPrintersAndWiFiTitle">
              <h4 className="AdminBusinessInfoAboutFormGroupTitle SemiBold">
                {t("business.wiFi")}
              </h4>
              <AddNewButton
                type={ENUMS_ADD_NEW_BUTTON.types.TYPE_B}
                onClick={handleAddNewPrint}
              />
            </div>
            {formData.printers.length > 0 ? (
              <div className="AdminBusinessInfoAboutPrintersAndWiFiList">
                <Tag
                  items={formData.printers}
                  type={ENUMS_TAG.types.TYPE_C}
                  isWrapped
                  onClick={handleOnEditPrinter}
                  onRemove={(option) => handleOnDeletePrinter(option.id)}
                />
              </div>
            ) : (
              <h6 className="AdminBusinessInfoAboutPrintersAndWiFiListMessage Medium">
                {t("emptyStates.noWiFi")}
              </h6>
            )}
          </div>
        </div>
        */}
      {/*<AddItemModal
        mainElementRef={mainElementRefPrinter}
        setOpenSlide={setOpenSlidePrinter}
        title={t("business.printer")}
        item={selectedPrinter}
        openSlide={openSlidePrinter}
        onSave={handleOnSavePrinter}
        onDelete={handleOnDeletePrinter}
      />
      */}
    </div>
  );
};

export default AdminBusinessSettings;
