import React from "react";
import PropTypes from "prop-types";
import AnimatedPlusMinusButton from "components/buttons/animated-plus-minus-button/AnimatedPlusMinusButton";

import "./GuestCount.scss";
import { useTranslation } from "react-i18next";

export const ENUMS = {
  name: "GuestCount",
};
const GuestCount = ({ onMinus, onPlus, count = 0 }) => {
  const { t } = useTranslation();

  return (
    <div className="GuestCount">
      <div>
        <h6>{t("dashboard.guest.numberOfGuest")}</h6>
        <p>
          {count} {t("dashboard.guest.guest")}
        </p>
      </div>
      <AnimatedPlusMinusButton
        onMinusClick={onMinus}
        onPlusClick={onPlus}
        count={count}
        doAnimate
        disableMinusButtonAtOne
      />
    </div>
  );
};

GuestCount.propTypes = {
  onMinus: PropTypes.func.isRequired,
  onPlus: PropTypes.func.isRequired,
  count: PropTypes.number,
};

export default GuestCount;
