const useTagsWithIcon = (menuItem, displayDataByLanguage, isMultiLanguage) => {
  const initialTags = isMultiLanguage
    ? (menuItem?.tags || []).map((item) => ({
        ...item,
        name: displayDataByLanguage(item.name),
      }))
    : menuItem?.tags;

  const allTagsWithIcon = [...initialTags].filter((tag) => tag !== null);
  return { allTagsWithIcon };
};

export default useTagsWithIcon;
