import React from "react";
import PropTypes from "prop-types";

import EmptyStar from "assets/icons/emoji/EmptyStar.svg";
import FilledStar from "assets/icons/emoji/FilledStar.svg";
import "./Rating.scss";

export const ENUMS = {
  name: "Rating",
};

const Rating = ({ title, rating, onRatingChange }) => {
  const handleOnRate = (ratingValue) => {
    onRatingChange(ratingValue);
  };
  return (
    <div className="Rating">
      <h5 className="SemiBold">{title}</h5>
      <div className="RatingEmoji">
        {[
          { value: 1, src: rating >= 1 ? FilledStar : EmptyStar },
          { value: 2, src: rating >= 2 ? FilledStar : EmptyStar },
          { value: 3, src: rating >= 3 ? FilledStar : EmptyStar },
          { value: 4, src: rating >= 4 ? FilledStar : EmptyStar },
          { value: 5, src: rating >= 5 ? FilledStar : EmptyStar },
        ].map((emoji) => (
          <div
            key={emoji.value}
            className={`RatingEmojiItem ${
              rating === emoji.value ? "isFilled" : ""
            }`}
            onClick={() => handleOnRate(emoji.value)}
          >
            <img src={emoji.src} alt="emoji" />
          </div>
        ))}
      </div>
    </div>
  );
};

Rating.propTypes = {
  /**
   * The title of the rating component
   */
  title: PropTypes.string.isRequired,

  /**
   * The rating value
   */
  rating: PropTypes.number,

  /**
   * A function to handle rating changes
   */
  onRatingChange: PropTypes.func.isRequired,
};

export default Rating;
