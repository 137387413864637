import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";

const useLanguage = () => {
  const menuDefaultLanguageCode = useSelector(
    (state) => state[STORE_NAMES.menu].data?.language?.code
  );
  const userPreferredLanguageCode = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage?.code
  );

  const displayDataByLanguage = (value) => {
    return (
      value.find((p) => p.languageCode === userPreferredLanguageCode)?.value ||
      value.find((p) => p.languageCode === menuDefaultLanguageCode)?.value ||
      value.find((p) => p.value)?.value ||
      ""
    );
  };

  return { displayDataByLanguage };
};

export default useLanguage;
