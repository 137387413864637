import { AXIOS } from "utils/api/axios";
import { INVENTORY_CATEGORIES } from "utils/constants/data/sample-menu";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createInventoryCategory = async (businessId, category) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.inventoryCategories}`,
    category
  );

export const getInventoryCategories = async () => {
  return {
    data: INVENTORY_CATEGORIES,
  };
};
// AXIOS.get(`/business/${businessId}/inventory-categories`);

export const updateInventoryCategory = async (businessId, category, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.inventoryCategories}/${id}`,
    category
  );

export const deleteInventoryCategory = async (businessId, id) =>
  AXIOS.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.inventoryCategories}/${id}`
  );
