import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as AddItemIcon } from "assets/icons/math-operators/add/Plus.svg";

import "./AddItem.scss";

const AddItem = ({ handleAddItemClick, label }) => {
  const { t } = useTranslation();
  return (
    <div className="AddItem OrderCard" onClick={handleAddItemClick}>
      <AddItemIcon />
      {label && <h6 className={"SemiBold"}>{t("buttons.addItem")}</h6>}
    </div>
  );
};

AddItem.propTypes = {
  /**
   * on click function
   */
  handleAddItemClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default AddItem;
