import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { STORE_NAMES } from "utils/constants/redux";
import TextIconButton from "components/buttons/text-icon-button/TextIconButton";
import { ReactComponent as IconRemove } from "assets/icons/trash/Trash.svg";
import { ReactComponent as IconConfirm } from "assets/icons/confirm/confirm.svg";
import { deleteOrderAsync } from "redux/actions/orderActions";
import {
  findMenuCategoryByItemId,
  findZoneOfTable,
  getGuestsWithConfirmed,
  getUniqueItems,
  getUsersWithConfirmed,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import useOutsideClick from "utils/hooks/useOutsideClick";
import {
  removeFinishedOrder,
  setOrReplaceSelectedOrder,
} from "redux/slices/ordersStore";
import { deleteTopicForOrder } from "redux/slices/chatStore";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersPriceCost,
  calculateConfirmedOrderItemsCount,
  calculateMenuItemPrice,
  calculateMenuItemPriceBySchedule,
  calculateServiceFee,
} from "utils/general";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "utils/constants/routes";

const AdminOrderActions = ({
  setOpenSlide,
  selectedOrder,
  pendingData,
  handleOnUpdateOrder,
  formData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const { data: menu } = useSelector((state) => state[STORE_NAMES.menu]);

  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const activeZone = findZoneOfTable(zones, formData?.table?.id);
  const [
    openSlideConfirmFinishOrder,
    setOpenSlideConfirmFinishOrder,
    mainElementRefConfirmFinishOrder,
  ] = useOutsideClick();

  const [
    openSlideConfirmDeleteOrder,
    setOpenSlideConfirmDeleteOrder,
    mainElementRefConfirmDeleteOrder,
  ] = useOutsideClick();

  const orderEnums = useSelector(
    (state) => state[STORE_NAMES.app].enums
  )?.orderStatus;

  const handleOnAsyncErrorForOrder = (errorMessage) => {
    handleOnAsyncError(errorMessage || t(commonAsyncErrorMessage));
  };
  const handleOnDeleteOrder = () => {
    setOpenSlideConfirmDeleteOrder(true);
  };

  const handleOnConfirmFinishOrder = (e) => {
    e.stopPropagation();
    setOpenSlideConfirmFinishOrder(false);
    dispatch(deleteTopicForOrder({ orderId: selectedOrder.id }));
    const guests = getGuestsWithConfirmed(formData);
    const users = getUsersWithConfirmed(formData);
    const uniqueItemsIds = getUniqueItems([
      ...formData.guests,
      ...formData.users,
    ]);
    const menuItems = uniqueItemsIds.map((uniqueItem) => {
      const category = findMenuCategoryByItemId(menu.categories, uniqueItem);
      return {
        id: uniqueItem,
        category,
      };
    });

    const subTotal = menu ? calculateAllOrdersPrice([...guests, ...users]) : 0;

    const costTotal = menu
      ? calculateAllOrdersPriceCost([...guests, ...users])
      : 0;

    const calculatedDiscount = menu
      ? calculateAllOrdersDiscountPrice([...guests, ...users])
      : -1;

    const calculatedServiceFee = calculateServiceFee(
      subTotal,
      calculatedDiscount,
      serviceFee
    );

    const updatedUsers = formData.users.map((user) => {
      return {
        ...user,
        orderItems: user.orderItems.map((orderItem) => {
          return {
            ...orderItem,
            item: {
              ...orderItem.item,
              priceSell:
                calculateMenuItemPrice(orderItem.item) +
                calculateMenuItemPriceBySchedule(orderItem.item),
            },
          };
        }),
      };
    });

    const updatedGuests = formData.guests.map((user) => {
      return {
        ...user,
        orderItems: user.orderItems.map((orderItem) => {
          return {
            ...orderItem,
            item: {
              ...orderItem.item,
              priceSell:
                calculateMenuItemPrice(orderItem.item) +
                calculateMenuItemPriceBySchedule(orderItem.item),
            },
          };
        }),
      };
    });

    const totalPrice = subTotal + calculatedDiscount + calculatedServiceFee;

    const menuItemsCount = calculateConfirmedOrderItemsCount([
      ...guests,
      ...users,
    ]);

    handleOnUpdateOrder(
      {
        ...formData,
        status: orderEnums.finished,
        users: updatedUsers,
        guests: updatedGuests,
        finalOrderInfo: {
          guestsCount: formData.guests.length,
          menuItemsCount,
          zoneId: activeZone?.id,
          zoneName: activeZone?.name,
          currencyId: menu.currency.id,
          currencyCode: menu.currency.code,
          sellTotal: subTotal,
          costTotal,
          total: totalPrice,
          serviceFee: calculatedServiceFee,
          discount: calculatedDiscount,
          menuItems,
        },
      },
      () => {
        setOpenSlide(false);
        dispatch(removeFinishedOrder(selectedOrder.id));
        searchParams.delete(QUERY_PARAMS.selectedOrder);
        setSearchParams(searchParams);
      }
    );
  };
  const handleOnCancelFinishOrder = (e) => {
    e.stopPropagation();
    setOpenSlideConfirmFinishOrder(false);
  };

  const handleOnConfirmOrderDelete = async () => {
    dispatch(deleteTopicForOrder({ orderId: selectedOrder.id }));
    const response = await dispatch(
      deleteOrderAsync({ businessId, id: selectedOrder.id })
    );
    if (response.error) {
      handleOnAsyncErrorForOrder();
    } else {
      handleOnAsyncSuccess(t("toastMessages.success.deleteOrder"), async () => {
        await dispatch(setOrReplaceSelectedOrder([response.payload]));
        searchParams.delete(QUERY_PARAMS.selectedOrder);
        setSearchParams(searchParams);
      });
    }
  };

  const handleOnCancelOrderDelete = (e) => {
    e.stopPropagation();
    setOpenSlideConfirmDeleteOrder(false);
  };

  const handleOnFinishOrder = () => {
    const isAllOrdersCompleted =
      pendingData.guests.length === 0 && pendingData.users.length === 0;

    const isAnyGuestOrderItemsUnconfirmed = formData.guests.some((guest) =>
      guest.orderItems.some((item) => item.isConfirmed === null)
    );

    if (!isAllOrdersCompleted || isAnyGuestOrderItemsUnconfirmed) {
      return toast.error(t("errorMessages.confirmOrders"));
    }
    setOpenSlideConfirmFinishOrder(true);
  };

  return (
    <>
      <TextIconButton
        text={t("buttons.delete")}
        svgComponent={<IconRemove />}
        onClick={() => handleOnDeleteOrder()}
        className="AdminOrderPageHeaderDeleteIcon"
      />
      <TextIconButton
        text={t("buttons.closeOrder")}
        svgComponent={<IconConfirm />}
        onClick={() => handleOnFinishOrder()}
        className="AdminOrderPageHeaderFinishIcon"
      />
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_C}
        title={t("modal.warningModalTitleFinishOrder")}
        mainElementRefConfirm={mainElementRefConfirmFinishOrder}
        onCancel={(e) => handleOnCancelFinishOrder(e)}
        onConfirm={(e) => handleOnConfirmFinishOrder(e)}
        openSlide={openSlideConfirmFinishOrder}
      />
      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_A}
        title={t("modal.deleteModalTitle")}
        description={t("modal.deleteModalDescription")}
        mainElementRefConfirm={mainElementRefConfirmDeleteOrder}
        onCancel={(e) => handleOnCancelOrderDelete(e)}
        onConfirm={(e) => handleOnConfirmOrderDelete(e)}
        openSlide={openSlideConfirmDeleteOrder}
      />
    </>
  );
};

AdminOrderActions.propTypes = {
  setOpenSlide: PropTypes.func,
  handleOnUpdateOrder: PropTypes.func,
  selectedOrder: PropTypes.object,
  pendingData: PropTypes.object,
  formData: PropTypes.object,
};

export default AdminOrderActions;
