import React from "react";
import AdminMap from "pages/admin/admin-pages/admin-qr-table-map/admin-map/AdminMap";

import "./AdminQrTableMap.scss";

const AdminQrTableMap = () => {
  return (
    <div className="AdminQrTableMap">
      <AdminMap />
    </div>
  );
};

export default AdminQrTableMap;
