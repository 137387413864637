import React from "react";
import PropTypes from "prop-types";
import FavoritesIconButton, {
  ENUMS as ENUMS_FAVORITES_ICON_BUTTON,
} from "components/buttons/favorites-icon-button/FavoritesIconButton";
import AddIconButton, {
  ENUMS as ENUMS_ADD_ICON_BUTTON,
} from "components/buttons/add-icon-button/AddIconButton";
import Tag, { ENUMS as ENUMS_TAG } from "components/elements/tag/Tag";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import useTags from "utils/hooks/useTags";
import TagWithIcon, {
  ENUMS as TAG_ENUMS_WITH_ICON,
} from "components/elements/tag-with-icon/TagWithIcon";
import useTagsWIcon from "utils/hooks/useTagsWithIcon";
import LazyImage from "components/lazy-image/LazyImage";

import "./MenuViewList.scss";

export const ENUMS = {
  name: "MenuViewList",
};

const MenuViewList = ({
  menuItem,
  onAdd,
  onFavorite,
  onClick,
  favoriteItems = [],
  orderItems = [],
}) => {
  const { displayDataByLanguage } = useLanguage();
  const discountPrice = calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);
  const { allTags } = useTags(menuItem, true);
  const { allTagsWithIcon } = useTagsWIcon(
    menuItem,
    displayDataByLanguage,
    true
  );
  const menuItemName = displayDataByLanguage(menuItem.name);

  return (
    <div onClick={() => onClick(menuItem.id)} className="MenuViewList">
      <div className="MenuViewListLeft">
        <h5 className="MenuViewListName Medium">{menuItemName}</h5>
        {allTags.length > 0 && (
          <Tag items={allTags} type={ENUMS_TAG.types.TYPE_A} />
        )}
        <div className="h7 Medium MenuViewListDescription">
          {displayDataByLanguage(menuItem.description)}
        </div>
        {allTagsWithIcon.length > 0 && (
          <div className="MenuViewListTagsWithIcon">
            <TagWithIcon
              type={TAG_ENUMS_WITH_ICON.types.TYPE_A}
              items={allTagsWithIcon}
            />
          </div>
        )}

        <div className="MenuViewListPrice">
          <Price
            type={ENUMS_PRICE.types.PRICE_M_BOLD}
            value={
              discountPrice !== null
                ? menuItemPrice + discountPrice
                : menuItemPrice
            }
          />
          {discountPrice !== null && (
            <Price
              type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
              value={menuItemPrice}
            />
          )}
        </div>
      </div>

      <div className="MenuViewListRight">
        <LazyImage
          className="MenuViewListImg"
          src={menuItem?.coverImageSrc}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={menuItemName}
        />
        {/*<img*/}
        {/*  className="MenuViewListImg"*/}
        {/*  src={menuItem.coverImageSrc || IMAGE_ITEM_PLACEHOLDER}*/}
        {/*  alt={menuItemName}*/}
        {/*/>*/}
        <div className="MenuViewListAddButton">
          <AddIconButton
            id={menuItem.id}
            onClick={() => onAdd(menuItem)}
            type={ENUMS_ADD_ICON_BUTTON.types.TYPE_C}
            value={orderItems.reduce(
              (sum, orderItem) =>
                sum + (orderItem.item.id === menuItem.id ? orderItem.count : 0),
              0
            )}
            hasListViewBorder
          />
        </div>
        <div className="MenuViewListFavoritesButton">
          <FavoritesIconButton
            id={menuItem.id}
            type={ENUMS_FAVORITES_ICON_BUTTON.types.TYPE_C}
            onClick={() => onFavorite(menuItem.id)}
            value={favoriteItems.includes(menuItem.id)}
            hasListViewBorder
          />
        </div>
      </div>
    </div>
  );
};

MenuViewList.propTypes = {
  /**
   * Menu item
   */
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    description: PropTypes.array,
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array,
    priceSell: PropTypes.number.isRequired,
    tags: PropTypes.array,
    modifications: PropTypes.array,
    rate: PropTypes.object.isRequired,
    isFixed: PropTypes.bool,
  }).isRequired,

  /**
   * Add action
   */
  onAdd: PropTypes.func.isRequired,

  /**
   * Favorite action
   */
  onFavorite: PropTypes.func.isRequired,

  /**
   * Component click action
   */
  onClick: PropTypes.func.isRequired,

  /**
   * An array of favorite items.
   */
  favoriteItems: PropTypes.array,

  /**
   * An array of order items.
   */
  orderItems: PropTypes.array,
};

export default MenuViewList;
