import React from "react";
import PropTypes from "prop-types";

import "./Button.scss";

const Button = ({
  onClick,
  IconLeft,
  IconRight,
  text,
  className,
  type = "button",
  disable,
}) => {
  return (
    <button
      className={`CustomButton ${className}`}
      onClick={onClick}
      type={type}
      disabled={disable}
    >
      {IconLeft}
      {text}
      {IconRight}
    </button>
  );
};

Button.propTypes = {
  /**
   * The SVG component to be displayed as the button's text left.
   */
  IconLeft: PropTypes.node,

  /**
   * The SVG component to be displayed as the button's text right.
   */
  IconRight: PropTypes.node,

  /**
   * The callback function to be called when the button is clicked.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * The text of button
   */
  text: PropTypes.node,
  /**
   * The type of button
   */
  type: PropTypes.string,
  /**
   * The classes of button
   */
  className: PropTypes.string,

  disable: PropTypes.bool,
};

export default Button;
