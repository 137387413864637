import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as AddIcon } from "assets/icons/math-operators/add/PlusAdmin.svg";

import "./AddButton.scss";

export const ENUMS = {
  name: "AddButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
  },
};

const AddButton = ({ type, onClick }) => {
  return (
    <button
      className={cx("AddButton", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        typeC: type === ENUMS.types.TYPE_C,
        typeD: type === ENUMS.types.TYPE_D,
      })}
      onClick={onClick}
      type="button"
    >
      <AddIcon className="AddButtonIcon" />
    </button>
  );
};

AddButton.propTypes = {
  /**
   * The types of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),

  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,
};

export default AddButton;
