import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useLanguage from "utils/hooks/useLanguage";

import ModificationItem, {
  ENUMS as MODIFICATION_ENUMS,
} from "components/elements/modification-item/ModificationItem";
import EditButtonPrimary, {
  ENUMS as ENUMS_EDIT_BUTTON_PRIMARY,
} from "components/admin/buttons/edit-button-primary/EditButtonPrimary";
import DeleteButton, {
  ENUMS as ENUMS_DELETE_BUTTON,
} from "components/buttons/delete-button/DeleteButton";

import "./ModificationsMenu.scss";

export const ENUMS = {
  name: "ModificationsMenu",
  actions: {
    INCREMENT: "Increment",
    DECREMENT: "Decrement",
  },
};

const ModificationsMenu = ({
  data,
  selectedOptions,
  setSelectedModifications,
  setFocusedModificationOption,
  focusedModificationOption,
  onEdit,
  onDelete,
  hasControls,
  isReadOnly,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const { t } = useTranslation();
  const handleSelect = (selectedOption, count) => {
    setFocusedModificationOption(selectedOption.id);
    if (selectedOptions) {
      setSelectedModifications((prevModifications) =>
        prevModifications.map((modification) => {
          if (modification.id === selectedOptions.id) {
            const updatedOptions = modification.options.filter(
              (option) => option.id !== selectedOption.id
            );
            if (modification.isMultiSelect) {
              if (updatedOptions.length === modification.options.length) {
                updatedOptions.push({ ...selectedOption, count: 1 });
              } else if (selectedOption.isCountable) {
                updatedOptions.push({ ...selectedOption, count: count });
              }
              return { ...modification, options: updatedOptions };
            } else if (updatedOptions.length === 0 && modification.isRequired) {
              return modification;
            }
            if (
              selectedOptions.options.some(
                (option) => option.id === selectedOption.id
              )
            ) {
              return {
                ...modification,
                options: [],
              };
            }
            return {
              ...modification,
              options: [{ ...selectedOption, count: 1 }],
            };
          }
          return modification;
        })
      );
    } else {
      setSelectedModifications((prev) => [
        ...prev,
        { ...data, options: [{ ...selectedOption, count: 1 }] },
      ]);
    }
  };
  const updateOptionCount = (id, action) => {
    setFocusedModificationOption(id);
    setSelectedModifications((prevModifications) =>
      prevModifications.map((modifications) => {
        if (modifications.id === selectedOptions.id) {
          const updatedOptions = modifications.options
            .map((option) => {
              if (option.id === id) {
                return {
                  ...option,
                  count:
                    action === ENUMS.actions.INCREMENT
                      ? option.count + 1
                      : option.count - 1,
                };
              }
              return option;
            })
            .filter((filteredOption) => filteredOption.count > 0);
          return { ...modifications, options: updatedOptions };
        }
        return modifications;
      })
    );
  };

  return (
    <div className="ModificationsMenu">
      <div className="ModificationsMenuHeader">
        <h6 className="Medium">
          {displayDataByLanguage(data.name)}
        </h6>
        {hasControls ? (
          <div className="ModificationsMenuHeaderControls">
            <EditButtonPrimary
              onClick={onEdit}
              type={ENUMS_EDIT_BUTTON_PRIMARY.types.TYPE_B}
            />
            <DeleteButton
              onClick={onDelete}
              type={ENUMS_DELETE_BUTTON.types.TYPE_B}
            />
          </div>
        ) : (
          <span>
            {data.options.length} {t("modification.selection.selection")}
          </span>
        )}
      </div>
      <ul>
        {data.options.map((option, index) => {
          const selectedOption = selectedOptions?.options.find(
            (selected) => selected.id === option.id
          );
          const count = selectedOption ? selectedOption.count : 0;

          return (
            <li key={index}>
              <ModificationItem
                className="ModificationsMenuLi"
                type={
                  option.isCountable
                    ? MODIFICATION_ENUMS.types.COUNTABLE
                    : MODIFICATION_ENUMS.types.UNCOUNTABLE
                }
                onClick={() => handleSelect(option, count)}
                count={count}
                maxCount={option.maxNumber}
                onPlus={() =>
                  updateOptionCount(option.id, ENUMS.actions.INCREMENT)
                }
                onMinus={() =>
                  updateOptionCount(option.id, ENUMS.actions.DECREMENT)
                }
                option={option}
                focusedModificationOption={focusedModificationOption}
                isReadOnly={isReadOnly}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const dataPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      priceSell: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      priceCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isCountable: PropTypes.bool.isRequired,
      name: PropTypes.array.isRequired,
    })
  ).isRequired,
});

ModificationsMenu.propTypes = {
  /**
   * An object of array representing the list of items in the combo menu.
   */
  data: dataPropType.isRequired,
  selectedOptions: PropTypes.object,
  setSelectedModifications: PropTypes.func,
  setFocusedModificationOption: PropTypes.func,
  focusedModificationOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),

  /**
   * Function to handle the "Edit" action.
   */
  onEdit: PropTypes.func,

  /**
   * Function to handle the "Delete" action.
   */
  onDelete: PropTypes.func,

  /**
   * A boolean flag indicating whether the component should display controls (Edit and Delete buttons).
   */
  hasControls: PropTypes.bool,

  /**
   * A boolean flag indicating whether the component is read-only
   */
  isReadOnly: PropTypes.bool,
};

export default ModificationsMenu;
