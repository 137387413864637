import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_ADD from "assets/icons/math-operators/add/Add.svg";
import ICON_ADD_SMALL from "assets/icons/math-operators/add/AddSmall.svg";

import "./AddIconButton.scss";

export const ENUMS = {
  name: "AddIconButton ",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C"
  },
};

const AddIconButton = ({ id, type, value, onClick, hasListViewBorder }) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick(id, value);
  };

  return (
    <button
      onClick={(e) => handleOnClick(e)}
      className={cx(
        "AddIconButton",
        {
          typeA: type === ENUMS.types.TYPE_A,
        },
        {
          typeB: type === ENUMS.types.TYPE_B,
        },
        {
          typeC: type === ENUMS.types.TYPE_C,
        },
        {
          menuListViewBorder: hasListViewBorder,
        }
      )}
      type="button"
    >
      <img
        src={type === ENUMS.types.TYPE_C ? ICON_ADD_SMALL : ICON_ADD}
        alt="add"
      />
      {value !== 0 && <span className="AddIconButtonBadge">{value}</span>}
    </button>
  );
};

AddIconButton.propTypes = {
  /**
   * The id of the component
   */
  id: PropTypes.number.isRequired,

  /**
   * The types of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C
  ]),

  /**
   * The value of the component
   */
  value: PropTypes.number.isRequired,

  /**
   * The function called when component clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * check if the component has list view border
   */
  hasListViewBorder: PropTypes.bool,
};

AddIconButton.defaultProps = {
  type: ENUMS.types.TYPE_A,
};
export default AddIconButton;
