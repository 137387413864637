import React, { useRef } from "react";
import PropTypes from "prop-types";

import { formatTimeToHHMM } from "utils/helpers";
import { RESERVATION_STATUS } from "utils/constants/data/base";
import ICON_ARROW from "assets/icons/arrows/AdminArrowLeft.svg";

import "./ReservationTableView.scss";

const ReservationTableView = ({ openReservationEditor, tables }) => {
  const tableRef = useRef(null);
  const tableIdsArray = tables?.map((table) => table.id);
  const handleTableScroll = (direction) => {
    const scrollAmount = 78;

    if (tableRef.current) {
      if (direction === "left") {
        tableRef.current.scrollLeft -= scrollAmount;
      } else if (direction === "right") {
        tableRef.current.scrollLeft += scrollAmount;
      }
    }
  };

  const generateTimeSlots = () => {
    const timeSlots = [];
    let currentTime = 9;
    let currentMinutes = 0;
    while (currentTime < 23 || (currentTime === 23 && currentMinutes === 0)) {
      const timeString = `${currentTime
        .toString()
        .padStart(2, "0")}:${currentMinutes.toString().padStart(2, "0")}`;
      timeSlots.push(timeString);

      // Increment the time by 30 minutes
      currentMinutes += 30;
      if (currentMinutes === 60) {
        currentTime += 1;
        currentMinutes = 0;
      }
    }
    return timeSlots;
  };

  const timeSlotsData = generateTimeSlots();
  const filteredTimeSlots = timeSlotsData.filter((timeSlot) =>
    timeSlot.endsWith(":00")
  );

  const timeToGridIndices = (time) => {
    const formattedTime = formatTimeToHHMM(new Date(time));
    const index = timeSlotsData.indexOf(formattedTime);
    return index >= 0 ? index + 1 : null;
  };

  return (
    <div className="ReservationTableView">
      <button
        className="TableScrollToRight ReservationTableScrollBtn"
        onClick={() => handleTableScroll("right")}
        type="button"
      >
        <img src={ICON_ARROW} alt="scroll to right" />
      </button>
      <button
        className="TableScrollToLeft ReservationTableScrollBtn"
        onClick={() => handleTableScroll("left")}
        type="button"
      >
        <img src={ICON_ARROW} alt="scroll to left " />
      </button>
      <div className="ReservationTable" ref={tableRef}>
        <div className="ReservationTableGrid">
          <div className="ReservationTableNames">
            {tableIdsArray.map((tableId) => {
              const table = tables.find((t) => t.id === tableId);

              return (
                <div key={tableId} className="TableName">
                  {table?.name ? table?.name : table?.id}
                </div>
              );
            })}
          </div>
          <div className="ReservationTableContent">
            <div className="ReservationTableTimeSlots">
              {filteredTimeSlots.map((timeSlot, i) => (
                <div key={`time_${i}`} className="timeSlot">
                  <span> {timeSlot} </span>
                </div>
              ))}
            </div>
            <div className="ReservationTableRowsWrapper">
              {tableIdsArray.map((tableId) => {
                const table = tables.find((t) => t.id === tableId);

                return (
                  <div
                    key={table.id}
                    className="ReservationTableRow"
                    style={{
                      gridTemplateColumns: `repeat(${timeSlotsData.length}, minmax(39px, auto))`,
                    }}
                  >
                    {table.reservations
                      ?.filter(
                        (reservation) =>
                          reservation.status === RESERVATION_STATUS.CONFIRMED
                      )
                      .map((reservation) => (
                        <div
                          key={reservation.id}
                          className="ReservationTableReservation"
                          style={{
                            gridColumnStart: timeToGridIndices(
                              reservation.startDateTime
                            ),
                            gridColumnEnd: timeToGridIndices(
                              reservation.endDateTime
                            ),
                          }}
                          onClick={() =>
                            openReservationEditor(reservation.id, table.id)
                          }
                        >
                          {reservation.guestName}
                        </div>
                      ))}
                  </div>
                );
              })}

              {filteredTimeSlots.map((timeSlot, index) => (
                <div
                  key={`border_${index}`}
                  className="TimeSlotBorder"
                  style={{
                    left: `${index * 78}px`,
                    backgroundColor: index === 0 ? "transparent" : "#efeded",
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReservationTableView.propTypes = {
  openReservationEditor: PropTypes.func.isRequired,
  tables: PropTypes.array.isRequired,
};

export default ReservationTableView;
