import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ReservationTableView from "pages/admin/admin-pages/admin-reservation/reservation-table/ReservationTableView";
import AdminPendingBooksModal from "./reservation-controller/admin-reservation-books/AdminPendingBooksModal";
import AdminReservationHeader from "./reservation-header/AdminReservationHeader";
import AdminReservationEditorModal from "./reservation-controller/admin-reservation-modal/AdminReservationEditorModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { EDITOR_TYPE } from "pages/admin/data/constants/general";
import { RESERVATION_STATUS } from "utils/constants/data/base";
import { STORE_NAMES } from "utils/constants/redux";

import "./AdminReservation.scss";

const AdminReservation = () => {
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const [activeZone, setActiveZone] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateTables, setSelectedDateTables] = useState([]);
  const [
    openCreateReservationModal,
    setOpenCreateReservationModal,
    mainElementRefCreateReservation,
  ] = useOutsideClick();
  const [
    openEditReservationModal,
    setOpenEditReservationModal,
    mainElementRefEditReservation,
  ] = useOutsideClick();
  const [openPendingBooks, setOpenPendingBooks, mainElementRefPendingBooks] =
    useOutsideClick();
  const { t } = useTranslation();
  const activeZoneTables = activeZone?.tables || [];
  useEffect(() => {
    if (!activeZone) {
      setActiveZone(zones[0]);
    } else {
      setActiveZone(zones.find((zone) => zone.id === activeZone.id));
    }
  }, [zones]);

  useEffect(() => {
    // Filter tables with reservations for the selected date
    const filteredTables = activeZoneTables?.map((table) => ({
      ...table,
      reservations: (table.reservations || []).filter((reservation) => {
        const reservationDate = new Date(reservation.startDateTime);
        return (
          reservationDate.getDate() === selectedDate.getDate() &&
          reservationDate.getMonth() === selectedDate.getMonth()
        );
      }),
    }));
    setSelectedDateTables(filteredTables);
  }, [selectedDate, zones, activeZone]);

  const showTableReservations =
    activeZoneTables && activeZoneTables.length > 0 && selectedDateTables;
  const allReservations = selectedDateTables?.flatMap(
    (table) => table.reservations || []
  );
  const pendingReservations = allReservations?.filter(
    (reservation) => reservation.status === RESERVATION_STATUS.PENDING
  );

  const handleOpenReservationEditor = (reservationId) => {
    const selectedReservation = allReservations.find(
      (reservation) => reservation.id === reservationId
    );

    if (selectedReservation) {
      setSelectedReservation(selectedReservation);
      setOpenEditReservationModal(true);
    }
  };

  const handleOnDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="AdminReservation">
      <h2 className="AdminReservationTitle SemiBold">
        {t("navbarRoutes.reservation")}
      </h2>
      <AdminReservationHeader
        selectedDate={selectedDate}
        selectedZone={activeZone}
        pendingReservations={pendingReservations}
        setSelectedZone={setActiveZone}
        onAddNewReservation={() => setOpenCreateReservationModal(true)}
        openPendingBooks={() => {
          setOpenPendingBooks(true);
        }}
        onDateChange={handleOnDateChange}
      />
      {showTableReservations && (
        <ReservationTableView
          tables={selectedDateTables}
          openReservationEditor={handleOpenReservationEditor}
        />
      )}
      <AdminReservationEditorModal
        mainElementRef={mainElementRefCreateReservation}
        openSlide={openCreateReservationModal}
        setOpenSlide={setOpenCreateReservationModal}
        title={t("reservation.createReservation")}
        formType={EDITOR_TYPE.CREATE}
      />
      <AdminReservationEditorModal
        mainElementRef={mainElementRefEditReservation}
        openSlide={openEditReservationModal}
        setOpenSlide={setOpenEditReservationModal}
        title={t("reservation.editReservation")}
        formType={EDITOR_TYPE.EDIT}
        data={selectedReservation}
      />
      <AdminPendingBooksModal
        mainElementRef={mainElementRefPendingBooks}
        openSlide={openPendingBooks}
        setOpenSlide={setOpenPendingBooks}
        data={pendingReservations}
        title={t("reservation.pendingBooks")}
      />
    </div>
  );
};

export default AdminReservation;
