import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PieChartWithNeedle from "components/admin/charts/pie-chart-with-needle/PieChartWithNeedle";

import "./StatsPieChartWithNeedleWrapper.scss";

const FULLNESS_TABLE_ENUMS = [
  { name: "A", value: 90, color: "#F34237" },
  { name: "B", value: 54, color: "#FFD43C" },
  { name: "C", value: 36, color: "#4CBF4A" },
];

const StatsPieChartWithNeedleWrapper = ({
  totalGuestsOfOrdersByActiveZone,
  fullTablesOfActiveZone,
  allTablesOfActiveZone,
}) => {
  const { t } = useTranslation();

  const needleValue =
    parseInt((fullTablesOfActiveZone.length / allTablesOfActiveZone) * 180) ||
    0;

  return (
    <div className="StatsPieChartWithNeedleWrapper">
      <div className="AdminOrderMapGuestsInfo">
        <div className="AdminOrderMapGuestsInfoInner">
          <h4 className="SemiBold AdminOrderMapGuestsInfoInnerCount">
            {totalGuestsOfOrdersByActiveZone}
          </h4>
          <h6 className="h7">
            {totalGuestsOfOrdersByActiveZone > 1
              ? t("orders.guests")
              : t("orders.guest")}
          </h6>
        </div>
      </div>
      <div className="AdminOrderMapGuestsInfo">
        <div className="AdminOrderMapGuestsInfoInner">
          <h4 className="SemiBold AdminOrderMapGuestsInfoInnerCount">
            {fullTablesOfActiveZone.length}
          </h4>
          <h6 className="h7">
            {fullTablesOfActiveZone.length > 1
              ? t("orders.orders")
              : t("orders.order")}
          </h6>
        </div>
      </div>
      <div className="AdminOrderFullnessOfTable">
        <PieChartWithNeedle
          data={FULLNESS_TABLE_ENUMS}
          needleValue={needleValue}
        />
        <div className="AdminOrderFullTablesStatistics">
          <h6 className="Medium">{t("buttons.fullTables")}</h6>
          <h6 className="Medium">
            {`(${fullTablesOfActiveZone.length} - ${allTablesOfActiveZone})`}
          </h6>
        </div>
      </div>
    </div>
  );
};

StatsPieChartWithNeedleWrapper.propTypes = {
  totalGuestsOfOrdersByActiveZone: PropTypes.number.isRequired,
  fullTablesOfActiveZone: PropTypes.array.isRequired,
  allTablesOfActiveZone: PropTypes.number.isRequired,
};

export default StatsPieChartWithNeedleWrapper;
