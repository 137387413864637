import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const CustomAudioPlayer = ({ audioSrc, isPlaying, deps }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      if (isPlaying) {
        audioElement.currentTime = 0;
        audioElement.play();
      } else {
        audioElement.pause();
      }
    }
  }, [isPlaying, ...deps]);

  return (
    <>
      <audio ref={audioRef} src={audioSrc} />
    </>
  );
};

CustomAudioPlayer.propTypes = {
  // The source URL of the audio file
  audioSrc: PropTypes.string.isRequired,

  // Boolean flag indicating whether the audio is currently playing
  isPlaying: PropTypes.bool.isRequired,

  deps: PropTypes.array,
};

CustomAudioPlayer.defaultProps = {
  deps: [],
};
export default CustomAudioPlayer;
