import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import IconButton from "components/buttons/icon-button/IconButton";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";

import "./AddLanguageModal.scss";

export const ENUMS = {
  name: "AddItem",
};

const AddLanguageModal = ({
  mainElementRef,
  setOpenSlide,
  openSlide,
  onSave,
  languages,
}) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(null);
  const handleOnModalClose = () => {
    setOpenSlide(false);
  };

  const handleOnSubmit = () => {
    handleOnModalClose();
    onSave(language);
  };

  useEffect(() => {
    setLanguage(languages[0]);
  }, [openSlide]);

  const languageLength = languages?.length;

  const AddTabModalHeader = (
    <div className="AddTabModalHeader">
      <h3 className="SemiBold AddTabModalHeaderTitle">
        {t("modification.selection.option.addLanguage")}
      </h3>
      <IconButton onClick={handleOnModalClose} svgComponent={<IconClose />} />
    </div>
  );

  const AddTabModalBody = (
    <div className="AddTabModalBody">
      {languageLength > 0 ? (
        <div
          className="AddTabModalBodyForm"
          onSubmit={(e) => e.preventDefault()}
        >
          <Dropdown
            onChange={(tab) => {
              if (tab) setLanguage(tab);
            }}
            value={language}
            options={languages}
            dropdownWithIcon
          />
        </div>
      ) : (
        <h5>{t("emptyStates.noLanguage")}</h5>
      )}
    </div>
  );
  const AddTabModalFooter = (
    <PrimaryButton
      onClick={languageLength> 0 ? handleOnSubmit : handleOnModalClose}
      text={languageLength> 0 ? t("buttons.save") : t("buttons.back")}
    />
  );

  return (
    <Modal
      header={AddTabModalHeader}
      body={AddTabModalBody}
      footer={AddTabModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    ></Modal>
  );
};

AddLanguageModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * A boolean indicating whether the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The function called when the form is submitted to save the data
   */
  onSave: PropTypes.func,

  /**
   * An array of languages
   */
  languages: PropTypes.array.isRequired,
};

export default AddLanguageModal;
