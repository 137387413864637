import React from "react";
import ReactDOM from "react-dom/client";
import "assets/styles/main.scss";
import "utils/constants/i18n/i18n-config";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Temporarily removed React.StrictMode due to conflicts with react-beautiful-dnd
// Drag-and-drop functionality breaks in strict mode
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
