import { AXIOS, AXIOS_NO_AUTH } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createOrderGuest = async (businessId, order) =>
  AXIOS_NO_AUTH.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}`,
    order
  );

export const createOrderAdmin = async (businessId, order) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}`,
    order
  );

export const getOrders = async (businessId) =>
  AXIOS.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}`
  );

export const getOrderById = async (businessId, orderId) =>
  AXIOS.get(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${orderId}`
  );

export const updateOrderGuest = async (businessId, order, id) =>
  AXIOS_NO_AUTH.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${id}`,
    order
  );

export const updateOrderAdmin = async (businessId, order, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${id}`,
    order
  );

export const updateOrderTable = async (businessId, order, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${id}/${API_PATH_PARAMS.table}`,
    order
  );

export const updateOrderAssignee = async (businessId, order, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${id}/${API_PATH_PARAMS.assignee}`,
    order
  );

export const updateOrderAction = async (businessId, order, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${id}/${API_PATH_PARAMS.action}`,
    order
  );

export const deleteOrder = async (businessId, id) =>
  AXIOS.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${id}`
  );
