import React from "react";
import PropTypes from "prop-types";

import "./Discount.scss";

export const ENUMS = {
  name: "Discount",
};

const Discount = ({ rate }) => {
  return rate !== 0 && <h5 className="DiscountRate Bold">{rate}%</h5>;
};

Discount.propTypes = {
  /**
   * The discount rate of the component
   */
  rate: PropTypes.number.isRequired,
};

export default Discount;
