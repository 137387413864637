import { createAsyncThunk } from "@reduxjs/toolkit";

import { STORE_NAMES } from "utils/constants/redux";
import {
  createInventoryCategory,
  deleteInventoryCategory,
  getInventoryCategories,
  updateInventoryCategory,
} from "utils/api/services/inventoryCategory";

export const getInventoryCategoriesAsync = createAsyncThunk(
  `${STORE_NAMES.inventory}/getInventoryCategories`,
  async (businessId) => {
    const response = await getInventoryCategories(businessId);
    return response.data;
  }
);

export const createInventoryCategoryAsync = createAsyncThunk(
  `${STORE_NAMES.inventory}/createInventoryCategory`,
  async ({ businessId, category }) => {
    const response = await createInventoryCategory(businessId, category);
    return response.data;
  }
);

export const updateInventoryCategoryAsync = createAsyncThunk(
  `${STORE_NAMES.inventory}/updateInventoryCategory`,
  async ({ businessId, category, id }) => {
    const response = await updateInventoryCategory(businessId, category, id);
    return response.data;
  }
);

export const deleteInventoryCategoryAsync = createAsyncThunk(
  `${STORE_NAMES.inventory}/deleteInventoryCategory`,
  async ({ businessId, id }) => {
    const response = await deleteInventoryCategory(businessId, id);
    return response.data;
  }
);
