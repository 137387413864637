import { REDIS_STREAM_CONSTANTS } from "utils/api/redis";

export const convertSocketChatDataToReduxChatData = (data) => {
  return {
    id: data.id,
    orderId: data.orderId,
    businessId: data.businessId,
    message: {
      text: data.payload.text,
      dateTime: data.payload.dateTime,
      author: {
        id: data.payload.author.id,
        name: data.payload.author.name,
        role: { name: data.payload.author.roleName },
      },
    },
  };
};

export const publishOrderChatMessage = (socket, data) => {
  socket.emit(REDIS_STREAM_CONSTANTS.subscribes.sendOrderChatMessage, {
    businessId: data.businessId,
    orderId: data.orderId,
    message: data.text,
    userId: data.authorId,
    authorName: data.authorName,
    authorRoleName: data.authorRoleName,
  });
};
