import { useSelector } from "react-redux";
import { STORE_NAMES } from "../constants/redux";

const useGetLoggedBusinessUser = () => {
  const { userWithPin: userLoggedWithPin, user: loggedUser } = useSelector(
    (state) => state[STORE_NAMES.user]
  );
  return userLoggedWithPin ?? loggedUser;
};

export default useGetLoggedBusinessUser;
