import IMAGE_DESSERT1 from "assets/images/products/desserts/dessert1.jpeg";
import IMAGE_DESSERT2 from "assets/images/products/desserts/dessert7.jpeg";
import IMAGE_DESSERT3 from "assets/images/products/desserts/dessert3.jpeg";
import IMAGE_DESSERT4 from "assets/images/products/desserts/dessert4.jpeg";
import IMAGE_DESSERT5 from "assets/images/products/desserts/dessert5.jpeg";
import IMAGE_DESSERT6 from "assets/images/products/desserts/dessert6.jpeg";

import IMAGE_COCKTAIL1 from "assets/images/products/drinks/cocktail1.JPG";
import IMAGE_COCKTAIL2 from "assets/images/products/drinks/cocktail2.jpeg";
import IMAGE_COCKTAIL3 from "assets/images/products/drinks/cocktail3.jpeg";
import IMAGE_COCKTAIL4 from "assets/images/products/drinks/cocktail4.jpeg";
import IMAGE_COCKTAIL5 from "assets/images/products/drinks/cocktail5.jpeg";
import IMAGE_COCKTAIL6 from "assets/images/products/drinks/cocktail6.jpeg";

import IMAGE_MEAL1 from "assets/images/products/meals/meal1.jpeg";
import IMAGE_MEAL2 from "assets/images/products/meals/meal2.jpeg";
import IMAGE_MEAL3 from "assets/images/products/meals/meal3.jpeg";
import IMAGE_MEAL4 from "assets/images/products/meals/meal4.jpeg";

import {
  ORDER_ACTIONS_TYPE,
  ORDER_STATUS_ENUM,
  ORDER_SUMMARY,
  WAITER,
} from "utils/constants/data/menu-model";
import { KITCHEN_STAGE, PAYMENT_METHODS } from "utils/constants/data/base";

export const CURRENCIES = {
  AZN: {
    symbol: "₼",
    code: "AZN",
  },
  USD: {
    symbol: "$",
    code: "USD",
  },
};

const MENU_ITEM_PRICE_1 = {
  amount: 20.0,
  currencyId: 1,
};

export const TAGS_NEW = [
  {
    id: 1,
    name: "Halal",
  },
  {
    id: 2,
    name: "Bio",
  },
  {
    id: 3,
    name: "Vegetarian",
  },
  {
    id: 4,
    name: "Vegan",
  },
  {
    id: 5,
    name: "Diabetic",
  },
  {
    id: 6,
    name: "Fish",
  },
  {
    id: 7,
    name: "Lamb",
  },
  {
    id: 8,
    name: "Non-Alcoholic",
  },
];

const TAGS = {
  halal: {
    id: 1,
    name: "Halal",
  },
  bio: {
    id: 2,
    name: "Bio",
  },
  Vegetarian: {
    id: 3,
    name: "Vegetarian",
  },
  vegan: {
    id: 4,
    name: "Vegan",
  },
  diabetic: {
    id: 5,
    name: "Diabetic",
  },
  fish: {
    id: 6,
    name: "Fish",
  },
  lamb: {
    id: 7,
    name: "Lamb",
  },
  nonAlcoholic: {
    id: 8,
    name: "Non-Alcoholic",
  },
  alcoholic: {
    id: 9,
    name: "Alcoholic",
  },
};

export const MENU_ITEM_MODIFICATION_1 = {
  id: 1,
  name: "Size",
  isMultiSelect: false,
  isRequired: true,
  options: [
    {
      id: 1,
      name: "Small",
      price: -1.5,
      isCountable: false,
    },
    {
      id: 2,
      name: "Medium",
      defaultValue: true,
      price: 0,
      isCountable: false,
    },
    { id: 3, name: "Large", price: 1.5, isCountable: false },
  ],
};
export const MENU_ITEM_MODIFICATION_2 = {
  id: 2,
  name: "Sauces",
  isMultiSelect: true,
  isRequired: false,
  options: [
    {
      id: 4,
      name: "Mozzarella",
      price: 1.5,
      isCountable: true,
    },
    {
      id: 5,
      name: "Cheddar",
      price: 1.5,
      isCountable: true,
    },
    {
      id: 6,
      name: "Ketchup",
      price: 1.5,
      isCountable: false,
    },
  ],
};

export const PHOTOS = [
  {
    id: 1,
    imgSrc: IMAGE_MEAL1,
  },
  {
    id: 2,
    imgSrc: IMAGE_MEAL2,
  },
  {
    id: 3,
    imgSrc: IMAGE_MEAL3,
  },
  {
    id: 4,
    imgSrc: IMAGE_MEAL2,
  },
  {
    id: 5,
    imgSrc: IMAGE_MEAL3,
  },
  {
    id: 6,
    imgSrc: IMAGE_MEAL3,
  },
  {
    id: 7,
    imgSrc: IMAGE_MEAL2,
  },
  {
    id: 8,
    imgSrc: IMAGE_MEAL3,
  },
];

//FOR SINGLE MENU
const MENU_DESSERTS_ITEMS = [
  {
    id: 1,
    name: "Cupcakes with cream and caramel",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_DESSERT1,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
  },
  {
    id: 2,
    name: "Raspberry cheesecake",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_DESSERT2,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
  },
  {
    id: 3,
    name: "Lemon Roulette",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_DESSERT3,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
  },
  {
    id: 4,
    name: "Strawberry pudding",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_DESSERT4,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
  },
  {
    id: 5,
    name: "Blueberry donuts",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_DESSERT5,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
  },
  {
    id: 6,
    name: "Strawberry Pancake With Honey Syrup",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_DESSERT6,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
  },
];

const MENU_DRINKS_ITEMS = [
  {
    id: 7,
    name: "Gin tonic",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_COCKTAIL1,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.alcoholic],
    modifications: [],
    category: {
      id: 2,
      name: "Drinks",
    },
  },
  {
    id: 8,
    name: "Brown derby",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_COCKTAIL2,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.Vegetarian],
    modifications: [],
    category: {
      id: 2,
      name: "Drinks",
    },
  },
  {
    id: 9,
    name: "Mai Tai",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_COCKTAIL3,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.Vegetarian],
    modifications: [],
    category: {
      id: 2,
      name: "Drinks",
    },
  },
  {
    id: 10,
    name: "Manhattan",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_COCKTAIL4,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.alcoholic, TAGS.Vegetarian],
    modifications: [],
  },
  {
    id: 11,
    name: "Old fashioned",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_COCKTAIL5,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.alcoholic, TAGS.Vegetarian],
    modifications: [],
  },
  {
    id: 12,
    name: "Martini",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_COCKTAIL6,
    otherImagesSrc: [],
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.alcoholic, TAGS.Vegetarian],
    modifications: [],
  },
];
export const MENU_MEALS_ITEMS = [
  {
    id: 13,
    name: "Slow-roasted pulled pork sandwich with tangy coleslaw and smoky barbecue sauce on a toasted brioche bun",
    description:
      "Indulge in the ultimate comfort food experience with our slow-roasted pulled pork sandwich. Tender and succulent pulled pork, cooked to perfection, is generously piled onto a toasted brioche bun. The pork is complemented by a delightful combination of tangy coleslaw that adds a refreshing crunch and smoky barbecue sauce that infuses every bite with rich, savory flavor.",
    coverImageSrc: IMAGE_MEAL1,
    otherImagesSrc: PHOTOS,
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian, TAGS.vegan],
    modifications: [MENU_ITEM_MODIFICATION_1, MENU_ITEM_MODIFICATION_2],
    currency: CURRENCIES.AZN,
    category: {
      id: 1,
      name: "Meals",
    },
  },
  {
    id: 14,
    name: "French toast",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_MEAL2,
    otherImagesSrc: PHOTOS,
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1, MENU_ITEM_MODIFICATION_2],
    currency: CURRENCIES.AZN,
    category: {
      id: 1,
      name: "Meals",
    },
    isPublished: false,
  },
  {
    id: 15,
    name: "Mac and cheese",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_MEAL3,
    otherImagesSrc: PHOTOS,
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
    currency: CURRENCIES.AZN,
    category: {
      id: 1,
      name: "Meals",
    },
  },
  {
    id: 16,
    name: "Burger",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_MEAL4,
    otherImagesSrc: PHOTOS,
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
    currency: CURRENCIES.AZN,
    category: {
      id: 1,
      name: "Meals",
    },
    isPublished: false,
  },
  {
    id: 17,
    name: "Breakfast with avocado and strawberries",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_MEAL1,
    otherImagesSrc: PHOTOS,
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
    modifications: [MENU_ITEM_MODIFICATION_1],
    currency: CURRENCIES.AZN,
    category: {
      id: 1,
      name: "Meals",
    },
  },
  {
    id: 18,
    name: "Fish Burger",
    description: "Delicious pizza with various toppings to choose from.",
    coverImageSrc: IMAGE_MEAL4,
    otherImagesSrc: PHOTOS,
    priceSell: [MENU_ITEM_PRICE_1],
    tags: [TAGS.bio, TAGS.halal, TAGS.fish],
    modifications: [MENU_ITEM_MODIFICATION_1],
    currency: CURRENCIES.AZN,
    category: {
      id: 1,
      name: "Meals",
    },
  },
];

export const MENU_CATEGORIES = [
  {
    id: 1,
    name: "Meals",
    menuItems: MENU_MEALS_ITEMS,
    isPublished: true,
  },
  {
    id: 2,
    name: "Drinks",
    menuItems: MENU_DRINKS_ITEMS,
    isPublished: false,
  },
  {
    id: 3,
    name: "Desserts",
    menuItems: MENU_DESSERTS_ITEMS,
    isPublished: false,
  },
  {
    id: 4,
    name: "Salads",
    menuItems: MENU_DESSERTS_ITEMS,
    isPublished: true,
  },
];

export const MENU_SAMPLE = {
  name: "Main Menu",
  currency: CURRENCIES.AZN,
  tags: TAGS_NEW,
  extraFees: {
    tax: 15, //percent
    serviceFee: 10, //percent
  },
  categories: MENU_CATEGORIES,
  defaultMenuView: 1,
  primaryColor: 1,
  borderRadius: 1,
};

export const COUNTRIES = [
  {
    id: 1,
    name: "Azerbaijan",
  },
  {
    id: 2,
    name: "Germany",
  },
  {
    id: 3,
    name: "Switzerland",
  },
];

export const MENU_ITEM_MODIFICATION_REDUX = {
  id: 1,
  name: "Size",
  isMultiSelect: false,
  isRequired: true,
  options: [
    {
      id: 1,
      name: "Small",
      price: -1.5,
      isCountable: false,
      count: 1,
    },
  ],
};

const MENU_ITEM = {
  id: 1,
  name: "Strawberry Cheesecake",
  description: "Strawberry Cheesecake with Chocolate Syrup",
  coverImageSrc: IMAGE_DESSERT1,
  otherImagesSrc: [IMAGE_DESSERT1],
  priceSell: [MENU_ITEM_PRICE_1],
  tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
  modifications: [MENU_ITEM_MODIFICATION_REDUX], // In Basket store will be empty if no modification applied
  rate: 10,
  isFixed: true,
};

const MENU_ITEM_2 = {
  id: 2,
  name: "Raspberry cheesecake",
  description: "Delicious pizza with various toppings to choose from.",
  coverImageSrc: IMAGE_DESSERT2,
  otherImagesSrc: [IMAGE_DESSERT1],
  priceSell: [MENU_ITEM_PRICE_1],
  tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
  modifications: [MENU_ITEM_MODIFICATION_REDUX], // In Basket store will be empty if no modification applied
  rate: 10,
  isFixed: true,
};

const MENU_ITEM_3 = {
  id: 3,
  name: "Raspberry cheesecake",
  description: "Delicious pizza with various toppings to choose from.",
  coverImageSrc: IMAGE_DESSERT3,
  otherImagesSrc: [IMAGE_DESSERT1],
  priceSell: [MENU_ITEM_PRICE_1],
  tags: [TAGS.bio, TAGS.halal, TAGS.Vegetarian],
  modifications: [MENU_ITEM_MODIFICATION_REDUX], // In Basket store will be empty if no modification applied
  rate: 10,
  isFixed: true,
};

const BASKET_ORDER_ITEM = {
  count: 1,
  item: MENU_ITEM,
};

const BASKET_ORDER_ITEM_2 = {
  count: 2,
  item: MENU_ITEM_2,
};

const BASKET_ORDER_ITEM_3 = {
  count: 2,
  item: MENU_ITEM_3,
};

const BASKET_ORDER_GUEST_REDUX = {
  uid: "uid",
  name: "Guest 1",
  orderMessage: "Espresso with brown sugar please.",
  favoriteItems: [MENU_ITEM],
  orderItems: [BASKET_ORDER_ITEM],
};

const BASKET_ORDER_GUEST_REDUX_2 = {
  uid: "uid2",
  name: "Guest 2",
  favoriteItems: [MENU_ITEM],
  orderItems: [BASKET_ORDER_ITEM_2, BASKET_ORDER_ITEM_3],
};

const BASKET_ORDER_GUEST_WAITER_REDUX = {
  uid: WAITER.id,
  orderItems: [BASKET_ORDER_ITEM],
};
export const ORDERS = [
  {
    tableId: 1,
    status: ORDER_STATUS_ENUM.ordered,
    createDate: "2023-10-28T07:16:01.146Z",
    id: "1",
    priceSummary: ORDER_SUMMARY,
    guests: [
      BASKET_ORDER_GUEST_REDUX,
      BASKET_ORDER_GUEST_REDUX_2,
      BASKET_ORDER_GUEST_WAITER_REDUX,
    ],
    actions: [
      {
        name: ORDER_ACTIONS_TYPE.bill,
        type: PAYMENT_METHODS.CASH,
        value: true,
      },
      {
        name: ORDER_ACTIONS_TYPE.waiter,
        value: false,
      },
    ],
  },
  {
    tableId: 2,
    status: ORDER_STATUS_ENUM.paid,
    createDate: "2023-10-28T12:28:01.146Z",
    id: "2",
    priceSummary: ORDER_SUMMARY,
    guests: [BASKET_ORDER_GUEST_REDUX_2],
    actions: [
      {
        name: ORDER_ACTIONS_TYPE.bill,
        type: PAYMENT_METHODS.POS_TERMINAL,
        value: true,
      },
      {
        name: ORDER_ACTIONS_TYPE.waiter,
        value: false,
      },
    ],
  },
  {
    tableId: 3,
    status: ORDER_STATUS_ENUM.confirmed,
    createDate: "2023-10-29T04:18:01.146Z",
    id: "3",
    priceSummary: ORDER_SUMMARY,
    guests: [BASKET_ORDER_GUEST_REDUX_2],
    actions: [
      {
        name: ORDER_ACTIONS_TYPE.bill,
        type: PAYMENT_METHODS.POS_TERMINAL,
        value: true,
      },
      {
        name: ORDER_ACTIONS_TYPE.waiter,
        value: true,
      },
    ],
  },
  {
    tableId: 4,
    status: ORDER_STATUS_ENUM.served,
    createDate: "2023-10-29T09:05:01.146Z",
    id: "4",
    priceSummary: ORDER_SUMMARY,
    guests: [BASKET_ORDER_GUEST_REDUX],
    actions: [
      {
        name: ORDER_ACTIONS_TYPE.bill,
        type: PAYMENT_METHODS.POS_TERMINAL,
        value: false,
      },
      {
        name: ORDER_ACTIONS_TYPE.waiter,
        value: false,
      },
    ],
  },
];

export const BUSINESSES = [
  {
    id: 1,
    name: "Paris Bistro",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Premium",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 2,
    name: "Novikov",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Pro",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 3,
    name: "Central Baku",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Standard",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 4,
    name: "Syrovarnya",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Premium",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 5,
    name: "Gate 25",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Standard",
      expirationDate: "2024-01-01T00:00:00.000Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 6,
    name: "Haven",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Standard",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 7,
    name: "Taksim Kebap",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Premium",
      expirationDate: "2024-01-01T00:00:00.000Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 8,
    name: "Qala Divari",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Pro",
      expirationDate: "2024-10-01T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 9,
    name: "Mangal Steak House",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Premium",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 10,
    name: "Cubar",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Premium",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
  {
    id: 11,
    name: "Qaynana",
    email: "parisbistro@gmail.com",
    plan: {
      id: 1,
      name: "Standard",
      expirationDate: "2024-10-23T14:01:59.778Z",
    },
    createDate: "2023-10-23T14:01:59.778Z",
  },
];

export const MOCK_ORDERS = [
  {
    tableId: 11,
    status: "Ordered",
    kitchenStage: KITCHEN_STAGE.NEW,
    createDate: "2023-11-04T22:19:13.099Z",
    id: "1222",
    items: [
      {
        id: 1,
        isChecked: false,
        name: "Super Papa",
        count: 1,
        orderDetails: [
          { title: "Size", value: "Large" },
          { title: "Crust", value: "Thick Crust" },
          { title: "Toppings", value: "Pepperoni, Sausage, Mushrooms, Onions" },
          { title: "Cheese", value: "Mozzarella" },
        ],
      },
    ],
  },
  {
    tableId: 22,
    status: "Preparing",
    kitchenStage: KITCHEN_STAGE.PROGRESS,
    createDate: "2023-11-04T22:21:13.099Z",
    id: "2222",
    items: [
      {
        id: 1,
        isChecked: true,
        name: "Super Papa",
        count: 1,
        orderDetails: [
          { title: "Size", value: "Large" },
          { title: "Crust", value: "Thick Crust" },
          { title: "Toppings", value: "Pepperoni, Sausage, Mushrooms, Onions" },
          { title: "Cheese", value: "Mozzarella" },
        ],
      },
      {
        id: 2,
        isChecked: false,
        name: "Vegetarian Delight",
        count: 1,
        orderDetails: [
          { title: "Size", value: "Medium" },
          { title: "Crust", value: "Thin Crust" },
          {
            title: "Toppings",
            value: "Bell Peppers, Olives, Onions, Tomatoes",
          },
          { title: "Cheese", value: "Mozzarella" },
        ],
      },
      {
        id: 3,
        isChecked: false,
        name: "Cheesecake",
        count: 1,
        orderDetails: [],
      },
      {
        id: 4,
        isChecked: true,
        name: "Custom Burger",
        count: 2,
        orderDetails: [
          { title: "Bun", value: "Sesame Seed" },
          { title: "Patties", value: "Beef" },
          { title: "Toppings", value: "Lettuce, Tomato, Onion, Pickles" },
          { title: "Sauces", value: "Ketchup, Mustard" },
        ],
      },
    ],
  },
  {
    tableId: 31,
    status: "Delivered",
    kitchenStage: KITCHEN_STAGE.NEW,
    createDate: "2023-11-04T22:25:13.099Z",
    id: "322",
    items: [
      {
        id: 1,
        isChecked: false,
        name: "Margherita Pizza",
        count: 1,
        orderDetails: [
          { title: "Size", value: "Medium" },
          { title: "Crust", value: "Thin Crust" },
          { title: "Toppings", value: "Tomato, Basil, Mozzarella" },
        ],
      },
    ],
  },
];

const INVENTORY_ITEMS = [
  {
    id: 1,
    name: "Sugar",
    amount: 0,
    warningAmount: 5,
    unit: {
      id: 1,
      name: "kg",
    },
  },
  {
    id: 2,
    name: "Salt",
    amount: 1,
    warningAmount: 2,
    unit: {
      id: 1,
      name: "kg",
    },
  },
];

export const INVENTORY_CATEGORIES = [
  {
    id: 1,
    name: "Meals",
    inventoryItems: INVENTORY_ITEMS,
  },
  {
    id: 2,
    name: "Drinks",
    inventoryItems: INVENTORY_ITEMS,
  },
  {
    id: 3,
    name: "Desserts",
    inventoryItems: INVENTORY_ITEMS,
  },
  {
    id: 4,
    name: "Salads",
    inventoryItems: INVENTORY_ITEMS,
  },
];
