import { AXIOS } from "utils/api/axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const createMenuItem = async (businessId, menuId, menuItem) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuItems}`,
    menuItem
  );

export const updateMenuItem = async (businessId, menuId, menuItem, id) =>
  AXIOS.patch(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuItems}/${id}`,
    menuItem
  );

export const deleteMenuItem = async (businessId, menuId, id) =>
  AXIOS.delete(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.menus}/${menuId}/${API_PATH_PARAMS.menuItems}/${id}`
  );
