import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Stories from "react-insta-stories";

import { createDOBucketName } from "utils/DO-Spaces";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";

import "./InstaStories.scss";

const ENUMS = {
  name: "instaStories",
  storyType: "image" | "video",
};

const INSTA_STORY_DURATION_SEC = 3000; // in ms

const InstaStories = ({ setShowInstaStory }) => {
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const navigate = useNavigate();
  const iLoyalHeading = {
    heading: business?.name,
    subheading: business?.contact?.gmail?.value,
    profileImage: createDOBucketName(business?.images?.logo),
  };

  const iLoyalStories =
    business?.images?.other?.map((imagePath) => ({
      url: createDOBucketName(imagePath),
      type: ENUMS.storyType,
      header: iLoyalHeading,
      seeMore: () => {
        navigate(
          `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.info}`
        );
      },
      duration: INSTA_STORY_DURATION_SEC,
    })) || [];

  const [stories, setStories] = useState(iLoyalStories);

  useEffect(() => {
    if (business.id) {
      setStories(iLoyalStories);
    }
  }, [business]);

  const onAllStoriesEndHandler = () => {
    setShowInstaStory(false);
  };

  const storyContent = {
    width: "auto",
    maxWidth: window.innerWidth <= 1000 ? "100%" : "500px",
    height: window.innerWidth <= 1000 ? "100dvh" : "700px",
    margin: "auto",
    objectFit: "cover",
    borderRadius: window.innerWidth <= 1000 ? "0" : "40px",
  };

  const storyContainerStyles = {
    width: window.innerWidth <= 1000 ? "100%" : "500px",
    height: window.innerWidth <= 1000 ? "100dvh" : "700px",
    borderRadius: window.innerWidth <= 1000 ? "0" : "40px",
  };

  return (
    <>
      <div className="InstaStoryCnt">
        <div className="CloseInstaStoryCnt" onClick={onAllStoriesEndHandler}>
          <IconClose />
        </div>
        <Stories
          stories={stories}
          defaultInterval={INSTA_STORY_DURATION_SEC}
          storyContainerStyles={storyContainerStyles}
          width={window.innerWidth <= 1000 ? "100%" : "500px"}
          height={window.innerWidth <= 1000 ? "100dvh" : "700px"}
          storyStyles={storyContent}
          loop={false}
          keyboardNavigation={true}
          onAllStoriesEnd={onAllStoriesEndHandler}
        />
      </div>
      <div className="InstaStoryCntUnderlay"></div>
    </>
  );
};

InstaStories.propTypes = {
  setShowInstaStory: PropTypes.func.isRequired,
};

export default InstaStories;
