import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ReactComponent as FilterIcon } from "assets/icons/filter/SearchIcon.svg";
import { QUERY_PARAMS } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import useOutsideClick from "utils/hooks/useOutsideClick";
import MenuFilterModal from "pages/client/menu/menu-filter-modal/MenuFilterModal";
import MenuViewSelection from "components/homepage-views/menu-view-selection/MenuViewSelection";
import InstaStories from "components/insta-stories/InstaStories";
import { createDOBucketName } from "utils/DO-Spaces";
import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "./MenuHeroSection.scss";

const MenuHeroSection = ({ onClick, currentOptionId }) => {
  const { t } = useTranslation();

  const menuCurrency = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency
  );
  const currencyCode = menuCurrency?.symbol;

  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const minDeposits =
    zones?.flatMap(
      (zone) => zone.tables?.map((table) => table.minDeposit) || []
    ) || [];

  const [openFilters, setOpenFilters, mainElementRef] = useOutsideClick();
  const [filtersModified, setFiltersModified] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const handleBurgerMenuClick = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showHamburgerMenu]: true,
    });
  };

  const [showInstaStory, setShowInstaStory] = useState(false);
  return (
    <div className="MenuHeroSection">
      {showInstaStory && business?.images?.other?.length > 0 && (
        <InstaStories setShowInstaStory={setShowInstaStory} />
      )}
      <div className="HeroHeader">
        <div>
          <div className="MenuHeroSectionBusinessInfo">
            <div className="LogoParent">
              {business?.images?.other?.length > 0 && (
                <div className="LogoOuterCircle" />
              )}
              {/*<img alt="logo" src={BusinessLogoBorder} />*/}
              {/*<BusinessLogoBorder />*/}
              <ImageWithPlaceholder
                imageSource={createDOBucketName(business?.images.logo)}
                placeholder={IMAGE_ITEM_PLACEHOLDER}
                alt="logo"
                className="Logo"
                onClick={() => setShowInstaStory(true)}
              />
            </div>

            <div className="MenuHeroSectionBusinessDetails">
              <h6 className="SemiBold RestaurantName">{business?.name}</h6>
              {serviceFee > 0 && (
                <h5 className="h7 MenuHeroSectionServiceFee ">
                  {t("inputs.serviceFee")}{`: ${serviceFee}%`}
                </h5>
              )}
              {minDeposits > 0 && (
                <h5 className="h7 MenuHeroSectionServiceFee ">
                  {t("inputs.deposit")}{`: ${minDeposits}${currencyCode}`}
                </h5>
              )}
            </div>
          </div>
        </div>
        <div className="FilterAndHamburgerMenu">
          <div className="FilterIcon" onClick={() => setOpenFilters(true)}>
            <FilterIcon />
            {filtersModified && <div className="FilterChanges"></div>}
          </div>
          <div className="HamburgerMenuContainer">
            <HamburgerButton onClick={handleBurgerMenuClick} />
          </div>
        </div>
      </div>
      <MenuFilterModal
        mainElementRef={mainElementRef}
        openSlide={openFilters}
        onClose={() => setOpenFilters(false)}
        setFiltersModified={setFiltersModified}
      />

      <div className="MenuViewSelectionWrapper">
        <MenuViewSelection
          onClick={onClick}
          currentOptionId={currentOptionId}
        />
      </div>
    </div>
  );
};

MenuHeroSection.propTypes = {
  /**
   * The  current option id of the MenuViewSelection component
   */
  currentOptionId: PropTypes.number.isRequired,

  /**
   * Click event when click selection
   */
  onClick: PropTypes.func.isRequired,
};

export default MenuHeroSection;
