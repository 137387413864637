import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TestImage from "pages/admin/admin-pages/admin-dashboard/admin-dash-menu-stats/testImage/image 1851.png";
import "components/admin/elements/date-accordion/DateAccordion.scss";
import CalendarRangePicker from "components/calendar/calendar-range-picker/CalendarRangePicker";
import { formatFromDateToDate } from "utils/helper-functions/dashboardStatsHelper";
import Button from "components/buttons/button/Button";

import "./AdminDashboard.scss";

export const ENUMS = {
  name: "DateAccordion",
  DATE_FIELDS: {
    FROM: "from",
    TO: "to",
  },
};

const SAMPLE_STAFF_DATA = {
  id: 1,
  name: "Roberto Carlos",
  image: TestImage,
  orderItems: 35,
  orders: 2000,
  sales: 1500,
  rating: 5.0,
};

const STAFF_SALES_DATA = {
  leftColumnName: "Top Performer",
  rightColumnName: "Low Performer",
  columnItemNames: {
    primary: "Sales",
    secondary: "Orders",
    ternary: "Rating",
  },
  leftColumn: [
    {
      sequence: "",
      itemName: "Cristiano Ronaldo",
      imageSrc: TestImage,
      primaryData: "6500₼",
      secondaryData: "300",
      ternaryData: "4.5",
      id: 1,
    },
    {
      sequence: "",
      itemName: "Messi",
      imageSrc: TestImage,
      primaryData: "5500₼",
      secondaryData: "500",
      ternaryData: "4",
      id: 2,
    },
    {
      sequence: "",
      itemName: "Bale",
      imageSrc: TestImage,
      primaryData: "4500₼",
      secondaryData: "600",
      ternaryData: "3.5",
      id: 3,
    },
  ],
  rightColumn: [
    {
      sequence: "",
      itemName: "Benzema",
      imageSrc: TestImage,
      primaryData: "1500₼",
      secondaryData: "100",
      ternaryData: "2",
      id: 4,
    },
    {
      sequence: "",
      itemName: "Marcelo",
      imageSrc: TestImage,
      primaryData: "1000₼",
      secondaryData: "75",
      ternaryData: "3.5",
      id: 5,
    },
    {
      sequence: "",
      itemName: "Ramos",
      imageSrc: TestImage,
      primaryData: "1800₼",
      secondaryData: "80",
      ternaryData: "2.5",
      id: 6,
    },
  ],
};

const STAFF_ORDERS_DATA = {
  leftColumnName: "Top Performer",
  rightColumnName: "Low Performer",
  columnItemNames: {
    primaryData: "Orders",
    secondaryData: "Sales",
    ternaryData: "Rating",
  },
  leftColumn: [
    {
      sequence: "",
      itemName: "Cristiano Ronaldo",
      imageSrc: TestImage,
      primaryData: "300",
      secondaryData: "6500₼",
      ternaryData: "4.5",
    },
    {
      sequence: "",
      itemName: "Messi",
      imageSrc: TestImage,
      primaryData: "132",
      secondaryData: "5500₼",
      ternaryData: "4",
    },
    {
      sequence: "",
      itemName: "Bale",
      imageSrc: TestImage,
      primaryData: "170",
      secondaryData: "4500₼",
      ternaryData: "3.5",
    },
  ],
  rightColumn: [
    {
      sequence: "",
      itemName: "Benzema",
      imageSrc: TestImage,
      primaryData: "45",
      secondaryData: "1500₼",
      ternaryData: "2",
    },
    {
      sequence: "",
      itemName: "Marcelo",
      imageSrc: TestImage,
      primaryData: "90",
      secondaryData: "1000₼",
      ternaryData: "3.5",
    },
    {
      sequence: "",
      itemName: "Ramos",
      imageSrc: TestImage,
      primaryData: "180",
      secondaryData: "1800₼",
      ternaryData: "2.5",
    },
  ],
};

const STAFF_RATING_DATA = {
  leftColumnName: "Top Performer",
  rightColumnName: "Low Performer",
  columnItemNames: {
    primary: "Rating",
    secondary: "Sales",
    ternary: "Orders",
  },
  leftColumn: [
    {
      sequence: "",
      itemName: "Cristiano Ronaldo",
      imageSrc: TestImage,
      primaryData: "5",
      secondaryData: "6500₼",
      ternaryData: "300",
    },
    {
      sequence: "",
      itemName: "Messi",
      imageSrc: TestImage,
      primaryData: "4.5",
      secondaryData: "5500₼",
      ternaryData: "132",
    },
    {
      sequence: "",
      itemName: "Bale",
      imageSrc: TestImage,
      primaryData: "4",
      secondaryData: "4500₼",
      ternaryData: "170",
    },
  ],
  rightColumn: [
    {
      sequence: "",
      itemName: "Benzema",
      imageSrc: TestImage,
      primaryData: "2",
      secondaryData: "1500₼",
      ternaryData: "45",
    },
    {
      sequence: "",
      itemName: "Marcelo",
      imageSrc: TestImage,
      primaryData: "55",
      secondaryData: "1000₼",
      ternaryData: "90",
    },
    {
      sequence: "",
      itemName: "Ramos",
      imageSrc: TestImage,
      primaryData: "2.6",
      secondaryData: "1800₼",
      ternaryData: "40",
    },
  ],
};

const STAFF_TABLE_DATA = {
  data: [
    SAMPLE_STAFF_DATA,
    SAMPLE_STAFF_DATA,
    SAMPLE_STAFF_DATA,
    SAMPLE_STAFF_DATA,
  ],
};

const ORDER_STAFF_DATA = {
  serviceFeedbackChartData: {
    meta: {
      type: "monotone",
      xAxisDataKey: "rating",
      data: [
        {
          dataKey: "value",
        },
      ],
    },
    data: [
      {
        name: "Happy",
        value: 400,
        rating: "4.1-5",
      },
      {
        name: "Shy",
        value: 70,
        rating: "3.1-4",
      },
      {
        name: "Numb",
        value: 200,
        rating: "2.1-3",
      },
      {
        name: "Sad",
        value: 300,
        rating: "1.1-2",
      },
      {
        name: "Angry",
        value: 100,
        rating: "1",
      },
    ],
  },
  mealFeedbackChartData: {
    meta: {
      type: "monotone",
      xAxisDataKey: "rating",
      data: [
        {
          dataKey: "value",
        },
      ],
    },
    data: [
      {
        name: "Happy",
        value: 400,
        rating: "4.1-5",
      },
      {
        name: "Shy",
        value: 70,
        rating: "3.1-4",
      },
      {
        name: "Numb",
        value: 200,
        rating: "2.1-3",
      },
      {
        name: "Sad",
        value: 300,
        rating: "1.1-2",
      },
      {
        name: "Angry",
        value: 100,
        rating: "1",
      },
    ],
  },
};

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(t("dashboard.dashboard"));
  const currentDate = new Date();
  const tenDaysBefore = new Date(currentDate);
  tenDaysBefore.setDate(currentDate.getDate() - 10);
  const [fromDate, setFromDate] = useState(tenDaysBefore);
  const [toDate, setToDate] = useState(new Date());
  const [checkFromToDate, setCheckFromToDate] = useState("");
  const [fromDateToDate, setFromDateToDate] = useState({
    from: formatFromDateToDate(fromDate),
    to: formatFromDateToDate(toDate),
  });
  const handleFromDateToDateChange = (newDates) => {
    const [newFromDate, newToDate] = newDates;

    if (
      newFromDate.getTime() !== fromDate.getTime() ||
      newToDate.getTime() !== toDate.getTime()
    ) {
      setFromDate(newFromDate);
      setToDate(newToDate);
      setCheckFromToDate("checkFromToDate");
    }
  };

  const handleApplyClick = () => {
    setFromDateToDate({
      from: formatFromDateToDate(fromDate),
      to: formatFromDateToDate(toDate),
    });
    setCheckFromToDate("");
  };

  // const handleExportClick = () => {
  //   let sheets = {
  //     sheets: [
  //       {
  //         name: "Sales Order",
  //         columns: ["Total", "Revenue", "Sales Summary3"],
  //         rows: [
  //           ["₼14052.48", "₼4010.82", "₼585.52"],
  //           ["₼14052.48", "₼4010.82", "₼585.52"],
  //           ["₼14052.48", "₼4010.82", "₼585.52"],
  //         ],
  //       },
  //       {
  //         name: "Sales Summary",
  //         columns: ["Total", "Revenue", "Sales Summary3"],
  //         rows: [
  //           ["₼14052.48", "₼4010.82", "₼585.52"],
  //           ["₼14052.48", "₼4010.82", "₼585.52"],
  //           ["₼14052.48", "₼4010.82", "₼585.52"],
  //         ],
  //       },
  //     ],
  //   };
  //
  //
  //
  //   exportToFile({
  //     fileType: FILE_TYPE.xlsx,
  //     fileName: "Report",
  //     date: "2024-07-30",
  //     sheets: sheets,
  //   });
  //
  // };

  return (
    <div className="AdminDashboard">
      <div className="AdminDashboardTitle">
        <h2 className="SemiBold AdminDashboardTitleText">{title}</h2>
        <div className="CalendarRangePickerAndButton">
          {/*<Button*/}
          {/*  text={t("buttons.export")}*/}
          {/*  className={`CalendarRangePickerExportButton`}*/}
          {/*  onClick={handleExportClick}*/}
          {/*/>*/}

          <CalendarRangePicker
            setFromDateToDateProps={handleFromDateToDateChange}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            className="AdminDashboardCalendarRangePicker"
          />
          <Button
            text={t("buttons.apply")}
            className={`CalendarRangePickerButton ${checkFromToDate}`}
            onClick={() => handleApplyClick()}
          />
        </div>
      </div>

      <Outlet
        context={{
          staffSalesData: STAFF_SALES_DATA,
          staffOrdersData: STAFF_ORDERS_DATA,
          staffRatingData: STAFF_RATING_DATA,
          staffTableData: STAFF_TABLE_DATA,
          orderStaffData: ORDER_STAFF_DATA,
          setTitle,
          fromDateToDate,
        }}
      />
    </div>
  );
};

export default AdminDashboard;
