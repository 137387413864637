import React from "react";

import Band from "./Band/Band";
import { getBrandConstructionLogo } from "utils/branding-helper";

const ConstructionLogo = getBrandConstructionLogo();

import "./UnderConstruction.scss";
import { useTranslation } from "react-i18next";

const UnderConstruction = () => {
  const { t } = useTranslation();

  return (
    <div className="UnderConstruction">
      <div className="UnderConstructionBody">
        <div className="UnderConstructionBodyLogo">
          <ConstructionLogo />
        </div>
        <h1 className="UnderConstructionBodyText Medium">
          TEZLİKLƏ BAZARI FƏTH ETMƏYƏ GƏLİRİK!
        </h1>
      </div>
      <div className="Bands">
        {[...Array(5)].map((_, index) => {
          return (
            <Band
              key={index}
              text={t("general.underConstruction")}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UnderConstruction;
