import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import InputControl from "components/admin/forms/input-control/InputControl";
import useOutsideClick from "utils/hooks/useOutsideClick";
import GuestCount from "components/elements/guest-count/GuestCount";
import DateSelection from "components/admin/forms/date-selection/DateSelection";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import CalendarPicker from "components/elements/calendar-picker/CalendarPicker";

import "./ReservationForm.scss";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "../../utils/constants/redux";
import { findTableById } from "../../utils/general";

const ReservationForm = ({ data, onDataUpdate }) => {
  const { zones } = useSelector((state) => state[STORE_NAMES.zones]);
  const [openCalendar, setOpenCalendar, mainElementRefCalendar] =
    useOutsideClick();
  const { t } = useTranslation();

  const reservationTimeOptions = Array.from({ length: 28 }, (_, index) => {
    const hour = Math.floor(index / 2) + 10;
    const minutes = index % 2 === 0 ? "00" : "30";

    return {
      id: index + 1,
      name: `${hour < 10 ? "0" : ""}${hour}:${minutes}`,
    };
  });
  const tableDropdownOptions = zones?.map((zone) => ({
    name: zone.name,
    subOptions: zone.tables,
  }));
  const allTables = zones?.flatMap((zone) => zone.tables);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onDataUpdate((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    onDataUpdate((prevFormData) => ({
      ...prevFormData,
      reservationDay: date.toDateString(),
    }));

    setOpenCalendar(false);
  };

  const handleGuestCountChange = (type) => {
    const updatedFormData = { ...data };

    // always show a minimum of 1 guest
    if (type === "minus" && updatedFormData.guestsCount > 1) {
      updatedFormData.guestsCount -= 1;
    } else if (type === "plus") {
      updatedFormData.guestsCount += 1;
    }

    onDataUpdate(updatedFormData);
  };

  const handleDropdownChange = (value, fieldName) => {
    onDataUpdate((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const findTimeObjectByValue = (value) => {
    if (!value) {
      return reservationTimeOptions[0];
    }

    return reservationTimeOptions.find((option) => option.name === value);
  };

  return (
    <>
      <div className="ReservationForm">
        <form
          className="ReservationEditorForm"
          onSubmit={(e) => e.preventDefault()}
          name="ReservationForm"
        >
          <div className="ReservationEditorContactInfo">
            <h4 className="SemiBold">{t("reservation.contactInfo")}</h4>
            <div className="ReservationEditorInputFields">
              <InputControl
                onChange={handleInputChange}
                name="guestName"
                type="text"
                placeholder={t("reservation.enterName")}
                value={data?.guestName}
              />

              <InputControl
                onChange={handleInputChange}
                name="guestNumber"
                type="text"
                placeholder={t("reservation.enterPhoneNumber")}
                value={data?.guestNumber}
              />

              <Dropdown
                onChange={(table) => {
                  handleDropdownChange(table.id, "tableId");
                }}
                placeholder={t("table.table")}
                value={findTableById(data?.tableId, allTables)}
                options={tableDropdownOptions}
                isGrouped
              />

              <InputControl
                onChange={handleInputChange}
                name="note"
                type="textarea"
                placeholder={t("inputs.addComment")}
                className="ReservationEditorInputTextarea"
                value={data?.note}
                textarea
              />
            </div>
          </div>
          <div className="ReservationEditorBookingInfo">
            <h4 className="SemiBold">{t("reservation.reservationInfo")} </h4>
            <div className="ReservationEditorInputFields">
              <GuestCount
                onMinus={() => handleGuestCountChange("minus")}
                onPlus={() => handleGuestCountChange("plus")}
                count={data?.guestsCount}
              />
              <DateSelection
                label={t("common.date.dateSelection")}
                buttonLabel={t("buttons.change")}
                onSelect={() => setOpenCalendar(true)}
                value={data?.reservationDay}
              />
              <div className="ReservationEditorBookingTimeContainer">
                <h6 className="Medium">{t("reservation.reservationTime")}</h6>
                <div className="ReservationEditorBookingTime">
                  <div className="DateTime">
                    <span>{t("common.time.from")}:</span>
                    <Dropdown
                      onChange={(option) =>
                        handleDropdownChange(option.name, "startTime")
                      }
                      value={findTimeObjectByValue(data?.startTime)}
                      options={reservationTimeOptions}
                      menuMaxHeight={150}
                    />
                  </div>
                  <div className="DateTime">
                    <span>{t("common.time.to")}:</span>
                    <Dropdown
                      onChange={(option) =>
                        handleDropdownChange(option.name, "endTime")
                      }
                      value={findTimeObjectByValue(data?.endTime)}
                      options={reservationTimeOptions}
                      menuMaxHeight={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <CalendarPicker
        value={data?.reservationDay}
        minDate={new Date()}
        refCalendar={mainElementRefCalendar}
        setOpenCalendar={setOpenCalendar}
        openCalendar={openCalendar}
        handleDateChange={(date) => handleDateChange(date)}
      />
    </>
  );
};

ReservationForm.propTypes = {
  data: PropTypes.object,
  onDataUpdate: PropTypes.func.isRequired,
};

export default ReservationForm;
