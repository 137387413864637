import isEqual from "lodash/isEqual";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

import {
  filterCategoryByIsPublishedAndSchedule,
  filterMenuItemByIsPublishedAndSchedule,
  calculateItemPriceWithDefaultModificationPrice,
} from "utils/general";
import ICON_EN from "assets/images/languages/EN.png";
import ICON_RU from "assets/images/languages/RU.png";
import ICON_AZ from "assets/images/languages/AZ.png";
import ICON_TR from "assets/images/languages/TR.webp";
import ICON_DE from "assets/images/languages/DE.png";
import { MONTHS } from "utils/constants/data/base";
import { deleteModificationFromItem } from "../redux/slices/basketStore";
import { createDOBucketName } from "./DO-Spaces";
import { MENU_FIELDS } from "./constants/data/menu-model";
import { SIGN_IN_METHOD } from "pages/common/login/sign-in/SignIn";
import { IMAGE_FILE } from "./constants/DOSpaces";
import { NAVBAR_ROUTES, ROUTES_OF_ROLES } from "./constants/routes";

export const filterMenuByOptions = ({ menu, priceRange, selectedTags }) => {
  const { min: minPrice, max: maxPrice } = priceRange;

  const filteredCategories = menu?.categories
    .filter((category) => category.isPublished)
    .map((category) => ({
      ...category,
      menuItems: category.menuItems.filter((item) => {
        return (
          (calculateItemPriceWithDefaultModificationPrice(item) ||
            item.priceSell) >= minPrice &&
          (calculateItemPriceWithDefaultModificationPrice(item) ||
            item.priceSell) <= maxPrice
        );
      }),
    }))
    .filter((category) => category?.menuItems.length > 0)
    .map((category) => {
      return {
        ...category,
        menuItems:
          selectedTags && selectedTags.length > 0
            ? category.menuItems.filter((menuItem) =>
              menuItem.tags.some((tag) => selectedTags.includes(tag.id))
            )
            : category.menuItems,
      };
    });

  return { ...menu, categories: filteredCategories };
};

export const getBasketModificationsWithDefaultValues = (menuModifications) => {
  return menuModifications.map((modification) => ({
    ...modification,
    options: modification.options
      .filter((option) => option.defaultValue)
      .map((option) => ({ ...option, count: 1 })),
  }));
};

export const findGuest = (userId, order) => {
  if (order?.guests) {
    return order.guests.find((guest) => guest.person.id === userId);
  }
};

export const findMenuItemIndexFromOrders = (menuItem, orderItems) => {
  return orderItems.findIndex((orderItem) => {
    return isEqual(orderItem.item, menuItem);
  });
};

export const getZoneAndTableById = (zones, tableId) => {
  const foundTable = zones
    .flatMap((zone) => zone.tables.map((table) => ({ zone, table })))
    .find(({ table }) => table.id === tableId);

  return foundTable
    ? `${foundTable.zone.name} / ${foundTable.table.name}`
    : "Unknown Zone / Table";
};

export const findZoneOfTable = (zones, tableID) => {
  let resultZone;
  zones.forEach((zone) => {
    const res = zone.tables.find((table) => table.id === tableID);
    if (res) {
      resultZone = zone;
    }
  });
  return resultZone;
};

export const findMenuCategoryByItemId = (menuCategories, menuItemID) => {
  for (const category of menuCategories) {
    for (const menuItem of category.menuItems) {
      if (menuItem.id === menuItemID) {
        return {
          id: category.id,
          name: category.name,
        };
      }
    }
  }
  return null;
};

export const deleteItemModificationFromBasket = (
  dispatch,
  guestId,
  menuItemId,
  modificationId
) => {
  dispatch(deleteModificationFromItem({ guestId, menuItemId, modificationId }));
};

export const findMenuItemByIdAndPublished = (
  menuCategories,
  menuItemInBasket,
  dispatch,
  guestId
) => {
  for (const category of menuCategories) {
    for (const menuItem of category.menuItems) {
      if (menuItem.id === menuItemInBasket.id && menuItem.isPublished) {
        return {
          ...menuItem,
          coverImageSrc: createDOBucketName(menuItem.coverImageSrc),
          otherImagesSrc: menuItem.otherImagesSrc?.map((otherImageSrc) =>
            createDOBucketName(otherImageSrc)
          ),
          modifications: menuItemInBasket.modifications
            .map((modificationInBasket) => {
              const correspondingModification = menuItem.modifications.find(
                (modification) => {
                  return modification.id === modificationInBasket.id;
                }
              );
              if (
                correspondingModification &&
                modificationInBasket.options.length > 0
              ) {
                return {
                  ...correspondingModification,
                  options: modificationInBasket.options
                    .map((optionInBasket) => {
                      const correspondingOption =
                        correspondingModification.options.find((option) => {
                          if (option.id === optionInBasket.id) {
                            return option;
                          }
                        });

                      if (correspondingOption) {
                        return {
                          ...correspondingOption,
                          count: optionInBasket.count,
                        };
                      } else {
                        deleteItemModificationFromBasket(
                          dispatch,
                          guestId,
                          menuItem.id,
                          modificationInBasket.id
                        );
                      }
                    })
                    .filter((option) => option !== undefined),
                };
              } else if (!correspondingModification) {
                deleteItemModificationFromBasket(
                  dispatch,
                  guestId,
                  menuItem.id,
                  modificationInBasket.id
                );
              }
            })
            .filter((modification) => modification !== undefined),
        };
      }
    }
  }
  return null;
};

export const findMostExpensivePrice = (menu) => {
  let maxPrice = 0;

  {
    menu.categories &&
      filterCategoryByIsPublishedAndSchedule(menu.categories).forEach(
        (category) => {
          filterMenuItemByIsPublishedAndSchedule(
            category.menuItems,
            category.id
          ).forEach((item) => {
            if (
              (calculateItemPriceWithDefaultModificationPrice(item) ||
                item.priceSell) > maxPrice
            ) {
              maxPrice =
                calculateItemPriceWithDefaultModificationPrice(item) ||
                item.priceSell;
            }
          });
        }
      );
  }

  return Math.ceil(maxPrice);
};

export const compressImage = async (image, maxSizeMB, maxWidthOrHeight) => {
  const options = {
    maxSizeMB: maxSizeMB || IMAGE_FILE.defaults.maxSizeMB,
    maxWidthOrHeight: maxWidthOrHeight || IMAGE_FILE.defaults.maxWidthOrHeight,
  };

  try {
    return await imageCompression(image, options);
  } catch (error) {
    throw new Error("Error compressing image:", error);
  }
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${formattedHours}:${formattedMinutes}`;
};

export const getInitialLettersFromFullName = (firstName, lastName) => {
  const firstInitial = firstName.charAt(0);
  const lastInitial = lastName.charAt(0);
  return `${firstInitial}${lastInitial}`.toUpperCase();
};

export const formatDateToShortFormat = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const areDatesInTheSameDay = (from, to, today) => {
  return from === today && to === today && from === to;
};

export const checkValidDateRange = (from, to, today) => {
  return (
    (today >= from && today <= to && from <= to) ||
    areDatesInTheSameDay(
      from.toDateString(),
      to.toDateString(),
      today.toDateString()
    )
  );
};

export const compareDateWithCurrentDate = (date) => {
  const today = new Date();

  return date > today ? date : today;
};

export function formatDateToLongFormat(dateString) {
  const originalDate = new Date(dateString);
  const day = originalDate.getUTCDate();
  const month = MONTHS.find(
    (month) => month.sequence === originalDate.getUTCMonth()
  )?.name;
  const year = originalDate.getUTCFullYear();

  return { day, month, year };
}

export const handleOnAsyncError = (errorMessage, callback) => {
  toast.error(errorMessage);
  if (typeof callback === "function") {
    callback();
  }
};

export const handleOnAsyncSuccess = (successMessage, callback) => {
  toast.success(successMessage);
  if (typeof callback === "function") {
    callback();
  }
};

export const generateGuestIdSuffix = (guestId) => {
  return `${guestId}G`;
};

export const formatTimeToHHMM = (dateTime) => {
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""
    }${minutes}`;
};

export const formatShortDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
};

export const formatDashBoardDate = (inputString) => {
  const date = new Date(inputString);
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
    month: "short",
  })}, ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;

  return formattedDate;
};

export const generateCombinedDateTime = (reservationDay, time) => {
  const date = new Date(reservationDay);
  const [hours, minutes] = time.split(":");

  // Set the time on the date object
  date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

  // Use toLocaleString to format the date and time
  return date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const getDestructuredReservation = (reservation) => {
  const {
    guestName,
    guestNumber,
    note,
    guestsCount,
    reservationDay,
    startTime,
    endTime,
    tableId,
  } = reservation;

  return {
    guestName,
    guestNumber,
    note,
    guestsCount,
    tableId,
    startDateTime: generateCombinedDateTime(reservationDay, startTime),
    endDateTime: generateCombinedDateTime(reservationDay, endTime),
  };
};

export const formatDateToCustomFormat = (dateString) => {
  const date = dateString ? new Date(dateString) : new Date();
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}/${year}`;
};

export const getLanguageImage = (languageCode) => {
  switch (languageCode) {
    case "EN":
      return ICON_EN;
    case "RU":
      return ICON_RU;
    case "AZ":
      return ICON_AZ;
    case "TR":
      return ICON_TR;
    case "DE":
      return ICON_DE;
    default:
      return null;
  }
};

export const getActiveLanguageValue = (property, activeLanguageCode) => {
  return (
    property.find((p) => p.languageCode === activeLanguageCode)?.value || ""
  );
};

export const findLanguagesByProperty = (property, allLanguages) => {
  const sortedLanguages = [];
  property.map((p) => {
    if (p.value !== null) {
      sortedLanguages.push(
        allLanguages.find((language) => language.code === p.languageCode)
      );
    }
  });
  return sortedLanguages;
};

export const getInitialSchedule = (date) => {
  date.setHours(0, 0, 0, 0);
  return {
    isActive: false,
    from: date.toISOString(),
    to: date.toISOString(),
    weekdays: {
      monday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
      tuesday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
      wednesday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
      thursday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
      friday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
      saturday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
      sunday: {
        from: "10:00",
        to: "23:00",
        isWorking: true,
      },
    },
  };
};

export const getTranslationPropertyRequestBody = (property) => {
  return (
    property
      .map((p) => ({
        ...p,
        languageCode: p.languageCode,
      }))
      // eslint-disable-next-line no-unused-vars
      .map(({ language, ...rest }) => rest)
  );
};

export const convertSnakeCaseToTitleCase = (text) => {
  return text
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
};

export const convertTitleCaseToSnakeCase = (text) => {
  return text?.toLowerCase()?.replace(/\s+/g, "_")?.toUpperCase();
};

export const getGuestsWithUnconfirmedItems = (formData) => {
  const guestsWithUnconfirmedItems = [];

  formData.guests.forEach((guest) => {
    const confirmedOrderItems = guest.orderItems.filter(
      (orderItem) =>
        orderItem.isConfirmed === null && orderItem.isPendingList !== true
    );
    if (confirmedOrderItems.length > 0) {
      guestsWithUnconfirmedItems.push({
        ...guest,
        orderItems: confirmedOrderItems,
      });
    }
  });
  return guestsWithUnconfirmedItems;
};

export const getGuestsWithConfirmed = (formData) => {
  const guestsWithConfirmedItems = [];

  formData.guests.forEach((guest) => {
    const confirmedOrderItems = guest.orderItems.filter(
      (orderItem) => orderItem.isConfirmed
    );
    if (confirmedOrderItems.length > 0) {
      guestsWithConfirmedItems.push({
        ...guest,
        orderItems: confirmedOrderItems,
      });
    }
  });
  return guestsWithConfirmedItems;
};

export const getUsersWithConfirmed = (formData) => {
  const usersWithConfirmedItems = [];

  formData.users.forEach((guest) => {
    const confirmedOrderItems = guest.orderItems.filter(
      (orderItem) => orderItem.isConfirmed
    );
    if (confirmedOrderItems.length > 0) {
      usersWithConfirmedItems.push({
        ...guest,
        orderItems: confirmedOrderItems,
      });
    }
  });
  return usersWithConfirmedItems;
};

export const getGuestsWithConfirmedAndRejected = (formData) => {
  const guestsWithConfirmedItems = [];

  formData.guests.forEach((guest) => {
    const confirmedOrderItems = guest.orderItems.filter(
      (orderItem) => orderItem.isConfirmed !== null
    );
    if (confirmedOrderItems.length > 0) {
      guestsWithConfirmedItems.push({
        ...guest,
        orderItems: confirmedOrderItems,
      });
    }
  });
  return guestsWithConfirmedItems;
};

export const getUsersWithConfirmedAndRejected = (formData) => {
  const usersWithConfirmedItems = [];

  formData.users.forEach((guest) => {
    const confirmedOrderItems = guest.orderItems.filter(
      (orderItem) => orderItem.isConfirmed !== null
    );
    if (confirmedOrderItems.length > 0) {
      usersWithConfirmedItems.push({
        ...guest,
        orderItems: confirmedOrderItems,
      });
    }
  });
  return usersWithConfirmedItems;
};

export const getUniqueItems = (users) => {
  const uniqueIds = new Set();
  users.forEach((order) => {
    order.orderItems.forEach((item) => {
      uniqueIds.add(item.item.id);
    });
  });
  return Array.from(uniqueIds);
};

export const filterZonesAndTablesByIsArchived = (zones) =>
  zones
    .filter((zone) => !zone.isArchived)
    .map((zone) => ({
      ...zone,
      tables: zone.tables.filter((table) => !table.isArchived),
    }));

export const filterByIsArchived = (items) =>
  items.filter((item) => !item.isArchived);

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isGuestOrUserHasAnyOrder = (guests, users) => {
  const isGuestHasOrder = guests.some((guest) =>
    guest.orderItems.some((orderItem) => orderItem.isConfirmed !== false)
  );
  if (isGuestHasOrder) {
    return true;
  }

  return users.some((user) =>
    user.orderItems.some((orderItem) => orderItem.isConfirmed !== false)
  );
};

export const mergeUserData = (firstUser, secondUser) => {
  const mergedUser = [];

  firstUser.forEach((currentGuest) => {
    const matchingGuest = secondUser.find(
      (guest) => guest.person.id === currentGuest.person.id
    );
    if (matchingGuest) {
      const mergedOrderItems = [];
      currentGuest.orderItems.forEach((currentOrderItem) => {
        const matchingOrderItem = matchingGuest.orderItems.find(
          (item) => item.id === currentOrderItem.id
        );
        if (matchingOrderItem) {
          mergedOrderItems.push({
            ...currentOrderItem,
            count: currentOrderItem.count + 1,
          });
        } else {
          mergedOrderItems.push(currentOrderItem);
        }
      });
      matchingGuest.orderItems.forEach((matchingOrderItem) => {
        const existingOrderItemIndex = mergedOrderItems.findIndex(
          (item) => item.id === matchingOrderItem.id
        );
        if (existingOrderItemIndex === -1) {
          mergedOrderItems.push(matchingOrderItem);
        }
      });
      const mergedGuest = {
        person: currentGuest.person,
        orderItems: mergedOrderItems,
      };
      mergedUser.push(mergedGuest);
    } else {
      mergedUser.push(currentGuest);
    }
  });

  secondUser.forEach((newGuest) => {
    const existingGuest = firstUser.find(
      (guest) => guest.person.id === newGuest.person.id
    );
    if (!existingGuest) {
      mergedUser.push(newGuest);
    }
  });

  return mergedUser;
};

export const mergeGuestData = (firstUser, secondUser) => {
  const mergedUser = [];

  firstUser.forEach((currentGuest) => {
    const matchingGuest = secondUser.find(
      (guest) => guest.person.id === currentGuest.person.id
    );
    if (matchingGuest) {
      const mergedOrderItems = [];
      currentGuest.orderItems.forEach((currentOrderItem) => {
        const matchingOrderItem = matchingGuest.orderItems.find(
          (item) => item.id === currentOrderItem.id
        );
        if (matchingOrderItem) {
          mergedOrderItems.push({
            ...matchingOrderItem,
            count: currentOrderItem.count,
            item: currentOrderItem.item,
          });
        } else {
          mergedOrderItems.push(currentOrderItem);
        }
      });
      matchingGuest.orderItems.forEach((matchingOrderItem) => {
        const existingOrderItemIndex = mergedOrderItems.findIndex(
          (item) => item.id === matchingOrderItem.id
        );
        if (existingOrderItemIndex === -1) {
          mergedOrderItems.push(matchingOrderItem);
        }
      });
      const mergedGuest = {
        ...currentGuest,
        person: currentGuest.person,
        orderItems: mergedOrderItems,
      };
      mergedUser.push(mergedGuest);
    } else {
      mergedUser.push(currentGuest);
    }
  });

  secondUser.forEach((newGuest) => {
    const existingGuest = firstUser.find(
      (guest) => guest.person.id === newGuest.person.id
    );
    if (!existingGuest) {
      mergedUser.push(newGuest);
    }
  });

  return mergedUser;
};

// Function to calculate brightness of a color
export const detectColorBrightness = (color) => {
  const hex = color?.replace("#", "");
  const r = parseInt(hex?.substring(0, 2), 16);
  const g = parseInt(hex?.substring(2, 4), 16);
  const b = parseInt(hex?.substring(4, 6), 16);
  const brightness = (Math.max(r, g, b) + Math.min(r, g, b)) / 2;
  return (brightness / 255) * 100;
};

export const deepMergeById = (target, source) => {
  const mergeArraysById = (targetArray, sourceArray) => {
    sourceArray.forEach((sourceItem) => {
      const targetIndex = targetArray.findIndex(
        (targetItem) => targetItem.id === sourceItem.id
      );
      if (targetIndex >= 0) {
        targetArray[targetIndex] = deepMergeById(
          targetArray[targetIndex],
          sourceItem
        );
      } else {
        targetArray.push(sourceItem);
      }
    });
    return targetArray;
  };

  Object.keys(source).forEach((key) => {
    if (Object.values(MENU_FIELDS).includes(key)) {
      target[key] = source[key];
    } else if (Array.isArray(source[key])) {
      if (!target[key]) {
        target[key] = [];
      }
      target[key] = mergeArraysById(target[key], source[key]);
    } else if (source[key] && typeof source[key] === "object") {
      if (!target[key] || typeof target[key] !== "object") {
        target[key] = {};
      }
      deepMergeById(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  });

  return target;
};

export const findModificationById = (modifications, modificationId) => {
  if (!modifications) {
    return null;
  }
  return modifications.find(
    (modification) => modification.id === modificationId
  );
};

export const findOptionById = (options, optionId) => {
  if (!options) {
    return null;
  }
  return options.find((option) => option.id === optionId);
};

export const findMenuItemById = (allCategories, menuId) => {
  for (const category of allCategories) {
    const foundItem = category?.menuItems?.find(
      (menuItem) => menuItem.id === menuId
    );
    if (foundItem) {
      return foundItem;
    }
  }
  return null;
};

export const getEmailOrPhone = (formData, signInMethod) => {
  if (signInMethod === SIGN_IN_METHOD.email) {
    return formData.email;
  }
  return formData.phoneNumber.replace(/\+/g, "");
};

export const getUserRoutesByRole = (role) => {
  return ROUTES_OF_ROLES.find((route) => route.role === role);
}


