import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCube, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import Offer from "assets/images/Offer.png";

import "./BannerSliderCube.scss";

const BannerSliderCube = ({ title, autoplayDisableOnInteraction }) => {
  return (
    <div className="BannerSlider">
      <h2 className="BannerSliderTitle Medium">{title}</h2>
      <Swiper
        effect={"cube"}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: autoplayDisableOnInteraction,
        }}
        pagination={true}
        modules={[EffectCube, Pagination, Autoplay]}
        init={false}
      >
        <SwiperSlide>
          <img src={Offer} alt="offer" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Offer} alt="offer" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Offer} alt="offer" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Offer} alt="offer" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

BannerSliderCube.propTypes = {
  title: PropTypes.string,
  autoplayDisableOnInteraction: PropTypes.bool,
};

export default BannerSliderCube;
