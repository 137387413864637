import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./TextIconButton.scss";

const TextIconButton = ({ onClick, className, svgComponent, text }) => {
  return (
    <button
      className={cx("TextIconButton", className)}
      onClick={onClick}
      type="button"
    >
      {svgComponent}
      <p>{text}</p>
    </button>
  );
};

TextIconButton.propTypes = {
  /**
   * The function called when button clicked
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Custom className for additional styling
   */
  className: PropTypes.string,

  /**
   * The SVG component to be displayed within the button.
   */
  svgComponent: PropTypes.node.isRequired,

  /**
   * Custom className for additional styling
   */
  text: PropTypes.string,
};

export default TextIconButton;
