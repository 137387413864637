import React from "react";
import PropTypes from "prop-types";

import "./InfraCard.scss";

const InfraCard = ({ count, label, contentOnHover }) => {
  return (
    <div className="InfraCard">
      <h3 className="InfraCardCount Bold">{count}</h3>
      <h6 className="InfraCardTitle Medium">{label}</h6>
      {contentOnHover && (
        <div className="InfraCardContentOnHover">
          <h6 className="Medium">{contentOnHover}</h6>
        </div>
      )}
    </div>
  );
};

InfraCard.propTypes = {
  /**
   * The count to display in the InfraCard
   */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /**
   * The label to display in the InfraCard
   */
  label: PropTypes.string.isRequired,

  /**
   * The content to display on hover in the InfraCard
   */
  contentOnHover: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default InfraCard;
