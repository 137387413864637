import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./DonutChart.scss";

export const ENUMS = {
  name: "DonutChart",
};

export const GRADIENTS = [
  {
    id: "gradient1",
    stops: [
      { offset: "0%", color: "#8D8D8D" },
      { offset: "100%", color: "#8D8D8D" },
    ],
  },
  {
    id: "gradient2",

    stops: [
      { offset: "0%", color: "#FFCA40" },
      { offset: "100%", color: "#FFCA40" },
    ],
  },
];
const DonutChart = ({ data }) => {
  const [activeItem, setActiveItem] = useState(null);
  const cx = 50;
  const cy = 50;
  const strokeWidth = 10;
  const radius = 35;
  const dashArray = 2 * Math.PI * radius;
  const startAngle = -90;
  let filled = 0;

  function calculateCategoryPercentages(data) {
    let overallTotal = 0;

    // Calculate the overall total sum of values
    data?.forEach((item) => {
      overallTotal += item.value;
    });

    // Calculate the percentage for each category
    data?.forEach((item) => {
      item.percentage = ((item.value / overallTotal) * 100).toFixed(2); // Keeping two decimal places for percentages
    });

    return data;
  }

  calculateCategoryPercentages(data);

  const sum = 100;

  const ratio = 100 / sum;

  let maxDataValue = 0;
  let maxDataValueContent = "";

  data.forEach((obj) => {
    const itemRatio = ratio * obj.percentage;

    obj.itemRatio = itemRatio;
    obj.angle = (filled * 360) / 100 + startAngle;
    obj.offset = dashArray - (dashArray * itemRatio) / 100;
    obj.filled = filled;

    filled += itemRatio;

    if (obj.percentage > maxDataValue) {
      maxDataValue = obj.percentage;
      maxDataValueContent = obj.content;
    }
  });

  useEffect(() => {
    setActiveItem(data[1]);
  }, [data]);

  return (
    <div className="DonutChart">
      <div className="DonutChartContainer">
        <svg viewBox="0 0 100 100">
          <defs>
            {GRADIENTS.map((gradient, index) => (
              <linearGradient
                key={index}
                id={gradient.id}
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                {gradient.stops.map((stop, index) => (
                  <stop
                    key={index}
                    offset={stop.offset}
                    style={{ stopColor: stop.color, stopOpacity: 1 }}
                  />
                ))}
              </linearGradient>
            ))}
          </defs>
          {data.map((item, index) => (
            <circle
              key={index}
              cx={cx}
              cy={cy}
              r={radius}
              fill="transparent"
              strokeWidth={strokeWidth}
              stroke={
                data.length === 1 ? "#FFCA40" : `url(#${item.gradientId})`
              }
              strokeDasharray={dashArray}
              strokeDashoffset={item.offset}
              transform={`rotate(${item.angle} ${cx} ${cy})`}
              className={activeItem === item ? "isActive" : ""}
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from={`${startAngle} ${cx} ${cy}`}
                to={`${item.angle} ${cx} ${cy}`}
                dur="1s"
              />
            </circle>
          ))}
          <circle cx={cx} cy={cy} r={radius - 8} fill="transparent"></circle>
        </svg>
        <div className="DonutChartPercentage">
          <h1 className="Bold">{maxDataValue === 0 ? 100 : maxDataValue}%</h1>
          <h6 className="h7">{maxDataValueContent}</h6>
        </div>
      </div>
      <ul className="DonutChartItems">
        {data.map((item, index) => {
          const gradient = GRADIENTS.find(
            (gradient) => gradient.id === item.gradientId
          );
          const background =
            data.length === 1
              ? "#FFCA40"
              : `linear-gradient(90deg, ${gradient.stops[0].color}, ${gradient.stops[1].color})`;

          return (
            <li key={index} className="DonutChartItem">
              <span
                className="DonutChartItemsColor"
                style={{ background }}
              ></span>
              <h6 className="DonutChartItemsName Medium">
                {item.percentage === "NaN" ? 100 : item.percentage}% {item.name}
              </h6>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

DonutChart.propTypes = {
  /**
   * The title of the analytics chart
   */
  title: PropTypes.string.isRequired,

  /**
   * The data for the donut chart
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      gradientId: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DonutChart;
