import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import MenuItemStatsCard from "components/cards/menu-item-stats-card/MenuItemStatsCard";
import EmptyCategory from "assets/icons/admin-dashboard/EmptyCategory.svg";
import { formatDateRange } from "utils/helper-functions/dashboardStatsHelper";

import "./AdminDashMenuByOrder.scss";

const AdminDashMenuByOrder = ({
  leftColumn,
  rightColumn,
  leftColumnName,
  rightColumnName,
  columnItemPrimaryName,
  columnItemSecondaryName,
  columnItemTernaryName,
  element,
  title,
  subTitle,
  className,
}) => {
  const { t } = useTranslation();
  const formattedDateRange = subTitle
    ? formatDateRange(subTitle.from, subTitle.to, t)
    : "";

  let checkDataLength = false;
  let checkOrderHeight = rightColumn.length === 0 && leftColumn.length === 0;
  const noOrderHeight = checkOrderHeight ? { height: "478px" } : {};
  if (
    rightColumn.length > 0 &&
    rightColumn.length < 2 &&
    leftColumn.length > 0 &&
    leftColumn.length < 2
  ) {
    checkDataLength = true;
  }
  return (
    <div
      className={`AdminDashMenuByOrder CardInsetShadow-v1 ${className}`}
      style={noOrderHeight}
    >
      <div className="AdminDashMenuByOrderTitleAndElement">
        <div className="AdminDashMenuByOrderTitleAndSubTitle">
          <h3 className="SemiBold">{title}</h3>
          <h5 className="SemiBold">({formattedDateRange})</h5>
        </div>
        {element && (
          <div
            className={cx("AdminDashMenuByOrderElement", {
              AdminDashMenuByOrderElementCustomElementSize: checkDataLength,
            })}
          >
            {element}
          </div>
        )}
      </div>

      {checkOrderHeight ? (
        <div className="AdminDashMenuByOrderEmpty">
          <img src={EmptyCategory} alt="Empty Category" />
          <h4 className="Medium AdminDashMenuByOrderEmptyMessage">
            {t("emptyStates.noOrderedCategory")}
          </h4>
        </div>
      ) : (
        <div className="AdminDashMenuByOrderFirstColumnAndSecondColumn">
          <div className="AdminDashMenuByOrderFirstColumn">
            <h3 className="Medium AdminDashMenuByOrderBestSellingTitle">
              {leftColumnName}
            </h3>

            <div className="AdminMenuByOrderStatCards">
              {leftColumn.map((item, index) => {
                return (
                  <div key={index}>
                    <MenuItemStatsCard
                      sequenceNumber={index}
                      primaryDataName={columnItemPrimaryName}
                      secondaryDataName={columnItemSecondaryName}
                      ternaryDataName={columnItemTernaryName}
                      imageSource={item.imageSrc}
                      name={item.itemName}
                      primaryData={item.primaryData}
                      secondaryData={item.secondaryData}
                      ternaryData={item.ternaryData}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="AdminDashMenuByOrderSecondColumn">
            <h3 className="Medium AdminDashMenuByOrderFirstColumnTitle">
              {rightColumnName}
            </h3>

            <div className="AdminMenuByOrderStatCards">
              {rightColumn.map((item, index) => {
                return (
                  <div key={index}>
                    <MenuItemStatsCard
                      sequenceNumber={index}
                      primaryDataName={columnItemPrimaryName}
                      secondaryDataName={columnItemSecondaryName}
                      ternaryDataName={columnItemTernaryName}
                      imageSource={item.imageSrc}
                      name={item.itemName}
                      primaryData={item.primaryData}
                      secondaryData={item.secondaryData}
                      ternaryData={item.ternaryData}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminDashMenuByOrder.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  element: PropTypes.element,
  leftColumnName: PropTypes.string,
  rightColumnName: PropTypes.string,
  columnItemPrimaryName: PropTypes.string,
  columnItemSecondaryName: PropTypes.string,
  columnItemTernaryName: PropTypes.string,
  leftColumn: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.string,
      itemName: PropTypes.string.isRequired,
      imageSrc: PropTypes.any,
      primaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      secondaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      ternaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  rightColumn: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.string,
      itemName: PropTypes.string.isRequired,
      imageSrc: PropTypes.any,
      primaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      secondaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      ternaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};

export default AdminDashMenuByOrder;
