import { AXIOS } from "utils/api/axios";
import { API_PATH_PARAMS, API_PATH_HELPERS } from "utils/constants/api";

export const getBusiness = async (id) =>
  AXIOS.get(`/${API_PATH_PARAMS.business}/${id}`);
export const createBusiness = async (business, method) =>
  AXIOS.post(
    `/${API_PATH_PARAMS.business}?${API_PATH_HELPERS.method}=${method}`,
    business
  );
export const updateBusiness = async (business, id) =>
  AXIOS.patch(`/${API_PATH_PARAMS.business}/${id}`, business);

export const updateBusinessSettings = async (business, id) =>
  AXIOS.patch(`/${API_PATH_PARAMS.business}/${id}/${API_PATH_PARAMS.settings}`, business);

export const deleteBusiness = async (id) =>
  AXIOS.delete(`/${API_PATH_PARAMS.business}/${id}`);

export const getAllBusinesses = () => {
  return AXIOS.get(`/${API_PATH_PARAMS.business}`);
};
