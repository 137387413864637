import React from "react";
import PropTypes from "prop-types";
import ReactConfetti from "react-confetti";
import { useTranslation } from "react-i18next";

import ICON_SMILEY_FACE from "assets/icons/other/SmileyFace.svg";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useWindowSize from "utils/hooks/useWindowSize";
import { getBrandMainLogo } from "utils/branding-helper";

import "./CompletedOrder.scss";

const MainLogo = getBrandMainLogo();

const CompletedOrder = ({
  onClick,
  hasConfettiEffect,
  hasSmileyFaceIcon,
  title,
  description,
}) => {
  const { width, height } = useWindowSize();
  const { t } = useTranslation();

  return (
    <div className="CompletedOrderPopUp">
      {hasConfettiEffect && (
        <ReactConfetti
          width={window.innerWidth <= 1000 ? width : 500}
          height={window.innerHeight <= 1000 ? height : 700}
          recycle={false}
          numberOfPieces={900}
        />
      )}
      <MainLogo className="CompletedOrderLogo" />
      <div className="CompletedOrderMainContainer">
        {hasSmileyFaceIcon && (
          <img
            src={ICON_SMILEY_FACE}
            alt="smiley-face"
            className="CompletedOrderSmileyFace"
          />
        )}
        <h3 className="SemiBold CompletedOrderTitle">{title}</h3>
        <h5 className="CompletedOrderDescription">{description}</h5>
      </div>

      <div className="CompletedOrderFooterContainer">
        <h5 className="Medium">{t("general.scanQR")}</h5>
        <h5 className="Medium CompletedOrderOrSeparator">{t("login.or")}</h5>
        <PrimaryButton
          text={t("buttons.close")}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_D}
          className="CompletedOrderCloseButton"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

CompletedOrder.propTypes = {
  /**
   * Function to handle the click event
   * */
  onClick: PropTypes.func,

  /**
   * Boolean to determine if the confetti effect should be displayed
   * */
  hasConfettiEffect: PropTypes.bool,

  /**
   * Boolean to determine if the smiley face should be displayed
   * */
  hasSmileyFaceIcon: PropTypes.bool,

  /**
   * Title of the completed order
   * */
  title: PropTypes.string,

  /**
   * Description of the completed order
   * */
  description: PropTypes.string,
};

export default CompletedOrder;
