import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import { useImageVisibility } from "utils/context-api/ImageVisibilityContext";
import useWindowSize from "utils/hooks/useWindowSize";

const LazyImage = ({ src, placeholder, alt, className }) => {
  const imgRef = useRef();
  const [imgSrc, setImgSrc] = useState(src || placeholder);
  const { visibleImages, setImageVisible } = useImageVisibility();
  const isVisible = visibleImages[src];
  const { height } = useWindowSize().size;

  useEffect(() => {
    if (isVisible) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageVisible(src);
            observer.disconnect();
          }
        });
      },
      {
        rootMargin: `${2 * height}px`,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [isVisible, setImageVisible, src]);

  const handleError = () => {
    setImgSrc(placeholder);
  };

  useEffect(() => {
    setImgSrc(src || placeholder);
  }, [src, isVisible]);

  return (
    <img
      className={"LazyImage " + className}
      ref={imgRef}
      src={isVisible ? imgSrc : ITEM_PLACEHOLDER}
      alt={alt}
      onError={handleError}
    />
  );
};

LazyImage.propTypes = {
  src: PropTypes.string,
  placeholder: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default LazyImage;
