import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { ErrorMessage } from "@hookform/error-message";
import { Trans, useTranslation } from "react-i18next";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { PhoneInput } from "react-international-phone";
import Modal from "components/modal/Modal";
import CloseButton from "components/buttons/close-button/CloseButton";
import AddGuestPhoto from "components/elements/add-guest-photo/AddGuestPhoto";
import PolicyModal from "components//policy-modal/PolicyModal";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { removeImageFromDO, uploadImageToDO } from "utils/DO-Spaces";
import { updateGuestInfoAsync } from "redux/actions/guestAction";
import cx from "classnames";

import { ENUMS as ENUMS_EMAIL_OR_PHONE } from "components/elements/email-or-phone/EmailOrPhone";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";
import { DO_FILE_TYPES, IMAGE_FILE } from "utils/constants/DOSpaces";
import { STORE_NAMES } from "utils/constants/redux";

import "./GuestAccountInfoModal.scss";

const GuestAccountInfoModal = ({
  openSlide,
  setOpenSlide,
  onClose,
  canEditGuestProfile,
  guestInfo,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const guestAccountInfoSchema = useValidationSchema(t).guestAccountInfoSchema;

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // const [uploadedImages, setUploadedImages] = useState([]);
  const [profilePic, setProfilePic] = useState(guestInfo?.profilePicture || "");
  const [openSlidePolicy, setOpenSlidePolicy, mainElementRefPolicy] =
    useOutsideClick();
  const [openSlideConfirm, setOpenSlideConfirm, mainElementRefConfirm] =
    useOutsideClick();
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const { id: guestId } = useSelector((state) => state[STORE_NAMES.guest]);

  const isLoadingUpdateGuestInfo = useSelector(
    (state) => state[STORE_NAMES.guest].thunkAPIStates?.updateGuestInfo
  );
  const { id: tableId } = useSelector(
    (state) => state[STORE_NAMES.qrScan].table
  );
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order.table.id === tableId);
  const guestInOrder = order?.guests.find(
    (guest) => guest.person.id === guestId
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    control,
    getValues,
    reset,
  } = useForm({
    criteriaMode: "all",
    resolver: zodResolver(guestAccountInfoSchema),
  });

  const watchedFields = useWatch({ control });

  const handleOnCancelConfirm = () => {
    setOpenSlideConfirm(false);
  };

  const handleOnConfirm = () => {
    setOpenSlideConfirm(false);
    setOpenSlide(false);
  };

  const handleOnSubmit = async () => {
    const formData = {
      ...getValues(),
      phoneNumber: getValues().phoneNumber.replace(/\+/g, ""),
      profilePic,
    };
    try {
      const response = await dispatch(
        updateGuestInfoAsync({
          id: guestId,
          guestInfo: {
            ...formData,
            orderData: [
              {
                businessId,
                tableId,
                orderId: order.id,
                orderGuestId: guestInOrder.id,
              },
            ],
          },
        })
      );
      if (response.error) {
        const errorData = handleAPIErrorMessage(response.payload);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      } else {
        // uploadedImages.map((image) => {
        // 	if (image !== profilePic) {
        // 		removeImageFromDO(image);
        // 	}
        // });
        // setUploadedImages([]);
        onClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (openSlide) {
      reset({
        name: canEditGuestProfile
          ? guestInfo?.name || t("dashboard.guest.guest") + " " + guestId
          : guestInfo?.name || "",
        phoneNumber: guestInfo?.phoneNumber || "",
      });

      setProfilePic(guestInfo?.profilePicture || "");
      setHasUnsavedChanges(false);
      setOpenSlideConfirm(false);
    }
  }, [openSlide]);

  useEffect(() => {
    const initialData = guestInfo
      ? {
          name: guestInfo.name || t("dashboard.guest.guest") + " " + guestId,
          phoneNumber: guestInfo.phoneNumber || "",
          profilePicture: guestInfo.profilePicture || "",
        }
      : {
          name: "",
          phoneNumber: "",
          profilePicture: "",
        };

    const currentData = {
      name: watchedFields.name,
      phoneNumber: watchedFields.phoneNumber?.replace(/\+/g, ""),
      profilePicture: profilePic,
    };

    const isEqualInitialAndCurrentData = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    setHasUnsavedChanges(
      !isEqualInitialAndCurrentData(initialData, currentData)
    );
  }, [watchedFields, guestInfo, profilePic]);

  const handleOnRemoveRedundantImagesFromAWSWhenCloseModal = () => {
    if (profilePic && guestInfo?.profilePicture !== profilePic) {
      removeImageFromDO(profilePic);
    }
  };

  const handleOnSelectSingleImage = async (selectedImage) => {
    const imageType = DO_FILE_TYPES.GUEST_PROFILE_PHOTO;
    try {
      await uploadImageToDO({
        image: selectedImage,
        maxSizeMB: IMAGE_FILE.guestAvatar.maxSizeMB,
        maxWidthOrHeight: IMAGE_FILE.guestAvatar.maxWidthOrHeight,
        fileType: imageType,
        businessId,
        onSuccess: (location) => {
          // setUploadedImages([...uploadedImages, location]);
          setProfilePic(location);
        },
        onError: () => undefined,
      });
    } catch (error) {
      throw new Error("Something went wrong...", error);
    }
  };

  const handlePhoneInputChange = (phone, meta, onChange) => {
    if (
      phone &&
      phone !== ENUMS_EMAIL_OR_PHONE.plusSign + meta.country.dialCode
    ) {
      onChange(phone);
    } else {
      onChange("");
    }
  };

  const handleOnModalClose = () => {
    if (hasUnsavedChanges) {
      setOpenSlideConfirm(true);
    } else {
      setOpenSlideConfirm(false);
      setOpenSlide(false);
      handleOnRemoveRedundantImagesFromAWSWhenCloseModal();
      onClose();
    }
  };

  const handleOnGuPolicyModalClose = () => {
    setOpenSlidePolicy(false);
  };
  const GuestAccountInfoModalHeader = (
    <div className="GuestAccountInfoModalHeader">
      <h2 className="GuestAccountInfoModalHeaderTitle Bold">
        {t("general.name")}
      </h2>
      <CloseButton
        onClick={handleOnModalClose}
        type={ENUMS_CLOSE_BUTTON.types.TYPE_F}
      />
    </div>
  );

  const GuestAccountInfoModalBody = (
    <div className="GuestAccountInfoModalBody">
      <form className="GuestAccountInfoModalForm">
        <div className="GuestAccountInfoModalBodyProfileWrapper">
          <AddGuestPhoto
            onFileSelect={(selectedImage) =>
              handleOnSelectSingleImage(selectedImage)
            }
            hasImage={!!profilePic}
            image={profilePic}
          />
          <h6 className="Medium">{t("general.profilePicture")}</h6>
        </div>
        <div className="GuestAccountInfoModalBodyInputContainer">
          <InputControl
            type="text"
            placeholder={t("inputs.yourName")}
            name="name"
            func={{ ...register("name") }}
            hasError={errors.name}
            labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
            error={
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
          />
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue={guestInfo?.phoneNumber || ""}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                value={value}
                onChange={(phone, meta) =>
                  handlePhoneInputChange(phone, meta, onChange)
                }
                defaultCountry={ENUMS_EMAIL_OR_PHONE.defaultCountry}
                hideDropdown
                forceDialCode
                placeholder={t("inputs.phoneNumber")}
                className={cx("phone-input", {
                  hasError: errors.phoneNumber,
                })}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            )}
          />
          <div className="EmailOrPhoneSignInMethodError">
            {errors.phoneNumber && (
              <ErrorMessage
                errors={errors}
                name="phoneNumber"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            )}
          </div>
        </div>
      </form>
      {!canEditGuestProfile && (
        <div className="GuestLoginModalPrivacyAndPolicyModal">
          <p
            className="GuestLoginModalPrivacyAndPolicyModalLink"
            onClick={() => setOpenSlidePolicy(true)}
          >
            <Trans i18nKey="policy.termsDataInfo" />
          </p>
          <PolicyModal
            open={openSlidePolicy}
            onClose={handleOnGuPolicyModalClose}
            mainElementRefPolicy={mainElementRefPolicy}
            policyModalTranslateFileKey={"joinUsTermsData"}
          />
        </div>
      )}
    </div>
  );

  const GuestAccountInfoModalFooter = (
    <PrimaryButton
      onClick={handleSubmit(handleOnSubmit)}
      text={canEditGuestProfile ? t("buttons.save") : t("buttons.continue")}
      className="GuestAccountInfoModalFooterButton"
      type={PRIMARY_BUTTON_ENUMS.types.TYPE_I}
      isLoading={isLoadingUpdateGuestInfo}
    />
  );

  return (
    <div className="GuestAccountInfoModal">
      <Modal
        header={GuestAccountInfoModalHeader}
        body={GuestAccountInfoModalBody}
        footer={GuestAccountInfoModalFooter}
        openSlide={openSlide}
        onClose={onClose}
      />

      <Confirm
        type={ENUMS_CONFIRM.types.TYPE_B}
        title={t("modal.warningModalTitleUnsavedChanges")}
        description={t("modal.warningModalDescription")}
        mainElementRefConfirm={mainElementRefConfirm}
        onCancel={handleOnCancelConfirm}
        onConfirm={handleOnConfirm}
        openSlide={openSlideConfirm}
      />
    </div>
  );
};

GuestAccountInfoModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  setOpenSlide: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  canEditGuestProfile: PropTypes.bool,
  guestInfo: PropTypes.shape({
    profilePicture: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

export default GuestAccountInfoModal;
