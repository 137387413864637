import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./ChartPeriodSelectButton.scss";
export const CHART_PERIODS = {
  hourly: "hourly",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
};

const ChartPeriodSelectButton = ({
  activeButton,
  setActiveButton,
  className,
  disableMonthlyButton = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`ChartPeriodSelectButton ${className}`}>
      <button
        className={`weekDaysBtnTitle Medium ${
          activeButton === CHART_PERIODS.hourly && "isActiveBtn"
        }`}
        onClick={() => setActiveButton(CHART_PERIODS.hourly)}
        type="button"
      >
        {t("periods.hourly")}
      </button>
      <button
        className={`weekDaysBtnTitle Medium ${
          activeButton === CHART_PERIODS.daily && "isActiveBtn"
        }`}
        onClick={() => setActiveButton(CHART_PERIODS.daily)}
        type="button"
      >
        {t("periods.daily")}
      </button>
      <button
        className={`weekDaysBtnTitle Medium ${
          activeButton === CHART_PERIODS.weekly && "isActiveBtn"
        }`}
        onClick={() => setActiveButton(CHART_PERIODS.weekly)}
        type="button"
      >
        {t("periods.weekly")}
      </button>
      {disableMonthlyButton ? (
        ""
      ) : (
        <button
          className={`weekDaysBtnTitle Medium ${
            activeButton === CHART_PERIODS.monthly && "isActiveBtn"
          }`}
          onClick={() => setActiveButton(CHART_PERIODS.monthly)}
          type="button"
        >
          {t("periods.monthly")}
        </button>
      )}
    </div>
  );
};

ChartPeriodSelectButton.propTypes = {
  activeButton: PropTypes.oneOf([
    CHART_PERIODS.hourly,
    CHART_PERIODS.daily,
    CHART_PERIODS.weekly,
    CHART_PERIODS.monthly,
  ]).isRequired,
  setActiveButton: PropTypes.func.isRequired,
  className: PropTypes.string,
  disableMonthlyButton: PropTypes.bool,
};

export default ChartPeriodSelectButton;
